import { IonModal, IonToolbar, IonSearchbar, IonButtons, IonButton, IonTitle, IonLabel, IonIcon } from '@ionic/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { closeOutline } from 'ionicons/icons';
import { locale } from '../locales/local';

interface FavoriteSearchModalProps {
	show: boolean;
	onClose?: any;
	searchHandler?: any;
	searchTypeHandler?: any;
}

const FavoriteSearchModal: React.FC<FavoriteSearchModalProps> = ({ show, onClose, searchHandler, searchTypeHandler, ...props }) => {
	const inputRef: React.RefObject<HTMLIonSearchbarElement> = useRef(null);

	const options = useMemo(
		() => [
			{ title: locale.memo.media, type: 'media' },
			{ title: locale.memo.links, type: 'links' },
			{ title: locale.memo.files, type: 'files' },
			{ title: locale.memo.music, type: 'music' },
			{ title: locale.memo.chat, type: 'chats' },
			{ title: locale.memo.audio, type: 'audio' },
			{ title: locale.memo.todo, type: 'todos' },
			{ title: locale.memo.places, type: 'places' },
		],
		[]
	);
	const [searchText, setSearchText] = useState<string>('');

	useEffect(() => {
		if (show) {
			// setTimeout(() => {
			// 	inputRef.current?.setFocus();
			// }, 100);
			// setTimeout(() => {
			// 	inputRef.current?.setFocus();
			// }, 500);
		} else {
			setSearchText('');
		}
	}, [show, inputRef]);

	const onCloseCallBack = useCallback(
		(event: any) => {
			onClose && onClose();
		},
		[onClose]
	);

	return (
		<IonModal showBackdrop={false} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="favorite-search-modal" animated={true}>
			<IonToolbar className="header">
				<IonButtons slot="start">
					<IonButton fill="clear" onClick={onClose}>
						<IonIcon icon={closeOutline} />
					</IonButton>
				</IonButtons>
				<IonTitle>{locale.favorite_search.title}</IonTitle>
			</IonToolbar>

			<IonSearchbar ref={inputRef} animated={true} mode="ios" style={{ color: '#000000' }} debounce={1} value={String(searchText)} onIonChange={(e) => {}} showCancelButton="never"></IonSearchbar>

			<div className="tools-container">
				{options.map((item, key) => {
					return (
						<IonLabel className="tool-item" key={key}>
							{item.title}
						</IonLabel>
					);
				})}
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => {
	return {
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		searchContact: state.searchContact,
	};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteSearchModal);

import React from 'react';
import { IonPage, IonButton, IonToolbar, IonCol, IonContent, IonGrid, IonImg, IonItem, IonLabel, IonRow, IonThumbnail, IonCardSubtitle, IonInput, IonList, IonText, IonLoading } from '@ionic/react';
import './style.scss';
import { TopNavbar } from '../common/header/topbar';
import { fetchProfilePic } from '../../helpers/common';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { UPDATE_CONTACT_RESET_VALUES, UPDATE_CONTACT_SHOW_LOADER } from '../../redux/constants/searchUser';
import { updateContact } from '../../redux/actions/addContact';
import { connect } from 'react-redux';
import store from '../../redux/store';
import { apiService } from '../../services/apiService';
import { locale } from '../../locales/local';

class ContactView extends React.Component<iProps, iState> {
	constructor(props: iProps) {
		super(props);

		const locationState = props.location.state,
			user = locationState?.user,
			actionType = locationState?.actionType;

		let alias: string = '',
			tags: string = '',
			remarks: string = '';

		if (actionType === 'edit' && user) {
			alias = user.alias || user.userId || user.username;
			remarks = user.remarks || '';
			tags = user.tags && user.tags.length > 0 ? user.tags.join(' ') : '';
		}
		
		this.state = { alias: alias, tags: tags, remarks: remarks, actionType: actionType, user: user };
	}

	componentDidUpdate() {
		if (this.props.addContacts.showSentToast) {
			store.dispatch({ type: UPDATE_CONTACT_RESET_VALUES });
			this.props.history.push('/contacts');
		}
	}

	async updateContact() {
		const { user, alias, tags, remarks } = this.state,
			tagsArr = tags ? tags.split(' ') : [];

		let contacts = await apiService.getConversations();

		for (let index = 0; index < contacts.length; index++) {
			let contact = contacts[index];
			if (contact._id === user?._id) {
				contact.username = alias;
				contact.remarks = remarks;
				contact.tags = tagsArr;
			}
		}
		this.props.updateContact({ contactId: user?._id, contacts: contacts }, true);
	}

	render() {
		const { actionType, user, alias, tags, remarks } = this.state;
		const myProps = { ...this.props, showBack: true, hideSearchBar: true };

		return (
			<IonPage>
				<IonToolbar className="toolbar-wrapper">
					<TopNavbar {...myProps} pageTitle={actionType === 'add' ? locale.contacts.contact_view.add_contact : locale.contacts.contact_view.edit_contact} unreadCount={0} searchTypeHandler={() => {}} searchHandler={() => {}} />
				</IonToolbar>
				<IonContent className="main-content-profile contact-view has-bottom-navbar has-topbar-with-searchbar">
					<div className="sec-1">
						<IonGrid style={{ cursor: 'pointer', borderBottom: '2px solid #f2f2f2' }}>
							<IonRow>
								<IonCol>
									<IonItem detail={false} lines="none">
										<IonThumbnail className="profile-thumb-user" style={{ width: 80, height: 80 }}>
											<IonImg title="Change" src={user?.profilePhoto ? user?.profilePhoto : fetchProfilePic(user?.userId, 'contact')} />
										</IonThumbnail>
										<div className="status-button-box">
											<IonLabel style={{ margin: '12px', textAlign: 'left', fontWeight: '900' }} color="light">
												{user?.name}
											</IonLabel>
										</div>
										<IonThumbnail style={{ width: 50, height: 50 }}>
											<IonImg src="assets/img/avatars/xekrom.png" />
										</IonThumbnail>
									</IonItem>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonItem detail={false} lines="none">
										<IonLabel className="view-profile-label" style={{ textAlign: 'left', minHeight: '10px' }} color="light">
											{locale.contacts.contact_view.name} {user?.name}
										</IonLabel>
									</IonItem>
									<IonItem detail={false} lines="none">
										<IonLabel className="view-profile-label" style={{ textAlign: 'left' }} color="light">
											{locale.contacts.contact_view.chat_id} {user?.username}
										</IonLabel>
									</IonItem>
									<IonItem detail={false} lines="none">
										<IonLabel className="view-profile-label" style={{ textAlign: 'left' }} color="light">
											{locale.contacts.contact_view.region} {user?.region}
										</IonLabel>
									</IonItem>
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
					<div className="sec-2">
						<IonList lines="full" style={{ cursor: 'pointer' }}>
							<IonItem className="label-value-row-item">
								<div className="label-value-row">
									<IonText slot="start" className="view-profile-label" style={{ textAlign: 'left', minHeight: '10px' }} color="light">
										{locale.contacts.contact_view.whats_up}{' '}
									</IonText>
									<IonLabel className="view-profile-label" style={{ textAlign: 'left', flex: 1, paddingLeft: 10 }} color="light">
										{user?.status ? user?.status : locale.contacts.contact_view.welcome_message}{' '}
									</IonLabel>
								</div>
							</IonItem>

							<IonItem className="label-value-row-item">
								<div className="label-value-row">
									<IonText slot="start" className="view-profile-label" style={{ textAlign: 'left', minHeight: '10px' }} color="light">
										{locale.contacts.contact_view.from}{' '}
									</IonText>
									<IonLabel className="view-profile-label" style={{ textAlign: 'left', flex: 1, paddingLeft: 10 }} color="light">
										{user?.region ? user?.region : locale.contacts.contact_view.planet_earth}
									</IonLabel>
								</div>
							</IonItem>
						</IonList>

						{actionType === 'edit' && (
							<div className="formContainer">
								<IonList className="bchatList" style={{ background: 'transparent' }}>
									<IonCardSubtitle style={{ marginTop: '5%', padding: 0, marginBottom: 5 }}>
										{locale.contacts.contact_view.set_alias_for} {user?.name}
									</IonCardSubtitle>
									<IonRow>
										<IonCol style={{ border: 'none' }} className="add-contact-col">
											<IonItem lines="none">
												<IonInput disabled={false} value={alias} onIonChange={(e) => this.setState({ alias: e.detail.value! })}></IonInput>
											</IonItem>
										</IonCol>
									</IonRow>
									<IonCardSubtitle style={{ marginTop: '5%', padding: 0, marginBottom: 5 }}>
										{locale.contacts.contact_view.set_tags_for} {user?.name}
									</IonCardSubtitle>
									<IonRow>
										<IonCol style={{ border: 'none' }} className="add-contact-col">
											<IonItem lines="none">
												<IonInput disabled={false} value={tags} onIonChange={(e) => this.setState({ tags: e.detail.value! })}></IonInput>
											</IonItem>
										</IonCol>
									</IonRow>
									<IonCardSubtitle style={{ marginTop: '5%', padding: 0, marginBottom: 5 }}>
										{locale.contacts.contact_view.set_remark_for} {user?.name}
									</IonCardSubtitle>
									<IonRow>
										<IonCol style={{ border: 'none' }} className="add-contact-col">
											<IonItem lines="none">
												<IonInput disabled={false} value={remarks} onIonChange={(e) => this.setState({ remarks: e.detail.value! })}></IonInput>
											</IonItem>
										</IonCol>
									</IonRow>
								</IonList>
							</div>
						)}
					</div>
					<div key={Math.random()} className="sec-4">
						<IonButton
							style={{ margin: '30px auto 20px  auto', width: '60%' }}
							expand="block"
							color="danger"
							onClick={(e) => {
								if (actionType === 'add') {
									this.props.history.push('/contacts/add', user);
								} else if (actionType === 'edit') {
									this.updateContact();
								}
							}}
						>
							{actionType === 'add' ? locale.contacts.contact_view.add : locale.contacts.contact_view.save}
						</IonButton>
					</div>
				</IonContent>

				<IonLoading isOpen={this.props.addContacts.isLoading} onDidDismiss={() => store.dispatch({ type: UPDATE_CONTACT_SHOW_LOADER, payload: false })} message={locale.global.please_wait} duration={5000} />

				<BottomNavbar unreadCount={0} {...this.props} />
			</IonPage>
		);
	}
}

interface iProps {
	addContacts: any;
	updateContact: any;
	user: any;
	userId: string;
	status: string;
	imgSrc: string;
	handlerText: string;
	actionHandler: any;
	history: any;
	location: any;
}
interface iState {
	alias: string;
	tags: string;
	remarks: string;
	actionType: string;
	user: any;
}

const mapStateToProps = (state: any) => {
	return { addContacts: state.addContacts };
};
const mapDispatchToProps = (dispatch: any) => ({
	updateContact: (payload: String) => dispatch(updateContact(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContactView);

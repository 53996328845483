
import React from 'react';
import './style.css';
import { Redirect, RouteComponentProps } from 'react-router';

interface iProps extends RouteComponentProps<{ name: string }> { }
interface iState { }

export default class NoMatchPage extends React.Component<iProps, iState> {

    render() {
        let content: any;

        if(window.location.hostname.length > 1 && window.location.hostname.startsWith('/')) {
            content = (<></>);
        }
        else {
            content = ( <Redirect to="/" /> );
        }

        return content;
    }
}



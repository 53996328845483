import React from 'react';
import { IonContent, IonLabel, IonPage,IonCard,IonCardHeader,IonCardSubtitle ,IonCardTitle,IonCardContent} from '@ionic/react';
 
import moment from 'moment';
 
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { connect } from 'react-redux';
 
import ReportJSON from '../../tests/report.json'
 
import { IonModal, IonButton,IonInfiniteScroll,IonInfiniteScrollContent } from '@ionic/react';

interface iProps  {}

interface iState {
	showModal: boolean;
	text: string;
}
class TestReportPage extends React.Component<iProps, iState> {
	constructor(props: iProps) {
		super(props);
		this.state = {
			showModal: false,
			text: ""
		};
	}

	renderDetails(result: any,status: any){
		let text = result.map((item: any)=>{
			return (
				<IonCard style={{width:'100%',height:'100%',minHeight:item.status=='passed' ? 60:300}}>
					<IonCardHeader>
						 
						<IonCardTitle>{item.ancestorTitles[0]} {item.title} - {item.status}</IonCardTitle>
					 
					</IonCardHeader>
					{item.status==="failed" &&
						<IonCardContent>
						   {item.failureMessages[0]}
						</IonCardContent>
				 	}
       			 </IonCard>
			)
		})
		this.setState({text:text,showModal:true})
	}

	displayPassedResult(status: any){
		const msg =	ReportJSON.testResults[0].assertionResults;
		console.log('messagge',msg)
		let result= msg.filter((item)=>{
			return (item.status == status || status=="all");
		})

		if(result.length>0){
			this.renderDetails(result,status);
		}
		else{
			this.setState({text:'',showModal:false})
		}

	}

	render() {
		const {showModal,text} = this.state;
		return (
			<IonPage className="account-setting-page">
			 <IonModal isOpen={showModal} cssClass='my-custom-class'>
				 <IonContent>
				 <IonButton onClick={() => this.setState({showModal:false,text:''})}>Close Modal</IonButton>
				 <IonInfiniteScroll>
      <IonInfiniteScrollContent
        loadingSpinner="bubbles"
        loadingText="Loading more data...">
				 {text}
				 </IonInfiniteScrollContent>
    </IonInfiniteScroll>
					</IonContent>
				 
				
			</IonModal>
				<IonContent className="main-content-profile has-bottom-navbar has-topbar">
					<IonList className="profile-list-sec2" lines="none">

                        <IonItem lines="none" button detail={true} onClick={(e)=>{
							this.displayPassedResult("all")
						}} >
                             <IonLabel slot="start" color="light">
								<p>{`Result`}</p>
							</IonLabel>
                            <p className="edit-profile-p" slot="end">
                                 {ReportJSON.success ? 'Success' : 'Failed'}
							</p>
						</IonItem>

                        <IonItem lines="none">
                             <IonLabel slot="start" color="light">
								<p>{`Started At`}</p>
							</IonLabel>
                            <p className="edit-profile-p" slot="end">
                                 {moment(ReportJSON.startTime).format('dddd, MMMM Do YYYY, h:mm:ss a')}
							</p>
						</IonItem>



                        <IonItem lines="none" >
                             <IonLabel slot="start" color="light">
								<p>{`No of total tests suite`}</p>
							</IonLabel>
                            <p className="edit-profile-p" slot="end">
                                 {ReportJSON.numTotalTestSuites}
							</p>
						</IonItem>

						<IonItem button lines="none" detail={true} onClick={(e)=>{
							this.displayPassedResult("failed")
						}}  >
							<IonLabel slot="start" color="light">
								<p>{`No of test failed suite`}</p>
							</IonLabel>
                            <p className="edit-profile-p" slot="end">
                                 {ReportJSON.numFailedTestSuites}
							</p>

						</IonItem>

						<IonItem lines="none" button detail={true} onClick={(e)=>{
							this.displayPassedResult("failed")
						}} >
                             <IonLabel slot="start" color="light">
								<p>{`No of failed tests`}</p>
							</IonLabel>
                            <p className="edit-profile-p" slot="end">
                                 {ReportJSON.numFailedTests}
							</p>
						</IonItem>
                        <IonItem lines="none">
                             <IonLabel slot="start" color="light">
								<p>{`No of passed tests suite`}</p>
							</IonLabel>
                            <p className="edit-profile-p" slot="end">
                                 {ReportJSON.numPassedTestSuites}
							</p>
						</IonItem>

                        <IonItem lines="none" button  detail={true} onClick={(e)=>{
							this.displayPassedResult("passed")
						}}>
                             <IonLabel slot="start" color="light">
								<p>{`No of passed tests`}</p>
							</IonLabel>
                            <p className="edit-profile-p" slot="end">
                                 {ReportJSON.numPassedTests}
							</p>
						</IonItem>

                        <IonItem lines="none" button detail={true}>
                             <IonLabel slot="start" color="light" onClick={(e)=>{
							this.displayPassedResult("passed")
						}} >
								<p>{`No of passed pending tests suite`}</p>
							</IonLabel>
                            <p className="edit-profile-p" slot="end">
                                 {ReportJSON.numPendingTestSuites}
							</p>
						</IonItem>
                        <IonItem lines="none">
                             <IonLabel slot="start" color="light">
								<p>{`No of pending tests`}</p>
							</IonLabel>
                            <p className="edit-profile-p" slot="end">
                                 {ReportJSON.numPendingTests}
							</p>
						</IonItem>
						 
					</IonList>
				</IonContent>
			 
			</IonPage>
		);
	}
}

 

export default TestReportPage;

import store from '../redux/store';
import { apiService } from './apiService';
import { DASHBOARD_INIT, DASHBOARD_PUSH_CONTACT, DASHBOARD_LAST_MESSAGE } from '../redux/constants/dashboard';
import { Constants } from '../constants';
import { info, prefixPhotos, isBlank } from '../helpers/common';
import { createNotification } from '../components/Notifications/notifications';

export const controlMessageService = {
	handler: async (message: any, id: any = undefined) => {
		info(`controlMessageService::processing control message ${message.action} id`, id);

		if(message.action !== 'delete') {
			const user = await apiService.me();

			let contact: any;

			switch (message.action) {
				case Constants.CONTROL.selfUpdate:
					info('controlMessageService::cmSelfUpdatehandler: updating user details', message.data);
					await apiService.updateUser(await prefixPhotos(message.data));
					break;

				case Constants.CONTROL.contactRequest:
					info('controlMessageService::handler::cmContactRequest: adding unconfirmed contact', message.data.userId);
					contact = await apiService.getContactBy_id(message.data._id);

					if (isBlank(contact)) {
						await apiService.addContact(await prefixPhotos(message.data));
					}

					store.dispatch({ type: DASHBOARD_PUSH_CONTACT, payload: message.data });
					createNotification({ from: 'control', body: `${message.data.userId} wants to connect with you.` }, true);
					break;

				case Constants.CONTROL.contactConfirmed:
					info('controlMessageService::handler::cmContactConfirmed: confirming contact', message.data.userId);

					contact = {
						...(await prefixPhotos(message.data)),
						lastMessage: {},
						firstMessage: {},
						unreadCount: 0,
						unreadMessages: [],
					};

					await apiService.confirmContact(contact);
					store.dispatch({ type: DASHBOARD_INIT, payload: await apiService.getConversations() });

					break;

				case Constants.CONTROL.contactDenied:
					info('controlMessageService::handler::cmContactDenied: denying contact', message.data.userId);
					await apiService.deleteContact(message.data);
					store.dispatch({ type: DASHBOARD_LAST_MESSAGE, payload: message.data });
					break;

				case Constants.CONTROL.contactBlock:
					info('controlMessageService::handler::cmContactBlock: blocking contact', message.data.userId);
					await apiService.blockContact(await prefixPhotos(message.data));
					store.dispatch({ type: DASHBOARD_LAST_MESSAGE, payload: message.data });
					break;

				case Constants.CONTROL.contactUnblock:
					info('controlMessageService::handler::cmContactUnblock: unblocking contact', message.data.userId);
					await apiService.unblockContact(await prefixPhotos(message.data));
					store.dispatch({ type: DASHBOARD_LAST_MESSAGE, payload: message.data });
					break;

				case Constants.CONTROL.contactChange:
					info('controlMessageService::handler::cmContactChange: changing contact details', message.data);
					await apiService.updateContact(await prefixPhotos(message.data));
					store.dispatch({ type: DASHBOARD_LAST_MESSAGE, payload: message.data });
					break;

				case Constants.CONTROL.contactDelete:
					info('controlMessageService::handler::cmContactDelete: deleting contact', message.data.userId);
					await apiService.deleteContact(message.data);
					store.dispatch({ type: DASHBOARD_LAST_MESSAGE, payload: message.data });
					break;

				case Constants.CONTROL.groupAdd:
					info('controlMessageService::handler::cmGroupAdd: adding group', message.data.groupname);

					/**
					 * first, add the group, then evaluate the members
					 * add any members that are not contacts to the contacts db
					 * with a type of groupMember
					 * ... then do the rest
					 */

					if (!user.groups.find((_group: any) => _group._id === message.data._id)) {
						await apiService.addGroup(message.data);
						store.dispatch({ type: DASHBOARD_INIT, payload: { conversation: await apiService.getConversations() } });
					}

					break;

				case Constants.CONTROL.groupChange:
					info('controlMessageService::handler::cmGroupChange: changing group', message.data.groupname);

					/**
					 * first, add the group, then evaluate the members
					 * add any members that are not contacts to the contacts db
					 * with a type of groupMember
					 * ... then do the rest
					 */

					if (!user.groups.find((_group: any) => _group._id === message.data._id)) {
						await apiService.updateGroup(prefixPhotos(message.data));
						store.dispatch({ type: DASHBOARD_INIT, payload: { conversation: await apiService.getConversations() } });
					}

					break;

				case Constants.CONTROL.groupDelete:
					info('controlMessageService::handler::cmGroupDelete: deleting group', message.data.userId);

					// TODO: This needs to be redone.
					/**
					 * first, add the group, then evaluate the members
					 * delete any members that are not contacts from the contacts db
					 * with a type of groupMember
					 * ... then do the rest
					 */

					/*if (user.groups.find((_group: any) => _group.jid === message.data.jid)) {
						let groups: any = await apiService.getGroups(),
							contacts: any = await apiService.getConversations(),
							groupIndex = groups.findIndex((_group: any) => _group.jid === message.data.jid);

						groups.splice(groupIndex, 1);
						user.contacts = [...contacts, ...groups];

						await apiService.deleteGroup(message.data);
						store.dispatch({ type: DASHBOARD_INIT, payload: user.contacts });
					} else {
						logError(`controlMessageService::handler::groupDelete: attempt to delete non-existent group ${message.data.jid}`);
					}*/

					break;

				case Constants.CONTROL.moment:
					break;

				case Constants.CONTROL.messageUpdated:
					await apiService.updateMessage({...message.data, fromControl: true });
					break;

				case Constants.CONTROL.messageRead:
					await apiService.handleUpdateReadStatus({ ids: message.data.ids });
					break;

				case Constants.CONTROL.mediaChanged:
					await apiService.updateMedia(message.data);
					break;

				default:
					info(`controlMessageService::handler::not handled: ${message.type} --- ${message.action}`);
					break;
			}
		}
		
		info(`controlMessageService::handler `, await apiService.ackControl({ id: id }));
	},
};

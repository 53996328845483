export const REGISTER_REQUESTED = "REGISTER_REQUESTED";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const USERNAME_AVAILABLE = "USERNAME_AVAILABLE";
export const USERNAME_UNAVAILABLE = "USERNAME_UNAVAILABLE";
export const USERNAME_ERROR = "USERNAME_ERROR";

export const SET_REGISTER_USERNAME = "SET_REGISTER_USERNAME";
export const SET_REGISTER_EMAIL = "SET_REGISTER_EMAIL";
export const SET_REGISTER_PASSWORD = "SET_REGISTER_PASSWORD";
export const SET_REGISTER_CPASSWORD = "SET_REGISTER_CPASSWORD";

import React from 'react';
import { IonToggle, IonPage, IonButton, IonToolbar, IonActionSheet, IonContent, IonItem, IonList, IonLabel } from '@ionic/react';
import './style.scss';
import { connect } from 'react-redux';
import { sendContactRequest } from '../../redux/actions/addContact';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { TopNavbar } from '../common/header/topbar';
import EnumService from '../../services/enumService';
import { locale } from '../../locales/local';
import { info } from '../../helpers/common';

class ManageContact extends React.Component<iProps, iState> {
	contactsPrivacySettings = [
		{ title: locale.contacts.manage_contact?.moment?.title, description: locale.contacts.manage_contact.moment?.description, value: 'moments' },
		{ title: locale.contacts.manage_contact?.chats_only?.title, description: locale.contacts.manage_contact?.chats_only?.description, value: 'chats' },
	];

	user: any;

	visiblesFields = {
		personalMessage: false,
		tags: false,
		alias: false,
		privacySetting: false,
		contactSetting: false,
	};

	constructor(props: iProps) {
		super(props);
		this.user = this.props.location?.state?.data;

		this.state = {
			muteNotification: false,
			stickOnTop: false,
			starred: false,
			blockContact: false,
			showActionSheetFor: null,
		};
	}

	componentDidUpdate() {}

	_renderSwitchItem(title: any, value: any, onChange: any) {
		return (
			<div className={'swtich-item'}>
				<IonToggle className="toggle-customised" mode="ios" value={value} onChange={onChange} />
				<IonLabel>{title}</IonLabel>
			</div>
		);
	};

	_renderActionSheet() {
		const { showActionSheetFor } = this.state;
		let headerTitle: any = null;
		let buttons = [];

		if (showActionSheetFor === EnumService.ManageContactActionsSheetOptions.DeleteContact) {
			headerTitle = locale.contacts.manage_contact.delete_contact_header;
			buttons.push({
				text: locale.contacts.manage_contact.delete_contact,
				role: 'destructive',
				handler: () => {
					info('Delete clicked');
				},
			});

			buttons.push({
				text: locale.contacts.manage_contact.cancel,
				handler: () => {
					info('Cancel clicked');
				},
			});
		} else if (showActionSheetFor === EnumService.ManageContactActionsSheetOptions.BlockContact) {
			headerTitle = locale.contacts.manage_contact.block_headsup;
			buttons.push({
				text: locale.global.ok,
				role: 'destructive',
				handler: () => {
					this.setState({ blockContact: true });
				},
			});

			buttons.push({
				text: locale.global.cancel,
				handler: () => {
					info('Cancel clicked');
				},
			});
		} else if (showActionSheetFor === EnumService.ManageContactActionsSheetOptions.ClearChatHistory) {
			buttons.push({
				text: locale.contacts.manage_contact.clear_chat_history,
				role: 'destructive',
				handler: () => {
					this.setState({ blockContact: true });
				},
			});

			buttons.push({
				text: locale.global.cancel,
				handler: () => {
					info('Cancel clicked');
				},
			});
		} else if (showActionSheetFor === EnumService.ManageContactActionsSheetOptions.ReportContact) {
			buttons.push({
				text: locale.contacts.manage_contact.lewd_content,
				handler: () => {},
			});
			buttons.push({
				text: locale.contacts.manage_contact.fraud,
				handler: () => {},
			});
			buttons.push({
				text: locale.contacts.manage_contact.account_compromised,
				handler: () => {},
			});
			buttons.push({
				text: locale.contacts.manage_contact.bot_account,
				handler: () => {},
			});

			buttons.push({
				text: locale.global.cancel,
				handler: () => {
					info('Cancel clicked');
				},
			});
		} else if (showActionSheetFor === EnumService.ManageContactActionsSheetOptions.PrivacyContact) {
			headerTitle = locale.contacts.manage_contact.block_headsup;
			buttons.push({
				text: locale.global.ok,
				role: 'destructive',
				handler: () => {},
			});

			buttons.push({
				text: locale.global.cancel,
				handler: () => {
					info('Cancel clicked');
				},
			});
		}

		return headerTitle ? (
			<IonActionSheet mode="ios" header={headerTitle} isOpen={this.state.showActionSheetFor != null} onDidDismiss={() => this.setState({ showActionSheetFor: null })} cssClass="manage-contact-action-sheet" buttons={buttons}></IonActionSheet>
		) : (
			<IonActionSheet mode="ios" isOpen={this.state.showActionSheetFor != null} onDidDismiss={() => this.setState({ showActionSheetFor: null })} cssClass="manage-contact-action-sheet" buttons={buttons}></IonActionSheet>
		);
	};

	render() {
		return (
			<IonPage className="manage-contact-page">
				<IonToolbar className="toolbar-wrapper">
					<TopNavbar {...this.props} pageTitle={'Manage Contact'} showBack={true} hideSearchBar={true} />
				</IonToolbar>

				<IonContent className="has-bottom-navbar has-topbar">
					<IonList className="manage-contact-options-list" lines="none">
						<IonItem detail={true} lines="none">
							<IonLabel>{locale.contacts.contact_view.edit_contact}</IonLabel>
						</IonItem>

						<IonItem
							detail={true}
							lines="none"
							onClick={() => {
								this.setState({ showActionSheetFor: EnumService.ManageContactActionsSheetOptions.PrivacyContact });
							}}
						>
							<IonLabel>{locale.global.privacy}</IonLabel>
						</IonItem>

						<div className="setting-toggle-container">
							{this._renderSwitchItem('Mute Notifications', this.state.muteNotification, () => {
								this.setState({ muteNotification: !this.state.muteNotification });
							})}
							<div className="vertical-line"></div>
							{this._renderSwitchItem('Stick on Top', this.state.stickOnTop, () => {
								this.setState({ stickOnTop: !this.state.stickOnTop });
							})}
							<div className="vertical-line"></div>
							{this._renderSwitchItem('Starred', this.state.starred, () => {
								this.setState({ starred: !this.state.starred });
							})}
						</div>

						<IonItem
							detail={true}
							lines="none"
							onClick={() => {
								this.setState({ showActionSheetFor: EnumService.ManageContactActionsSheetOptions.ClearChatHistory });
							}}
						>
							<IonLabel>{locale.contacts.manage_contact.clear_chat_history}</IonLabel>
						</IonItem>

						<IonItem detail={true} lines="none">
							<IonLabel>{locale.contacts.manage_contact.disappearing_message}</IonLabel>
						</IonItem>

						<IonItem className="swtich-item" detail={false} lines="none">
							<IonLabel>{locale.global.block}</IonLabel>
							<IonToggle
								className="toggle-customised"
								checked={this.state.blockContact}
								slot="end"
								onClick={(e) => {
									if (!this.state.blockContact) {
										this.setState({ showActionSheetFor: EnumService.ManageContactActionsSheetOptions.BlockContact });
									} else {
										this.setState({ blockContact: false });
									}
								}}
							></IonToggle>
						</IonItem>

						<IonItem
							detail={true}
							lines="none"
							onClick={() => {
								this.setState({ showActionSheetFor: EnumService.ManageContactActionsSheetOptions.ReportContact });
							}}
						>
							<IonLabel>{locale.global.report}</IonLabel>
						</IonItem>
					</IonList>

					<div className="action-btn-container">
						<IonButton
							className="action-btn"
							expand="block"
							onClick={(e) => {
								this.setState({ showActionSheetFor: EnumService.ManageContactActionsSheetOptions.DeleteContact });
							}}
						>
							{locale.global.delete}
						</IonButton>
					</div>
				</IonContent>
				<BottomNavbar unreadCount={0} {...this.props} />

				{this._renderActionSheet()}
			</IonPage>
		);
	}
}

interface iProps {
	addContacts: any;
	sendContactRequest: Function;
	isLoading: boolean;
	history: any;
	location: any;
	confirmContact: Function;
}
interface iState {
	muteNotification?: boolean;
	stickOnTop?: boolean;
	starred?: boolean;
	blockContact: boolean;
	showActionSheetFor: string | null;
}
const mapStateToProps = (state: any) => {
	return {
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		dashboard: state.dashboard,
		addContacts: state.addContacts,
	};
};
const mapDispatchToProps = (dispatch: any) => ({
	sendContactRequest: (payload: String) => dispatch(sendContactRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageContact);

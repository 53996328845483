export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_INIT_CONTACTS = "LOGIN_INIT_CONTACTS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const REGISTER = "USER_REGISTER";

export const USER_AUTHENTICATED = "USER_AUTHENTICATED";

export const SET_USERNAME = "SET_USERNAME";
export const SET_PASSWORD = "SET_PASSWORD";
import React from 'react';
import { IonContent, IonLabel, IonPage } from '@ionic/react';
import './style.scss';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { connect } from 'react-redux';
import { pageLoading, updateUser } from '../../redux/actions/userProfile';
import { locale } from '../../locales/local';
import {getInternalStorage, logoutUser } from '../../helpers/common';
import { TopNavbar } from '../common/header/topbar';

interface iProps extends RouteComponentProps<{ name: string }> {}

interface iState {}
class SettingsPage extends React.Component<iProps, iState> {
	cookies:any = getInternalStorage();

	render() {
		return (
			<IonPage className="account-setting-page">
				<TopNavbar {...this.props} pageTitle={locale.settings.title} showBack={true} hideSearchBar={true} />

				<IonContent className="main-content-profile has-bottom-navbar has-topbar">
					<IonList className="profile-list-sec2" lines="none">
						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p>{locale.settings.account_sec}</p>
							</IonLabel>
						</IonItem>

						<IonItem lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p>{locale.settings.parental_mode}</p>
							</IonLabel>
							<p className="edit-profile-p" slot="end">
								{locale.settings.not_enabled}
							</p>
						</IonItem>

						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p>{locale.settings.message_notif}</p>
							</IonLabel>
						</IonItem>

						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p>{locale.global.privacy}</p>
							</IonLabel>
						</IonItem>

						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p>{locale.global.general}</p>
							</IonLabel>
						</IonItem>

						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p>{locale.settings.help_feedback}</p>
							</IonLabel>
						</IonItem>

						<IonItem button lines="none" detail={true} onClick={() => this.setState({ openUploader: true })}>
							<IonLabel slot="start" color="light">
								<p>{locale.global.about}</p>
							</IonLabel>
							<p className="edit-profile-p" slot="end">
								{`${locale.global.app_name} ${locale.global.version_word} ${this.cookies.version}`}
							</p>
						</IonItem>

						<IonItem button lines="none" detail={false} onClick={() => this.setState({ openUploader: true })}>
							<IonLabel className="toolbarTitle" color="light">
								<p>{locale.global.switch_ac}</p>
							</IonLabel>
						</IonItem>

						<IonItem button lines="none" detail={false} onClick={() => logoutUser()}>
							<IonLabel className="toolbarTitle" color="light">
								<p>{locale.global.logout}</p>
							</IonLabel>
						</IonItem>
					</IonList>
				</IonContent>
				<BottomNavbar unreadCount={0} {...this.props} />
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);

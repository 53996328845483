import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonButtons, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import './style.scss';
import { locale } from '../../locales/local';
import { PinturaEditor } from 'react-pintura';
import { info } from '../../helpers/common'

// Import styles and functionalities from `pintura`
import 'pintura/pintura.css';

import {
	// editor
	locale_en_gb,
	createDefaultImageReader,
	createDefaultImageWriter,
	createDefaultShapePreprocessor,
	// plugins
	setPlugins,
	plugin_crop,
	plugin_crop_locale_en_gb,
	plugin_finetune,
	plugin_finetune_locale_en_gb,
	plugin_finetune_defaults,
	plugin_filter,
	plugin_filter_locale_en_gb,
	plugin_filter_defaults,
	plugin_decorate,
	markup_editor_defaults,
	plugin_decorate_locale_en_gb,
	markup_editor_locale_en_gb,
	getEditorDefaults,
} from 'pintura';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_decorate);

const processDefaults = {
		imageReader: createDefaultImageReader(),
		imageWriter: createDefaultImageWriter({
			mimeType: 'image/webp',
			quality: 1,
		}),
	},
	editorDefaults = {
		...getEditorDefaults(),
		...processDefaults,
		enableButtonRevert: true,
		enableButtonExport: false,
		...plugin_finetune_defaults,
		...plugin_filter_defaults,
		...markup_editor_defaults,
		locale: {
			...locale_en_gb,
			...plugin_crop_locale_en_gb,
			...plugin_finetune_locale_en_gb,
			...plugin_filter_locale_en_gb,
			...plugin_decorate_locale_en_gb,
			...markup_editor_locale_en_gb,
		},
		imageCropAspectRatio: 1,
		shapePreprocessor: createDefaultShapePreprocessor(),
	};

interface iProps {
	show: boolean;
	selectedImage: any;
	onClose?: any;
	onSave?: any;
}


const ImageEditor: React.FC<iProps> = ({ show, selectedImage, onClose, onSave }) => {
	const [isUndoButtonDisabled, setIsUndoButtonDisabled] = useState(true);
	const [loaded, setLoaded] = useState(false);
	const pinturaRef: React.RefObject<any> = useRef(null);

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				const element: any = document.querySelector("[title='Revert']");

				if (element) {
					var observer = new MutationObserver((mutations) => {
						mutations?.forEach((mutation) => {
							if (mutation.type === 'attributes') {
								setIsUndoButtonDisabled(element.disabled);
							}
						});
					});

					if (observer) {
						observer.observe(element, {
							attributes: true, //configure it to listen to attribute changes
						});
					}
				}
			}, 4000);

			setTimeout(() => {
				setLoaded(true);
			}, 500);
		} else {
			setLoaded(false);
			setIsUndoButtonDisabled(true);
		}
	}, [show]);

	return (
		<IonModal key="pinturaEditorModal" isOpen={show} onDidDismiss={onClose} swipeToClose={false} showBackdrop={false} cssClass="pintura-editor-modal">
			<IonToolbar mode="ios">
				<IonTitle>{locale.global.photo}</IonTitle>
			</IonToolbar>
			<div className="editor-container">
				{loaded && (
					<PinturaEditor
						key="pinturaEditor"
						{...editorDefaults}
						imageCropAspectRatio={1}
						className="editor"
						ref={pinturaRef}
						src={selectedImage}
						onLoad={(res: any) => console.log('load image', res)}
						onProcess={(result: any) => {
							const file = result?.dest;
							pinturaRef?.current?.editor?.close && pinturaRef?.current?.editor?.close();
							onSave && onSave(file);
						}}
					/>
				)}
			</div>

			<IonToolbar className="save-cancel-btns">
				<IonButtons slot="start">
					<IonButton
						slot="end"
						className="edit-cancel-btn"
						onClick={() => {
							pinturaRef?.current?.editor?.close && pinturaRef?.current?.editor?.close();
							onClose && onClose();
						}}
					>
						{locale.global.cancel}
					</IonButton>
				</IonButtons>
				<IonTitle>
					<IonButton
						disabled={isUndoButtonDisabled}
						fill="clear"
						mode="ios"
						className="undo-btn"
						onClick={() => {
							const element: any = document.querySelector("[title='Revert']");
							if (element) {
								element.click();
							} else {
								pinturaRef?.current?.editor?.history?.undo();
							}
						}}
					>
						{locale.global.undo}
					</IonButton>
				</IonTitle>
				<IonButtons slot="end">
					<IonButton
						className="edit-cancel-btn"
						slot="end"
						onClick={() => {
							pinturaRef?.current?.editor?.processImage();
						}}
					>
						{locale.global.done}
					</IonButton>
				</IonButtons>
			</IonToolbar>
		</IonModal>
	);
};

export default ImageEditor;

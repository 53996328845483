import { REGISTER_REQUESTED, REGISTER_SUCCESS, REGISTER_FAILED, REGISTER_ERROR, USERNAME_ERROR, USERNAME_AVAILABLE, USERNAME_UNAVAILABLE, SET_REGISTER_USERNAME, SET_REGISTER_EMAIL, SET_REGISTER_PASSWORD, SET_REGISTER_CPASSWORD } from '../constants/register';
import { REGISTER_SHOW_LOADER, REGISTER_FORM_ERROR, REGISTER_GENERAL_ERROR, REGISTER_RESET_VALUES } from '../constants/common';
import { info } from '../../helpers/common';
import { locale } from '../../locales/local';

const initialState = {
		isLoggedIn: false,
		hasErrors: false,
		errorMessage: '',
		showLoading: false,
		userIdAvailable: false,
		creatingAccount: false,
		title: locale.register.title,
		userId: '',
		password: '',
		email: '',
		phone: null,
		cPassword: '',
		placeholder_username: locale.register.placeholder.username,
		placeholder_email: locale.register.placeholder.email,
		placeholder_phone: locale.register.placeholder.phone,
		placeholder_pwd: locale.register.placeholder.password,
		placeholder_cpwd: locale.register.placeholder.confirm_password_full,
		loadingText: locale.global.please_wait,
	};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	let returnValue: any;
	switch (action.type) {
		case REGISTER_RESET_VALUES: {
			info(REGISTER_RESET_VALUES);
			returnValue = {
				...state,
				hasErrors: false,
				userIdAvailable: false,
				errorMessage: '',
				showLoading: false,
				creatingAccount: false,
			};
			break;
		}

		case REGISTER_SHOW_LOADER: {
			returnValue = {
				...state,
				showLoading: action.payload,
			};
			break;
		}

		case REGISTER_FORM_ERROR: {
			returnValue = {
				...state,
				showLoading: false,
				hasErrors: true,
				errorMessage: action.payload.errorMessage,
			};
			break;
		}

		case SET_REGISTER_USERNAME: {
			returnValue = { ...state, hasErrors: action.payload.hasErrors, userId: action.payload.userId };
			break;
		}
		case SET_REGISTER_EMAIL: {
			returnValue = { ...state, hasErrors: action.payload.hasErrors, email: action.payload.email };
			break;
		}
		case SET_REGISTER_PASSWORD: {
			returnValue = { ...state, hasErrors: action.payload.hasErrors, password: action.payload.password };
			break;
		}
		case SET_REGISTER_CPASSWORD: {
			returnValue = { ...state, hasErrors: action.payload.hasErrors, cPassword: action.payload.cpassword };
			break;
		}

		case USERNAME_UNAVAILABLE: {
			returnValue = {
				...state,
				isLoading: false,
				userIdAvailable: false,
				hasErrors: true,
				showLoading: false,
				errorMessage: action.payload,
			};
			break;
		}

		case USERNAME_AVAILABLE: {
			returnValue = {
				...state,
				hasErrors: false,
				userIdAvailable: true,
				isLoading: false,
				showLoading: false,
			};
			break;
		}

		case USERNAME_ERROR: {
			returnValue = {
				...state,
				isLoading: false,
				hasErrors: true,
				showLoading: false,
				errorMessage: action.payload.errorMessage,
			};
			break;
		}

		case REGISTER_REQUESTED: {
			returnValue = {
				...state,
				hasErrors: false,
				data: action.payload,
				errorMessage: '',
				showLoading: true,
				creatingAccount: true,
			};
			break;
		}

		case REGISTER_SUCCESS: {
			returnValue = {
				...state,
				hasErrors: false,
				showLoading: false,
				isLoggedIn: true,
				loggedInUser: action.payload,
				errorMessage: '',
				creatingAccount: false,
			};
			break;
		}

		case REGISTER_FAILED: {
			info('REGISTER_FAILED---', action.payload);
			info(action.payload);
			returnValue = {
				...state,
				hasErrors: true,
				isLoading: false,
				showLoading: false,
				creatingAccount: false,
				errorMessage: action.payload.Error?.code ? ` ${action.payload.Error.message}` : locale.global.technical_difficulties,
			};
			break;
		}

		case REGISTER_ERROR: {
			info('REGISTER_ERROR---', action.payload);
			returnValue = {
				...state,
				hasErrors: true,
				data: action.payload,
				showLoading: false,
				creatingAccount: false,
				errorMessage: action.payload,
			};
			break;
		}

		case REGISTER_GENERAL_ERROR: {
			info('REGISTER_FAILED---', action.payload);
			returnValue = {
				...state,
				hasErrors: true,
				showLoading: false,
				creatingAccount: false,
				errorMessage: action.payload.errorMessage,
			};
			break;
		}

		default:
			returnValue = state;
			break;
	}
	return returnValue;
}

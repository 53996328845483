import React, { ReactNode, useCallback } from 'react';
import './style.css';
import { useLongPress } from 'use-long-press';

interface iProps {
	children: ReactNode;
	className?: string;
	threshold?: number;
	onStart?: Function;
	onFinish?: Function;
	onCancel?: Function;
	onLongPress?: Function;
	onClick?: Function;
}

export const LongpressContainer: React.FC<iProps> = ({ children, onStart, onFinish, onCancel, onLongPress, onClick, className, threshold, }) => {
	const callback = useCallback(() => {
		if (onLongPress) onLongPress();
	}, [onLongPress]);

	const bind = useLongPress(callback, {
		threshold: threshold || 800,
		onStart: () => {
			onStart && onStart();
		},
		onFinish: () => {
			onFinish && onFinish();
		},
		onCancel: () => {
			onCancel && onCancel();
		},
	});

	return (
		<div className={className} {...bind} onClick={() => onClick && onClick()}>
			{children}
		</div>
	);
};

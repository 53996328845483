export default class EnumService {
	static ProfileFriendActionTypes = {
		ContactRequestView: 'contact-request-view',
		ContactAdd: 'contact-add',
		ContactView: 'contact-view',
		ChatProfileView: 'chat-profile-view',
	};

	static ContactAddBy = {
		Id: 'Searched ID',
		QrCode: 'Qr Code',
		Group: 'Group',
	};

	static ContactsPageActionType = {
		CreateGroup: 'create-group',
		ForwardMessage: 'forward-message',
		NewChat: 'new-chat',
		ChooseContactForTag: 'choose-contact-for-tag',
	};

	static ChatMessageType = {
		FILE: 'file',
		TEXT: 'text',
		TEXT_REPLY: 'textReply',
		TEXT_RESEND: 'resendText',
		IMAGE: 'image',
		AUDIO: 'audio',
		VIDEO: 'vide',
		IMAGE_REPLY: 'imageReply',
		IMAGE_RESEND: 'resendMedia',
		CONTACT: 'contact',
	};

	static ChatContactsPageActionType = {
		ForwardMessage: 'forward-message',
		ShareContact: 'share-contact',
	};

	static ContactsListMode = {
		ChatContactsList: 'chat-contacts-list',
		NormalContactsList: 'normal-contacts-list',
		ChooseContactsList: 'choose-contacts-list',
		NewRequestContactsList: 'new-request-contacts-list',
		MostRecentContactsList: 'most-recent-contacts-list',
		RecentlyAddedContactsList: 'recently-added-contacts-list',
		RemoveContactListMode: 'remove-contacts-list-mode',
	};

	static MessageSendStatus = {
		PendingSent: 'PendingSent',
		PendingUpload: 'PendingUpload',
		Sent: 'Sent',
		SentFailed: 'SentFailed',
		Received: 'Received',
	};

	static ManageContactActionsSheetOptions = {
		ClearChatHistory: 'clear_chat_history',
		BlockContact: 'block_contact',
		DeleteContact: 'delete_contact',
		PrivacyContact: 'privacy_contact',
		ReportContact: 'report_contact',
	};

	static PubSubEventType = {
		RecallEdit: 'RecallEdit',
		QuickAccessBtnVisibility: 'QuickAccessBtnVisibility',
		ReplyToTheConversation: 'ReplyToTheConversation',
	};
}

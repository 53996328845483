import { IonModal } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { apiService } from '../../services/apiService';
import QrCodeComponent from './QrCodeComponent';
import './style.scss';

interface QuickAccessModalProps {
	show: boolean;
	showMyQrCodeOnly?: boolean;
	onClose?: any;
}

const QuickAccessModal: React.FC<QuickAccessModalProps> = ({ show, showMyQrCodeOnly, onClose }) => {
	const [currentUser, setCurrentUser] = useState<any>(null);
	let user: any;

	apiService.me().then((_user: any) => user = _user);

	useEffect(() => {
		if (show) {
			setCurrentUser(user);
		}
	}, [show, user]);

	const onCloseCallBack = useCallback(
		(event: any) => {
			event.stopPropagation();
			onClose && onClose();
		},
		[onClose]
	);

	return (
		<IonModal animated={false} showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={false} cssClass="quick-access-modal">
			<div className="hotkey-container" onClick={onCloseCallBack}>
				<div className="hotkey-content-container" onClick={onCloseCallBack}>
					<QrCodeComponent loggedInUser={currentUser} onClose={() => {}} />
				</div>
			</div>
		</IonModal>
	);
};

export default QuickAccessModal;

import { IonButton, IonButtons, IonCheckbox, IonIcon, IonItem, IonLabel, IonText, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import './style.scss';
import { addOutline } from 'ionicons/icons';
import { locale } from '../../locales/local';

interface ProjectTaskComponentProps {
	onClose?: any;
}

const ProjectTaskComponent: React.FC<ProjectTaskComponentProps> = ({ onClose }) => {
	const projects = [
		{ title: locale.quickaccess.festival, subtitle: locale.quickaccess.festival_sub },
		{ title: locale.quickaccess.mobile_game, subtitle: locale.quickaccess.mobile_game_sub },
	];

	return (
		<div
			className="project-task-component component-expand-full"
			onClick={(event) => {
				event.stopPropagation();
				onClose && onClose();
			}}
		>
			<IonLabel className="card-title">{locale.quickaccess.tasks}</IonLabel>
			<div className="card" onClick={(event) => event.stopPropagation()}>
				<IonToolbar style={{ '--background': 'transparent' }}>
					<IonTitle slot="start" style={{ textAlign: 'left', paddingLeft: '12px', fontWeight: '900', fontSize: '20px' }}>
						{locale.quickaccess.squirel}
					</IonTitle>
					<IonButtons slot="end">
						<IonButton fill="clear">
							<IonIcon icon={addOutline} slot="icon-only"></IonIcon>
						</IonButton>
					</IonButtons>
				</IonToolbar>
				{projects.map((item, key) => {
					return <TaskItemButton key={key} title={item.title} subtitle={item.subtitle} />;
				})}
			</div>
		</div>
	);
};

export default ProjectTaskComponent;

interface TaskItemButtonProps {
	title: string;
	subtitle: string;
	onClick?: any;
}
const TaskItemButton: React.FC<TaskItemButtonProps> = ({ title, subtitle, onClick }) => {
	return (
		<IonItem lines="none" className="task-item">
			<div className="thumbnail">
				<img alt="person" src={'./assets/img/basquiat.jpg'} />
				<IonText>{locale.quickaccess.due_date}</IonText>
				<IonText>04 / 10 / 21</IonText>
			</div>
			<IonLabel className={'task-item-label'}>
				<h2>MOR茉🐝</h2>
				<IonItem className="inner-item">
					<IonCheckbox> </IonCheckbox>
					<IonLabel>
						<h3>{locale.quickaccess.paint_slinky}</h3>
						<p style={{ padding: '0px' }}>WAER A MASK PLEASE</p>
					</IonLabel>
				</IonItem>
			</IonLabel>
		</IonItem>
	);
};

import { CameraDirection, CameraResultType, CameraSource, Camera } from '@capacitor/camera';

export default class CameraService {
	public capturePhotoFromCamera = (callBack: any) => {
		this.detectWebcam((hasWebcam: boolean) => {
			if (hasWebcam) {
				this.takePhotoFromCamera((photo: any) => {
					callBack && callBack(photo?.dataUrl);
				});
			} else {
				this.triggerUpload('camera', (photo: any) => {
					callBack && callBack(photo);
				});
			}
		});
	};

	public choosePhotoFromAlbum = (callBack: any) => {
		this.triggerUpload('album', (photo: any) => {
			callBack && callBack(photo);
		});
	};

	triggerUpload(type = 'album', callBack: any = null) {
		let input: any = document.createElement('input');
		input.type = 'file';
		input.accept = 'image/png,image/jpeg';
		if (type === 'camera') {
			input.capture = 'environment';
			// input.capture = 'user'; //for front
		} else {
			// input.capture = 'filesystem';
		}
		input.onchange = (event: any) => {
			const file = event.target.files[0];

			//Convert file object to base64 string
			let reader = new FileReader();
			reader.onloadend = async () => {
				const base64String = reader.result as string;
				base64String.replace('data:', '').replace(/^.+,/, '');
				callBack && callBack(base64String);
				input = null;
			};
			reader.readAsDataURL(file);
		};
		setTimeout(function () {
			input.click();
		}, 200);
	}

	async takePhotoFromCamera(callBack: any = null) {
		let allowEditing = false;

		const capturedPhoto = await Camera.getPhoto({
			resultType: CameraResultType.DataUrl,
			source: CameraSource.Camera,
			quality: 100,
			allowEditing,
			height: 512,
			correctOrientation: true,
			preserveAspectRatio: true,
			webUseInput: false,
			direction: CameraDirection.Rear,
		});
		try {
			callBack && callBack(capturedPhoto);
		} catch (e) {}
	}

	detectWebcam = (callback: any) => {
		let md = navigator.mediaDevices;
		if (!md || !md.enumerateDevices) return callback(false);
		md.enumerateDevices().then((devices) => {
			callback(devices.some((device) => 'videoinput' === device.kind));
		});
	};
}

import React, { useMemo, useState } from 'react';
import { IonIcon, IonLabel, IonTabBar, IonTabButton } from '@ionic/react';
import './style.scss';
import { LongpressContainer } from '../../../components/LongpressContainer';
import QuickAccessModal from '../../quickaccess/QuickAccessModal';
import { connect } from 'react-redux';
import { locale } from '../../../locales/local';
import NotificationBadge from '../../../components/NotificationBadge';
import { Link } from 'react-router-dom';

interface ContainerProps {
	unreadCount?: number;
	history?: any;
	dashboard?: any;
}
const defaultProps: ContainerProps = {
	unreadCount: 0,
};
var isPlusButtonLongress = false;

export const BottomNavbar: React.FC<ContainerProps> = (props: any) => {
	const [showQuickAccessModal, setShowQuickAccessModal] = useState(false);
	const extractPath = props.location?.pathname?.replace(/^[/]/, '');

	const newContactRequestCount = useMemo(() => (props.dashboard?.conversations ? props.dashboard.conversations.filter((_conversation: any) => _conversation.status === 'pendingConfirm') : 0), [props]).length;

	return (
		<>
			<IonTabBar key="bottombar" slot="bottom" className={'bottom-tabs-bar '}>
				<IonTabButton className={'bottom-tabs ' + (extractPath === 'auth' ? 'tab-selected' : '')}>
					<Link to="/auth" className="link"></Link>
					<IonIcon className="ionIcon1" src="/assets/icon/tab-chat-selected.svg" color="primary"></IonIcon>
					<IonIcon className="ionIcon2" src="/assets/icon/tab-chat.svg" color="primary"></IonIcon>
					<IonLabel>{locale.bottom_navbar.titles.chat}</IonLabel>
					<NotificationBadge unreadCount={props.unreadCount} />
				</IonTabButton>
				<IonTabButton className={'bottom-tabs ' + (extractPath === 'contacts' ? 'tab-selected' : '')}>
					<Link to="/contacts" className="link"></Link>
					<IonIcon className="ionIcon1" src="/assets/icon/tab-contacts-selected.svg"></IonIcon>
					<IonIcon className="ionIcon2" src="/assets/icon/tab-contacts.svg"></IonIcon>
					<IonLabel>{locale.bottom_navbar.titles.contacts}</IonLabel>
					<NotificationBadge className="dot-circle" showOnlyCircle={true} />
					<NotificationBadge unreadCount={newContactRequestCount} />
				</IonTabButton>

				<IonTabButton className={'bottom-tabs large-btn ' + (extractPath === 'quick-access' || showQuickAccessModal ? 'tab-selected' : '')} href="/login">
					{/* <Link to="/quick-access" className="link"></Link> */}
					<LongpressContainer
						threshold={500}
						onLongPress={() => {
							isPlusButtonLongress = true;
							// props.history?.push('/quick-access-qrcode', { showMyQrCodeOnly: true });
							console.log('onLongPress');
							setShowQuickAccessModal(true);
						}}
						onFinish={() => {
							console.log('onLongPress Finish');
							// setShowQuickAccessModal(false);
							setTimeout(() => {
								isPlusButtonLongress = false;
							}, 2000);
						}}
						onCancel={() => {
							console.log('onLongPress Cancel');
							setShowQuickAccessModal(false);
							isPlusButtonLongress = false;
						}}
						onClick={() => {
							setShowQuickAccessModal(false);
							if (!isPlusButtonLongress) {
								if (props.location?.pathname !== '/quick-access') {
									props.history?.push('/quick-access', {
										previousPath: props.location?.pathname,
									});
								}
							}
						}}
					>
						<IonIcon className="ionIcon1" src="/assets/icon/tab-add-selected.svg"></IonIcon>
						<IonIcon className="ionIcon2" src="/assets/icon/tab-add.svg"></IonIcon>
					</LongpressContainer>
					<NotificationBadge className="dot-circle" showOnlyCircle={true} />
				</IonTabButton>

				<IonTabButton className={'bottom-tabs ' + (extractPath === 'discover' ? 'tab-selected' : '')}>
					<Link to="/discover" className="link"></Link>
					<IonIcon className="ionIcon1" src="/assets/icon/tab-discover-selected.svg"></IonIcon>
					<IonIcon className="ionIcon2" src="/assets/icon/tab-discover.svg"></IonIcon>
					<IonLabel>{locale.bottom_navbar.titles.discover}</IonLabel>
					<NotificationBadge className="dot-circle" showOnlyCircle={true} />
				</IonTabButton>
				<IonTabButton className={'bottom-tabs ' + (extractPath === 'profile' ? 'tab-selected' : '')}>
					<Link to="/profile" className="link"></Link>
					<IonIcon className="ionIcon1" src="/assets/icon/tab-me-selected.svg"></IonIcon>
					<IonIcon className="ionIcon2" src="/assets/icon/tab-me.svg"></IonIcon>
					<IonLabel>{locale.bottom_navbar.titles.me}</IonLabel>
					<NotificationBadge className="dot-circle" showOnlyCircle={true} />
				</IonTabButton>
			</IonTabBar>

			<QuickAccessModal
				show={showQuickAccessModal}
				onClose={() => {
					setShowQuickAccessModal(false);
				}}
			/>
		</>
	);
};

BottomNavbar.defaultProps = defaultProps;

const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BottomNavbar);

import React, { useCallback } from 'react';
import { IonImg, IonItemDivider, IonItemGroup, IonLabel, IonList, IonItem, IonThumbnail, IonCheckbox, IonButton, IonIcon } from '@ionic/react';
import './style.scss';
import EnumService from '../../../services/enumService';
import { locale } from '../../../locales/local';
import { removeCircleOutline } from 'ionicons/icons';
import { sortByAlias } from '../../../helpers/common';
import _ from 'lodash';

interface iProps {
	props: any;
	contactListMode?: string;
	hideAlphabetDevider?: boolean;
	contacts: any;
	selectedContacts?: any;
	disabledContacts?: any;
	onContactRemove?: any;
	onContactSelect?: Function;
}

export const ContactsList: React.FC<iProps> = ({ props, contacts, contactListMode, hideAlphabetDevider, selectedContacts, disabledContacts, onContactSelect, onContactRemove }) => {
	const extractContactName = useCallback((contact: any) => {
		return contact?.alias || contact?.username || contact?.userId || '';
	}, []);

	return (
		<IonList className="contact-list-component" mode="md">
			<IonItemGroup className="alpha-contacts">
				{contacts?.length > 0 ? (
					contacts.sort(sortByAlias)?.map((_contact: any, index: number) => {
						let previous = index - 1;

						const contactName = extractContactName(_contact),
							previousContact = contacts[previous],
							prevContactName = previousContact ? extractContactName(previousContact) : null,
							isSelected = _.includes(selectedContacts, _contact?._id),
							isDisabled = _.includes(disabledContacts, _contact?._id);

						return (
							<React.Fragment key={index}>
								{!hideAlphabetDevider && (!previousContact || prevContactName.toLowerCase().charAt(0) !== contactName.toLowerCase().charAt(0)) ? (
									<>
										<IonItemDivider className="cont-divider">
											<IonLabel>{contactName.charAt(0).toUpperCase()}</IonLabel>
										</IonItemDivider>
									</>
								) : null}

								<IonItem
									onClick={() => {
										onContactSelect && onContactSelect(_contact);
										if (
											(contactListMode === EnumService.ContactsListMode.NormalContactsList || contactListMode === EnumService.ContactsListMode.RecentlyAddedContactsList) &&
											_contact.status === 'pendingConfirm'
										) {
											props.history.push('/profile-friend', {
												data: _contact,
												friendProfileActionType: EnumService.ProfileFriendActionTypes.ContactView,
											});
											// this.props.history.push('/contacts/view', {
											// 	user: cont,
											// 	actionType: 'edit',
											// });
										}
									}}
									lines="none"
									disabled={isDisabled}
									detail={false}
								>
									{contactListMode === EnumService.ContactsListMode.ChooseContactsList && <IonCheckbox mode={'ios'} slot={'start'} checked={isSelected} />}

									<IonThumbnail slot="start">
										<IonImg
											style={{ borderRadius: '3px' }}
											src={_contact.hasOwnProperty('profilePhoto') && _contact.profilePhoto ? _contact.profilePhoto : 'assets/img/avatar-placeholder.gif'}
										/>
									</IonThumbnail>
									<IonLabel className="search-bar">
										<h3>{contactName}</h3>
									</IonLabel>

									{contactListMode === EnumService.ContactsListMode.NewRequestContactsList && (
										<IonButton
											fill="clear"
											className="view-newrequest"
											slot="end"
											onClick={() => {
												props.history.push('/profile-friend', {
													data: _contact,
													friendProfileActionType: EnumService.ProfileFriendActionTypes.ContactRequestView,
												});
											}}
										>
											{locale.contact_list.info.view}
										</IonButton>
									)}

									{contactListMode === EnumService.ContactsListMode.MostRecentContactsList && (
										<IonButton fill="clear" className="view-mostrecent" slot="end">
											{locale.contact_list.info.view}
										</IonButton>
									)}

									{contactListMode === EnumService.ContactsListMode.RecentlyAddedContactsList && (
										<>
											{_contact.status === 'pendingReply' ? (
												<IonButton fill="clear" className="view-recentlyadded" slot="end">
													{locale.contact_list.info.pending}
												</IonButton>
											) : (
												_contact.status !== 'pendingConfirm' && (
													<IonButton fill="clear" className="view-recentlyadded" slot="end">
														{locale.contact_list.info.added}
													</IonButton>
												)
											)}
										</>
									)}
									{contactListMode === EnumService.ContactsListMode.RemoveContactListMode && (
										<IonButton fill="clear" className="remove-contact-btn" slot="end" onClick={onContactRemove}>
											<IonIcon src={removeCircleOutline}></IonIcon>
										</IonButton>
									)}
								</IonItem>
							</React.Fragment>
						);
					})
				) : (
					<>
						{/* {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((char: string, index: number) => (
							<IonItemDivider key={index} className="cont-divider">
								<IonLabel>{char}</IonLabel>
							</IonItemDivider>
						))} */}
					</>
				)}
			</IonItemGroup>
		</IonList>
	);
};

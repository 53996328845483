import { createClient } from 'stanza';
import { Config } from '../config/config';
import { info, getInternalStorage, setInternalStorage, logError, isBlank, isJsonString, prefixPhotos, isConversationOpen, isDashboardOpen, getActiveConversation, sleep, compressSelectedFile } from '../helpers/common';
import { apiService } from './apiService';
import store from '../redux/store';
import { controlMessageService } from './controlMessageService';
import { createNotification } from '../components/Notifications/notifications';
import md5 from 'md5';
import _ from 'lodash';
import { DASHBOARD_SHOW_LOADER } from '../redux/constants/dashboard';
import { XMPP_CLIENT_INIT } from '../redux/constants/xmpp';
import { AppManager } from './appManager';
import { Parser } from 'xml2js';
import { Constants } from '../constants';
import { v4 as uuidv4 } from 'uuid';

let cookies: any = getInternalStorage();
export const xmpp = {
	client: null as any,
	queue: [] as any[],
	isReady: false as Boolean,
	isSessionStarting: false as Boolean,
	isSessionStarted: false as Boolean,
	isConnecting: false as Boolean,
	isConnected: false as Boolean,
	isResumed: false as Boolean,
	isReloading: false as Boolean,
	isReconnecting: false as Boolean,
	isDisconnecting: false as Boolean,
	isDisconnected: true as Boolean,
	isClosed: true as Boolean,
	isLoggingOut: false as Boolean,
	inErrorState: false as Boolean,
	isHardReload: false as Boolean,
	isRecoveringFromDisconnect: false as Boolean,
	isProcessingMessage: false as Boolean,
	messagesLoaded: false as Boolean,
	availabilityInterval: undefined as any,
	connectTimeout: undefined as any,
	sentTimeout: undefined as any,
	messageIsSent: false as Boolean,
	recentControlIds: [] as any,
	initialize: async () => {
		let user = await apiService.me();

		if (window.navigator.onLine) {
			if (xmpp?.client && xmpp?.isSessionStarted && xmpp?.client?.sessionStarted) {
				info('xmpp::initialize: Already connected.');
			} else {
				info('xmpp::initialize: Initializing ...');
				try {
					xmpp.client = null;

					const xmppOptions = {
						jid: user.jid,
						password: user.password || getInternalStorage().up,
						server: Config.xmppServer,
						resource: cookies.uuid,
						transports: { bosh: false, websocket: true },
					};

					info('xmpp::initialize::xmppOptions:', xmppOptions);
					xmpp.client = createClient(xmppOptions);
				} catch (error) {
					logError('xmpp::initialize::Error:', error);
				}
			}
		} else {
			logError(`xmpp::initialize: network is offline.`);
			await xmpp.xmppManager({ inErrorState: true });
		}
	},

	reset: async (options: any = {}) => {
		info('xmpp::reset: options', options);

		if (xmpp.client && !options.wasDisconnected) {
			info('xmpp::reset: calling xmpp.disconnect()');
			await xmpp.disconnect();
		}

		if (!options.keepListeners) {
			xmpp.stopListeners();
		}

		xmpp.client = options.client || null;
		xmpp.isReady = options.isReady || false;
		xmpp.isConnecting = options.isConnecting || false;
		xmpp.isConnected = options.isConnected || false;
		xmpp.isReconnecting = options.isReconnecting || false;
		xmpp.isReloading = options.isReloading || false;
		xmpp.isHardReload = options.isHardReload || false;
		xmpp.isDisconnected = options.isDisconnected || true;
		xmpp.isDisconnecting = options.isDisconnecting || false;
		xmpp.isSessionStarted = options.isSessionStarted || false;
		xmpp.isSessionStarting = options.isSessionStarting || false;
		xmpp.isClosed = options.isClosed || true;
		xmpp.inErrorState = options.inErrorState || false;
	},

	connect: async () => {
		if (!xmpp.isConnecting) {
			store.dispatch({ type: DASHBOARD_SHOW_LOADER, payload: { loader: true, loaderMessage: 'Connecting ...' } });
			cookies = getInternalStorage();
			const cachedSM = getInternalStorage().sm;
			await xmpp.initialize();

			if (xmpp.client && !xmpp.isConnected && !xmpp.isConnecting) {
				try {
					xmpp.isConnecting = true;

					await xmpp.startXmppListeners([
						{ event: Constants.STANZA_EVENT_TYPES['connected'], fn: xmpp.handleConnected },
						{ event: Constants.STANZA_EVENT_TYPES['session:started'], fn: xmpp.handleSessionStarted },
						{ event: Constants.STANZA_EVENT_TYPES['stream:management:resumed'], fn: xmpp.handleResumed },
					]);

					info('xmpp::connect: Connecting ...');
					store.dispatch({ type: DASHBOARD_SHOW_LOADER, payload: { loader: true, loaderMessage: 'Connecting ...' } });

					xmpp.client.sm.cache(async (state: any) => setInternalStorage('sm', state));

					if (!isBlank(cachedSM)) {
						info('xmpp::connect: Will attempt to resume previous session...');
						await xmpp.client.sm.load(cachedSM);
					}

					await xmpp.client
						.connect()
						.catch((error: any) => {
							logError('xmpp::connect.connect::Error:', error);
						})
						.then(async () => {
							const handleNotConnected = async () => {
								info('xmpp::connect::handleNotConnected: xmpp connection timeout.  Resetting and restarting xmppManager');
								xmpp.connectTimeout = undefined;
								await xmpp.xmppManager();
							};
							info('xmpp::connect.connect:: request completed');
							xmpp.connectTimeout = setTimeout(async () => await handleNotConnected(), 5000);
						});
				} catch (error) {
					logError('xmpp::connect::Error:', error);
				}
			}
		} else {
			info('xmpp::connect: xmpp.isConnecting is', xmpp.isConnecting);
		}
	},

	handleConnected: (data: any) => {
		info('xmpp::handleConnected: xmpp is connected');
		xmpp.isDisconnected = false;
		xmpp.isClosed = false;
		xmpp.isConnecting = false;
		xmpp.isConnected = true;
		xmpp.isReconnecting = false;
		xmpp.isSessionStarting = true;
		xmpp.isSessionStarted = false;
	},

	handleSessionStarted: async (data: any) => {
		info('xmpp::handleSessionStarted::data:', data);
		xmpp.isSessionStarting = false;
		xmpp.isSessionStarted = true;
		await xmpp.startXmpp();
	},

	handleKeepAlive: async (data: any) => {
		info('xmpp::handleKeepAlive: enabled:', data);
		await xmpp.client.updateCaps();
		info('xmpp::handleKeepAlive: Sending presence for capabilities ...');
		await xmpp.client.sendPresence({ legacyCapabilities: await xmpp.client.disco.getCaps() });
		await xmpp.sendPresence('available');

		if (!xmpp.isReady && !xmpp.isResumed && !xmpp.inErrorState) {
			info('xmpp::handleKeepAlive: Enabling carbons ...');
			try {
				await xmpp.client
					.enableCarbons()
					.then(() => {
						info('xmpp::handleKeepAlive::enableCarbons: Carbons enabled.');
						xmpp.isReady = true;
						info('xmpp::handleKeepAlive: xmpp isReady');
						store.dispatch({ type: XMPP_CLIENT_INIT, payload: xmpp.client });
					})
					.catch(async (error: any) => {
						logError('xmpp::handleKeepAlive::client.enableCarbons::Error', error);
						xmpp.inErrorState = true;
						await xmpp.xmppManager();
					});
			} catch (err) {
				info('xmpp::handleKeepAlive::enableCarbons: Server does not support carbons.');
				xmpp.inErrorState = true;
			}
		} else if (xmpp.isResumed) {
			xmpp.isReady = true;
			xmpp.isResumed = false;
			info('xmpp::handleKeepAlive: xmpp isResumed and isReady');
			store.dispatch({ type: XMPP_CLIENT_INIT, payload: xmpp.client });
		}

		store.dispatch({ type: DASHBOARD_SHOW_LOADER, payload: { loader: false } });
	},

	handleResumed: async (message: any) => {
		cookies = getInternalStorage();

		if (message?.type === 'resumed') {
			info(`xmpp::handleResumed: xmpp connection obtained from resumed session.`);
			xmpp.isConnected = true;
			xmpp.isResumed = true;
			xmpp.isConnecting = false;
			xmpp.isReconnecting = false;
			xmpp.isSessionStarting = false;
			xmpp.isSessionStarted = true;
			xmpp.isReady = true;
			await xmpp.startXmpp(false);
		} else {
			info(`xmpp::handleResumed::message`, message);
		}
	},

	startXmpp: async (enableKeepAlive: Boolean = true) => {
		info(
			`xmpp::startXmpp`,
			_.omitBy(xmpp, (_state) => typeof _state !== 'boolean')
		);

		if (xmpp.client && xmpp.isConnected && xmpp.isSessionStarted) {
			info(
				`xmpp::startXmpp`,
				_.omitBy(xmpp, (_state) => typeof _state !== 'boolean')
			);

			if (xmpp.connectTimeout) {
				clearTimeout(xmpp.connectTimeout);
				xmpp.connectTimeout = undefined;
			}

			if (xmpp.availabilityInterval) {
				clearInterval(xmpp.availabilityInterval);
				xmpp.availabilityInterval = undefined;
			}

			await xmpp.startXmppListeners();

			if(enableKeepAlive) {
				info('xmpp::startXmpp: Enabling keepAlive ...');
				await xmpp.client.enableKeepAlive({ interval: 30, timeout: 15 });
			}
		} else {
			await xmpp.connect();
		}
	},

	xmppManager: async (options: any = {}) => {
		/*info(
			`xmpp::xmppManager`,
			_.omitBy(xmpp, (_state) => typeof _state !== 'boolean')
		);*/

		if (xmpp.inErrorState) {
			AppManager.reload();
		} else {
			cookies = getInternalStorage();

			if (cookies.uuid) {
				if (window.navigator.onLine) {
					if(!options.noReset) {
						await xmpp.reset(options);
					}

					info(`xmpp::xmppManager: calling startXmpp ...`);
					await xmpp.startXmpp();
				} else {
					info(`xmpp::xmppManager: network is offline. Setting xmppManager interval.`);

					if (!xmpp.availabilityInterval) {
						xmpp.availabilityInterval = setInterval(async () => await xmpp.xmppManager(), 5000);
					}
				}
			} else {
				//info('xmpp::xmppManager::not logged in: setting xmppManager interval');
				xmpp.availabilityInterval = setInterval(async () => await xmpp.xmppManager(), 5000);
			}
		}
	},

	disconnect: async () => {
		info(`xmpp::disconnect: Disconnect requested.`);

		if (xmpp.inErrorState) {
			logError('xmpp::disconnect: xmpp is in error state.');
		}

		if (xmpp.client) {
			xmpp.isDisconnecting = true;
			xmpp.client.disconnect();
		}

		xmpp.isReady = false;
	},

	handleDisconnected: async (error: any) => {
		xmpp.isDisconnected = true;

		if (!xmpp.isLoggingOut) {
			info(`xmpp::xmppManager::handleDisconnected: reconnecting ...`);
			await xmpp.xmppManager();
		}
	},

	/**
	 *
	 * @param receiverJid: 'any'
	 * @param messageBody:'string'| {file:any, message:string} | {data:any, message:string}
	 * @param messageType:'text' | 'image' | 'contact' | 'undefined' - default:text
	 * @param lastMessage:'object' | 'undefined' - default:{}
	 * @param relatedMessageId: 'string' | 'undefined' - default:undefined
	 * @param taggedMembers: 'array | undefined' - default:[]
	 */
	sendMessage: async (receiverJid: any, messageBody: any = '', messageType: string = 'text', lastMessage: any = {}, relatedMessageId: any = undefined, taggedMembers: any[] = []) => {
		const user = apiService.me(),
			fetchLinkPreview: Function = async (url: string) => {
				info('url:', url);

				// sanitize the provide url/domain
				if (!url.startsWith('http://') && !url.startsWith('https://')) {
					url = `https://${url}`;
				} else if (url.startsWith('http://')) {
					url = `https://${url.split('http://')[1]}`;
				}

				return await apiService.fetchLinkPreview({ url: url }).then(async (_response: any) => _response);
			},
			handleNotSent = async () => {
				info('xmpp::sendMessage::handleNotSet: timeout');
				await xmpp.xmppManager();
			},
			urlMatches: any = messageType === 'text' ? messageBody.match(/[a-zA-Z\d]+:?(\/\/(\w+:\w+@))?([a-zA-Z\d.-]+\.[A-Za-z]{2,4})(:\d+)?(.*)?/g) : undefined;

		let linkPreview: any,
			originalMediaType: string = messageType,
			mediaType: string = messageType,
			mediaUrl: string = '',
			mediaThumbnail: string = '',
			mediaFile: string = messageBody.constructor === Object ? messageBody?.file : undefined,
			body: any,
			stanza: any,
			serverSequence: any = isBlank(lastMessage)
				? -1
				: window.navigator.onLine
				? await apiService.getConversationSequence(lastMessage.conversationHash).then((_response: any) => {
						if (!_response.Error) {
							return parseInt(_response.conversationSequence);
						} else {
							return undefined;
						}
				  })
				: undefined,
			conversationSequence: any = 0,
			messageStatus: string = 'PendingSent',
			messageKey: string,
			previousMediaWasSent: Boolean = false;

		if (!isBlank(lastMessage) && !isBlank(serverSequence)) {
			conversationSequence = ++serverSequence;
		}

		if (window.navigator.onLine && messageType.includes('resend')) {
			// messageType will be resendText or resendImage if the message is being resent
			// the relatedMessageId will be the messageKey from the message that failed to send
			// need to make this the messageKey of the resent message

			messageKey = relatedMessageId;
			let unsentMessage = (await apiService.getUnsentMessage({ id: messageKey }))[0];

			if (!isBlank(unsentMessage)) {
				stanza = unsentMessage.stanza;

				if (messageType === 'resendMedia') {
					mediaThumbnail = unsentMessage.thumbnail;
					mediaType = unsentMessage.mediaType;
					originalMediaType = unsentMessage.originalMediaType;
				}
			} else {
				messageType = 'text';
			}
		} else {
			messageKey = uuidv4();

			// sanitize the outgoing messageBody
			// convert media in message to property
			if (mediaFile && !previousMediaWasSent) {
				// need to put this into a proper file object

				if (mediaFile.startsWith('data:image')) {
					mediaThumbnail = await compressSelectedFile(mediaFile, { x: 48, y: 48, fit: 'contain', upscale: false });
					mediaType = 'thumbnail';
					originalMediaType = messageBody.size;
				} else {
					// TODO: add handling for other media types
				}

				messageBody = messageBody.message;
				messageStatus = 'PendingUpload';
			}
			messageBody = _.trimEnd(messageBody.replace(/"/g, '&quot;').replace(/'/g, '&apos;').replace('<div><br></div>', ''), ' \n');

			// new conversation will need this
			if (!lastMessage?.conversationHash) {
				lastMessage.conversationHash = md5(`${(await apiService.me()).jid}_${receiverJid}`);
			}

			if (urlMatches) {
				linkPreview = await fetchLinkPreview(urlMatches[0]);
				mediaType = 'url';
			}

			if (user.encryptMessages) {
				let privateKey = getInternalStorage().pk;
			}

			body = {
				read: false,
				replaced: false,
				recalled: false,
				replaces: messageType === 'text' ? relatedMessageId : undefined,
				inReplyTo: messageType.endsWith('Reply') ? relatedMessageId : undefined,
				replacedBy: undefined,
				deleted: false,
				translated: false,
				timestamp: new Date(),
				body: messageBody,
				messageType: messageType,
				tagged: taggedMembers,
				conversationSequence: conversationSequence.toString(),
				conversationHash: lastMessage.conversationHash,
				mediaType: mediaType,
				mediaUrl: mediaUrl,
				mediaThumbnail: mediaThumbnail,
				linkPreview: linkPreview,
				status: messageStatus,
				messageKey: messageKey,
			};

			stanza = {
				body: JSON.stringify(body),
				from: (await apiService.me()).jid,
				to: receiverJid,
				requestReceipt: true,
				timestamp: body.timestamp,
				type: receiverJid.includes('conference') ? 'groupchat' : 'chat',
			};

			apiService.saveUnsentMessage({ id: messageKey, stanza: stanza, media: mediaFile, originalMediaType: originalMediaType, mediaThumbnail: mediaThumbnail, mediaType: mediaType });
		}

		if (window.navigator.onLine) {
			//info('xmpp::sendMessage:', stanza);

			// simulate the reception of an actual sent message so that it gets processed.
			info('xmpp::sendMessage: sending pre-ack message', {
				...stanza,
				id: messageKey,
				body: JSON.stringify({ ...body, status: Constants.MESSAGE_STATUS.JustSent }),
			});

			await xmpp.handleMessageSent(
				{
					...stanza,
					id: messageKey,
					body: JSON.stringify({ ...body, status: Constants.MESSAGE_STATUS.PendingSent }),
				},
				false
			);

			if (!xmpp.isReady || xmpp.inErrorState) {
				info(
					`xmpp::sendMessage`,
					_.omitBy(xmpp, (_state) => typeof _state !== 'boolean')
				);

				await xmpp.xmppManager();

				while (!xmpp.isReady) {
					info('xmpp::sendMessage: waiting for connection ...');
					await sleep(1000);
				}
			}

			xmpp.messageIsSent = false;
			info('xmpp::sendMessage: sending', stanza);
			await xmpp.client.sendMessage(stanza);
			xmpp.sentTimeout = setTimeout(async () => await handleNotSent(), 5000);

			//while (!xmpp.messageIsSent) {
			//	await sleep(500);
			//}

			if (mediaFile) {
				apiService.uploadMedia({ messageKey: messageKey, media: mediaFile, mediaType: originalMediaType });
			}
		} else {
			logError('xmpp::sendMessage: unable to send message.  No connection to internet.');
			info('xmpp::sendMessage: storing unsent message', {
				...stanza,
				id: messageKey,
				body: JSON.stringify({ ...body, status: Constants.MESSAGE_STATUS.SentFailed }),
			});

			// simulate the reception of an actual sent message so that it gets processed.
			xmpp.handleMessageSent(
				{
					...stanza,
					id: messageKey,
					body: JSON.stringify({ ...body, status: Constants.MESSAGE_STATUS.SentFailed }),
				},
				false
			);

			await xmpp.xmppManager();
		}
	},

	sendPresence: async (status: any = undefined) => {
		if (window.navigator.onLine) {
			let user = await apiService.me();
			await xmpp.client.sendPresence({ jid: user.jid, status: status });
		} else {
			logError(`xmpp::sendPresence: network is offline.`);
			await xmpp.xmppManager();
		}
	},

	handlePresence: async (message: any) => info(`xmpp::handlePresence::message:`, message),

	messageHandler: async () => {
		xmpp.isProcessingMessage = true;

		while (xmpp.queue.length > 0) {
			cookies = getInternalStorage();

			const user = await apiService.me(),
				getConversation = async (to: string, from: string) => {
					try {
						let conversationHash = to === from ? user.notepadJid : md5(`${to}_${from}`),
							conversation = conversations.find((_conversation: any) => _conversation.conversationHash === conversationHash);

						// this can occur when the initial message comes in during a contact confirmation
						if (isBlank(conversation)) {
							//logError(`xmpp.messageHandler::getConversation: conversation was not found`);
							conversation = await apiService.getContactByJid(from);
						}

						// this can occur when the initial message comes in during a contact confirmation or a replayed control message
						if (!conversation?.conversationHash) {
							//logError(`xmpp.messageHandler::getConversation: conversationHash was not found`);
							conversation.conversationHash = conversationHash;
							await apiService.updateConversation(conversation);
							conversations.push(conversation);
						}

						return conversation;
					} catch (error) {
						logError('xmpp::messageHandler::getConversation', error);
						return {};
					}
				},
				getExistingMessage = async () => {
					let args: any = {},
						existingMessage: any = {};

					if (!message?.action) {
						if (message.id && messageBody.status !== Constants.MESSAGE_STATUS.PendingSent) {
							args.id = message.id;
						} else if (messageBody.messageKey) {
							args.id = messageBody.messageKey;
						}
					} else {
						if (message?.data?.ids) {
							args.id = message.data.ids[0];
						}
					}

					if (!isBlank(args)) {
						existingMessage = await apiService.getMessage(args);
					}

					return existingMessage;
				},
				// eslint-disable-next-line no-loop-func
				processMessage = async () => {
					info(`xmpp::messageHandler::${source}:`, message);

					newMessage = {
						...newMessage,
						conversationHash: conversation.conversationHash,
						conversationSequence: messageBody.conversationSequence,
						id: message.id,
						language: messageBody?.lang || navigator.language.split('-')[0],
						body: messageBody.hasOwnProperty('body') ? messageBody.body.replace(/&quot;/g, '"') : '',
						translated: messageBody?.translated ? 1 : 0,
						translation: messageBody.translated ? messageBody.translation.replace(/&quot;/g, '"') : messageBody.translation,
						messageType: messageBody.messageType,
						messageKey: messageBody.messageKey,
						tags: messageBody.tags || [],
						recalled: messageBody?.recalled ? 1 : 0,
						replaced: messageBody?.replaced ? 1 : 0,
						replaces: messageBody?.replaces,
						replacedBy: messageBody?.replacedBy,
						inReplyTo: messageBody?.inReplyTo,
						forwardedFrom: messageBody?.forwardedFrom,
						deleted: 0,
						tagged: messageBody?.tagged ? (messageBody.tagged.some((_tagged: any) => _tagged === user.userId) ? 1 : 0) : 0,
						mediaType: messageBody?.mediaType,
						mediaThumbnail: messageBody?.mediaThumbnail,
						mediaUrl: isBlank(newMessage.mediaUrl) ? messageBody?.mediaUrl : newMessage.mediaUrl,
						linkPreview: messageBody?.linkPreview,
					};

					if (!duplicate || update || duplicateIsActual) {
						info(`xmpp::messageHandler: ${duplicate ? `Message is ${duplicateIsAck ? 'a sent acknowledgement' : duplicateIsActual ? 'the actual sent message' : 'a duplicate'} with a status change.` : ''} Updating local database.`);
						// check if the message is in sequence

						if (!isCarbon && !duplicate && conversation && !isBlank(conversation.lastMessage) && parseInt(newMessage.conversationSequence) - parseInt(conversation.lastMessage.conversationSequence) > 1) {
							// the difference should be exactly 1
							// if it is not, a message has been missed
							if (conversation && conversation.lastMessage) {
								info(`xmpp::messageHandler: Message is out of sequence.  conversation.lastMessage.conversationSequence is ${conversation.lastMessage.conversationSequence}, but newMessage.conversationSequence is ${newMessage.conversationSequence}  Requesting missing messages from server ...`);

								await apiService.getServerConversations([{ conversationHash: conversation.lastMessage.conversationHash, start: conversation.lastMessage.conversationSequence + 1 }]);
							} else {
								logError(`xmpp::messageHandler: Message is out of sequence, but conversation is empty.`);
							}
						}

						if (!isCarbon && _.includes([Constants.MESSAGE_STATUS.PendingSent, Constants.MESSAGE_STATUS.Sent, Constants.MESSAGE_STATUS.PendingAck, Constants.MESSAGE_STATUS.PendingUpload], newMessage.status)) {
							newMessage = await apiService.replaceMessage({ ...newMessage, read: 1 });
							info(`xmpp::messageHandler::${source}: message ${newMessage.messageKey} replaced.`);

							if (_.includes([Constants.STANZA_EVENT_TYPES['chat:sent:viaCarbon'], Constants.STANZA_EVENT_TYPES['chat:sent:acked']], source)) {
								await apiService.updateReadStatus({ ids: [newMessage.id] });
							}
						} else {

							if(isCarbon) {
								newMessage = await apiService.saveMessage({ ...newMessage, status: newMessage.status === Constants.MESSAGE_STATUS.PendingUpload ? newMessage.status : Constants.MESSAGE_STATUS.Sent, read: 1 });
							}
							else {
								newMessage = await apiService.saveMessage({ ...newMessage, status: Constants.MESSAGE_STATUS.PendingSent });
							}
							conversation.lastMessage = newMessage;
							await apiService.updateConversation(conversation);
							info(`xmpp::messageHandler::${source}: message saved.`);
						}
					} else {
						info(`xmpp::messageHandler: Message is ${duplicateIsAck ? 'a sent acknowledgement' : duplicateIsActual ? 'the actual sent message' : 'a duplicate'}.  Not updating local database.`);

						if (_.includes([Constants.STANZA_EVENT_TYPES['chat:sent:viaCarbon'], Constants.STANZA_EVENT_TYPES['chat:sent:acked']], source)) {
							await apiService.updateReadStatus({ ids: [newMessage.id] });
						}
					}

					if (!duplicate || duplicateIsAck || duplicateIsActual) {
						if (conversation.status === 'confirmed') {
							if (!personalNotepad && !duplicateIsAck) {
								await apiService.updateDashboard({ message: newMessage });
							}

							if (personalNotepad || (conversationIsOpen && (newMessage.from.startsWith(activeConversation) || newMessage.to.startsWith(activeConversation)))) {
								info(`xmpp::messageHandler: UI update required.`);
								await apiService.updateConversation(conversation);
							}

							if (notify && conversation.status === 'confirmed' && !duplicateIsAck) {
								createNotification(
									newMessage.id !== existingMessage?.id && newMessage.sender !== 'Me' && cookies.desktopNotifications && newMessage.read === 0 && (!cookies.active || (!dashBoardIsOpen && (!conversationIsOpen || (conversationIsOpen && activeConversation !== newMessage.sender))))
										? newMessage
										: undefined,
									true
								);
							}

							if (messageBody.replaces) {
								// get the message being replaced and annotate it with the id of the replacement.
								apiService.updateMessage(
									{
										id: (await apiService.getMessage({ id: messageBody.replaces })).id,
										isReplaced: true,
										replacedBy: newMessage.id,
									},
									newMessage.sender === 'Me'
								);
							}
						}
					} else {
						info(`xmpp::messageHandler: Message is already displayed.  Dashboard update only.`);
						await apiService.updateDashboard({ message: newMessage });
					}
				};

			let queued = xmpp.queue.shift(),
				message: any = queued.message,
				messageBody: any = queued.messageBody,
				source: any = queued.source,
				controlData: any = queued.controlData,
				newMessage: any = {},
				existingMessage = await getExistingMessage(),
				isCarbon = source === Constants.STANZA_EVENT_TYPES['chat:sent:viaCarbon'],
				duplicate = !isBlank(message.id) && (message.id === existingMessage?.id || messageBody.messageKey === existingMessage?.messageKey),
				duplicateIsActual = duplicate && source === Constants.STANZA_EVENT_TYPES['chat:sent'] && messageBody.status === Constants.MESSAGE_STATUS.PendingSent,
				duplicateIsAck = duplicate && _.includes([Constants.STANZA_EVENT_TYPES['chat:sent:acked'], Constants.STANZA_EVENT_TYPES['chat:sent:viaCarbon']], source),
				update = false,
				notify: Boolean = false,
				conversations: any[] = await apiService.getConversations(),
				conversation: any = {},
				from: any[] = [],
				conversationIsOpen: Boolean = isConversationOpen(),
				dashBoardIsOpen: Boolean = isDashboardOpen(),
				activeConversation: any = getActiveConversation(user),
				personalNotepad: Boolean = activeConversation === user.userId,
				timestamp = message?.timestamp ? new Date(message.timestamp).toISOString() : new Date().toISOString();

			if (source === Constants.STANZA_EVENT_TYPES['chat:sent'] && message.type === 'groupchat') {
				if (!xmpp.isReconnecting) {
					info(`xmpp.messageHandler::pre-check: converting chat:sent to groupchat:sent on message.type groupchat`);
					source = Constants.STANZA_EVENT_TYPES['grouchat:sent'];
				} else {
					info(`xmpp.messageHandler::pre-check: converting chat:sent.  xmpp is reconnecting.  Discarding this message as it will be resent.`);
					source = undefined;
					xmpp.isRecoveringFromDisconnect = true;
				}
			} else if (source === Constants.STANZA_EVENT_TYPES['message:failed']) {
				// capture the details of the message, since it needs to be resent.
				// we don't know if this was a p2p or group chat message at this stage
				// it will be followed by a message sent - but no ack - so we need to preserve this state
				// beyond a potential AppManager.reload of the page
			}

			switch (source) {
				case undefined:
					break;

				case Constants.STANZA_EVENT_TYPES['error']:
					info(`xmpp::messageHandler::error`, message);
					// the following condition has been seen to randomly occur
					// specifically in the case of sending a message while know to be online
					// the action here should be to re-establish the session and re-send the message
					// this condition occurs AFTER receiving the initial chat:sent or groupchat:sent message - which must be removed locally
					// this condition is followed by the reception of a chat:sent:acked message - which must be ignored

					/*if (_.includes(Object.keys(message.error[0]), 'service-unavailable') && message.error[0].text[0]._ === 'User session not found') {
						from = message.from.split('/');
						to = message.to.split('@')[0];
						setInternalStorage('toResend', JSON.stringify(message));
						xmpp.inErrorState = true;
						xmpp.isSessionStarted = false;
						AppManager.reload();
					}*/

					break;

				//failed to send a message that is displayed
				case Constants.STANZA_EVENT_TYPES['message:failed']:
					from = message.from.split('/');
					newMessage.from = from[0];
					newMessage.to = !message.to ? user.jid : message.to;
					personalNotepad = message.to === user.notepadJid;
					conversation = personalNotepad ? user : conversations.find((_conversation: any) => _conversation.jid === message.to) || conversations.find((_conversation: any) => _conversation.jid === message.from[0]);
					newMessage.sender = 'Me';
					newMessage.read = conversationIsOpen && (newMessage.from.startsWith(activeConversation) || newMessage.to.startsWith(activeConversation)) ? (message?.read ? 1 : 0) : 0;
					newMessage.timestamp = timestamp;
					newMessage.type = existingMessage?.type || 'chat';
					newMessage.status = Constants.MESSAGE_STATUS.SentFailed;
					break;

				//groupchat or chatpad
				case Constants.STANZA_EVENT_TYPES['groupchat:received']:
				case Constants.STANZA_EVENT_TYPES['groupchat:received:resent']:
					if (!duplicate && !existingMessage?.read) {
						from = message.from.split('/');
						newMessage.from = from[0];
						newMessage.to = message.to;
						personalNotepad = newMessage.from === user.notepadJid;

						if (personalNotepad) {
							newMessage.sender = 'Me';
							notify = false;
							conversation = user;
						} else {
							const messageGroup: any = from[1],
								group = await apiService.getGroupByJid(newMessage.from),
								groupMember = group?.members?.find((_member: any) => _member.userId === messageGroup);

							newMessage.sender = groupMember?.userId === user.userId ? 'Me' : group?.members?.find((member: any) => member?.userId === messageGroup).alias;
							notify = newMessage.sender !== 'Me' && !message.delay;
							conversation = conversations.find((_conversation: any) => _conversation.jid === newMessage.from);
						}

						newMessage.read = conversationIsOpen && (newMessage.from.startsWith(activeConversation) || newMessage.to.startsWith(activeConversation)) ? (message?.read ? 1 : 0) : 0;
						newMessage.timestamp = timestamp;
						newMessage.type = 'groupchat';
						newMessage.status = Constants.MESSAGE_STATUS.Received;

						if (!isBlank(messageBody.mediaType) && messageBody.mediaType !== 'thumbnail' && !isBlank(messageBody.mediaUrl) && messageBody.mediaUrl.startsWith('/')) {
							newMessage.mediaUrl = prefixPhotos(messageBody.mediaUrl);
						}
					}
					break;

				// p2p chat
				case Constants.STANZA_EVENT_TYPES['chat:received']:
					from = message.from.split('/');
					newMessage.from = from[0];
					newMessage.to = message.to;
					conversation = await getConversation(message.to, newMessage.from);
					newMessage.sender = newMessage.from.split('@')[0];
					newMessage.read = isBlank(existingMessage) ? (conversationIsOpen && activeConversation === newMessage.sender ? 1 : 0) : message?.read ? 1 : 0;
					newMessage.timestamp = timestamp;
					newMessage.type = 'chat';
					newMessage.status = Constants.MESSAGE_STATUS.Received;
					notify = !message.delay;

					if (!isBlank(messageBody.mediaType) && messageBody.mediaType !== 'thumbnail' && !isBlank(messageBody.mediaUrl) && messageBody.mediaUrl.startsWith('/')) {
						newMessage.mediaUrl = prefixPhotos(messageBody.mediaUrl);
					}

					break;

				// sent from groupchat or chatpad
				case Constants.STANZA_EVENT_TYPES['groupchat:sent']:
					from = message.from.split('/');
					personalNotepad = message.to === user.notepadJid;
					conversation = personalNotepad ? user : conversations.find((_conversation: any) => _conversation.jid === message.to);
					newMessage.from = from[0];
					newMessage.to = message.to;
					newMessage.sender = newMessage.from === user.jid ? 'Me' : (await apiService.getContactByJid(newMessage.from)).alias; // should this lookup group alias?
					newMessage.read = conversationIsOpen && (newMessage.from.startsWith(activeConversation) || newMessage.to.startsWith(activeConversation)) ? (message?.read ? 1 : 0) : 0;
					newMessage.timestamp = timestamp;
					newMessage.type = 'groupchat';
					newMessage.status = messageBody.status;
					break;

				// sent from chat
				case Constants.STANZA_EVENT_TYPES['chat:sent']:
					info('xmpp::messageHandler::sent message:', message);
					from = message.from.split('/');
					newMessage.from = from[0];
					newMessage.to = !message.to ? user.jid : message.to;
					personalNotepad = message.to === user.notepadJid;
					conversation = personalNotepad ? user : conversations.find((_conversation: any) => _conversation.jid === message.to) || conversations.find((_conversation: any) => _conversation.jid === message.from[0]);
					newMessage.sender = 'Me';
					newMessage.read = conversationIsOpen && (newMessage.from.startsWith(activeConversation) || newMessage.to.startsWith(activeConversation)) ? (message?.read ? 1 : 0) : 0;
					newMessage.timestamp = timestamp;
					newMessage.type = existingMessage?.type || 'chat';
					newMessage.status = messageBody.status;
					break;

				// sent from chat viaCarbon or acked from chat
				case Constants.STANZA_EVENT_TYPES['chat:sent:viaCarbon']:
				case Constants.STANZA_EVENT_TYPES['chat:sent:acked']:
					from = message.from.split('/');
					newMessage.from = from[0];
					newMessage.to = message.to;
					personalNotepad = message.to === user.notepadJid;
					conversation = personalNotepad ? user : source === 'chat:sent:viaCarbon' ? await getConversation(from[0], newMessage.to) : conversations.find((_conversation: any) => _conversation.jid === message.to); // specifically set this way
					newMessage.sender = 'Me';
					newMessage.read = message?.read ? 1 : 0;
					newMessage.timestamp = timestamp;
					newMessage.type = existingMessage?.type || 'chat';
					newMessage.status = messageBody?.mediaType === 'thumbnail' ? Constants.MESSAGE_STATUS.PendingUpload : Constants.MESSAGE_STATUS.Sent;

					if (messageBody?.mediaType !== 'thumbnail' && messageBody?.mediaUrl?.startsWith('/')) {
						newMessage.mediaUrl = prefixPhotos(messageBody.mediaUrl);
					}

					break;

				// control message
				case Constants.STANZA_EVENT_TYPES['controlMessage']:
					if (message.action) {
						info('xmpp::xmppManager::messageHandler::control: Received', message.action, message.controlId);

						let controlMessageId: any;

						if (controlData.message.archived) {
							controlMessageId = controlData.message.archived[0].$.id;
						} else {
							// this is a forwarded message/carbon copy
							// determine if we need it
							// we need to process carbons for chat:sent and groupchat:sent

							if (_.includes([Constants.CONTROL.selfUpdate, Constants.CONTROL.messageUpdated, Constants.CONTROL.messageRead, Constants.CONTROL.mediaUpdated], message.action)) {
								controlMessageId = controlData.message.sent[0].forwarded[0].message[0].archived[0].$.id;
							}
						}

						if (!isBlank(controlMessageId)) {
							if (!_.includes(xmpp.recentControlIds, message.controlId)) {
								if (message.exclude !== cookies.uuid) {
									info(`xmpp::xmppManager::messageHandler::control: ControlMessageService on `, controlData.message, message);
									await controlMessageService.handler(message, controlMessageId);
									xmpp.recentControlIds.push(message.controlId);

									if (xmpp.recentControlIds.length > 25) {
										xmpp.recentControlIds.pop();
									}
								} else if (message.exclude === cookies.uuid) {
									info(`xmpp::xmppManager::messageHandler::control: Ignoring because it is not intended for this device.`, message);
									await controlMessageService.handler({ action: 'delete' }, controlMessageId);
								}
							} else {
								info(`xmpp::xmppManager::messageHandler::control: Discarding duplicate controlId`, message.controlId);
								await controlMessageService.handler({ action: 'delete' }, controlMessageId);
							}
						} else {
							info(`xmpp::xmppManager::messageHandler::control: Discarding`, message);
						}
					} else {
						info('xmpp::xmppManager::messageHandler::control: Control Message has no action.');
					}
					notify = false;
					break;

				default:
					break;
			}

			if (!isBlank(newMessage)) {
				await processMessage();
			}
		}

		xmpp.isProcessingMessage = false;
	},
	handleMessageError: (error: any) => {
		info(`xmpp::handleMessageError:`, error);
	},
	handleErrorConditions: async (source: string, error: any) => {
		info(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}: condition: ${error?.condition}:`, error?.text);

		if (!xmpp.isHardReload && !_.includes(Constants.STANZA_ERROR_CONDITIONS, error?.condition) && source !== Constants.STANZA_EVENT_TYPES['message:failed']) {
			logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}:${error.condition}: reloading ...`);
			AppManager.reload();
		} else if (source === Constants.STANZA_EVENT_TYPES['message:failed']) {
			logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}: we must have become disconnected from the internet.`, error);
			xmpp.isReconnecting = true;
		} else if (error.condition) {
			xmpp.stopListeners();
			let options = {
					..._.omitBy(xmpp, (_state) => typeof _state !== 'boolean'),
					isSessionStarted: false,
					isConnected: false,
					isDisconnected: true,
					inErrorState: xmpp.inErrorState,
					isReconnecting: xmpp.isReconnecting,
				},
				doReset = true;

			switch (error.condition) {
				case Constants.STANZA_ERROR_CONDITIONS['policy-violation']:
					logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}: reloading ...`);
					options.inErrorState = true;
					break;

				case Constants.STANZA_ERROR_CONDITIONS['invalid-xml']:
					logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}: logged.`);
					doReset = false;
					break;

				case Constants.STANZA_ERROR_CONDITIONS['system-shutdown']:
					// wait 1 minute for the server to come back up, then reconnect
					logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}: Setting inErrorState true.`);
					break;

				case Constants.STANZA_ERROR_CONDITIONS['conflict']:
				case Constants.STANZA_ERROR_CONDITIONS['connection-timeout']:
					logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}:`, error);
					options.isReconnecting = true;
					break;

				case Constants.STANZA_ERROR_CONDITIONS['not-authorized']:
					logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}: this could be a password mismatch: `, error);
					options.isReconnecting = true;
					break;

				default:
					break;
			}

			if (doReset) {
				if (xmpp.isReconnecting) {
					info('xmpp::handleErrorConditions:: calling xmpp.disconnect');
					await xmpp.disconnect();
				}
				await xmpp.xmppManager(options);
			} else if (xmpp.isReconnecting || xmpp.inErrorState || error.condition === Constants.STANZA_ERROR_CONDITIONS['system-shutdown']) {
				await xmpp.xmppManager();
			}
		}
	},
	handleAuthFailed: async (error: any) => await xmpp.handleErrorConditions(Constants.STANZA_ERROR_CONDITIONS['auth:failed'], error),
	handleIqGetPing: async () => await AppManager.checkVersion(),
	handleStream_Error: async (error: any) => await xmpp.handleErrorConditions(Constants.STANZA_ERROR_CONDITIONS['stream:error'], error),
	handleStreamError: async (error: any) => await xmpp.handleErrorConditions(Constants.STANZA_EVENT_TYPES['streamError'], error),
	handleMessageFailed: async (error: any) => await xmpp.handleErrorConditions(Constants.STANZA_EVENT_TYPES['message:failed'], error),
	handleMessageHibernated: (message: any) => info(`xmpp::xmppManager::hibernated::message`, message),
	handleStreamManagementAck: async (message: any) => await AppManager.checkVersion(),

	handleRawIncoming: (data: any) => {
		let parser = new Parser();

		parser.parseString(data, async (_err: any, result: any) => {
			/** possible result top level properties:
					message
					open
					stream:features
					challenge
					success
					failed
					iq
					enabled
					presence
					r
					a
					close
					not-authorized
					error
				*/

			if (result.body) {
				result.message = result.body;
			}

			if (result.close) {
				xmpp.isClosed = true;
			} else if (result.failed) {
				info('xmpp::handleRawIncoming::result::failed:', result);
			} else if (result.message) {
				let message: any,
					event: any,
					item: any,
					source: any,
					unhandled: any = undefined,
					delay: any = undefined,
					type: any = undefined,
					controlData: any = false,
					handleThis: Boolean = true;

				if (result.message?.$ && result.message?.body && _.isArray(result.message?.body) && result.message?.error) {
					message = { ...result.message.$, body: result.message.body[0], error: result.message.error };
					source = 'error';
				} else if (
					(result.message?.$ && result.message?.body && _.isArray(result.message?.body) && JSON.parse(result.message.body[0]).type === 'control') ||
					(result.message?.sent &&
						_.isArray(result.message.sent) &&
						result.message.sent[0].forwarded &&
						_.isArray(result.message.sent[0].forwarded) &&
						result.message.sent[0].forwarded[0].message &&
						_.isArray(result.message.sent[0].forwarded[0].message) &&
						result.message.sent[0].forwarded[0].message[0]?.body &&
						_.isArray(result.message.sent[0].forwarded[0].message[0].body) &&
						JSON.parse(result.message.sent[0].forwarded[0].message[0].body).type === 'control')
				) {
					if (result.message?.body) {
						message = JSON.parse(result.message.body[0]);
					} else if (result.message?.sent) {
						message = JSON.parse(result.message.sent[0].forwarded[0].message[0].body[0]);
					}

					source = Constants.STANZA_EVENT_TYPES['controlMessage'];
					controlData = result;
				} else if (result.message?.$ && result.message?.body && _.isArray(result.message?.body) && (!cookies.active || !result.message.$.xmlns)) {
					message = { ...result.message.$, body: result.message.body[0] };
					source = Constants.STANZA_EVENT_TYPES['chat:received'];
				} else if (result.message?.sent && _.isArray(result.message?.sent) && result.message.sent[0].forwarded && _.isArray(result.message.sent[0].forwarded)) {
					// do not process control messages sent to contacts on our behalf
					// but we need to remove the control message from the server
					if (isJsonString(result?.message?.body) && JSON.parse(result.message.body)?.type === 'control') {
						source = Constants.STANZA_EVENT_TYPES['extraneous'];
						handleThis = false;
					} else {
						// message sent by user from another device while offline on current device and now being received
						message = { ...result.message.sent[0].forwarded[0].message[0].$, body: result.message.sent[0].forwarded[0].message[0].body };
						source = Constants.STANZA_EVENT_TYPES['chat:sent'];
					}
				} else if ((result.message?.archived && _.isArray(result.message.archived) && result.message?.body && _.isArray(result.message.body)) || (result.message?.event && _.isArray(result.message.event) && _.isArray(result.message.event[0]?.items) && _.isArray(result.message.event[0].items[0]?.item))) {
					if (result.message?.event) {
						event = result.message?.event[0];
						item = event.items[0].item[0];
					} else if (result.message?.body) {
						item = result.message?.archived
							? {
									message: [
										{
											body: result.message.body,
											$: result.message.$,
										},
									],
									unsubscribe: result.message?.unsubscribe,
									subscribe: result.message?.subscribe,
							  }
							: {};
					}

					if (_.isArray(item?.message) && _.isArray(item.message[0]?.body) && !isBlank(item.message[0]?.$)) {
						if (_.isArray(item?.unsubscribe) && item.unsubscribe[0]?.$) {
							// received when a member is unsubscribed from a group
							unhandled = item.unsubscribe[0].$.jid;
							handleThis = false;
							// usage TBD
							// assume look up group, find member, remove member.
							// need to verify if this message is received by every member or just the owner
							// attempt to modify group on server
						} else if (_.isArray(item?.subscribe) && item.subscribe[0]?.$) {
							// received when a member is subscribed to a group
							unhandled = item.subscribe[0].$.jid;
							handleThis = false;
							// usage TBD
							// assume look up group, find member, add member.
							// need to verify if this message is received by every member or just the owner
							// attempt to modify group on server
						} else {
							// received when user has sent a message to a groupchat ... we care if it came from another device
							// and check in the message handler
							message = { ...item.message[0].$, body: item.message[0].body[0] };

							delay = result.message?.delay && _.isArray(result.message.delay) && result.message.delay[0]?._ ? result.message.delay[0]._ : undefined;

							if (delay) {
								info('xmpp::handleRawIncoming::message::delay:', delay);
							}

							type = item.message[0].$?.type;

							if (type === 'groupchat') {
								source = Constants.STANZA_EVENT_TYPES[`groupchat:received${delay === 'Resent' ? ':resent' : ''}`];
							} else if (type === 'chat') {
								source = Constants.STANZA_EVENT_TYPES[`chat:received${delay === 'Resent' ? ':resent' : ''}`];
							}
						}
					} else if (item.$ && _.isArray(item.$)) {
						unhandled = item.$[0]?.node;
						info(`xmpp::handleRawIncoming:: Not processing ${unhandled}`);
						handleThis = false;
					}
				} else {
					info('xmpp::handleRawIncoming: we hit here with:', JSON.stringify(result.message));
					handleThis = false;
				}

				if (handleThis) {
					let messageBody: any = message;

					if (_.isArray(message?.body)) {
						messageBody = message.body[0];
					} else if (message.body) {
						messageBody = message.body;
					}

					if (isJsonString(messageBody)) {
						messageBody = JSON.parse(message.body);
					}

					xmpp.queue.push({ message: message, messageBody: messageBody, source: source, controlData: controlData });

					if (!xmpp.isProcessingMessage) {
						await xmpp.messageHandler();
					}
				} else {
					if (!unhandled) {
						info('xmpp::handleRawIncoming: unable to identify message body in:', result);
					} else {
						info(`xmpp::handleRawIncoming: discarding unhandled ${unhandled}:`, result);
					}
				}
			} else if (result.resumed) {
				info('xmpp::handleRawIncoming::result.resumed:', result);
			} else if (result.enabled) {
				await xmpp.handleKeepAlive();
			} else if (!result.a && !result.iq && !result.r && !result.presence && !result.resumed) {
				info('xmpp::handleRawIncoming::result:', Object.keys(result)[0], result);
			}
		});
	},
	handleChat: async (message: any) => {
		if (message.type && message.type === 'groupchat') {
			clearTimeout(xmpp.sentTimeout);
			xmpp.messageIsSent = true;
			xmpp.queue.push({ message: message, messageBody: JSON.parse(message.body), source: Constants.STANZA_EVENT_TYPES['groupchat:sent'], controlData: false });

			if (!xmpp.isProcessingMessage) {
				await xmpp.messageHandler();
			}
		}
	},
	handleMessage: async (message: any) => {
		if (message.type && message.type === 'chat' && !message.carbon) {
			xmpp.queue.push({ message: message, messageBody: JSON.parse(message.body), source: Constants.STANZA_EVENT_TYPES['chat:received'], controlData: false });

			if (!xmpp.isProcessingMessage) {
				await xmpp.messageHandler();
			}
		}
	},
	handleMessageSent: async (message: any, viaCarbon: Boolean) => {
		if (message.type && message.type.includes('chat')) {
			clearTimeout(xmpp.sentTimeout);
			xmpp.messageIsSent = true;
			xmpp.queue.push({ message: message, messageBody: JSON.parse(message.body), source: Constants.STANZA_EVENT_TYPES[`chat:sent${viaCarbon ? ':viaCarbon' : ''}`], controlData: false });

			if (!xmpp.isProcessingMessage) {
				await xmpp.messageHandler();
			}
		}
	},
	handleMessageAcked: async (message: any) => {
		//info('xmpp::handleMessageAcked:', message);
		//if (message.type && message.type === 'chat') {
		//info('handleMessageAcked:', message);
		xmpp.queue.push({ message: message, messageBody: JSON.parse(message.body), source: Constants.STANZA_EVENT_TYPES['chat:sent:acked'], controlData: false });

		if (!xmpp.isProcessingMessage) {
			await xmpp.messageHandler();
		}
		//}
	},
	handleGroupChat: (message: any) => info('xmpp::xmppManager::groupchat::message:', message),
	handleTransportDisconnected: async () => {
		if (!xmpp.isLoggingOut) {
			info('xmpp::handleTransportDisconnected:: re-establishing connection');
			await xmpp.xmppManager({ wasDisconnected: true });
		}
	},
	handleAll: (event: any, result: any) => {
		if (
			!xmpp.isHardReload &&
			!event.startsWith(Constants.STANZA_EVENT_TYPES['iq']) &&
			!event.startsWith(Constants.STANZA_EVENT_TYPES['presence:id:']) &&
			!event.startsWith(Constants.STANZA_EVENT_TYPES['message:id:']) &&
			!event.startsWith(Constants.STANZA_EVENT_TYPES['sm:id:']) &&
			!_.includes(Constants.STANZA_EVENT_TYPES, event)
		) {
			info('xmpp::handleAll::', event, result ? result : '');
		}
	},
	setMessagesLoaded: () => (xmpp.messagesLoaded = true),
	startXmppListeners: async (action: any = undefined) => {
		if (xmpp.client) {
			cookies = getInternalStorage();

			if (!cookies.listeners) {
				cookies.listeners = [];
			} else {
				await xmpp.stopListeners(true);
			}

			if (!action) {
				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['session:started'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['session:started'], fn: xmpp.handleSessionStarted });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['connected'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['connected'], fn: xmpp.handleConnected });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['auth:failed'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['auth:failed'], fn: xmpp.handleAuthFailed });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['iq:get:ping'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['iq:get:ping'], fn: xmpp.handleIqGetPing });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['stream:error'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['stream:error'], fn: xmpp.handleStream_Error });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['streamError'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['streamError'], fn: xmpp.handleStreamError });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['message:failed'])) {
					// interesting event.  occurs when sending and the websocket has become disconnected, and usually followed by a valid chat:sent indicator,
					// with a message id, however the message is not acutally in the database and has not been sent to recipient
					// we need to reconnect, and then resend the message
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['message:failed'], fn: xmpp.handleMessageFailed });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['stream:management:ack'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['stream:management:ack'], fn: xmpp.handleStreamManagementAck });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['stream:management:resumed'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['stream:management:resumed'], fn: xmpp.handleResumed });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['disconnected'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['disconnected'], fn: xmpp.handleDisconnected });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['message:error'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['message:error'], fn: xmpp.handleMessageError });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['message:hibernated'])) {
					// this occurs when an attempt to send a message while not connected occurs
					// the hibernated messages should get sent when the connection is re-established
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['message:hibernated'], fn: xmpp.handleMessageHibernated });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['presence'])) {
					// we get this in response to sending a presence message, or when we log into a different device
					// with the same credentials.  the 'resource' portion of the full jid is the uuid of the different device
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['presence'], fn: xmpp.handlePresence });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['raw:incoming'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['raw:incoming'], fn: xmpp.handleRawIncoming });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['chat'])) {
					// this is where we receive incoming group chat messages from ejabbered
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['chat'], fn: xmpp.handleChat });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['message'])) {
					// this is where we receive incoming chat messages from ejabbered
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['message'], fn: xmpp.handleMessage });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['message:sent'])) {
					// this is generated when a message is sent to ejabberd - it does not necessarily mean that it has been delivered
					// if the xmpp.user session has ended, the message will have to be resent
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['message:sent'], fn: xmpp.handleMessageSent });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['message:acked'])) {
					// acknowledgement that ejabbered has received and processed an outgoing P2P message
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['message:acked'], fn: xmpp.handleMessageAcked });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['groupchat'])) {
					// acknowledgement that ejabbered has received and processed an outgoing MUC message
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['groupchat'], fn: xmpp.handleGroupChat });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['--transport-disconnected'])) {
					// this is thrown when the websocket throws an error
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['--transport-disconnected'], fn: xmpp.handleTransportDisconnected });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['*'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['*'], fn: xmpp.handleAll });
				}
			} else {
				for (let _listener of action) {
					cookies.listeners.push({ element: 'xmpp', event: _listener.event, fn: _listener.fn });
				}
			}

			for (let listener of cookies.listeners.filter((_listener: any) => _listener.element === 'xmpp' && !_listener.started)) {
				xmpp.client.on(listener.event, listener.fn);
				listener.started = true;
			}

			setInternalStorage('listeners', cookies.listeners);
		}
	},

	stopListeners: async (xmppOnly: Boolean = true) => {
		cookies = getInternalStorage();

		if (cookies.listeners && cookies.listeners.constructor === Array && cookies.listeners.length > 0) {
			//info(`xmpp::stopListeners: stopping ${xmppOnly ? cookies.listeners.filter((_listener: any) => _listener?.element === 'xmpp').length : cookies.listeners.length}`);

			while (cookies.listeners.length > 0) {
				try {
					let listener = cookies.listeners.pop();
					//info(`stopping ${listener.event}`);

					if (!xmppOnly && listener?.element === 'window') {
						window.removeEventListener(listener.event, listener.fn);
					} else if (!xmppOnly && listener?.element === 'document') {
						document.removeEventListener(listener.event, listener.fn);
					} else if (xmpp.client && listener?.element === 'xmpp') {
						xmpp.client.off(listener.event, listener.fn);
					}
				} catch (error) {
					logError(error);
				}
			}
		}

		setInternalStorage('listeners', []);
	},
} as any;

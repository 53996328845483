import React from 'react';
import { IonIcon, IonImg, IonLabel, IonThumbnail } from '@ionic/react';
import './style.scss';
import { star } from 'ionicons/icons';
import { info } from '../../../helpers/common';
interface iProps {
	props: any;
	contacts: any;
}

export const StarredContacts: React.FC<iProps> = ({ contacts }) => {
	return (
		<div className="starred-contacts-component">
			<div className="star-icon">
				<IonIcon slot="start" style={{ color: '#FFCC4C', marginRight: '0px' }} icon={star} />
			</div>
			<div className="starred-wrapper">
				{contacts.length > 0 ? (
					contacts.map((contact: any, index: any) => {
						return (
							<div className="starred-item" key={index}>
								<IonThumbnail key={index} onClick={() => info('')}>
									<IonImg style={{ borderRadius: '3px' }} src={'./assets/img/blank.jpg'} />
								</IonThumbnail>
								<IonLabel>{contact.title}</IonLabel>
							</div>
						);
					})
				) : (
					<IonThumbnail className="starred" onClick={() => info('')}>
						{/* <IonImg style={{ borderRadius: '3px' }} src={`https://randomuser.me/api/portraits/${2 % 2 === 0 ? 'men' : 'women'}/1.jpg`} /> */}
					</IonThumbnail>
				)}
			</div>
		</div>
	);
};

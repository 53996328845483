/* eslint-disable @typescript-eslint/no-unused-expressions */
import _ from 'lodash';
import metadata from '../metadata.json';
import store from '../redux/store';
import { USER_LOGOUT } from '../redux/constants/common';
import { Config } from '../config/config';
import { apiService } from '../services/apiService';
import { xmpp } from '../services/xmpp';
import { Storage } from '@capacitor/storage';
import { locale } from '../locales/local';
import { createDefaultImageReader, createDefaultImageWriter, PinturaEditorOptions, processImage } from 'pintura';

export const blankProfilePic = './assets/img/upload-photo-holder.png';

export async function fetchProfilePic(id: any = 'placeholder', userType: string = 'user', fullImage: Boolean = false) {
	let response: any, imageSource: any;

	if (id === 'placeholder') {
		response = blankProfilePic;
	} else if (userType === 'user') {
		imageSource = await apiService.me();
		response = !isBlank(imageSource) ? (fullImage ? imageSource.profileThumb || imageSource.profilePhoto : imageSource.profilePhoto) : blankProfilePic;
	} else if (userType === 'contact') {
		imageSource = await apiService.getContactByUserId(id);
		response = !isBlank(imageSource) ? (fullImage ? imageSource.profileThumb || imageSource.profilePhoto : imageSource.profilePhoto) : blankProfilePic;
	} else if (userType === 'group') {
		imageSource = await apiService.getGroupByJid(id);
		response = !isBlank(imageSource) ? (fullImage ? imageSource.groupThumb || imageSource.groupPhoto : imageSource.groupPhoto) : blankProfilePic;
	}

	return response;
}

export async function prefixPhotos(imageSource: any) {
	const preloadImage = (image: string) =>
		new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = resolve;
			img.onerror = reject;
			img.src = image;
		});

	if (_.isString(imageSource)) {
		imageSource = `${Config.IMAGE_PATH}${imageSource}`;
	} else {
		if (imageSource.profilePhoto && imageSource.profilePhoto.startsWith('/') && !imageSource.profilePhoto.startsWith(Config.IMAGE_PATH)) {
			imageSource.profilePhoto = `${Config.IMAGE_PATH}${imageSource.profilePhoto}`;
			await preloadImage(imageSource.profilePhoto).catch((error: any) => {
				logError(`common::prefixPhotos: cannot retrieve profilePhoto for ${imageSource.userId},  ${imageSource.profilePhoto}:`, error);
				imageSource.profilePhoto = `${Config.IMAGE_PATH}/logo.webp`;
			});
		}

		if (imageSource.profileThumb) {
			imageSource.profileThumb = `${Config.IMAGE_PATH}${imageSource.profileThumb}`;
			await preloadImage(imageSource.profileThumb).catch((error: any) => {
				logError(`common::prefixPhotos: cannot retrieve profilePhoto for ${imageSource.userId},  ${imageSource.profileThumb}:`, error);
				imageSource.profileThumb = `${Config.IMAGE_PATH}/logo.webp`;
			});
		}

		if (imageSource.groupPhoto && imageSource.groupPhoto.startsWith('/') && !imageSource.groupPhoto.startsWith(Config.IMAGE_PATH)) {
			imageSource.groupPhoto = `${Config.IMAGE_PATH}${imageSource.groupPhoto}`;
			await preloadImage(imageSource.groupPhoto).catch((error: any) => {
				logError(`common::prefixPhotos: cannot retrieve groupPhoto for ${imageSource.groupname}, ${imageSource.groupPhoto}:`, error);
				imageSource.groupPhoto = `${Config.IMAGE_PATH}/logo.webp`;
			});
		}

		if (imageSource.groupThumb) {
			imageSource.groupThumb = `${Config.IMAGE_PATH}${imageSource.groupThumb}`;
			await preloadImage(imageSource.groupThumb).catch((error: any) => {
				logError(`common::prefixPhotos: cannot retrieve groupPhoto for ${imageSource.groupname}, ${imageSource.groupThumb}:`, error);
				imageSource.groupThumb = `${Config.IMAGE_PATH}/logo.webp`;
			});
		}

		if (imageSource.qrCode && imageSource.qrCode.startsWith('/') && !imageSource.qrCode.startsWith(Config.IMAGE_PATH)) {
			imageSource.qrCode = `${Config.IMAGE_PATH}${imageSource.qrCode}`;
			await preloadImage(imageSource.qrCode).catch((error: any) => {
				logError(`common::prefixPhotos: cannot retrieve qrCode for ${imageSource.userId || imageSource.groupname}, ${imageSource.qrCode}:`, error);
				imageSource.qrCode = `${Config.IMAGE_PATH}/logo.webp`;
			});
		}

		if (imageSource.gallery && imageSource.gallery.length > 0) {
			for (let image of imageSource.gallery) {
				if (!isBlank(image) && !isBlank(image.url) && image.url.startsWith('/') && !image.url.startsWith(Config.IMAGE_PATH)) {
					image.url = `${Config.IMAGE_PATH}${image.url}`;
				}
			}
		}
	}

	return imageSource;
}

export function unprefixPhotos(conversation: any) {
	if (conversation.profilePhoto) {
		conversation.profilePhoto = conversation.profilePhoto.replace(Config.IMAGE_PATH, '');
	}

	if (conversation.groupPhoto) {
		conversation.groupPhoto = conversation.groupPhoto.replace(Config.IMAGE_PATH, '');
	}

	if (conversation.qrCode) {
		conversation.qrCode = conversation.qrCode.replace(Config.IMAGE_PATH, '');
	}

	if (conversation.gallery && conversation.gallery.length > 0) {
		for (let image of conversation.gallery) {
			if (typeof image === 'object') {
				image.url = image.url?.replace(Config.IMAGE_PATH, '');
			} else {
				image = image.replace(Config.IMAGE_PATH, '');
			}
		}
	}

	return conversation;
}
export function isBlank(value: any) {
	return (_.isString(value) && _.isEmpty(value)) || (_.isArray(value) && value.length === 0) || (_.isObject(value) && Object.keys(value).length === 0) || _.isNil(value) || _.isNull(value) || _.isUndefined(value);
}

export async function sleep(ms: any) {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
}

export function isJsonString(str: any) {
	return !isBlank(str) && str.constructor === String && str.constructor !== Object && ((str.startsWith('{') && str.endsWith('}')) || (str.startsWith('[') && str.endsWith(']')));
}

export function validateEmail(email: any) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function getUsernameFromEmail(input: String = '') {
	let output = input.match(/(.*?)@.*?/);
	return output && output[1] ? output[1] : null;
}

export function validateUsername(userId: any) {
	let re = /^[a-zA-Z0-9_]{2,}[a-zA-Z_]+[0-9_]*$/;
	return re.test(String(userId).toLowerCase());
}

export function validatePhone(phone: any) {
	let re = /^\d{10}$/;
	return re.test(String(phone).toLowerCase()) || false;
}

export async function info(data: any, extra1: any = '', extra2: any = '') {
	if (!_.includes([''], metadata.buildTag)) {
		console.info(data, extra1, extra2);
	}

	/*let cookies = getInternalStorage(),
		user = cookies.uuid ? await apiService.me() : undefined;

	if (cookies.mobileDevice && user && user.userId === 'doncarlosone') {
		apiService.sendStatus({
			uuid: cookies.uuid,
			info: `visibility: ${document.visibilityState}, displayMode: ${cookies.displayMode}. network: ${cookies.network}`,
			cookies: _.omit(cookies, ['listeners', 'sm']),
			xmpp: _.omit(xmpp, ['client', 'user']),
			logExtra1: extra1,
			logExtra2: extra2,
		});
	}*/
}

export async function logError(data: any, extra1: any = '', extra2: any = '') {
	if (metadata.buildTag !== '') {
		console.error(data, extra1, extra2);
	}

	let cookies = getInternalStorage(),
		user = cookies.uuid ? await apiService.me() : undefined;

	if (cookies.mobileDevice && user && user.userId === 'doncarlosone') {
		apiService.sendStatus({
			logError: `visibility: ${document.visibilityState}, displayMode: ${cookies.displayMode}. network: ${cookies.network}`,
			cookies: _.omit(cookies, ['listeners', 'sm']),
			xmpp: _.omit(xmpp, ['client', 'user']),
			logExtra1: extra1,
			logExtra2: extra2,
		});
	}
}

export function isConversationOpen() {
	const currentPath: any[] = window.location.href.split('/'),
		pathLast: any = currentPath?.pop(),
		pathSecondLast: any = currentPath?.pop();

	return pathSecondLast === 'chat' || pathLast === 'personal-notepad';
}

export function getActiveConversation(user: any) {
	const currentPath: any[] = window.location.href.split('/'),
		pathLast: any = currentPath?.pop(),
		pathSecondLast: any = currentPath?.pop();

	if (isBlank(user)) {
		user = (async () => await apiService.me())();
	}

	return pathSecondLast === 'chat' ? pathLast : pathLast === 'personal-notepad' ? user?.userId : undefined;
}

export function isPersonalNotepad() {
	const currentPath: any[] = window.location.href.split('/'),
		pathLast: any = currentPath?.pop();

	return pathLast === 'personal-notepad';
}

export function isDashboardOpen() {
	const currentPath: any[] = window.location.href.split('/'),
		pathSecondLast: any = currentPath?.pop();

	return pathSecondLast === 'auth';
}

export function getInternalStorage() {
	const exclusions: any[] = ['epr_ru', 'toResend', 'secure__ls__metadata', 'com.be-society'],
		compressed: any[] = ['sm'],
		secureExclusions: any[] = ['up'],
		items: any = {};

	for (let _item of Object.keys(localStorage)) {
		if (!_.includes(exclusions, _item)) {
			items[_item] = localStorage[_item];

			let ascii = Buffer.from(items[_item] as string, 'base64').toString('ascii');

			if (items[_item] === null) {
				items[_item] = null;
			} else if (items[_item] === '+1' || items[_item] === 'true') {
				items[_item] = true;
			} else if (items[_item] === '-1' || items[_item] === 'false') {
				items[_item] = false;
			} else if (!isNaN(items[_item])) {
				items[_item] = parseInt(items[_item]);
			} else if (_.includes(compressed, _item)) {
				try {
					items[_item] = JSON.parse(items[_item]);
				} catch (error) {
					logError(error);
				}
			} else if (isJsonString(items[_item]) || isJsonString(ascii)) {
				if (ascii) {
					try {
						items[_item] = JSON.parse(ascii);
					} catch (error) {
						logError(error);
					}
				}

				if (_.isArray(items[_item])) {
					for (let index in items[_item]) {
						if (items[_item][index].fn) {
							// potentially unsafe eval here, but acceptable as it is tightly controlled
							items[_item][index].fn = eval(`(${items[_item][index].fn})`);
						}
					}
				}
			}
			//}
		}
	}

	return items;
}

export function setInternalStorage(item: string, value: any) {
	const secureExclusions: any[] = ['up'],
		compressed: any[] = ['sm'];

	if (value === 'true' || value === true) {
		value = '+1';
	} else if (value === 'false' || value === false) {
		value = '-1';
	} else if (_.isArray(value)) {
		for (let index in value) {
			if (value[index].fn) {
				value[index].fn = value[index].fn.toString();
			}
		}

		value = Buffer.from(JSON.stringify(value) as string, 'ascii').toString('base64');
	} else if (_.includes(compressed, item)) {
		value = JSON.stringify(value);
	} else if (_.isObject(value)) {
		value = Buffer.from(JSON.stringify(value) as string, 'ascii').toString('base64');
	} else if (!isNaN(value)) {
		value = parseInt(value);
	}

	localStorage[item] = value;
}

export function deleteItemFromInternalStorage(item: string) {
	delete localStorage[item];
}

export function clearInternalStorage() {
	localStorage.clear();
}

export function sortByTimestamp(a: any, b: any) {
	let a1: any = a?.timestamp || a?.lastMessage?.timestamp,
		b1: any = b?.timestamp || b?.lastMessage?.timestamp,
		earlier: number = a?.timestamp ? -1 : 1,
		later: number = a?.timestamp ? 1 : -1,
		response: number = 0;

	if (a1) {
		response = new Date(a1).getTime() > new Date(b1).getTime() ? later : new Date(a1).getTime() < new Date(b1).getTime() ? earlier : 0;
	}

	return response;
}

export function sortByAlias(a: any, b: any) {
	let a1: any = (a?.alias || a?.userId || a?.username || a?.groupName).toLowerCase(),
		b1: any = (b?.alias || b?.userId || b?.username || b?.groupName).toLowerCase();

	return a1 > b1 ? 1 : b1 > a1 ? -1 : 0;
}

export function dataURItoBlob(dataURI: any) {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	var byteString = atob(dataURI.split(',')[1]);

	// separate out the mime component
	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to an ArrayBuffer
	var ab = new ArrayBuffer(byteString.length);
	var ia = new Uint8Array(ab);
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ab], { type: mimeString });
}

function blobToDataURI(blob: Blob) {
	return new Promise((resolve, reject) => {
		var fr = new FileReader();
		fr.onload = () => {
			resolve(fr.result);
		};
		fr.onerror = reject;
		fr.readAsDataURL(blob);
	});
}

export async function compressSelectedFile(file: any, size: any = undefined, callback: any = undefined) {
	const options: PinturaEditorOptions = {
		locale: 'en',
		imageReader: createDefaultImageReader(),
		imageWriter: createDefaultImageWriter({ mimeType: 'image/webp' }),
		imageTargetSize: size,
	};

	let response: any;

	if (file.startsWith('data:')) {
		file = dataURItoBlob(file);
	}

	if (!['image/jpeg', 'image/png', 'image/jpg', 'image/webp'].includes(file.type)) {
		response = { hasError: true, errorMessage: locale.groups.invalid_file_format };
	} else {
		const image: any = await processImage(file, options);
		response = await blobToDataURI(image.dest);

		if (callback) {
			callback(image);
		}
	}

	return response;
}

export async function logoutUser(forceDatabaseReset: Boolean = false) {
	let cookies: any = getInternalStorage();
	info('common::logout:: requested');

	store.dispatch({ type: USER_LOGOUT });

	if (xmpp.client) {
		xmpp.isLoggingOut = true;
		info('common::logout:: calling xmpp.disconnect');
		await xmpp.disconnect();
	}

	//info('common::logout: closing database');
	//await apiService.closeDatabase(cookies.db).catch('DatabaseClosedError', (error: any) => logError(`common::logoutUser::closeDatabase::Error`, error));

	if ('serviceWorker' in window.navigator && (!cookies.personalDevice || forceDatabaseReset)) {
		clearInternalStorage();

		info('common::logout: web browser and not personal device. removing database');
		await apiService.deleteDatabase(cookies.db).catch('DatabaseClosedError', (error: any) => logError(`common::logoutUser::deleteDatabase::Error`, error));

		deleteItemFromInternalStorage('db');
	} else {
		deleteItemFromInternalStorage('sm');
		deleteItemFromInternalStorage('uuid');
		deleteItemFromInternalStorage('registered');
		deleteItemFromInternalStorage('uuid');
		deleteItemFromInternalStorage('active');
	}

	info('common::logout:: reloading now');
	window.location.href = '/';
}

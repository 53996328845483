import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonText, IonToolbar } from '@ionic/react';
import React, { useState } from 'react';
import './style.scss';
import { addOutline, removeOutline } from 'ionicons/icons';
import ProjectTaskComponent from './ProjectTaskComponent';
import { locale } from '../../locales/local';
interface ProjectsComponentProps {
	onClose?: any;
}

const ProjectsComponent: React.FC<ProjectsComponentProps> = ({ onClose }) => {
	const [openProjectTask, setOpenProjectTask] = useState(false);

	const projects = [
		{ title: locale.quickaccess.festival, subtitle: locale.quickaccess.festival_sub },
		{ title: locale.quickaccess.squirel, subtitle: locale.quickaccess.squirel_sub },
		{ title: locale.quickaccess.mobile_game, subtitle: locale.quickaccess.mobile_game_sub },
	];

	return openProjectTask ? (
		<ProjectTaskComponent onClose={() => setOpenProjectTask(false)} />
	) : (
		<div
			className="projects-component component-expand-full"
			onClick={(event) => {
				event.stopPropagation();
				onClose && onClose();
			}}
		>
			<IonLabel className="card-title">{locale.quickaccess.projects}</IonLabel>
			<div className="card" onClick={(event) => event.stopPropagation()}>
				{projects.map((item, key) => {
					return (
						<ProjectItemButton
							key={key}
							title={item.title}
							subtitle={item.subtitle}
							onClick={() => {
								setOpenProjectTask(true);
							}}
						/>
					);
				})}
			</div>

			<IonToolbar style={{ '--background': 'transparent' }}>
				<IonButtons slot="end" onClick={(event) => event.stopPropagation()}>
					<IonButton fill="clear">
						<IonIcon icon={removeOutline} slot="icon-only"></IonIcon>
					</IonButton>

					<IonButton fill="clear">
						<IonIcon icon={addOutline} slot="icon-only"></IonIcon>
					</IonButton>
				</IonButtons>
			</IonToolbar>
		</div>
	);
};

export default ProjectsComponent;

interface ProjectItemButtonProps {
	title: string;
	subtitle: string;
	onClick?: any;
}
const ProjectItemButton: React.FC<ProjectItemButtonProps> = ({ title, subtitle, onClick }) => {
	return (
		<IonItem lines="none" onClick={onClick}>
			<IonLabel>{title}</IonLabel>
			<IonText>{subtitle}</IonText>
		</IonItem>
	);
};

import { USER_PROFILE_SHOW_LOADER, USER_PROFILE_RESET_VALUES } from '../constants/common';
import { PROFILE_EDITED, PROFILE_UPDATE_ERROR, PROFILE_UPDATE_FAILED, PROFILE_UPDATE_SUCCESS, PROFILE_UPDATING } from '../constants/profileupdate';

const initialState = {
		isLoggedIn: false,
		errorMessage: '',
		hasEdited: false,
		showLoading: false,
		isLoading: false,
		showToast: false,
	};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	let returnValue: any;
	switch (action.type) {
		case USER_PROFILE_RESET_VALUES: {
			returnValue = {
				...state,
				hasErrors: false,
				errorMessage: '',
				hasEdited: false,
				showLoading: false,
				isLoading: false,
				showToast: false,
			};
			break;
		}

		case PROFILE_UPDATING: {
			returnValue = {
				...state,
				isLoading: true,
			};
			break;
		}

		case PROFILE_UPDATE_FAILED: {
			returnValue = {
				...state,
				hasErrors: true,
				errorMessage: action.payload.errorMessage,
				isLoading: false,
				hasEdited: false,
			};
			break;
		}

		case PROFILE_UPDATE_ERROR: {
			returnValue = {
				...state,
				hasErrors: false,
				errorMessage: action.payload.errorMessage,
				isLoading: false,
				hasEdited: false,
			};
			break;
		}

		case PROFILE_UPDATE_SUCCESS: {
			returnValue = {
				...state,
				isLoading: false,
				hasErrors: true,
				hasEdited: false,
			};
			break;
		}

		case PROFILE_EDITED: {
			returnValue = {
				...state,
				hasEdited: action.payload,
			};
			break;
		}
		case USER_PROFILE_SHOW_LOADER: {
			returnValue = {
				...state,
				isLoading: action.payload,
			};
			break;
		}

		default:
			returnValue = state;
			break;
	}
	return returnValue;
}

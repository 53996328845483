import { logError } from '../../helpers/common';
import {
	CHAT_CLEAR,
	CHAT_FETCHING_HISTORY,
	CHAT_HISTORY_LOADED,
	CHAT_HISTORY_LOADED_ERROR,
	CHAT_INIT,
	CHAT_RELOAD,
	CHAT_LOADING,
	CHAT_UPDATE_HISTORY,
	CHAT_UPDATE_CONVERSATION,
	CHAT_REMOVE_FROM_HISTORY,
	CHAT_RESET,
} from '../constants/chat';
import { locale } from '../../locales/local';
import _ from 'lodash';

const initialState = {
	isLoggedIn: false,
	hasErrors: false as boolean,
	type: '' as string,
	receiver: false as any,
	history: [] as any[],
	errorMessage: '' as string,
	loaderMessage: '' as string,
	emptyMessage: locale.reducers.chat.init_state.syncing_messages as string,
	showLoading: false as boolean,
	showToast: false as boolean,
	conversations: [] as any[],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	let returnValue: any = null,
		history: any;

	switch (action.type) {
		case CHAT_RESET:
			returnValue = {
				...state,
				history: [],
				receiver: false,
			};

			break;

		case CHAT_INIT: {
			//const chatHistory: any = action.payload.history || state.history;
			//action.payload.history && delete action.payload['history'];

			returnValue = {
				...state,
				receiver: action.payload.receiver,
				conversations: action.payload.conversations,
				history: action.payload.history,
				type: action.payload.receiver.jid.includes('conference') ? 'groupchat' : 'chat',
			};

			break;
		}

		case CHAT_RELOAD: {
			const chatHistory = action.payload.history || state.history;
			action.payload.history && delete action.payload['history'];

			returnValue = {
				...state,
				history: chatHistory,
			};
			break;
		}

		case CHAT_CLEAR: {
			returnValue = {
				...state,
				receiver: false,
			};
			break;
		}

		case CHAT_UPDATE_CONVERSATION:
			returnValue = {
				...state,
				history: action.payload.history || state.history,
				receiver: action.payload.receiver || state.receiver,
			};

			break;

		case CHAT_UPDATE_HISTORY:
			history = state.history;

			if (state.receiver && state.receiver.hasOwnProperty('jid')) {
				if (!_.isArray(action.payload)) {
					action.payload = [action.payload];
				}

				for(const newItem of action.payload) {
					if(state.receiver.jid === newItem.from.split('/')[0]) {
						const historyIndex = history.findIndex((item: any) => item.id === newItem.originalId);

						if(historyIndex < 0) {
							history.push(newItem);
						}

						if (newItem.isDeleted) {
							history.splice(historyIndex, 1);
						} else {
							history[historyIndex] = newItem;
						}
					}
				}
			}

			returnValue = {
				...state,
				showLoading: false,
				history: history,
			};
			break;

		case CHAT_REMOVE_FROM_HISTORY:
			history = state.history;

			let receiverJid = state.receiver && state.receiver.hasOwnProperty('jid') && state.receiver.jid === action.payload.from.split('/')[0] ? state.receiver.jid : action.receiver ? action.receiver : undefined;

			if (receiverJid === action.payload.from.split('/')[0]) {
				history.splice(
					history.findIndex((item: any) => item.id === action.payload.id),
					1
				);
			}

			returnValue = {
				...state,
				showLoading: false,
				history: history,
			};
			break;

		case CHAT_FETCHING_HISTORY:
			returnValue = {
				...state,
				showLoading: true,
				loaderMessage: action.payload.loaderMessage,
			};

			break;

		case CHAT_LOADING:
			returnValue = {
				...state,
				showLoading: action.payload.showLoading,
				loaderMessage: action.payload.loaderMessage,
			};

			break;

		case CHAT_HISTORY_LOADED:
			if (!state) {
				logError('chat::reducer: state is empty.  setting to initial state');
				state = initialState;
			}

			history = state?.history;

			if (history?.length > 0) {
				history.push(action.payload.history);
			} else {
				history = action.payload.history;
			}

			returnValue = {
				...state,
				showLoading: false,
				hasError: false,
				history: history,
				conversations: action.payload.conversations,
				emptyMessage: state?.history ? locale.reducers.chat.init_state.no_message : '',
				type: action.payload.type,
			};

			if (action.payload.cb) {
				action.payload.cb();
			}
			break;

		case CHAT_HISTORY_LOADED_ERROR:
			returnValue = {
				...state,
				showLoading: false,
				hasError: true,
				emptyMessage: action.payload.error && action.payload.error.text ? action.payload.error.text : locale.reducers.chat.init_state.error_history,
			};

			break;

		default:
			returnValue = state;
			break;
	}

	return returnValue;
}

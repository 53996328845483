import Dexie from 'dexie';
import dbSchema from '../dbSchema';
import { getInternalStorage } from '../../helpers/common';
import { info, logError } from '../../helpers/common';
 
export const indexedDb = {
	db: undefined as any,
	inError: false as boolean,

	getDb: async () => {
		let response: any;

		if (indexedDb.db) {
			response = indexedDb.db as any;
		} else if (!indexedDb.inError) {
			await indexedDb.initialise();
			response = indexedDb.db as any;
		}

		return response;
	},

	initialise: async () => {
		const cookies = getInternalStorage();
		indexedDb.db = new Dexie(cookies.db, { autoOpen: true });
		indexedDb.db.version(cookies.dbVersion || 11).stores(dbSchema);
		info('indexedDb::initialize: initialized');
	},

	getDatabases: async () => await Dexie.getDatabaseNames(),

	getDatabase: async () => {
		let cookies: any = getInternalStorage();
		await Dexie.exists(cookies.db);
	},

	closeDatabase: async (database: any = undefined) => (await indexedDb.getDb()).close(),

	deleteDatabase: async (database: any = undefined) => {
		let cookies: any = getInternalStorage();
		await Dexie.delete(database || cookies.db);
	},

	get: async (collection: any, sortBy: any = undefined, reverse: Boolean = false) =>
		(await indexedDb.getDb())[collection]
			.toArray()
			.then((result: any[]) => {
				let response: any[] = result;

				if (sortBy) {
					response = response.sort((a: any, b: any) => (a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0));
				}

				if (reverse) {
					response = response.reverse();
				}

				return collection === 'user' ? response[0] : response;
			})
			.catch(async (e: any) => {
				if (!indexedDb.inError) {
					indexedDb.inError = true;
					await indexedDb.initialise();
					return await indexedDb.get(collection, sortBy, reverse);
				} else {
					logError(`indexedDb::get::Open failed querying ${collection}: ${e.stack}`);
					return [];
				}
			}),

	where: async (collection: any, key: String, value: any, sortBy: any = undefined, reverse: Boolean = false) => {
		return (await indexedDb.getDb())[collection]
			.where(key)
			.equals(value)
			.toArray()
			.then((result: any[]) => {
				let response: any[] = result;

				if (sortBy) {
					response = response.sort((a: any, b: any) => (a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0));
				}

				if (reverse) {
					response = response.reverse();
				}

				return response;
			})
			.catch(async (e: any) => {
				if (!indexedDb.inError) {
					indexedDb.inError = true;
					await indexedDb.initialise();
					return await indexedDb.where(collection, key, value, sortBy, reverse);
				} else {
					logError(`indexedDb::where::Open ${collection} failed querying ${key} for ${value}: ${e.stack}`);
					return [];
				}
			});
	},

	whereObject: async (collection: any, query: any, sortBy: any = undefined, reverse: Boolean = false) =>
		await (
			await indexedDb.getDb()
		)[collection]
			.where(query)
			.toArray()
			.then((result: any) => {
				let response: any[] = result;
				if (sortBy) {
					response = response.sort((a: any, b: any) => (a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0));
				}

				if (reverse) {
					response = response.reverse();
				}

				return response;
			})
			.catch(async (e: any) => {
				if (!indexedDb.inError) {
					indexedDb.inError = true;
					await indexedDb.initialise();
					return await indexedDb.whereObject(collection, query, sortBy, reverse);
				} else {
					logError(`indexedDb::whereObject::Open ${collection} failed querying ${query}: ${e.stack}`);
					return [];
				}
			}),

	whereIn: async (collection: any, index: string, range: any[], sortBy: any = undefined, reverse: Boolean = false) =>
		await (
			await indexedDb.getDb()
		)[collection]
			.where(index)
			.inAnyRange([range])
			.toArray()
			.then((result: any) => {
				let response: any[] = result;
				if (sortBy) {
					response = response.sort((a: any, b: any) => (a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0));
				}

				if (reverse) {
					response = response.reverse();
				}

				return response;
			})
			.catch(async (e: any) => {
				if (!indexedDb.inError) {
					indexedDb.inError = true;
					await indexedDb.initialise();
					return await indexedDb.whereIn(collection, index, range, sortBy, reverse);
				} else {
					logError(`indexedDb::whereObject::Open ${collection} failed querying ${index} for ${JSON.stringify(range)}: ${e.stack}`);
					return [];
				}
			}),

	clear: async (collection: any) => (await indexedDb.getDb())[collection].clear(),

	put: async (collection: any, data: any, key: Number = -1) => {
		let response: any;

		if (key >= 0) {
			response = (await indexedDb.getDb())[collection]
				.put(data, key)
				.then(() => data)
				.catch(async (e: any) => {
					if (!indexedDb.inError) {
						indexedDb.inError = true;
						await indexedDb.initialise();
						return await indexedDb.put(collection, data, key);
					} else {
						logError(`Open failed: for ${collection} failed putting ${data} at ${key}: ${JSON.stringify(e)}`);
						return data;
					}
				});
		} else {
			response = (await indexedDb.getDb())[collection]
				.put(data)
				.then(() => data)
				.catch(async (e: any) => {
					if (!indexedDb.inError) {
						indexedDb.inError = true;
						await indexedDb.initialise();
						return await indexedDb.put(collection, data, key);
					} else {
						logError(`indexedDb::put::Open failed: ${e.stack}`);
						return data;
					}
				});
		}

		return response;
	},

	bulkPut: async (collection: any, data: any) => {
		return (await indexedDb.getDb())[collection].bulkPut(data).catch(async (e: any) => {
			if (!indexedDb.inError) {
				indexedDb.inError = true;
				await indexedDb.initialise();
				return await indexedDb.bulkPut(collection, data);
			} else {
				logError(`indexedDb::bulkPut::Open for ${collection} failed putting ${JSON.stringify(data)}: ${e.stack}`);
				return data;
			}
		});
	},

	putWhere: async (collection: any, key: any, value: any, data: any) => {
		return (await indexedDb.getDb())[collection]
			.where(key)
			.equals(value)
			.modify(data)
			.catch(async (e: any) => {
				if (!indexedDb.inError) {
					indexedDb.inError = true;
					await indexedDb.initialise();
					return await indexedDb.putWhere(collection, key, value, data);
				} else {
					logError(`indexedDb::putWhere::Open for ${collection} failed putting ${key} at ${value}: ${e.stack}`);
					return data;
				}
			});
	},

	modifyWhere: async (collection: any, key: any, value: any, data: any) => await indexedDb.putWhere(collection, key, value, data),

	delete: async (collection: any, data: any) =>
		(await indexedDb.getDb())[collection]
			.where(data.deleteBy)
			.equals(data[data.deleteBy])
			.delete()
			.then(() => info(`Deleted from ${collection}: ${data._id || data.id}`))
			.catch(async (e: any) => {
				if (!indexedDb.inError) {
					indexedDb.inError = true;
					await indexedDb.initialise();
					return await indexedDb.delete(collection, data);
				} else {
					logError(`indexedDb::delete::Open failed: ${e.stack}`);
				}
			}),
};

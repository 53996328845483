import React from 'react';
import { IonImg, IonThumbnail } from '@ionic/react';
import './style.scss';
import NotificationBadge from '../../../components/NotificationBadge';

const ConversationProfilePhoto: React.FC<any> = ({ conversation }) => {
	return (
		<div className="conversation-profile-pic-container">
			<IonThumbnail className="thumb-user" slot="start" onClick={() => {}}>
				<IonImg src={conversation.groupPhoto || conversation.profilePhoto || 'assets/img/upload-photo-holder.png'}></IonImg>
			</IonThumbnail>
			<NotificationBadge unreadCount={conversation.unreadCount} />
		</div>
	);
};
export default ConversationProfilePhoto;

import React, { useState } from 'react';
import { IonItemOptions, IonItemOption } from '@ionic/react';
import './style.scss';
import { info } from '../../../helpers/common';

const ConversationItemOptions: React.FC<any> = ({ ionItemSlideRef }) => {
	const [showConfirmFor, setShowConfirmFor] = useState('');

	return (
		<IonItemOptions className="conversation-options" side="end">
			{showConfirmFor !== '' ? (
				<IonItemOption
					className={'confirm-btn ' + (showConfirmFor ? showConfirmFor : '')}
					onClick={() => {
						setShowConfirmFor('');
						info('confirm-btn');
						ionItemSlideRef?.current?.close();
					}}
				>
					Confirm
				</IonItemOption>
			) : (
				<>
					<IonItemOption
						className="mark-unread-btn"
						onClick={() => {
							ionItemSlideRef?.current?.close();
							info('Mark as Unread clicked');
						}}
					>
						Mark as Unread
					</IonItemOption>
					<IonItemOption
						className="hide-btn"
						onClick={() => {
							setShowConfirmFor('hide');
							info('Hide clicked');
						}}
					>
						Hide
					</IonItemOption>
					<IonItemOption
						className="delete-btn"
						onClick={() => {
							setShowConfirmFor('delete');
							info('Delete clicked');
						}}
					>
						Delete
					</IonItemOption>
				</>
			)}
		</IonItemOptions>
	);
};

export default ConversationItemOptions;

import { combineReducers } from 'redux';
import pubsub from './pubsub';
import auth from './auth';
import global from './global';
import register from './register';
import dashboard from './dashboard';
import xmpp from './xmpp';
import chat from './chat';
import profile from './userProfile';
import searchContact from './searchContact';
import addContacts from './addContacts';

export default combineReducers({ global, pubsub, auth, register, dashboard, xmpp, chat, profile, searchContact, addContacts });

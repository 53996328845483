import React from 'react'
import { IonButton, IonContent, IonPage } from '@ionic/react';

class Call extends React.Component {
    render() {
        return <IonPage>
            <IonContent>
                <IonButton color="secondary" routerLink='/vv'>make a call</IonButton>
                <IonButton color="success" routerLink='/Receivervv'>Receive call</IonButton>
            </IonContent>
        </IonPage>
    }
}

export default Call
import { LOGIN_REQUESTED, LOGIN_FAILED, REGISTER, LOGIN_INIT_CONTACTS, LOGIN_SUCCESS, USER_AUTHENTICATED } from '../constants/auth';
import { AUTH_SHOW_LOADER, AUTH_FORM_ERROR, AUTH_RESET_VALUES, USER_LOGIN } from '../constants/common';
import { DASHBOARD_INIT } from '../constants/dashboard';
import { v4 as uuidv4 } from 'uuid';
import md5 from 'md5';
import { apiService } from '../../services/apiService';
import { getInternalStorage, setInternalStorage, logError, isBlank, prefixPhotos, info } from '../../helpers/common';
import store from '../store';
import _ from 'lodash';
import { locale } from '../../locales/local';
import { xmpp } from '../../services/xmpp';

export const resetValues = () => ({ type: AUTH_RESET_VALUES });

export const authenticated = async (user: any, payload: any = {}) => {
	if(!isBlank(payload)) {
		info('auth::authenticate: logged in');
		const isWebBrowser = 'serviceWorker' in window.navigator;

		let cookies = getInternalStorage(),
			messageLimit = 1,
			refreshRequired = true;

		user = await prefixPhotos(user);
		store.dispatch({ type: USER_LOGIN, payload: user });
		store.dispatch({ type: USER_AUTHENTICATED, payload: user });
		setInternalStorage('active', true);
		setInternalStorage('token', user.token);
		
		// is this a personalDevice?  If not, delete any databases found here
		if (!payload.personalDevice) {

			if(isWebBrowser) {
				let databases = (await apiService.listDatabases()).filter((_database: any) => !_database.includes('workbox'));

				for (let dbIndex = 1; dbIndex < databases.length; dbIndex++) {
					await apiService.deleteDatabase(databases[dbIndex]);
				}
			}

		} else if (payload.personalDevice && cookies.db) {
			refreshRequired = false;
		}
			
		cookies.db = md5(`${user.userId}`);
		setInternalStorage('db', cookies.db);

		setInternalStorage('personalDevice', payload.personalDevice);
		store.dispatch({ type: LOGIN_SUCCESS, payload: user });
		info('auth::authenticated: user authenticated.');
		user.conversationHash = md5(`${user.jid}_${user.notepadJid}`);
		setInternalStorage('uuid', payload.uuid);
		
		await apiService.addUser(user);
		info('auth::authenticated: user added.');

		store.dispatch({ type: LOGIN_INIT_CONTACTS, payload: locale.global.fetching_contacts });
		info('auth::authenticated: fetching contacts ...');

		let contacts: any = await apiService
			.getContacts(true)
			.catch((error: any) => logError(`auth::authenticated::getContacts::error: ${error}`));

		info(`auth::authenticated: ${contacts.length} contacts will be evaluated`);
		info(`auth::authenticated: fetching groups ...`);
		
		let groups: any = await apiService
			.getGroups(true)
			.catch((error: any) => logError(`getGroups::error: ${error}`));
			
		info(`auth::authenticated: ${groups.length} groups will be evaluated`);

		const addConversationAttributes = async (conversation: any, type: string) => {
			let conversationHash: any = {
				conversationHash: md5(`${user.jid}_${conversation.jid}`),
				limit: messageLimit,
			};

			let messages: any;
			
			if(!refreshRequired) {
				messages = await apiService.getMessagesByHash(conversationHash.conversationHash);
			}

			conversation = {
				...conversation,
				conversationHash: conversationHash.conversationHash,
				lastMessage: !refreshRequired ? messages[messages.length - 1] : {},
				firstMessage: !refreshRequired ? messages[0] : {},
				unreadCount: 0,
				unreadMessages: [],
				taggedCount: 0,
				taggedMessages: [],
				type: type,
			};

			if(conversation.type === 'chat') {
				conversation.alias = user.contacts.find((_contact: any) => _contact._id === conversation._id)?.alias || conversation.alias;
			}
			else {
				conversation.members.forEach((_member: any) => {
					let _contact = contacts.find((_contact: any) => _contact._id === _member._id);
					_member.alias = _contact?.alias || _member.alias;	
				})
			}

			return [conversation, conversationHash];
		};

		let conversationHashes: any[] = [];

		for (let contactIndex in contacts) {
			let conversationHash: any;
			//info(`auth::authenticated: adding conversation attributes for ${contacts[contactIndex].userId} ...`);
			[contacts[contactIndex], conversationHash] = await addConversationAttributes(contacts[contactIndex], 'chat');

			if (contacts[contactIndex].status === 'confirmed') {
				conversationHashes.push(conversationHash);
			}
		}

		for (let groupIndex in groups) {
			let conversationHash: any;
			//info(`auth::authenticated: adding conversation attributes for ${groups[groupIndex].groupname} ...`);
			[groups[groupIndex], conversationHash] = await addConversationAttributes(groups[groupIndex], 'groupchat');
			groups[groupIndex].status = 'confirmed';
			conversationHashes.push(conversationHash);

			groups[groupIndex].members.forEach((_member: any) => {
				let checkMember = contacts.find((_contact: any) => _contact.userId === _member.userId);

				if(checkMember) {
					_member = checkMember;
				}
			})
		}

		const conversations = [...contacts, ...groups];

		info('auth::authenticated: adding contacts to db ...');
		await apiService.addContacts(conversations);

		info('auth::authenticated: adding groups to db ...');
		await apiService.addGroups(groups);

		if (refreshRequired) {
			conversationHashes.push({ conversationHash: user.conversationHash, limit: 1 });
			info(`auth::authenticated: fetching last message for ${conversationHashes.length} conversations ...`);
			await apiService.refreshMessages(true, conversations, conversationHashes, payload.history);
		} else {
			//xmpp.setMessagesLoaded();
			store.dispatch({ type: DASHBOARD_INIT, payload: { conversations: conversations } });
			payload.history.push('/auth');
		}
	}
};
export const login = async (payload: any) => {
	store.dispatch({ type: LOGIN_REQUESTED, payload: {} });
	let cookies = getInternalStorage();

	payload.uuid = cookies.uuid || uuidv4();

	let user: any = await apiService.authenticate(_.omit(payload, ['personalDevice', 'history']));

	if (user.Error) {
		store.dispatch({ type: LOGIN_FAILED, payload: user });
	} else {
		setInternalStorage('up', user.password);
		await authenticated(user, payload);
	}
};

export const formHasErrors = (payload: any) => ({ type: AUTH_FORM_ERROR, payload: payload });
export const isLoading = (loader: boolean) => ({ type: AUTH_SHOW_LOADER, payload: loader });
export const register = (payload: any) => ({ type: REGISTER, payload: payload });

import fetch from 'node-fetch';
import { Config } from '../config/config';
import _ from 'lodash';
import { info, isBlank, logError, setInternalStorage, getInternalStorage, isJsonString, sleep } from '../helpers/common';
import { apiService } from './apiService';
import { createPubsub } from '../helpers/pubsub';
import axios from 'axios';

const pubsub = createPubsub('com.be-society'),
	initWSS = async () => {
		api.wss = new WebSocket('wss://local.charlesassaf.com:3100');

		while(api.wss.readyState === 0) {
			await sleep(200);
		}
		
		api.wss.onmessage = ((event: any) => {
			let data: any = isJsonString(event.data) ? JSON.parse(event.data) : event.data;
			info('serverApi::initWSS.onMessage: received: %s', data);
		});
	},
	apiFetch = async (path: string, data: any = {}, noIdNeeded: Boolean = false) => {
		const headers: any = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			cookies: any = getInternalStorage(),
			user = !noIdNeeded ? await apiService.me() : undefined;

		let response: any;

		// sanity check if network is considered offline
		if (window.navigator.onLine && (!cookies.network || cookies.network === 'offline')) {
			// attempt to send a ping to the api server

			await fetch(`${Config.apiUrl}/ping/`, {
				method: 'POST',
				headers: headers,
				timeout: cookies.mode === 'DEV' ? 60000 : 5000,
			})
				.then((res: any) => {
					cookies.network = 'online';
					setInternalStorage('network', 'online');
				})
				.catch((err) => {
					logError('serverApi::fetch:: API server is offline');
				});
		}

		if (window.navigator.onLine && cookies.network === 'online') {
			if (cookies.isApp) {
				info(`serverApi::fetch:: requesting: ${Config.apiUrl}/${path}/ with`, data);
			}

			if (!noIdNeeded) {
				data._id = user._id;
				data.uuid = user.uuid;
				headers.Authorization = `Bearer ${cookies.token}`;
			}

			try {
				if (path === 'uploadMedia') {
					const controller = new AbortController();

					response = await axios({
						method: 'post',
						url: `${Config.apiUrl}/${path}/`,
						headers: headers,
						timeout: cookies.mode === 'DEV' ? 60000 : 5000,
						signal: controller.signal,
						data: JSON.stringify(_.omitBy(data, isBlank)),
						onUploadProgress: (progressEvent: any) => pubsub.send({ action: 'uploadMedia', messageKey: data.messageKey, percentComplete: Math.round((progressEvent.loaded * 100) / progressEvent.total) }),
					})
						.then(function (_response) {
							info('serverApi::fetch::axios:', _response);
							return controller;
						})
						.catch((error: any) => {
							logError(`serverApi::fetch::axios: Error requesting: ${Config.apiUrl}/${path}/`, error);
							response = { Error: error };
							throw response;
						});
				} else {
					response = await fetch(`${Config.apiUrl}/${path}/`, {
						method: 'POST',
						headers: headers,
						timeout: cookies.mode === 'DEV' ? 60000 : 5000,
						body: JSON.stringify(_.omitBy(data, isBlank)),
					})
						.then((res: any) => res.json())
						.catch((error) => {
							logError(`serverApi::fetch::Error requesting: ${Config.apiUrl}/${path}/`, error);
							response = { Error: error };
							throw response;
						});

					// test out a socket send
					/*if (!api.socketStarted) {
						await initWSS();
						api.socketStarted = true;
					}

					api.wss.send(JSON.stringify(_.omitBy(data, isBlank)));*/
				}
			} catch (err) {
				let error: any = err;
				logError('serverApi::fetch::Error in try:', error.message);
				setInternalStorage('network', 'offline');
				response = { Error: err };
				throw response;
			}
		} else {
			response = { Error: 'Network is offline' };
			throw response;
		}

		return response;
	},
	api: any = {
		wss: undefined  as any,

		socketStarted: false as Boolean,

		ping: async () => await apiFetch('ping', null, true),

		authenticate: async (data: any) => await apiFetch('authenticate', data, true),

		checkAvailabliity: async (data: any) => await apiFetch('checkUserId', data, true),

		registerUser: async (data: any) => await apiFetch('addUser', data, true),

		getUsers: async (data: any) => await apiFetch('getUsers', data),

		updateUser: async (data: any) => await apiFetch('updateUser', data),

		getContacts: async () => await apiFetch('getContacts'),

		addContact: async (data: any) => await apiFetch('addContact', data),

		confirmContact: async (data: any) => await apiFetch('confirmContact', data),

		denyContact: async (data: any) => await apiFetch('denyContact', data),

		blockContact: async (data: any) => await apiFetch('denyContact', data),

		updateContact: async (data: any) => await apiFetch('updateContact', data),

		deleteContact: async (data: any) => await apiFetch('deleteContact', data),

		getGroups: async () => await apiFetch('getGroups'),

		checkGroupname: async (data: any) => await apiFetch('Groupname', data),

		addGroup: async (data: any) => await apiFetch('addGroup', data),

		updateGroup: async (data: any) => await apiFetch('updateGroup', data),

		deleteGroup: async (data: any) => await apiFetch('deleteGroup', data),

		getMessages: async (data: any) => await apiFetch('getMessages', data),

		getSequence: async (data: any) => await apiFetch('getSequence', data),

		updateMessage: async (data: any) => await apiFetch('updateMessage', data),

		recallMessage: async (data: any) => await apiFetch('recallMessage', data),

		uploadMedia: async (data: any) => await apiFetch('uploadMedia', data),

		updateReadStatus: async (data: any) => await apiFetch('updateReadStatus', data),

		translateMessage: async (data: any) => await apiFetch('translateMessage', data),

		fetchLinkPreview: async (data: any) => await apiFetch('fetchLinkPreview', data),

		sendStatus: async (data: any) => await apiFetch('status', data, true),

		ackControl: async (data: any) => await apiFetch('ackControl', data),
	};

export const serverApi = api;

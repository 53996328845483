import { IonModal, IonToolbar, IonSearchbar, IonButtons, IonButton, IonTitle } from '@ionic/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IconTextButton from '../../components/IconTextButton';
import './style.scss';
import { searchPageLoading, searchUserContact } from '../../redux/actions/searchContact';
import { connect } from 'react-redux';
import { locale } from '../../locales/local';

interface SearchContactsModalProps {
	show: boolean;
	onClose?: any;
	searchHandler?: any;
	searchTypeHandler?: any;
}

const SearchContactsModal: React.FC<SearchContactsModalProps> = ({ show, onClose, searchHandler, searchTypeHandler, ...props }) => {
	const inputRef: React.RefObject<HTMLIonSearchbarElement> = useRef(null);

	const options = useMemo(
		() => [
			{ icon: 'group.svg', title: locale.memo.groups, type: 'groups' },
			{ icon: 'album.svg', title: locale.memo.media, type: 'media' },
			{ icon: 'file.svg', title: locale.memo.files, type: 'files' },
			{ icon: 'audio.svg', title: locale.memo.audio, type: 'audio' },
			{ icon: 'links.svg', title: locale.memo.links, type: 'links' },
			{ icon: 'todos.svg', title: locale.memo.todo, type: 'todos' },
			{ icon: 'transactions.svg', title: locale.memo.transactions, type: 'transactions' },
			{ icon: 'contracts.svg', title: locale.memo.contracts, type: 'contracts' },
			{ icon: 'mini-programs.svg', title: locale.memo.mini_prog, type: 'miniprograms' },
			{ icon: 'heart.svg', title: locale.memo.favorite, type: 'favorite' },
		],
		[]
	);
	const [searchText, setSearchText] = useState<string>('');

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				inputRef.current?.setFocus();
			}, 100);
			setTimeout(() => {
				inputRef.current?.setFocus();
			}, 500);
		} else {
			setSearchText('');
		}
	}, [show, inputRef]);

	const onCloseCallBack = useCallback(
		(event: any) => {
			onClose && onClose();
		},
		[onClose]
	);

	return (
		<IonModal mode="ios" showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="search-contacts-tool-modal" animated={false}>
			<IonToolbar>
				<IonTitle>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							searchHandler(searchText);
						}}
					>
						<IonSearchbar
							ref={inputRef}
							animated={false}
							className="searchbar-wrapper"
							style={{ color: '#000000' }}
							debounce={1}
							value={String(searchText)}
							onIonChange={(e) => {
								setSearchText(e.detail.value!);
								searchTypeHandler && searchTypeHandler(e.detail.value!);
							}}
							showCancelButton="never"
						></IonSearchbar>
					</form>
				</IonTitle>

				<IonButtons slot="end">
					<IonButton fill="clear" onClick={onCloseCallBack}>
						{locale.global.cancel}
					</IonButton>
				</IonButtons>
			</IonToolbar>
			<div className="tools-container" onClick={onCloseCallBack}>
				{options.map((item, key) => {
					return (
						<IconTextButton
							containerStyle={{ width: '50%', margin: 0, padding: '16px' }}
							iconStyle={{ width: '30px', height: '30px' }}
							icon={item.icon}
							title={item.title}
							key={key}
							onClick={(event: any) => {
								event.stopPropagation();
							}}
						></IconTextButton>
					);
				})}
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => {
	return {
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		searchContact: state.searchContact,
	};
};
const mapDispatchToProps = (dispatch: any) => ({
	searchUserContact: (payload: String) => dispatch(searchUserContact(payload)),
	searchPageLoading: (payload: String) => dispatch(searchPageLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContactsModal);

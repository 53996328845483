import React, { useEffect, useRef } from 'react';
import { IonIcon, IonRow, IonGrid, IonText, IonModal } from '@ionic/react';
import './style.scss';
import './thread.scss';

type CardProps = {
	popupType?: string;
	show: boolean;
	isEnlarge: boolean;
	canRecall: boolean;
	canTranslate: boolean;
	align: string;
	onHide?: Function;
	snippet: any;
	selectedThreadElementPosition: any;
	topRowItems: any;
	bottomRowItems: any;
	onItemSelect?: Function;
};

export const EmojiPopup = ({ show, popupType, canRecall, canTranslate, align, snippet, isEnlarge, selectedThreadElementPosition, topRowItems, bottomRowItems, onItemSelect, onHide }: CardProps) => {
	const topRowRef: React.RefObject<HTMLIonRowElement> = useRef(null);
	const msgRef: React.RefObject<HTMLIonRowElement> = useRef(null);
	const gridRef: React.RefObject<HTMLIonGridElement> = useRef(null);

	let topRowHeight = 0;
	if (popupType === 'emoji') {
		topRowHeight = 58;
	} else if (popupType === 'quickFunction') {
		topRowHeight = 79;
	}

	const topPosition = selectedThreadElementPosition?.top - topRowHeight;

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				const checkGridBottom = (gridRef?.current?.clientHeight || 0) + topPosition;
				if (checkGridBottom > window.innerHeight) {
					const gridRefObj: any = gridRef.current;
					gridRefObj.style.top = topPosition - (checkGridBottom - window.innerHeight) + 'px';
				}
			}, 50);
		}
	}, [show, gridRef, topPosition, gridRef?.current?.clientHeight]);

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				let selection: any = window.getSelection();
				let myElement = document.getElementsByClassName('message')[0];
				if (selection.rangeCount > 0) {
					selection.removeAllRanges();
				}
				if (myElement) {
					let range = document.createRange();
					range.selectNode(myElement);
					selection.addRange(range);
				}
			}, 50);
		}
	}, [show, msgRef]);

	const gridStyle: any = {
		position: 'absolute',
		top: topPosition > 0 ? topPosition : 0 + 'px',
		minWidth: '70vw',
		display: 'flex',
		flexDirection: 'column',
	};

	if (show) {
		if (align === 'left') {
			gridStyle.left = selectedThreadElementPosition?.left - 20;
			gridStyle.alignItems = 'flex-start';
		} else if (align === 'right') {
			gridStyle.right = window.innerWidth - selectedThreadElementPosition?.right - 20;
			gridStyle.alignItems = 'flex-end';
		}
	}

	return (
		<IonModal
			mode="md"
			showBackdrop={false}
			isOpen={show}
			onDidDismiss={() => {
				onHide && onHide();
			}}
			swipeToClose={false}
			cssClass={'emoji-popup-container-modal'}
			animated={false}
		>
			<div
				id="emojiPopupContainer"
				className={'emoji-popup-container ' + align + '' + (isEnlarge ? ' enlarge' : '')}
				onClick={() => {
					onHide && onHide();
				}}
			>
				<IonGrid
					onClick={(event) => {
						if (!isEnlarge) {
							event.stopPropagation();
						}
					}}
					style={gridStyle}
					ref={gridRef}
				>
					{!isEnlarge && (
						<IonRow className="emoji-popover-row-top" ref={topRowRef}>
							<>
								{topRowItems &&
									topRowItems.map((item: any, key: any) => {
										if (!canRecall && item.type === 'recall') {
											return null;
										} else if (canRecall && item.type === 'delete') {
											return null;
										}

										return (
											<ChatOptionItem
												key={key}
												icon={item.icon}
												size={item.size}
												title={item.title}
												hide={item.forViewAdjustment}
												onClick={() => {
													onItemSelect && onItemSelect(item);
												}}
											/>
										);
									})}
							</>
						</IonRow>
					)}
					<IonRow ref={msgRef} className="message" style={{ maxWidth: selectedThreadElementPosition?.width + 10 + 'px', marginRight: align === 'right' ? '20px' : 0, marginLeft: align === 'left' ? '20px' : 0 }}>
						{snippet}
					</IonRow>
					{!isEnlarge && (
						<IonRow className="emoji-popover-row-top">
							<>
								{bottomRowItems &&
									bottomRowItems.map((item: any, key: any) => {
										if (item.hide) {
											return null;
										}

										if (!canTranslate && item.type === 'translate') {
											return null;
										}

										return (
											<ChatOptionItem
												key={key}
												size={item.size}
												icon={item.icon}
												title={item.title}
												hide={item.forViewAdjustment}
												onClick={() => {
													onItemSelect && onItemSelect(item);
												}}
											/>
										);
									})}
							</>
						</IonRow>
					)}
				</IonGrid>
			</div>
		</IonModal>
	);
};

interface ChatOptionItemProps {
	icon?: string;
	title?: string;
	size?: string;
	hide?: boolean;
	onClick?: any;
}

const ChatOptionItem: React.FC<ChatOptionItemProps> = ({ icon, hide, title, size, onClick }) => {
	return (
		<div className="popup-item" style={{ opacity: hide ? 0 : 1 }}>
			{icon && <IonIcon style={{ width: size || '26px', height: size || '26px' }} onClick={onClick} size="large" src={icon}></IonIcon>}
			{title && <IonText style={{ fontSize: '11px', color: '#000000', marginTop: 8 }}>{title}</IonText>}
		</div>
	);
};

import React from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonItemDivider, IonLabel, IonLoading, IonNote, IonPage, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';
import { IonList, IonItem } from '@ionic/react';
import { ContactsList } from './components/ContactsList';
import { connect } from 'react-redux';
import { apiService } from '../../services/apiService';
import EnumService from '../../services/enumService';
import { SelectedContacts } from './components/SelectedContacts';
import { resetValues } from '../../redux/actions/dashboard';
import ShareMessageModal from '../../modals/shareMessageModal';
import ToastStylish from '../../components/ToastStylish';
import { isJsonString, sortByAlias } from '../../helpers/common';
import { initChat } from '../../redux/actions/chat';
import store from '../../redux/store';
import { DASHBOARD_LAST_MESSAGE_CLEAR } from '../../redux/constants/dashboard';
import { locale } from '../../locales/local';
import { info } from '../../helpers/common';
import _ from 'lodash';
import { xmpp } from '../../services/xmpp';
const MessageIcon = './assets/icon/message.svg';
const WorkIcon = './assets/icon/basket.svg';
const ContactIcon = './assets/icon/contact.svg';


interface iProps extends RouteComponentProps<{ name: string }> {
	//addGroup: Function;
	initChat: Function;
	resetValues: Function;
	location: any;
	loggedInUser: any;
	xmpp: any;
	dashboard: any;
}
interface iState {
	isSelectMultipleContacts: boolean;
	loggedInUser?: any;
	actionType?: any;
	contacts: any;
	selectedContacts?: any[];
	openShareMessageModal: boolean;
	searchText: string;
	showToast: boolean;
	toastMessage: string;
	toastIcon: any;
	shareData?: any;
	contactDetail?: any;
}

class ChatContacts extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;

	constructor(props: iProps) {
		super(props);
		const locationState = props.location.state,
			actionType = locationState?.actionType,
			chatDetail = locationState?.chatDetail,
			contactDetail: any = locationState?.contactDetail;

		let shareData: any;

		if (actionType === EnumService.ChatContactsPageActionType.ForwardMessage) {
			shareData = chatDetail;
		} else if (actionType === EnumService.ChatContactsPageActionType.ShareContact) {
			shareData = contactDetail;
		}

		this.state = {
			loggedInUser: undefined,
			contacts: undefined,
			shareData: shareData,
			showToast: false,
			toastMessage: '',
			toastIcon: '',
			isSelectMultipleContacts: false,
			openShareMessageModal: false,
			actionType: actionType,
			searchText: '',
			selectedContacts: [],
		};

		this.props.resetValues();
	}

	getData = async () => {
		let user = await apiService.me(),
			contacts: any = await apiService.getContacts(false, 'confirmed').then((_contacts: any) => {
				let contacts: any = [];

				if (_contacts && _contacts.length > 0) {
					contacts = _contacts.sort(sortByAlias);
				}

				return contacts;
			});

		return [user, contacts];
	};

	setPageData = async () => {
		let [user, contacts] = await this.getData();

		this.setState((state, props) => ({
			...state,
			loggedInUser: user,
			contacts: contacts,
		}));

		this.componentIsMounted = true;
	};

	componentDidMount = () => {
		this.setPageData();
	};

	componentDidUpdate = () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			this.setPageData();
		}
	};

	componentWillUnmount() {
		this.componentIsMounted = false;
	}

	_onContactSelect = (cont: any) => {
		if (this.state.isSelectMultipleContacts) {
			try {
				if (!this.state.selectedContacts || this.state.selectedContacts.length < 9) {
					let tmpList: any = this.state.selectedContacts || [];
					const contactIdIndex = tmpList.indexOf(cont?._id);
					if (contactIdIndex === -1) {
						tmpList.push(cont._id);
					} else {
						tmpList.splice(contactIdIndex, 1);
					}
					this.setState({ selectedContacts: tmpList });
				}
			} catch (error) {}
		} else {
			try {
				this.setState({ selectedContacts: [cont._id], openShareMessageModal: true });
			} catch (error) {}
		}
	};

	_shareDataWithContact = async (message: string) => {
		this.setState({ openShareMessageModal: false });

		const { actionType, shareData, isSelectMultipleContacts, selectedContacts } = this.state;
		if (selectedContacts && selectedContacts.length > 0) {
			let msgBody: any = {},
				messageType: string = '',
				receiverContact: any = {};

			if (actionType === EnumService.ChatContactsPageActionType.ShareContact) {
				msgBody = {
					message: message,
					data: this.state.shareData,
				};
				messageType = EnumService.ChatMessageType.CONTACT;
			} else if (actionType === EnumService.ChatContactsPageActionType.ForwardMessage) {
				let chatBody = isJsonString(shareData.body) ? JSON.parse(shareData.body) : shareData.body;
				msgBody = chatBody.body;
				messageType = chatBody.messageType;
			}

			for (let contact of this.state.contacts) {
				if (_.includes(selectedContacts, contact._id)) {
					receiverContact = contact;
					await xmpp.sendMessage(receiverContact.jid, msgBody, messageType, receiverContact.lastMessage);
				}
			}

			this.setState({
				openShareMessageModal: false,
				showToast: true,
				toastMessage: locale.contacts.chat_contacts.sent,
				toastIcon: checkmarkOutline,
				selectedContacts: [],
				isSelectMultipleContacts: false,
			});

			setTimeout(() => {
				if (isSelectMultipleContacts) {
					this.props.history.push('/auth');
				} else {
					if (receiverContact) {
						receiverContact.handlerText = locale.contacts.chat_contacts.start_chat;
						this.props.initChat(receiverContact);
						store.dispatch({ type: DASHBOARD_LAST_MESSAGE_CLEAR, payload: receiverContact });
						this.props.history.push('/chat');
					} else {
						this.props.history.push('/auth');
					}
				}
			}, 2000);
		}
	};

	render() {
		if (this.componentIsMounted) {
			const { selectedContacts } = this.state;

			return (
				<>
					<IonPage className="contacts-page chat-contacts-page">
						<IonToolbar className="header toolbar-wrapper">
							<IonButtons slot="start">
								{this.state.isSelectMultipleContacts ? (
									<IonButton
										fill="clear"
										onClick={() => {
											this.setState({ isSelectMultipleContacts: false, selectedContacts: [] });
										}}
									>
										<IonIcon slot="icon-only" icon={closeOutline} />
									</IonButton>
								) : (
									<IonButton
										fill="clear"
										onClick={() => {
											this.props.history.goBack();
										}}
									>
										{locale.contacts.chat_contacts.close}
									</IonButton>
								)}
							</IonButtons>
							<IonTitle> {this.state.isSelectMultipleContacts ? locale.contacts.chat_contacts.select_multiple : locale.contacts.chat_contacts.select_chat}</IonTitle>
							<IonButtons slot="end">
								{this.state.isSelectMultipleContacts ? (
									<IonButton
										className="done-button"
										disabled={this.state.selectedContacts?.length === 0}
										fill="clear"
										onClick={() => {
											this.setState({ openShareMessageModal: true });
										}}
									>
										Done( {this.state.selectedContacts?.length} )
									</IonButton>
								) : (
									<IonButton fill="clear" onClick={() => this.setState({ isSelectMultipleContacts: true })}>
										Multiple
									</IonButton>
								)}
							</IonButtons>
						</IonToolbar>

						<IonToolbar>
							<IonSearchbar
								animated={true}
								mode="ios"
								className="searchbar-wrapper"
								style={{ color: '#000000' }}
								debounce={1}
								value={this.state.searchText}
								onIonChange={(e) => {
									this.setState({ searchText: e.detail.value! });
									// props.searchTypeHandler(e.detail.value!, props);
								}}
								showCancelButton="never"
							></IonSearchbar>
						</IonToolbar>

						{selectedContacts && selectedContacts.length > 0 && (
							<>
								<SelectedContacts contacts={this.state.contacts} selectedContacts={selectedContacts} props={this.props} onContactSelect={this._onContactSelect} />
							</>
						)}

						<IonContent className="main-content-profile">
							<div className="sec-3">
								<IonList className="contact-groups-list">
									{this.state.isSelectMultipleContacts ? (
										<>
											<IonItem
												button
												onClick={() => {
													this.props.history.push('/contacts', {
														actionType: EnumService.ContactsPageActionType.ForwardMessage,
													});
												}}
												lines="none"
												detail={true}
											>
												<IonIcon slot="start" src={ContactIcon} />
												<IonLabel>{locale.contacts.chat_contacts.select_from_contact}</IonLabel>
												<IonNote></IonNote>
											</IonItem>
										</>
									) : (
										<>
											<IonItem button onClick={() => info('New chats')} lines="none" detail={true}>
												<IonIcon slot="start" src={MessageIcon} />
												<IonLabel>{locale.contacts.chat_contacts.new_chat}</IonLabel>
												<IonNote></IonNote>
											</IonItem>
											<IonItem button onClick={() => info('Work Chats')} lines="none" detail={true}>
												<IonIcon slot="start" src={WorkIcon} />
												<IonLabel>{locale.contacts.chat_contacts.work_chat}</IonLabel>
												<IonNote></IonNote>
											</IonItem>
										</>
									)}
								</IonList>
							</div>

							<IonItemDivider>{locale.contacts.chat_contacts.recent_chat}</IonItemDivider>

							<ContactsList
								props={this.props}
								hideAlphabetDevider={true}
								selectedContacts={selectedContacts}
								contacts={this.state.contacts}
								contactListMode={this.state.isSelectMultipleContacts ? EnumService.ContactsListMode.ChooseContactsList : EnumService.ContactsListMode.ChatContactsList}
								onContactSelect={this._onContactSelect}
							/>
						</IonContent>
					</IonPage>

					<IonLoading isOpen={this.props.dashboard.isLoading} message={this.props.dashboard.loaderMessage} />

					<ShareMessageModal
						sharingType={this.state.actionType === EnumService.ChatContactsPageActionType.ShareContact ? 'contact' : 'chat'}
						show={this.state.openShareMessageModal}
						shareData={this.state.shareData}
						contacts={this.state.contacts}
						selectedContacts={selectedContacts}
						onClose={() => this.setState({ openShareMessageModal: false })}
						onSend={(message: any) => {
							this._shareDataWithContact(message);
						}}
					/>
					<ToastStylish
						message={this.state.toastMessage}
						svgIcon={this.state.toastIcon}
						show={this.state.showToast}
						onClose={() => this.setState({ showToast: false, toastMessage: '', toastIcon: null })}
					/>
				</>
			);
		} else {
			return <></>;
		}
	}
}

const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	//addGroup: (payload: any) => dispatch(addGroup(payload)),
	initChat: (payload: any) => dispatch(initChat(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatContacts);

import { IonImg, IonLabel, IonText } from '@ionic/react';
import React from 'react';
import './style.scss';

interface QrCodeComponentProps {
	loggedInUser?: any;
	onClose?: any;
}

const QrCodeComponent: React.FC<QrCodeComponentProps> = ({ loggedInUser, onClose }) => {
	const profileUserName = loggedInUser?.username || loggedInUser?.userId || '';

	return (
		<div
			className="qrcode-component"
			onClick={(event) => {
				event.stopPropagation();
				onClose && onClose();
			}}
		>
			<IonLabel>{loggedInUser?.name}</IonLabel>
			<IonText>{(!profileUserName.includes('@') ? '@' : '') + '' + profileUserName}</IonText>
			<IonImg onClick={(event) => event.stopPropagation()} src={loggedInUser?.qrCode} />
		</div>
	);
};

export default QrCodeComponent;

import React from 'react';
import { IonIcon, IonImg, IonLabel, IonThumbnail } from '@ionic/react';
import './style.scss';
import { addOutline } from 'ionicons/icons';
import { locale } from '../../../locales/local';import _ from 'lodash';

interface iProps {
	props?: any;
	contacts: any;
	highlightContacts?: any;
	showAddContactBtn?: boolean;
	selectedContacts?: any;
	onContactSelect?: Function;
	onAddContact?: Function;
}

export const SelectedContacts: React.FC<iProps> = ({ highlightContacts, contacts, selectedContacts, showAddContactBtn, onContactSelect, onAddContact }) => {
	return (
		<div className="selected-contacts-component">
			<div className="starred-wrapper">
				{contacts.length > 0 &&
					contacts.map((cont: any, index: any) => {
						if (_.includes(selectedContacts, cont._id)) {
							const isHighLight = highlightContacts && _.includes(highlightContacts, cont._id);
							return (
								<div className={'contact-item '} key={index}>
									<IonThumbnail className={isHighLight ? 'highlight' : ''} onClick={() => onContactSelect && onContactSelect(cont)}>
										<IonImg style={{ borderRadius: '3px' }} src={cont.hasOwnProperty('profilePhoto') && cont.profilePhoto ? cont.profilePhoto : 'assets/img/avatar-placeholder.gif'} />
									</IonThumbnail>
									<IonLabel>{cont.name || cont.username}</IonLabel>
								</div>
							);
						}
						return null;
					})}

				{showAddContactBtn && (
					<div className="contact-item add-contact-item">
						<IonThumbnail onClick={() => onAddContact && onAddContact()}>
							<IonIcon icon={addOutline} />
						</IonThumbnail>
						<IonLabel>{locale.contact_list.info.add}</IonLabel>
					</div>
				)}
			</div>
		</div>
	);
};

import {
	DASHBOARD_ADD_CONTACT,
	DASHBOARD_ADD_CONTACT_ERROR,
	DASHBOARD_ADD_CONTACT_FAILED,
	DASHBOARD_ADD_CONTACT_SUCCESS,
	DASHBOARD_ERROR,
	DASHBOARD_FAILED,
	DASHBOARD_REQUESTED,
	DASHBOARD_SEARCH_END,
	DASHBOARD_SEARCH_ERROR,
	DASHBOARD_SEARCH_FAILED,
	DASHBOARD_SEARCH_REQUESTED,
	DASHBOARD_SEARCH_SUCCESS,
	DASHBOARD_SEARCH_SUCCESS_WITH_LOADING,
	DASHBOARD_INIT,
	DASHBOARD_SHOW_MODAL,
	DASHBOARD_SHOW_TOAST,
	DASHBOARD_SUCCESS,
	DASHBOARD_PUSH_CONTACT,
	DASHBOARD_PUSH_CONTACT_ERROR,
	DASHBOARD_LAST_MESSAGE,
	DASHBOARD_SHOW_GROUP_MODAL,
	DASHBOARD_PUSH_GROUP,
	DASHBOARD_CREATE_GROUP_REQUESTED,
	DASHBOARD_CREATE_GROUP_SUCCESS,
	DASHBOARD_CREATE_GROUP_FAILED,
	DASHBOARD_CONFIRM_CONTACT,
	DASHBOARD_CONFIRM_CONTACT_SUCCESS,
	DASHBOARD_LAST_MESSAGE_CLEAR,
	DASHBOARD_SHOW_LOADER,
	DASHBOARD_HIDE_LOADER,
	DASHBOARD_CONFIRM_CONTACT_FAILED,
	DASHBOARD_MESSAGES_LOADED,
} from '../constants/dashboard';
import { DASHBOARD_FORM_ERROR, DASHBOARD_RESET_VALUES } from '../constants/common';
import { isBlank, sortByTimestamp } from '../../helpers/common';
import { locale } from '../../locales/local';
import _ from 'lodash';

const initialState = {
	title: locale.reducers.dashboard.init_state.title,
	searchText: '' as String,
	loaderMessage: locale.reducers.dashboard.init_state.connecting as String,
	unreadCount: 0 as Number,
	hasErrors: false as Boolean,
	errorMessage: '' as String,
	isLoading: true as Boolean,
	isSearching: false as Boolean,
	showToast: false as Boolean,
	showInvite: false as Boolean,
	showGroupModal: false as Boolean,
	isGroupCreated: false as Boolean,
	requestConfirmSuccess: false as Boolean,
	addedGroup: null as any,
	showModal: false as Boolean,
	searchResults: [] as any,
	conversations: [] as any,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	let returnValue: any, conversations: any[];

	switch (action.type) {
		// --------------------- --------------------- common actions  --------------------- ---------------------
		case DASHBOARD_RESET_VALUES:
			returnValue = {
				...state,
				loaderMessage: locale.global.please_wait,
				hasErrors: false,
				errorMessage: '',
				isLoading: false,
				isSearching: false,
				showToast: false,
				searchText: '',
				searchResults: [],
				isGroupCreated: false,
				requestConfirmSuccess: false,
				addedGroup: null,
			};

			break;

		case DASHBOARD_SHOW_LOADER:
			returnValue = {
				...state,
				isLoading: action.payload.loader,
				loaderMessage: action.payload.loader ? action.payload.loaderMessage : '',
			};

			break;

		case DASHBOARD_HIDE_LOADER:
			returnValue = {
				...state,
				isLoading: false,
				loaderMessage: '',
			};

			break;

		case DASHBOARD_FORM_ERROR:
			returnValue = {
				...state,
				isLoading: false,
				hasErrors: true,
				errorMessage: action.payload.errorMessage,
			};

			break;

		// --------------------- --------------------- Main Dashboard specific  --------------------- ---------------------
		case DASHBOARD_INIT:
			
			conversations = _.compact(action.payload?.conversations
				.map((conversation: any) => (conversation.lastMessage && conversation.lastMessage.timestamp ? conversation : false))
				.sort(sortByTimestamp)
				.concat(action.payload?.conversations.map((conversation: any) => conversation?.lastMessage?.timestamp ? false : conversation)));

			returnValue = {
				...state,
				isLoading: false,
				loaderMessage: '',
				conversations: conversations,
			};

			break;

		case DASHBOARD_MESSAGES_LOADED:
			conversations = state.conversations;

			if (!isBlank(action.payload) && !isBlank(conversations)) {
				action.payload.conversationHashes.forEach((_conversationHash: any) => {
					conversations.find((_conversation: any) => _conversation.conversationHash === _conversationHash.conversationHash).loaded = action.payload.loaded;
				});
			}

			returnValue = {
				...state,
				conversations: conversations,
			};

			break;

		case DASHBOARD_LAST_MESSAGE:
			conversations = state.conversations;

			if (!isBlank(action.payload)) {
				conversations[conversations.findIndex((conversation: any) => conversation.conversationHash === action.payload.conversationHash)] = action.payload;
			}

			returnValue = {
				...state,
				conversations: conversations,
				unreadCount: conversations.reduce((acc: any, curVal: any) => (acc += curVal.unreadCount), 0),
			};

			break;

		case DASHBOARD_LAST_MESSAGE_CLEAR:
			returnValue = { ...state, conversations: state.conversations };
			break;

		case DASHBOARD_SHOW_MODAL:
			returnValue = {
				...state,
				showModal: action.payload,
			};

			break;

		case DASHBOARD_SHOW_GROUP_MODAL:
			returnValue = {
				...state,
				showGroupModal: action.payload,
			};

			break;

		case DASHBOARD_REQUESTED:
			returnValue = {
				...state,
				isLoading: true,
				loaderMessage: action.payload.loaderMessage,
			};

			break;

		case DASHBOARD_SUCCESS:
			returnValue = {
				...state,
				isLoading: false,
				loaderMessage: locale.global.please_wait,
				showToast: true,
				hasErrors: false,
				errorMessage: action.payload,
			};

			break;

		case DASHBOARD_FAILED:
			returnValue = {
				...state,
				isLoading: false,
				loaderMessage: locale.global.please_wait,
				hasErrors: true,
				errorMessage: action.payload,
			};

			break;

		case DASHBOARD_ERROR:
			returnValue = {
				...state,
				isLoading: false,
				loaderMessage: locale.global.please_wait,
				hasErrors: true,
				errorMessage: action.payload,
			};

			break;

		// --------------------- --------------------- SEARCH USER  --------------------- ---------------------

		case DASHBOARD_SEARCH_REQUESTED:
			returnValue = {
				...state,
				isLoading: true,
				isSearching: true,
				loaderMessage: action.payload.loaderMessage,
			};

			break;

		case DASHBOARD_SEARCH_END:
			returnValue = {
				...state,
				isLoading: false,
				loaderMessage: locale.global.please_wait,
				isSearching: false,
				showInvite: false,
			};

			break;

		case DASHBOARD_SEARCH_SUCCESS:
			returnValue = {
				...state,
				isLoading: false,
				hasErrors: false,
				isSearching: true,
				showInvite: action.showInvite,
				loaderMessage: locale.global.please_wait,
				searchResults: action.payload,
			};

			break;

		case DASHBOARD_SEARCH_SUCCESS_WITH_LOADING:
			returnValue = {
				...state,
				hasErrors: false,
				isSearching: true,
				loaderMessage: locale.global.please_wait,
				showInvite: action.showInvite,
				searchResults: action.payload,
			};

			break;

		case DASHBOARD_SEARCH_FAILED:
			returnValue = {
				...state,
				isLoading: false,
				loaderMessage: locale.global.please_wait,
				hasErrors: true,
				isSearching: true,
				showToast: true,
				errorMessage: action.payload.Error.message,
			};

			break;

		case DASHBOARD_SEARCH_ERROR:
			returnValue = {
				...state,
				isLoading: false,
				loaderMessage: locale.global.please_wait,
				hasErrors: true,
				isSearching: true,
				showToast: true,
				errorMessage: locale.global.error_occured, //action.payload.Error.message,
			};

			break;

		// --------------------- --------------------- contact request  --------------------- ---------------------

		case DASHBOARD_SHOW_TOAST:
			returnValue = {
				...state,
				showToast: true,
				errorMessage: action.payload.toastMessasge,
			};

			break;

		// --------------------- --------------------- contact request  --------------------- ---------------------

		case DASHBOARD_ADD_CONTACT:
			returnValue = {
				...state,
				isLoading: true,
				loaderMessage: action.payload.loaderMessage,
			};

			break;

		case DASHBOARD_CONFIRM_CONTACT:
			returnValue = {
				...state,
				isLoading: true,
				isSearching: false,
				requestConfirmSuccess: false,
				loaderMessage: action.payload.loaderMessage,
			};

			break;

		case DASHBOARD_PUSH_GROUP:
			state.conversations.push(action.payload);
			returnValue = { ...state };
			break;

		case DASHBOARD_PUSH_CONTACT:
			conversations = state.conversations;

			if (!conversations.some((contact: any) => action.payload._id === contact._id)) {
				conversations.push(action.payload);
				returnValue = { ...state, conversations: conversations };
			} else {
				returnValue = { ...state };
			}

			break;

		case DASHBOARD_PUSH_CONTACT_ERROR:
			returnValue = {
				...state,
				isLoading: false,
				loaderMessage: locale.global.please_wait,
				hasErrors: true,
				errorMessage: action.payload,
			};

			break;

		case DASHBOARD_ADD_CONTACT_SUCCESS:
			returnValue = {
				...state,
				isLoading: false,
				hasErrors: false,
				isSearching: false,
				showToast: true,
				conversations: action.payload.conversations,
				loaderMessage: locale.global.please_wait,
				errorMessage: locale.reducers.dashboard.init_state.contact_sent,
			};

			break;

		case DASHBOARD_CONFIRM_CONTACT_SUCCESS:
			returnValue = {
				...state,
				isLoading: false,
				requestConfirmSuccess: true,
				hasErrors: false,
				isSearching: false,
				conversations: action.payload.conversations,
				loaderMessage: '',
			};

			break;

		case DASHBOARD_CONFIRM_CONTACT_FAILED:
			returnValue = {
				...state,
				isLoading: false,
				requestConfirmSuccess: false,
				hasErrors: true,
				isSearching: false,
				errorMessage: action.payload,
				loaderMessage: '',
			};

			break;

		case DASHBOARD_ADD_CONTACT_FAILED:
			returnValue = {
				...state,
				isLoading: false,
				loaderMessage: '',
				hasErrors: true,
				isSearching: true,
				showToast: true,
				errorMessage: action.payload.Error.hasOwnProperty('message') ? action.payload.Error.message : locale.global.something_wrong,
			};

			break;

		case DASHBOARD_ADD_CONTACT_ERROR:
			returnValue = {
				...state,
				isLoading: false,
				loaderMessage: locale.global.please_wait,
				hasErrors: true,
				isSearching: true,
				showToast: true,
				errorMessage: action.payload.Error.hasOwnProperty('message') ? action.payload.Error.message : locale.global.something_wrong,
			};

			break;

		// --------------------- --------------------- Manage Group  --------------------- ---------------------
		case DASHBOARD_CREATE_GROUP_REQUESTED:
			returnValue = {
				...state,
				isLoading: true,
				loaderMessage: locale.reducers.dashboard.init_state.creating_group,
				isSearching: false,
				isGroupCreated: false,
				addedGroup: null,
			};

			break;

		case DASHBOARD_CREATE_GROUP_FAILED:
			returnValue = {
				...state,
				isLoading: false,
				loaderMessage: '',
				hasErrors: true,
				isGroupCreated: false,
				addedGroup: null,
				showToast: true,
				errorMessage: action.payload?.Error?.message || locale.global.something_wrong,
			};

			break;

		case DASHBOARD_CREATE_GROUP_SUCCESS:
			let newConversation = false;
			conversations = state.conversations;

			if (!conversations.some((conversation: any) => action.payload.conversationHash === conversation.conversationHash)) {
				conversations.push(action.payload);
				newConversation = true;
			}

			returnValue = {
				...state,
				isLoading: false,
				isSearching: false,
				conversations: conversations,
				showToast: false,
				errorMessage: newConversation ? '' : 'Could not add conversation',
				// isGroupCreated: newConversation,
				addedGroup: newConversation ? action.payload : undefined,
				unreadCount: 0,
				loaderMessage: '',
				requestConfirmSuccess: true,
			};

			break;

		// --------------------- --------------------- Default state  --------------------- ---------------------

		default:
			returnValue = {
				...state,
			};

			break;
	}

	return returnValue;
}

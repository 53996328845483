import React from 'react';
import {
	IonButton,
	IonContent,
	IonList,
	IonInput,
	IonItemDivider,
	IonLabel,
	IonItem,
	IonToolbar,
	IonThumbnail,
	IonImg,
	IonPage,
	IonFooter,
	IonButtons,
	IonToggle,
	IonNote,
	IonLoading,
} from '@ionic/react';
import './style.scss';
import { sortByAlias } from '../../helpers/common';
import { Config } from '../../config/config';
import { connect } from 'react-redux';
import { apiService } from '../../services/apiService';
import { addGroup } from '../../redux/actions/dashboard';
import { resetValues } from '../../redux/actions/auth';
import store from '../../redux/store';
import { initChat } from '../../redux/actions/chat';
import { DASHBOARD_LAST_MESSAGE_CLEAR } from '../../redux/constants/dashboard';
import { SelectedContacts } from '../contacts/components/SelectedContacts';
import { TopNavbar } from '../common/header/topbar';
import ImageEditor from '../profile/pinturaEditor';
import EnumService from '../../services/enumService';
import { locale } from '../../locales/local';

interface iState {
	aliasInGroup: string;
	groupDescription: string;
	selectedGroupPhoto: any;
	groupPhoto: string;
	hasError: boolean;
	allContacts: any;
	errorMessage: string;
	selectedContacts: any;
	contacts: any;
	loggedInUser?: any;
	groupName: string;
	selectedImage?: any;
	showPinturaEditor: boolean;
	oldLayout: boolean;
	canDisplayQRCode: boolean;
	canInvite: boolean;
}

interface iProps {
	self: any;
	loggedInUser: any;
	history: any;
	contacts: any;
	actionHandler: Function;
	closeHandler: Function;
	addGroup: Function;
	resetValues: Function;
	initChat: Function;
	location: any;
	dashboard: any;
}

class GroupManager extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);
		const locationState = props.location.state,
			selectedContacts = locationState?.memberIds || [];

		let user: any, contacts: any;

		apiService.me().then((_user: any) => (user = _user));
		apiService.getContacts(false, 'confirmed').then((_contacts: any) => (contacts = _contacts));

		this.state = {
			loggedInUser: user,
			showPinturaEditor: false,
			canDisplayQRCode: false,
			canInvite: false,
			aliasInGroup: '',
			groupName: '',
			groupDescription: '',
			selectedGroupPhoto: '',
			groupPhoto: '',
			hasError: false,
			oldLayout: false,
			allContacts: [],
			contacts: contacts,
			errorMessage: '',
			selectedContacts: selectedContacts,
		};

		this.props.resetValues();
	}

	async setPageData() {
		let user: any = await apiService.me(),
			contacts: any = await apiService.getContacts(false, 'confirmed').then((_contacts: any) => {
				let contacts: any = [];

				if (_contacts && _contacts.length > 0) {
					contacts = _contacts.sort(sortByAlias);
				}

				return contacts;
			});

		this.setState({
			loggedInUser: user,
			contacts: contacts,
			allContacts: contacts,
			aliasInGroup: user.alias,
		});
	}

	async componentDidMount() {
		await this.setPageData();
		this.componentIsMounted = true;
	}

	async componentDidUpdate() {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
			this.componentIsMounted = true;
		}
	}

	openGroupChat(data: any) {
		data.handlerText = locale.global.start_chat;
		this.props.initChat(data);
		store.dispatch({ type: DASHBOARD_LAST_MESSAGE_CLEAR, payload: data });
		this.props.history.push(data.room ? `/chat/${data.room}` : data.userId ? `/chat/${data.room}` : '/');
	}

	_createGroup = () => {
		if (this.state.selectedContacts && this.state.selectedContacts?.length > 0) {
			const payload = {
				groupname: this.state.groupName,
				groupDescription: this.state.groupDescription,
				groupPhoto: this.state.selectedGroupPhoto,
				members: this.state.selectedContacts,
				canDisplayQRCode: this.state.canDisplayQRCode,
				canInvite: this.state.canInvite,
				history: this.props.history,
			};
			this.props.addGroup(payload);
		}
	};

	_triggerPhotoUpload = () => {
		let uploader = document.getElementById('group-photo');
		uploader?.click();
	};

	toggleSelectedContact = (index: any) => {
		let allContacts = this.state.allContacts,
			selectedContacts = this.state.selectedContacts;

		allContacts[index].isSelected = !this.state.allContacts[index].isSelected;

		if (selectedContacts.hasOwnProperty(this.state.contacts[index]._id)) {
			delete selectedContacts[this.state.contacts[index]._id];
		} else {
			selectedContacts[this.state.contacts[index]._id] = {
				_id: this.state.contacts[index]._id,
				userId: this.state.contacts[index].userId,
				username: this.state.contacts[index].username,
				jid: `${this.state.contacts[index].userId}@${Config.xmppServer}`,
				admin: false,
			};
		}
		this.setState({
			allContacts: [...allContacts],
			selectedContacts: selectedContacts,
		});
	};

	_onPinturaEditingDone = (file: any) => {
		// encode the file using the FileReader API
		const reader = new FileReader();
		reader.onloadend = async () => {
			// use a regex to remove data url part
			const base64String = reader.result as string;
			base64String.replace('data:', '').replace(/^.+,/, '');
			// log to console
			this.setState({ showPinturaEditor: false, selectedGroupPhoto: base64String });
		};

		reader.readAsDataURL(file);
	};

	_onFileChange = (event: any) => {
		const file = event.target.files[0];

		//Convert file object to base64 string
		let reader = new FileReader();
		reader.onloadend = async () => {
			const base64String = reader.result as string;
			base64String.replace('data:', '').replace(/^.+,/, '');
			this.setState({
				selectedImage: base64String,
				showPinturaEditor: true,
			});
		};
		reader.readAsDataURL(file);
	};

	_renderSwitchItem = (title: any, value: any, onChange: any) => {
		return (
			<IonItem button lines="none" className={'swtich-item'}>
				<IonLabel slot="start">
					<p>{title}</p>
				</IonLabel>
				<IonToggle slot="end" className="toggle-customised" mode="ios" value={value} onChange={onChange} />
			</IonItem>
		);
	};

	render() {
		if (this.componentIsMounted) {
			const { selectedContacts, groupName, selectedGroupPhoto } = this.state;

			return (
				<IonPage className="group-manager-page">
					<TopNavbar {...this.props} pageTitle={locale.groups.new_group} isHideRightButton={true} showBack={true} hideSearchBar={true} />

					<IonContent className="groupCustomCard has-topbar">
						<IonList lines="none">
							<IonItem lines="none" className="groupname-item">
								<IonThumbnail slot="start" onClick={this._triggerPhotoUpload}>
									{selectedGroupPhoto ? <IonImg src={selectedGroupPhoto} /> : <IonImg className="placeholder" src={'./assets/icon/camera.svg'} />}
								</IonThumbnail>

								<IonInput value={groupName} onIonChange={(e) => this.setState({ groupName: e.detail.value! })} placeholder="Please specify Group Name" />
							</IonItem>

							{this._renderSwitchItem(locale.global.qr_code, this.state.canDisplayQRCode, () => {
								this.setState({ canDisplayQRCode: !this.state.canDisplayQRCode });
							})}
							{this._renderSwitchItem(locale.global.invite_only, this.state.canInvite, () => {
								this.setState({ canInvite: !this.state.canInvite });
							})}

							<IonItem button lines="none" className={'group-notice-item'} detail={true}>
								<IonLabel slot="start">
									<h3>{locale.groups.group_notice}</h3>
									<p>{locale.groups.not_set}</p>
								</IonLabel>
							</IonItem>

							<IonItem button lines="none" className="my-alias-group-item" detail={true}>
								<IonLabel slot="start">
									<h3>{locale.groups.my_alias}</h3>
								</IonLabel>
								<IonNote slot="end">{this.state.loggedInUser?.username}</IonNote>
							</IonItem>
						</IonList>
						<IonItemDivider className="group-members-devider">
							<IonLabel>{locale.groups.members}</IonLabel>
							<IonNote>{this.state.selectedContacts?.length}</IonNote>
						</IonItemDivider>

						<br />
						<SelectedContacts
							showAddContactBtn={true}
							contacts={this.state.contacts}
							selectedContacts={this.state.selectedContacts}
							onAddContact={() =>
								this.props.history.push('/contacts', {
									actionType: EnumService.ContactsPageActionType.CreateGroup,
									selectedContacts: this.state.selectedContacts,
								})
							}
						/>
						<br />
					</IonContent>

					<IonFooter className="selected-contact-mode-footer">
						<IonToolbar className="toolbar-wrapper">
							<IonButtons slot="end">
								<IonButton className="send-button" disabled={!selectedContacts || selectedContacts?.length <= 1 || !groupName} onClick={this._createGroup}>
									{locale.global.create}
									{selectedContacts && selectedContacts.length > 0 && '(' + selectedContacts.length + ')'}
								</IonButton>
							</IonButtons>
						</IonToolbar>
					</IonFooter>
					<input accept="image/*" id="group-photo" onChange={this._onFileChange} type="file" style={{ display: 'none' }} title={""} />

					<IonLoading isOpen={this.props.dashboard.isLoading} message={this.props.dashboard.loaderMessage} />

					<ImageEditor
						selectedImage={this.state.selectedImage}
						show={this.state.showPinturaEditor}
						onClose={() => {
							this.setState({ showPinturaEditor: false });
						}}
						onSave={this._onPinturaEditingDone}
					/>
				</IonPage>
			);
		} else {
			return <></>;
		}
	}
}
const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	addGroup: (payload: any) => dispatch(addGroup(payload)),
	initChat: (payload: any) => dispatch(initChat(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupManager);

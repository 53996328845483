import React, { createRef } from 'react';
import md5 from 'md5';
import { formHasErrors, isLoading, login, resetValues } from '../../redux/actions/auth';
import store from '../../redux/store';
import { IonButton, IonCheckbox, IonCol, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPage, IonRow } from '@ionic/react';
import './style.scss';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { LOGIN_FORM_ERROR } from '../../redux/constants/common';
import { getInternalStorage, isJsonString, setInternalStorage } from '../../helpers/common';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { locale } from '../../locales/local';
import metadata from '../../metadata.json';

interface IProps {
	auth: any;
	title: String;
	placeholder_email: string;
	placeholder_pwd: string;
	submitLabel: string;
	errorMessage: string;
	navigation: Navigator;
	hasErrors: boolean;
	showLoading: boolean;
	userLoginHandler: Function;
	startLoading: Function;
	formHasErrors: Function;
	resetValues: Function;
	history: any;
	password: String;
	showToast: boolean;
}

type IState = {
	personalDeviceShowFullText: boolean;
	userId: String;
	password: String;
	personalDevice: Boolean;
	showPassword: boolean;
	version: any;
};

class LoginComponent extends React.Component<IProps, IState> {
	userIdInputRef: any = createRef();
	passwordInputRef: any = createRef();
	personalDeviceRef: any = createRef();
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;

	constructor(props: IProps) {
		super(props);
		this.state = { userId: '', password: '', personalDevice: false, personalDeviceShowFullText: false, showPassword: false, version: undefined };
	}

	cookies: any = getInternalStorage();

	async setPageData() {
		this.cookies.personalDevice = this.cookies.personalDevice === 'undefined' ? false : this.cookies.personalDevice || false;
		setInternalStorage('personalDevice', this.cookies.personalDevice);
		this.cookies.active = false;
		setInternalStorage('active', this.cookies.active);
		this.cookies.version = `${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`;
		setInternalStorage('version', this.cookies.version);
		this.cookies.dbVersion = metadata.dbVersion;
		setInternalStorage('dbVersion', this.cookies.dbVersion);
		this.setState({
			version: this.cookies.version,
		});
	}

	async componentDidMount() {
		this.componentIsMounted = false;
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		await this.setPageData();
		this.componentIsMounted = true;
	}

	async componentDidUpdate() {
		if (!this.componentIsUpdated) {
			this.setPageData();
			this.componentIsUpdated = true;
		}
	}

	isLoading(loadValue: boolean) {
		this.props.startLoading(loadValue);
	}

	login(userId: string, password: string, personalDevice: Boolean) {
		if (!userId || !password) {
			store.dispatch({
				type: LOGIN_FORM_ERROR,
				payload: { hasErrors: true, errorMessage: locale.login.error.all_fields },
			});
		} else {
			this.props.startLoading(true);
			this.props.userLoginHandler({ userId: userId ? userId.toLowerCase() : '', password: password ? md5(String(password)) : '', personalDevice: personalDevice, history: this.props.history });
		}
	}

	preLogin() {
		let { userId, password, personalDevice } = this.props.auth,
			autoFillUserId: any = document.getElementById('userId')!.getElementsByTagName('input')[0].value,
			autoFillPassword: any = document.getElementById('password')!.getElementsByTagName('input')[0].value,
			autoFillPersonalDevice: any = document.getElementById('personalDevice')?.getAttribute('aria-checked') === 'true';

		this.login(autoFillUserId ? autoFillUserId : userId, autoFillPassword ? autoFillPassword : password, autoFillPersonalDevice ? autoFillPersonalDevice : personalDevice);
	}

	redirect() {
		this.props.history.push('/signup');
	}

	moveToNextField(event: any, nextFieldRef: any, callBack: any = null) {
		if (event.key === 'Enter' || event.key === 'Tab') {
			event.preventDefault();
			if (callBack) {
				callBack();
			}
			if (nextFieldRef) {
				nextFieldRef?.current.setFocus(true);
			}
		}
	}

	_renderFieldWarning(title: String, onClick: any = null) {
		return (
			<IonRow className="input-helper-row" onClick={onClick}>
				<IonCol>{title}</IonCol>
			</IonRow>
		);
	}

	render() {
		const { showPassword, userId, password, personalDeviceShowFullText } = this.state;
		let isLoginBtnEnable = userId && password ? true : false;

		return (
			<IonPage className="auth-page">
				{this.cookies.uuid ? (
					<Redirect to="/auth" />
				) : (
					<>
						<IonContent className="auth-wrapper" fullscreen>
							<div className="inner-content">
								<div className="vertical-space-1" />

								<div className="be-society">
									<h1>{locale.global.app_name}</h1>
									<IonLabel>{locale.global.tagline}</IonLabel>
								</div>

								<div className="vertical-space-2" />

								<form className="formContainer" style={{ marginTop: '66px' }}>
									<IonList className="input-item-list">
										{this.props.auth.hasErrors ? <p className="warnings">{isJsonString(this.props.auth?.errorMessage) ? 'json' : this.props.auth?.errorMessage}</p> : null}

										<IonLabel className="auth-title">Log in</IonLabel>

										<IonItem className="auth-input-item" lines="none">
											<IonInput
												required
												/*value={String(userId)}*/
												placeholder={locale.login.placeholder.userId}
												id="userId"
												type="text"
												onIonChange={(e) => this.setState({ userId: e.detail.value! })}
												clearInput
												autofocus
												enterkeyhint={password ? 'enter' : undefined}
												ref={this.userIdInputRef}
												onKeyUp={(event: any) => {
													this.moveToNextField(event, this.passwordInputRef);
												}}
											></IonInput>
											<IonLabel slot="end">@{locale.global.app_name}</IonLabel>
										</IonItem>

										{this._renderFieldWarning(locale.login.warning.forgot_username)}
										<IonItem className="auth-input-item top-space" lines="none">
											<IonInput
												required
												/*value={String(password)}*/
												placeholder={locale.login.placeholder.password}
												type={showPassword ? 'text' : 'password'}
												id="password"
												onIonChange={(e) => {
													this.setState({ password: e.detail.value! });
													isLoginBtnEnable = userId && password ? true : false;
												}}
												onIonBlur={(e) => {
													isLoginBtnEnable = userId && password ? true : false;
												}}
												clearInput
												ref={this.passwordInputRef}
												enterkeyhint={password ? 'done' : undefined}
												onKeyUp={(event: any) => {
													this.moveToNextField(event, null, () => {
														if (userId && password) {
															this.preLogin();
														}
													});
												}}
											></IonInput>

											{password && (
												<IonButton className="visibility-onoff-btn" fill="clear" onClick={() => this.setState({ showPassword: !showPassword })}>
													<IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
												</IonButton>
											)}
										</IonItem>

										{this._renderFieldWarning(locale.login.warning.forgot_password)}
										<IonItem lines="none" className="personal-device-checkbox-item">
											<IonCheckbox mode="ios" id="personalDevice" slot="start" color="dark" value="off" checked={this.cookies.personalDevice} ref={this.personalDeviceRef}></IonCheckbox>
											<IonLabel>{locale.login.checkbox.personal_device}</IonLabel>
										</IonItem>

										<p className="personal-device-text">
											{locale.login.info.personal_device}
											{this.state.personalDeviceShowFullText && <span>&nbsp;{locale.login.info.learnMore}</span>}
											<span className="typography" onClick={() => this.setState({ personalDeviceShowFullText: !personalDeviceShowFullText })}>
												&nbsp;{personalDeviceShowFullText ? locale.login.info.read : locale.login.info.learn_more}
											</span>
										</p>
									</IonList>
								</form>

								<IonRow>
									<IonCol className="auth-button-col">
										<IonButton disabled={!isLoginBtnEnable} className="auth-button" color="#000000" type="submit" fill="solid" onClick={(e) => this.preLogin()}>
											Log in
										</IonButton>
									</IonCol>
								</IonRow>

								<IonRow>
									<IonCol className="auth-register-button">
										<IonButton
											className="register-button"
											fill="clear"
											onClick={() => {
												// this.props.history.push('/signup');
											}}
										>
											Register
										</IonButton>
									</IonCol>
								</IonRow>

								<IonRow className="copyright-version-row">
									<IonCol>
										<p style={{ fontSize: '12px', padding: '0 15px', margin: '0', marginBottom: '10px', color: '#585858', textAlign: 'center' }}>
											{locale.global.copyright}
											<img src="./assets/icon/copyright.png" className="copyright-symbol" alt={'copyright'} />
											{locale.global.copyright_year}
											<br />
											{locale.global.version_word} {this.state.version}
										</p>
									</IonCol>
								</IonRow>
							</div>
						</IonContent>
						<IonLoading cssClass={'login-loader'} mode="md" isOpen={this.props.auth.isLoading} onDidDismiss={() => this.isLoading(false)} message={this.props.auth.loaderText} />
					</>
				)}
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => ({ auth: state.auth });

const mapDispatchToProps = (dispatch: any) => ({
	userLoginHandler: (payload: any) => store.dispatch(async () => await login(payload)),
	startLoading: (loader: boolean) => store.dispatch(isLoading(loader)),
	formHasErrors: (payload: any) => store.dispatch(formHasErrors(payload)),
	resetValues: () => store.dispatch(resetValues()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);

import { IonFabButton, IonIcon, IonModal, IonToolbar } from '@ionic/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import QrReader from 'react-qr-scanner';
import {getInternalStorage, info } from '../../helpers/common';
import './style.scss';
const ScanQrCode = './../../../assets/icon/scan-qrcode.svg';
const ScanGallery = './../../../assets/icon/scan-gallery.svg';
const ScanGradiantLine = './../../../assets/icon/scan-gradiant-line.svg';

interface QrCodeComponentProps {
	show: boolean;
	onClose?: any;
}

const QrCodeScanModal: React.FC<QrCodeComponentProps> = ({ show, onClose }) => {
	const [startScanning, setStartScanning] = useState(false),
		[selectedScanOption, setSelectedScanOption] = useState('Code'),
		[scannedCode, setScannedCode] = useState(''),
		handleError = useCallback((error) => {
			info('Qr Error ', console.error);
		}, []),
		handleScan = useCallback((response) => {
			info('QR Scan success', response);
			if (response) {
				setScannedCode(response);
			}
		}, []),
		scanOptions = useMemo(() => ['Code', 'Item', 'Translate'], []),
		isIOS: boolean = /Mobi/i.test(navigator.userAgent),
		isSafari: boolean = !/Chrome/i.test(navigator.userAgent) || !/Mozilla/i.test(navigator.userAgent),
		cookies: any = getInternalStorage();
	
	info('QRCodeScanModal:: isMobile', cookies.mobileDevice);
	info('QRCodeScanModal:: isSafari', isSafari);
	info('QRCodeScanModal:: isIOS', isIOS);

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				setStartScanning(true);
			}, 2000);
		}
	}, [show]);

	return (
		<IonModal animated={false} showBackdrop={true} isOpen={show} onDidDismiss={onClose} swipeToClose={false} cssClass="qrcode-scan-modal">
			<div className="content-view">
				<div className="qrview-container" onClick={onClose}>
					<QrReader className="qrreader" delay={300} onError={handleError} onScan={handleScan} facingMode={cookies.mobileDevice ? 'rear' : 'front'} legacyMode={isIOS || isSafari} />

					<div className="scan-btns">
						<div className="scan-qr-barcode-title">
							Scan
							<br />
							QR code / barcode
							<br />
							<div style={{ color: '#FF5E5E', fontSize: '18px', margin: 20 }}>{scannedCode}</div>
						</div>
						<IonToolbar>
							<IonFabButton slot="start">
								<IonIcon icon={ScanQrCode}></IonIcon>
							</IonFabButton>
							<IonFabButton slot="end">
								<IonIcon icon={ScanGallery}></IonIcon>
							</IonFabButton>
						</IonToolbar>
					</div>

					{startScanning && <img src={ScanGradiantLine} className="scanning-animated-line" alt="scanline" />}
				</div>
				<div className="bottom-view">
					<div className="scan-options-row">
						{scanOptions.map((item, key) => {
							return (
								<div key={key} className={'scan-option-item ' + (selectedScanOption === item ? 'active' : '')} onClick={() => setSelectedScanOption(item)}>
									{item}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</IonModal>
	);
};

export default QrCodeScanModal;

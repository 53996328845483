import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AppManager } from './services/appManager';

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

serviceWorkerRegistration.register({
      onUpdate: (registration: any) => {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        AppManager.reload();
      },
    });

defineCustomElements(window);
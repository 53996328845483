import React from 'react';
import { IonContent, IonItemDivider, IonLabel, IonPage } from '@ionic/react';
import './style.scss';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { RouteComponentProps } from 'react-router';
import { TopNavbar } from '../common/header/topbar';
import { ContactsList } from './components/ContactsList';
import { connect } from 'react-redux';
import { apiService } from '../../services/apiService';
import EnumService from '../../services/enumService';
import { locale } from '../../locales/local';
import { sortByAlias } from '../../helpers/common';
import _ from 'lodash';

interface iProps extends RouteComponentProps<{ name: string }> {
	dashboard: any;
	location: any;
}
interface iState {
	loggedInUser?: any;
	contacts: any;
	confirmedContacts: any;
	pendingContacts: any;
}

class RecentContacts extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);

		this.state = {
			loggedInUser: undefined,
			contacts: undefined,
			confirmedContacts: undefined,
			pendingContacts: undefined,
		};
	}

	setPageData = async () => {
		let user = await apiService.me(),
			confirmedContacts: any = [],
			pendingContacts: any = [],
			contacts: any = await apiService.getContacts(false, 'all').then((_contacts: any) => {
				let contacts: any = [];

				if (_contacts && _contacts.length > 0) {
					contacts = _contacts.sort(sortByAlias);
				}

				return contacts;
			});

		// Filter contacts list
		contacts.forEach((_contact: any) => {
			if (_.includes(['pendingConfirm'], _contact?.status)) {
				pendingContacts.push(_contact);
			}
			if (_.includes(['confirmed'], _contact?.status) || _.includes(['pendingReply'], _contact?.status)) {
				confirmedContacts.push(_contact);
			}
		});

		this.setState((state, props) => ({
			...state,
			loggedInUser: user,
			contacts: contacts,
			confirmedContacts,
			pendingContacts,
		}));

		this.componentIsMounted = true;
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	setSearchText = () => {};

	searchAllGroups = (searchValue: string, props: any) => props.history.push('/contacts/search', searchValue);

	render() {
		const { confirmedContacts, pendingContacts } = this.state;

		return (
			<>
				<IonPage className="contacts-page">
					<TopNavbar {...this.props} showBack={true} pageTitle={'New Contacts'} searchTypeHandler={this.setSearchText} searchHandler={this.searchAllGroups} onSearchBarFocus={() => {}} />
					<IonContent className="main-content-profile has-bottom-navbar has-topbar-with-searchbar">
						<IonItemDivider className="recent-contacts-divider-title">
							<IonLabel>{locale.contacts.recent_contacts.new_req}</IonLabel>
						</IonItemDivider>
						<ContactsList props={this.props} hideAlphabetDevider={true} contacts={pendingContacts} contactListMode={EnumService.ContactsListMode.NewRequestContactsList} />
						<IonItemDivider className="recent-contacts-divider-title">
							<IonLabel>{locale.contacts.recent_contacts.most_recent}</IonLabel>
						</IonItemDivider>
						<ContactsList props={this.props} hideAlphabetDevider={true} contacts={[]} contactListMode={EnumService.ContactsListMode.MostRecentContactsList} />

						<IonItemDivider className="recent-contacts-divider-title">
							<IonLabel>{locale.contacts.recent_contacts.recently_added}</IonLabel>
						</IonItemDivider>
						<ContactsList props={this.props} hideAlphabetDevider={true} contacts={confirmedContacts} contactListMode={EnumService.ContactsListMode.RecentlyAddedContactsList} />
					</IonContent>
					<BottomNavbar
						unreadCount={this.props?.dashboard?.conversations ? this.props.dashboard.conversations?.filter((_conversation: any) => _conversation?.unreadCount?.length > 0).length : 0}
						{...this.props}
					/>
				</IonPage>
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RecentContacts);

import { IonButton, IonButtons, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import './style.scss';

const TrashIcon = './assets/icon/trash.svg';
const ForwardIcon = './assets/icon/forward-icon.svg';

interface iProps {
	count: number;
	onDelete?: any;
	onForward?: any;
}

export const ChatSelectView: React.FC<iProps> = ({ count, onDelete, onForward }) => {
	return (
		<div className="chat-select-view">
			<IonToolbar>
				<IonButtons slot="start">
					<IonButton fill="clear" onClick={onDelete}>
						<IonIcon slot="icon-only" src={TrashIcon} />
					</IonButton>
				</IonButtons>
				<IonTitle>{count}</IonTitle>
				<IonButtons slot="end">
					<IonButton fill="clear" onClick={onForward}>
						<IonIcon slot="icon-only" src={ForwardIcon} />
					</IonButton>
				</IonButtons>
			</IonToolbar>
		</div>
	);
};

import React, { createRef, useCallback, useEffect, useRef } from 'react';
import { IonButtons, IonIcon, IonList, IonItem, IonLabel, IonThumbnail, IonImg, IonToolbar, IonTitle, IonButton } from '@ionic/react';
import './style.scss';
import { atOutline } from 'ionicons/icons';
import Emoji from '../../../emoji/Emoji';
import { ChatOtherOptions } from '../ChatOtherOptions/ChatOtherOptions';
import SharedService from '../../../../services/sharedService';
import CameraService from '../../../../services/cameraService';
import { connect } from 'react-redux';
import { publishData, resetPublishData } from '../../../../redux/actions/pubsub';
import EnumService from '../../../../services/enumService';
import GroupMembersTagModal from '../../../../modals/GroupMembersTagModal';
import { blankProfilePic, info, getInternalStorage, isBlank } from '../../../../helpers/common';
import sanitizeHtml from 'sanitize-html';
import _ from 'lodash';
import ChatReplyView from './ChatReplyView';
import { xmpp } from '../../../../services/xmpp';
import VoiceRecord from '../../../../modals/VoiceRecord/VoiceRecord';

interface iProps {
	self: any;
	myRef: any;
	pubsub: any;
	allContacts: any;
	resetPublishData: Function;
	publishData: Function;
	loggedInUser: any;
	props: any;
	onPhotoUpload?: Function;
	onProfilePhotoPress?: Function;
	onPasteImageFromClipboard?: Function;
	onChatBoxHeightChange?: Function;
	onMessageSent?: Function;
}

function createRange(node: any, chars: any, range: any) {
	if (!range) {
		range = document.createRange();
		range.selectNode(node);
		range.setStart(node, 0);
	}

	if (chars.count === 0) {
		range.setEnd(node, chars.count);
	} else if (node && chars.count > 0) {
		if (node.nodeType === Node.TEXT_NODE) {
			if (node.textContent.length < chars.count) {
				chars.count -= node.textContent.length;
			} else {
				range.setEnd(node, chars.count);
				chars.count = 0;
			}
		} else {
			for (let lp in node.childNodes) {
				range = createRange(node.childNodes[lp], chars, range);

				if (chars.count === 0) {
					break;
				}
			}
		}
	}

	return range;
}

let chatBoxHeight = 0;
let isMessageSending = false;

const AdditionalOptionTypes = {
		EmojiPicker: 'emoji',
		ChatOtherOptions: 'chat-other-options',
	},
	cameraService = new CameraService(),
	ChatInputBox: React.FC<iProps> = ({ self, myRef, pubsub, allContacts, loggedInUser, publishData, resetPublishData, props, onPhotoUpload, onPasteImageFromClipboard, onProfilePhotoPress, onChatBoxHeightChange, onMessageSent }) => {
		const chatboxRef: React.RefObject<HTMLDivElement> = createRef(),
			selfRef: React.RefObject<any> = useRef({}),
			userTagPopupRef: React.RefObject<HTMLDivElement> = useRef(null),
			codeRef: React.RefObject<HTMLDivElement> = useRef(null),
			[message, setMessage] = React.useState(''),
			[showVoiceRecordModal, setShowVoiceRecordModal] = React.useState<boolean>(false),
			[showAdditionalOption, setShowAdditionalOption] = React.useState<any>(null),
			[showUserTagModal, setShowUserTagModal] = React.useState<boolean>(false),
			[showUserTagPopup, setShowUserTagPopup] = React.useState<boolean>(false),
			[recalledMessageId, setRecalledMessageId] = React.useState<any>(undefined),
			[isMobileKeyboardOpen, setIsMobileKeyboardOpen] = React.useState<boolean>(false),
			[alphabetsList, setAlphabetsList] = React.useState<any>([]),
			[sortedMembersList, setSortedMembersList] = React.useState<any>([]),
			[taggedMembers, setTaggedMembers] = React.useState<any>([]),
			[messageData, setMessageData] = React.useState<any>(null),
			[showReplyView, setShowReplyView] = React.useState<boolean>(false),
			cookies: any = getInternalStorage(),
			currentPath: any[] = window.location?.pathname?.split('/'),
			pathLast: any = currentPath?.pop(),
			personalNotepadPath: Boolean = pathLast === 'personal-notepad',
			sanitizeConf = {
				allowedTags: ['a', 'span', 'br'],
				allowedAttributes: { a: ['href', 'class'], span: ['style', 'class'] },
			},
			focusChatInput = useCallback(() => {
				const chatEditableInput = document.getElementById('chatEditableInput');
				chatEditableInput?.focus();
			}, []),
			onFocusChatInput = useCallback(() => {
				setShowAdditionalOption(null);
				if (cookies.mobileDevice) {
					setIsMobileKeyboardOpen(true);
				}
			}, [cookies.mobileDevice]),
			onBlurChatInput = useCallback(() => {
				if (cookies.mobileDevice) {
					_.delay(() => {
						setIsMobileKeyboardOpen(false);
					}, 100);
				}
			}, [cookies.mobileDevice]),
			setCaret = (element: any = undefined, node: any = undefined) => {
				element = (element && node) || codeRef?.current;

				if (element) {
					let selection: any = window.getSelection(),
						range: any = document.createRange();

					range = createRange(node || element, { count: element.innerText.length }, range);
					selection.removeAllRanges();
					range.selectNodeContents(element);
					range.collapse(false);
					selection.addRange(range);
				}
			},
			handleBackspace = (event: any, character: string) => {
				if (codeRef.current) {
					codeRef.current.innerHTML = codeRef.current.innerHTML.slice(0, codeRef.current.innerHTML.lastIndexOf(character));
					setCaret();
					setMessage(String(codeRef.current.innerHTML));
					event.preventDefault();
				}
			},
			submitMessage = async (messageType: string = EnumService.ChatMessageType.TEXT) => {
				if (message && props.chat?.receiver && !isMessageSending) {
					focusChatInput();
					isMessageSending = true;
					let relatedMessageId = recalledMessageId;
					if (showReplyView) {
						relatedMessageId = messageData?.id;
						messageType = EnumService.ChatMessageType.TEXT_REPLY;
					}
					await xmpp.sendMessage(props.chat.receiver.jid, message, messageType, props.chat.receiver.lastMessage, relatedMessageId, taggedMembers);
					isMessageSending = false;
					onMessageSent && onMessageSent();
					clearInputPress();
					setShowAdditionalOption(null);

					closeReplyView();
				}
			},
			focusChatInputBack = useCallback(() => {
				focusChatInput();
				setShowAdditionalOption(null);
			}, [focusChatInput]),
			emojiPickerIconPress = useCallback(() => {
				publishData({ type: 'resize-chatscreen', data: {} });
				setShowAdditionalOption(AdditionalOptionTypes.EmojiPicker === showAdditionalOption ? null : AdditionalOptionTypes.EmojiPicker);
			}, [showAdditionalOption, publishData]),
			plusIconPress = useCallback(() => {
				publishData({ type: 'resize-chatscreen', data: {} });
				info('showAdditionalOption', showAdditionalOption);
				setShowAdditionalOption(AdditionalOptionTypes.ChatOtherOptions === showAdditionalOption ? null : AdditionalOptionTypes.ChatOtherOptions);
			}, [showAdditionalOption, publishData]),
			cameraIconPress = useCallback(() => {
				cameraService.capturePhotoFromCamera((photo: any) => {
					onPhotoUpload && onPhotoUpload(photo);
				});

				setShowAdditionalOption(null);
			}, [onPhotoUpload]),
			onShowUserTagPopup = useCallback(() => {
				if (!showUserTagPopup) {
					const element: any = userTagPopupRef?.current;

					element?.classList.remove('hide');
					element?.classList.add('show');

					const codeElem = codeRef?.current;

					if (element && codeElem) {
						let offsetParent: any = codeElem?.offsetParent;
						if (offsetParent?.offsetParent) {
							offsetParent = offsetParent.offsetParent;
						}
						element.style.bottom = `${(offsetParent?.clientHeight || 0) + 2}px`;
					}
				}
				setShowUserTagPopup(true);
			}, [showUserTagPopup, userTagPopupRef]),
			onHideUserTagPopup = useCallback(() => {
				if (showUserTagPopup) {
					const element: any = userTagPopupRef?.current;
					element?.classList.remove('show');
					element?.classList.add('hide');
				}

				setShowUserTagPopup(false);
			}, [showUserTagPopup, userTagPopupRef]),
			onShowUserTagModal = useCallback(() => {
				onHideUserTagPopup();
				setShowUserTagModal(true);
			}, [onHideUserTagPopup]),
			onHideUserTagModal = useCallback(() => {
				onHideUserTagPopup();
				setShowUserTagModal(false);
			}, [onHideUserTagPopup]),
			clearInputPress = useCallback(() => {
				setMessage('');

				if (codeRef && codeRef.current) {
					codeRef.current.innerHTML = '';
				}

				onHideUserTagModal();

				focusChatInput();
				const delayId = _.delay(() => {
					if (recalledMessageId) {
						setRecalledMessageId(undefined);
					}
				}, 200);

				setShowAdditionalOption(null);
				return () => {
					clearTimeout(delayId);
				};
			}, [codeRef, onHideUserTagModal, recalledMessageId, focusChatInput]),
			outsideClicked = useCallback(() => {
				setShowAdditionalOption(null);
				onHideUserTagPopup();
			}, [onHideUserTagPopup]),
			onUserTag = useCallback(
				(taggedUserIds, isGroupModal = false) => {
					const element: any = codeRef?.current,
						lastChild: any = element?.lastChild;

					if (lastChild) {
						const wholeText: any = codeRef?.current?.innerText;

						if (wholeText) {
							const attrIndex: number = wholeText.lastIndexOf('@');

							if (attrIndex >= 0) {
								const lastVal: string = wholeText.substring(attrIndex + 1).toLowerCase();

								taggedUserIds.some((alias: any) => {
									if (loggedInUser?.alias !== alias) {
										let userInfo: any = allContacts?.find((obj: any) => obj.alias === alias);

										userInfo = userInfo ? userInfo : props.chat?.receiver.members?.find((obj: any) => obj.alias === alias);

										const userProfileName: string = (userInfo?.alias || userInfo?.username || '').toLowerCase(),
											textContent = lastChild.textContent?.toLowerCase();

										if (userProfileName.includes(lastVal)) {
											if (textContent.lastIndexOf(lastVal) >= 0) {
												lastChild.textContent = lastChild.textContent.substring(0, textContent.lastIndexOf(lastVal) + 1);
											} else if (lastChild && lastChild.innerText) {
												if (!lastChild.innerText[lastChild.innerText.length - 1].toLowerCase().includes(lastVal)) {
													lastChild.innerText = lastChild.innerText.substring(0, attrIndex);
												}
											}
											return true;
										}
									}
									return false;
								});
							}
						}

						if (lastChild.textContent === '@') {
							lastChild.remove();
						} else if (lastChild && lastChild.innerText && lastChild.innerText[lastChild.innerText.length - 1] === '@') {
							lastChild.innerText = lastChild.innerText.slice(0, -1);
						}
					}

					if (element) {
						let textToAppend = '',
							tagged: any[] = [];
						if (taggedUserIds.length === props.chat?.receiver.members.length - 1) {
							textToAppend = `<span class="tagged">@All</span>&nbsp;`;
						} else {
							// this needs to use alias (not userid) to look up the group member, as that is what is displayed and
							// selected by the user.
							taggedUserIds.forEach((userId: any, key: any) => {
								let userInfo = props.chat?.receiver.members?.find((obj: any) => obj.alias === userId) || allContacts?.find((obj: any) => obj.alias === userId);
								textToAppend = `${textToAppend}<a class="tagged" href="chat/${userInfo.userId}">@${userInfo?.alias || userInfo?.username}</a>${key !== taggedUserIds.length - 1 ? ',&nbsp;' : '&nbsp;'}`;
								tagged.push(userInfo.userId);
							});
						}
						if (isGroupModal) {
							element.innerHTML = `${element.innerHTML}${textToAppend}`;
						} else {
							const startPos = element.innerHTML.lastIndexOf('@');
							element.innerHTML = `${element.innerHTML.slice(0, startPos)}${textToAppend}`;
						}

						setTaggedMembers(tagged);
						setCaret();
						setMessage(String(codeRef?.current?.innerHTML));
					}
					onHideUserTagModal();
				},
				[onHideUserTagModal, loggedInUser?.alias, props.chat?.receiver?.members, codeRef, allContacts]
			),
			TagIcon = ({ iconPos }: any) => (
				<IonIcon
					className={`icon-inside-chat-input ${iconPos}`}
					slot="start"
					icon={atOutline}
					onClick={() => {
						onShowUserTagModal();
					}}
				/>
			),
			ClearTextIcon = () => <IonIcon slot="start" className="clear-chat-icon" src={'./assets/icon/close-icon.svg'} onClick={clearInputPress} />;

		const openReplyView = useCallback(
			(_msgData) => {
				setMessageData(_msgData);
				setShowReplyView(true);
				if (selfRef && selfRef.current) {
					selfRef.current.isReplyViewOpen = true;
					selfRef.current.replyForMessageId = _msgData.id;
				}
			},
			[selfRef]
		);

		const closeReplyView = useCallback(() => {
			setShowReplyView(false);
			setMessageData(null);
			if (selfRef && selfRef.current) {
				selfRef.current.isReplyViewOpen = false;
				selfRef.current.replyForMessageId = null;
			}
		}, [selfRef]);

		if (selfRef && selfRef.current) {
			selfRef.current.closeReplyView = closeReplyView;
		}

		const isGroupChat = props.chat?.hasOwnProperty('type') && props?.chat.type === 'groupchat' && !personalNotepadPath;

		myRef && myRef(selfRef);

		useEffect(() => {
			if (pubsub?.publishedData) {
				if (pubsub?.publishType === EnumService.PubSubEventType.RecallEdit) {
					if (pubsub?.publishedData?.messageType === 'text') {
						setMessage(pubsub?.publishedData?.body);
						focusChatInput();

						if (codeRef && codeRef.current) {
							codeRef.current.innerHTML = pubsub?.publishedData?.body;
							setRecalledMessageId(pubsub?.publishedData?.id);
						}
					}
				} else if (pubsub?.publishType === EnumService.PubSubEventType.ReplyToTheConversation) {
					openReplyView(pubsub?.publishedData);
					resetPublishData();

					focusChatInput();
				}
			}
			resetPublishData();

			return () => {};
		}, [pubsub?.publishType, pubsub?.publishedData, resetPublishData, focusChatInput, openReplyView]);

		useEffect(() => {
			const delayId = _.delay(() => {
				focusChatInput();
			}, 100);
			return () => {
				clearTimeout(delayId);
				chatBoxHeight = 0;
			};
		}, [codeRef, focusChatInput]);

		useEffect(() => {
			if (chatboxRef.current) {
				const { current } = chatboxRef;
				const boundingRect = current.getBoundingClientRect();
				const { height } = boundingRect;
				if (chatBoxHeight !== height) {
					chatBoxHeight = height;
					onChatBoxHeightChange && onChatBoxHeightChange(height);
				}
			}
		}, [chatboxRef, onChatBoxHeightChange]);

		useEffect(() => {
			const currentRef = chatboxRef?.current;
			currentRef?.parentElement?.getElementsByTagName('ion-content')[0]?.addEventListener('click', outsideClicked, { passive: true });
			return () => {
				currentRef?.parentElement?.getElementsByTagName('ion-content')[0]?.removeEventListener('click', outsideClicked);
			};
		}, [chatboxRef, outsideClicked]);

		useEffect(() => {
			if (props.chat?.receiver?.members && props.chat?.receiver?.members.length > 0) {
				let sortedList = props.chat?.receiver?.members;
				sortedList.sort((a: any, b: any) => (a.alias?.toLowerCase() < b.alias?.toLowerCase() ? -1 : a.alias?.toLowerCase() > b.alias?.toLowerCase() ? 1 : 0));

				const membersListExceptMe: any = [];
				const memberAlphabets: any = [];
				sortedList.map((item: any) => {
					if (loggedInUser?.userId !== item.userId) {
						membersListExceptMe.push(item);
						memberAlphabets.push(item.alias?.toUpperCase()?.charAt(0));
					}
					return null;
				});
				const uniqueAlphabets = memberAlphabets.filter((item: any, i: number, self: any) => self.indexOf(item) === i);

				setAlphabetsList(uniqueAlphabets);
				setSortedMembersList(membersListExceptMe);
			}
		}, [loggedInUser, props.chat?.receiver?.members]);

		const onPaste = useCallback(
			(event: any) => {
				event.preventDefault();
				// Paste image from clipboard

				for (let item of event?.clipboardData?.items) {
					// Skip content if not image
					if (item.type.startsWith('image')) {
						// Retrieve image on clipboard as blob
						let blob: any = item.getAsFile(),
							reader: any = new FileReader();

						setMessage('');
						// eslint-disable-next-line no-loop-func
						reader.onload = (event: any) => {
							setMessage('');
							onPasteImageFromClipboard && onPasteImageFromClipboard(event.target.result);
						};
						reader.readAsDataURL(blob);
						break;
					} else if (item.type.startsWith('text/plain')) {
						document.execCommand('inserthtml', false, event.clipboardData.getData('text/plain').replace(/&quot;/g, ''));
					}
				}
			},
			[onPasteImageFromClipboard]
		);

		useEffect(() => {
			window.addEventListener('paste', onPaste, false);
			return () => {
				window.removeEventListener('paste', onPaste, false);
			};
		}, [onPaste]);

		// myRef && myRef(selfRef);

		return (
			<div id="chatInputBox" className="chat-input-box" ref={chatboxRef} onClick={(event) => event.stopPropagation()} style={{ paddingBottom: cookies.mobileDevice && !isMobileKeyboardOpen && cookies.displayMode === 'standalone' ? '42px' : '34px' }}>
				{showReplyView && (
					<ChatReplyView
						messageData={messageData}
						onClose={() => {
							closeReplyView();
						}}
					/>
				)}

				{props.chat?.hasOwnProperty('type') && props?.chat.type === 'groupchat' && (
					<div className={'user-list-popup'} ref={userTagPopupRef}>
						<div className="header-toolbar">
							<IonToolbar mode="ios">
								<IonTitle className="title">Tag User</IonTitle>
								<IonButtons slot="end">
									<IonButton
										className={'all-btn'}
										onClick={() => {
											const idsList: any = [];
											sortedMembersList.forEach((item: any) => {
												if (!_.includes(idsList, item.userId)) {
													idsList.push(item.userId);
												}
											});
											onUserTag(idsList);
										}}
									>
										@ALL
									</IonButton>
								</IonButtons>
							</IonToolbar>
						</div>
						<IonList lines="none">
							{sortedMembersList?.map((item: any, key: any) => {
								const userInfo: any = self.conversations?.find((_contact: any) => _contact.userId === item?.userId) || self.props.chat?.receiver?.members?.find((_member: any) => _member.alias === item?.userId) || allContacts?.find((obj: any) => obj.userId === item.userId),
									userProfileName: any = item.alias,
									wholeText: any = codeRef?.current?.innerText;

								if (wholeText) {
									const msgsplits: any[] = wholeText.split('@');

									if (msgsplits && msgsplits.length > 0) {
										const lastVal: string = msgsplits[msgsplits.length - 1];

										if (lastVal && userProfileName && !userProfileName.toLowerCase().includes(lastVal.toLowerCase())) {
											return null;
										}
									}
								}

								return (
									<IonItem
										key={key}
										onClick={() => {
											onUserTag([item.userId]);
										}}
									>
										<IonThumbnail slot="start">
											<IonImg src={userInfo?.profilePhoto || blankProfilePic} />
										</IonThumbnail>
										<IonLabel>{userProfileName}</IonLabel>
									</IonItem>
								);
							})}
						</IonList>
					</div>
				)}

				<div className="toolbar-view">
					<IonButtons slot="start">
						{isGroupChat && message ? (
							<ClearTextIcon />
						) : (
							<>
								{showAdditionalOption !== AdditionalOptionTypes.ChatOtherOptions && <IonIcon slot="start" src={'./assets/icon/add-plus-icon.svg'} onClick={plusIconPress} />}
								{showAdditionalOption === AdditionalOptionTypes.ChatOtherOptions && <IonIcon slot="start" onClick={focusChatInputBack} icon={'./assets/icon/keyboard.svg'} />}
							</>
						)}
					</IonButtons>

					<div className="messeger-chat-input">
						{isGroupChat && !message && <TagIcon iconPos="left" />}

						<div
							id="chatEditableInput"
							className="editable-input"
							contentEditable={true}
							ref={codeRef}
							onFocus={onFocusChatInput}
							onBlur={onBlurChatInput}
							onInput={(event) => {
								// Set bottom position of Popup if opened

								if (isGroupChat) {
									const element: any = codeRef?.current;
									if (showUserTagPopup && userTagPopupRef) {
										const userTagPopupElem = userTagPopupRef.current;

										if (userTagPopupElem) {
											let offsetParent: any = element?.offsetParent;
											if (offsetParent?.offsetParent) {
												offsetParent = offsetParent.offsetParent;
											}
											userTagPopupElem.style.bottom = `${(offsetParent?.clientHeight || 0) + 2}px`;
										}
									}

									const wholeText: any = codeRef?.current?.innerText,
										lastChar: string = wholeText ? wholeText[wholeText?.length - 1] : '';

									let shouldShowTagPopup = false;

									if (wholeText) {
										sortedMembersList?.forEach((item: any) => {
											const userProfileName: string = item?.alias,
												msgsplits: any[] = wholeText.split('@');

											if (msgsplits && msgsplits.length > 1) {
												const lastVal: string = msgsplits[msgsplits.length - 1];
												shouldShowTagPopup = !isBlank(lastVal) && userProfileName.toLowerCase().includes(lastVal.toLowerCase());
											}
										});
									}

									if (lastChar === '@' || shouldShowTagPopup) {
										onShowUserTagPopup();
									} else {
										onHideUserTagPopup();
									}
								}

								const msg: string = String(codeRef?.current?.innerHTML);

								if (msg === '<br>') {
									clearInputPress();
								} else {
									setMessage(sanitizeHtml(msg, sanitizeConf));
								}
							}}
							onKeyDown={(event) => {
								const element: any = codeRef?.current;

								switch (event.key) {
									// handle Enter key press
									case 'Enter':
										if ((!cookies.mobileDevice && event.ctrlKey) || cookies.mobileDevice) {
											event.preventDefault();

											if (element) {
												element.innerHTML = `${element.innerHTML}<br><br>`;
												setMessage(`${element.innerHTML}<br><br>`);
												setCaret();
												focusChatInputBack();
											} else {
												setMessage(`${message}<br><br>`);
											}
										} else if (event.key === 'Enter') {
											event.preventDefault();
											setMessage(message);
											submitMessage();
										}

										break;

									case 'Space':
										// convert space to &nbsp;
										event.preventDefault();
										element.innerHTML = `${element.innerHTML.slice(0, element.innerHTML.lastIndexOf(' '))}&nbsp;`;

										break;

									case 'Tab':
										// handle Tab keypress while selecting tagged members
										if (!cookies.mobileDevice) {
											// kill the tab press in any case
											event.preventDefault();
											let current: any = codeRef?.current,
												currentInnerText: string = current?.innerText || '',
												hasTags: boolean = !isBlank(currentInnerText) && currentInnerText.includes('@');

											if (element && isGroupChat && hasTags && userTagPopupRef?.current?.children.length === 2) {
												let tag: any = Array.from(userTagPopupRef.current.children[1].childNodes).find((node: any) => node.innerText.toLowerCase().startsWith(currentInnerText.toLowerCase().substr(currentInnerText.lastIndexOf('@') + 1)));

												if (tag?.innerText) {
													onUserTag([tag?.innerText]);
												}
											}

											focusChatInput();
										}

										break;

									// handle Backspace to remove tag or html space

									//TODO: handle inline backspace when there is HTML

									case 'Backspace':
										if (element.innerHTML.endsWith('<br><br>')) {
											handleBackspace(event, '<br><br>');
										} else if (element.innerHTML.endsWith('&nbsp;')) {
											handleBackspace(event, '&nbsp;');
										} else if (element.innerHTML.endsWith('</a>')) {
											handleBackspace(event, '<a');
										} else if (element.innerHTML.endsWith('</span>')) {
											let memberStart = element.innerHTML.lastIndexOf('<span'),
												memberEnd = element.innerHTML.indexOf('</span>', memberStart),
												memberSpan = element.innerHTML.substring(memberStart, memberEnd),
												member = memberSpan.split('@')[1].split('<');

											setTaggedMembers(
												taggedMembers.splice(
													taggedMembers.findIndex((_member: any) => _member === member),
													1
												)
											);
											handleBackspace(event, '<span');
										}

										break;

									default:
										break;
								}
							}}
						></div>

						{!isGroupChat && message && <ClearTextIcon />}
						{isGroupChat && message && <TagIcon iconPos="right" />}
						{(!message || message.length === 0) && (
							<IonButton className="icon-inside-chat-input right" onClick={() => setShowVoiceRecordModal(true)} fill="clear">
								<IonIcon slot="end" src={'./assets/icon/mic-icon.svg'} />
							</IonButton>
						)}
					</div>
					<IonButtons slot="primary">
						{(!message || message.length === 0) && (
							<>
								{showAdditionalOption === AdditionalOptionTypes.EmojiPicker && <IonIcon slot="end" onClick={focusChatInputBack} src={'./assets/icon/keyboard.svg'} />}
								{showAdditionalOption !== AdditionalOptionTypes.EmojiPicker && <IonIcon slot="end" onClick={() => emojiPickerIconPress()} src={'./assets/icon/smiley-icon.svg'} />}
								<IonIcon slot="end" src={'./assets/icon/chat-album-icon.svg'} onClick={cameraIconPress} />
							</>
						)}

						{message && message.length > 0 && (
							<IonIcon
								slot="end"
								className="send-icon"
								src="./assets/icon/send.svg"
								onClick={(event) => {
									submitMessage();
								}}
							/>
						)}
					</IonButtons>
				</div>

				{showAdditionalOption === AdditionalOptionTypes.EmojiPicker && (
					<div className={'showEmoj'}>
						<Emoji
							onEmojiChoose={(emojiObject) => {
								const element: any = codeRef?.current;
								element.innerHTML = `${element.innerHTML}${emojiObject.emoji}`;
								setMessage(String(codeRef?.current?.innerHTML));
							}}
						/>
					</div>
				)}

				{showAdditionalOption === AdditionalOptionTypes.ChatOtherOptions && (
					<ChatOtherOptions
						onTriggerFileUpload={() => {
							cameraService.choosePhotoFromAlbum((photo: any) => {
								onPhotoUpload && onPhotoUpload(photo);
							});
						}}
						props={props}
					/>
				)}

				{props.chat?.hasOwnProperty('type') && props.chat.type === 'groupchat' && (
					<GroupMembersTagModal
						props={props}
						self={self}
						alphabetsList={alphabetsList}
						sortedMembersList={sortedMembersList}
						allContacts={allContacts}
						show={showUserTagModal}
						onProfilePhotoPress={onProfilePhotoPress}
						onClose={() => {
							onHideUserTagModal();
						}}
						onDone={(userIds: any) => onUserTag(userIds, true)}
					/>
				)}

				<VoiceRecord
					show={showVoiceRecordModal}
					onClose={() => {
						setShowVoiceRecordModal(false);
					}}
				/>
			</div>
		);
	};

const mapStateToProps = (state: any) => {
	return {
		pubsub: state.pubsub,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetPublishData: () => dispatch(resetPublishData()),
	publishData: (payload: any) => dispatch(publishData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatInputBox);

import { IonModal, IonText, IonIcon } from '@ionic/react';
import React, { useEffect } from 'react';
import './style.scss';

interface ToastStylishProps {
	show: boolean;
	svgIcon: any;
	duration?: number;
	message: string;
	onClose: any;
}

const ToastStylish: React.FC<ToastStylishProps> = ({ show, onClose, duration, svgIcon, message }) => {
	useEffect(() => {
		if (show) {
			setTimeout(
				() => {
					onClose && onClose();
				},
				duration ? duration : 2000
			);
		}
	}, [show, onClose, duration]);

	return (
		<IonModal showBackdrop={false} isOpen={show} swipeToClose={false} cssClass="toast-stylish-modal" animated={false}>
			<div className="toast-container">
				<IonIcon src={svgIcon} />
				<IonText>{message}</IonText>
			</div>
		</IonModal>
	);
};

export default ToastStylish;

import React from 'react';
import { IonButton, IonContent, IonList, IonInput, IonItemDivider, IonLabel, IonItem, IonToolbar, IonThumbnail, IonImg, IonIcon, IonToast, IonItemGroup } from '@ionic/react';
import './style.scss';
import { fetchProfilePic, info, compressSelectedFile } from '../../helpers/common';
import { checkmarkCircle, chevronBack } from 'ionicons/icons';
import { Config } from '../../config/config';
import { connect } from 'react-redux';
import { locale } from '../../locales/local';
import { apiService } from '../../services/apiService';

interface iState {
	groupname: string;
	groupDescription: string;
	selectedGroupLogoFile: any;
	groupLogoBase64: string;
	hasError: boolean;
	allContacts: any;
	errorMessage: string;
	selectedContacts: any;
	contacts: any;
	oldLayout: boolean;
}

interface iProps {
	self: any;
	loggedInUser: any;
	history: any;
	contacts: any;
	actionHandler: Function;
	closeHandler: Function;
}

class CreateGroup extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;
	componentIsUpdating: Boolean = false;
	constructor(props: iProps) {
		super(props);
		this.state = {
			groupname: '',
			groupDescription: '',
			selectedGroupLogoFile: '',
			groupLogoBase64: '',
			hasError: false,
			oldLayout: false,
			allContacts: [],
			contacts: [],
			errorMessage: '',
			selectedContacts: [],
		};
	}

	async setPageData(state: any = undefined) {
		if (state) {
			this.setState((state: any, props: any) => ({
				...state,
			}));
		} else {
			let contacts = await apiService.getContacts();

			this.setState((state: any, props: any) => ({
				allContacts: contacts,
			}));
		}

		this.componentIsMounted = true;
	}

	async componentDidMount() {
		info('CreateGroup::componentDidMount:start');
		if (!this.componentIsMounted) {
			this.setPageData();
		}
		info('CreateGroup::componentDidMount:end');
	}

	async componentDidUpdate() {
		if (this.componentIsMounted && !this.componentIsUpdating) {
			info('CreateGroup::componentDidUpdate:start');
			this.componentIsUpdating = true;
			await this.setPageData();
			this.componentIsUpdating = false;
			info('addContact::componentDidUpdate:end');
		} else {
			info('addContact::componentDidUpdate:ignored');
		}
	}

	sendRequest() {}

	async validateAndSubmit() {
		
		if (!this.state.groupname || Object.values(this.state.selectedContacts).length <= 0) {
			// setHasError(true);
			this.setState({ hasError: false, errorMessage: !this.state.groupname ? locale.groups.must_have_name : locale.groups.must_have_1_mem });
		} else {
			let payload: any;

			if (this.state.selectedGroupLogoFile) {
				let image: any = await compressSelectedFile(this.state.selectedGroupLogoFile);

				if(image.constructor === Object) {
					this.setState(image);
				}
				else {
					this.setState({ groupLogoBase64: image });
				}

				payload = { groupname: this.state.groupname, groupDescription: this.state.groupDescription, groupPhoto: image, members: Object.values(this.state.selectedContacts) };
				// actionHandler(payload, self);
				alert('actionHandler cloiacn submit');
			} else {
				payload = { groupname: this.state.groupname, groupDescription: this.state.groupDescription, groupPhoto: this.state.groupLogoBase64, members: Object.values(this.state.selectedContacts) };
				// actionHandler(payload, self);
				alert('actionHandler cloiacn submit');
			}
			info(payload);
		}
	}

	triggerPhotoUpload() {
		let uploader = document.getElementById('group-photo');
		uploader?.click();
	}

	toggleSelectedContact(index: any) {
		let allContacts = this.state.allContacts;
		let selectedContacts = this.state.selectedContacts;
		allContacts[index].isSelected = !this.state.allContacts[index].isSelected;
		this.setState({ allContacts: [...allContacts] });
		if (selectedContacts.hasOwnProperty(this.state.contacts[index]._id)) {
			delete selectedContacts[this.state.contacts[index]._id];
		} else {
			selectedContacts[this.state.contacts[index]._id] = {
				_id: this.state.contacts[index]._id,
				userId: this.state.contacts[index].userId,
				username: this.state.contacts[index].username,
				jid: `${this.state.contacts[index].userId}@${Config.xmppServer}`,
				admin: false,
			};
		}
		this.setState({ selectedContacts: selectedContacts });
	}

	render() {
		return (
			<>
				<IonToolbar className="cg-toolbar-wrapper">
					<IonToolbar className="toolbar-wrapper">
						<IonToolbar className="toolbar-wrapper">
							<div className="icon" onClick={() => this.props.history.goBack()}>
								<IonIcon size="large" slot="icon-only" icon={chevronBack} />
							</div>
							<IonButton className="send-req-button" slot="end" color="danger" style={{ width: '80px' }} onClick={(e) => this.sendRequest()}>
								{' '}
								{locale.global.send}
							</IonButton>
						</IonToolbar>
					</IonToolbar>
					{/* <IonTitle style={{ textTransform: 'capitalize', margin: '15px 0 15px 0', textAlign: 'left' }} className='toolbarTitle'>Create Group</IonTitle> */}
					{this.state.oldLayout ? (
						<IonList className="bchatList">
							<IonItem detail={false} key={1} className="searched-list">
								<IonInput
									className="formFields"
									required
									id="group_name"
									value={String(this.state.groupname)}
									placeholder="Name"
									// onIonChange={e => this.setState({ groupName: e.detail.value!, hasError: false })}
									clearInput
								/>
							</IonItem>
							<IonItem detail={false} key={2} className="searched-list">
								<IonInput className="formFields" required id="group_name" value={String(this.state.groupDescription)} placeholder="Description" onIonChange={(e) => this.setState({ groupDescription: e.detail.value!, hasError: false })} clearInput />
							</IonItem>
							<IonItem detail={false} key={3} lines="none">
								<input type="file" id="group-photo" style={{ display: 'none' }} title={""} onChange={(event: any) => this.setState({ selectedGroupLogoFile: event.target.files[0] })} />
								<IonButton className="gpToolbarTitle" type="submit" slot="start" fill="solid" onClick={(e) => this.triggerPhotoUpload()}>
									{locale.global.choose_photo}
								</IonButton>
								<IonLabel>{this.state.selectedGroupLogoFile ? this.state.selectedGroupLogoFile.name : 'No photo selected'}</IonLabel>
							</IonItem>
						</IonList>
					) : null}
				</IonToolbar>
				<IonContent className="groupCustomCard">
					<IonItemDivider style={{ background: '#000000', padding: '0 15px', color: '#ffffff' }}>
						<IonLabel>{locale.global.select_members}</IonLabel>
					</IonItemDivider>

					<IonList className="contact-page-list">
						<IonItemGroup className="alpha-contacts">
							{this.state.allContacts.length > 0 ? (
								this.state.allContacts.map((contact: any, index: number) => {
									let next = index + 1;
									if (contact.status === 'confirmed') {
										return (
											<React.Fragment key={Math.random()}>
												{index === 0 ? (
													<>
														<IonItemDivider className="cont-divider">
															<IonLabel>{contact.name.charAt(0).toUpperCase()}</IonLabel>
														</IonItemDivider>
													</>
												) : null}
												<IonItem key={Math.random()} lines="none" detail={false} onClick={() => {}}>
													<IonThumbnail slot="start" onClick={() => info(contact)}>
														<IonImg style={{ borderRadius: '3px' }} src={contact.profilePhoto ? contact.profilePhoto : fetchProfilePic(contact.userId, 'contact')} />
													</IonThumbnail>
													{this.state.selectedContacts.contact._id ? <IonIcon style={{ color: '#000000' }} slot="end" icon={checkmarkCircle}></IonIcon> : null}
													<IonLabel onClick={() => info('clicked contacts')} className="search-bar">
														<h3>{contact.name}</h3>
													</IonLabel>
												</IonItem>
												<IonItemDivider className="profile-divider" />
												{this.state.allContacts[next] && contact.userId.charAt(0) !== this.state.allContacts[next].userId.charAt(0) ? (
													<>
														<IonItemDivider className="cont-divider">
															<IonLabel>{this.state.allContacts[next].userId.charAt(0).toUpperCase()}</IonLabel>
														</IonItemDivider>
													</>
												) : null}
											</React.Fragment>
										);
									} else {
										return <div key={contact._id}></div>;
									}
								})
							) : (
								<>
									<IonItemDivider className="profile-divider" />
									{'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((char: string, index: number) => (
										<>
											<IonItemDivider key={index} className="cont-divider">
												<IonLabel>{char}</IonLabel>
											</IonItemDivider>
											<IonItemDivider className="profile-divider" />
										</>
									))}
								</>
							)}
						</IonItemGroup>
					</IonList>

					{this.state.allContacts.map((user: any, index: any) => {
						if (user.status === 'confirmed') {
							return (
								<>
									{/* <div key={user._id}>
                                            <IonItem onClick={(e) => this.toggleSelectedContact(index)} detail={false} button={true} className='confirmed-list'>
                                                <IonThumbnail className='thumb-user' slot="start" onClick={(e) => info(user, index)} >
                                                    <IonImg src={fetchProfilePic(user.userId)} />
                                                </IonThumbnail>
                                                {this.state.selectedContacts.hasOwnProperty(user._id) ? <IonIcon style={{ color: '#000000' }} slot="end" icon={checkmarkCircle}></IonIcon> : null}
                                                <IonLabel >
                                                    {user.userId}
                                                    <IonCardSubtitle style={{ textTransform: 'capitalize' }}> {"Hey there! I am using Be-Society"} </IonCardSubtitle>
                                                </IonLabel>
                                            </IonItem>
                                        </div> */}
								</>
							);
						} else {
							return <div key={user._id}></div>;
						}
					})}
				</IonContent>
				{/* <IonFooter>
                    <IonToolbar className='gp-footer'>
                        <IonButton className='gpToolbarTitle'
                            type="submit"
                            slot='end'
                            fill="solid"
                            // onClick={(e) => closeHandler(false)}
                            onClick={(e) => alert("closeHandler clicked")}
                        >Cancel</IonButton>
                        <IonButton className='gpToolbarSubmit'
                            type="submit"
                            slot='end'
                            fill="solid"
                            onClick={(e: any) => { this.validateAndSubmit(); }}
                        >Create Group</IonButton>
                    </IonToolbar>
                </IonFooter> */}
				<IonToast
					color="danger"
					isOpen={this.state.hasError}
					onDidDismiss={() => this.setState({ hasError: false })}
					message={this.state.errorMessage}
					position="bottom"
					buttons={[
						{
							text: 'Close',
							role: 'cancel',
							handler: () => {
								info('Cancel clicked');
							},
						},
					]}
				/>
			</>
		);
	}
}
const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn	
	};
};

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);

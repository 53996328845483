import {getInternalStorage,setInternalStorage, info, logError } from '../../helpers/common';
import { apiService } from '../../services/apiService';
import { locale } from '../../locales/local';
import _ from 'lodash';
import striptags from 'striptags';
import { AppManager } from '../../services/appManager';
export async function createNotification(message: any = undefined, withAudio: Boolean = false) {
	const playAudio = () => {
		const audio = new Audio('/assets/alerts/notification.mp3');

		audio.addEventListener('loadeddata', () => {
			audio.volume = 0.5;
			audio
				.play()
				.then(() => {
					info('Notifications::createNotification::playAudio audio played.');
				})
				.catch(() => {
					logError('Notifications::createNotification::playAudio: audio was not played.');
				});
		});
	};

	if (message) {
		if ((getInternalStorage()).desktopNotifications) {
			// Create and show the notification
			info('Notifications::createNotification', message);

			const img: string = '/assets/icon/icon.png',
				group: any | undefined = message?.type === 'groupchat' ? await apiService.getGroupByJid(message.from) : undefined,
				from: any = message.from !== 'control' ? `${message.sender}${group ? `@${group.groupname}` : ''}` : 'BE-Society',
				notification: any = new Notification(locale.notification.title, {
					body: striptags(message.type !== 'control' ? `${from} ${locale.notification.says}\n${_.isObject(message.body) ? message.body?.message : message.body}` : message.body).replace(/&nbsp;/g, ' '),
					icon: img,
					image: _.isObject(message.body) ? message.body.file : undefined,
					requireInteraction: true,
					tag: message.from,
				});

			notification.onclick = async (event: any) => {
				if (event.currentTarget.tag !== 'control') {
					window.focus();
					window.history.pushState(window.history.state, 'BE-SOCIETY', `/chat/${event.currentTarget.tag.split('@')[0]}`);
					setInternalStorage('reloading', true);
					AppManager.reload();
				}

				notification.close();
			};

			document.addEventListener('visibilitychange', () => {
				if (document.visibilityState === 'visible') {
					// The tab has become visible so clear the now-stale Notification.
					notification.close();
				}
			}, {once: true});

			document.addEventListener('click', () => notification.close(), { once: true });

			if (withAudio) {
				notification.onshow = () => playAudio();
			}
		}
	} else if (withAudio) {
		playAudio();
	}
}

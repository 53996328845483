import React, { useEffect, useState } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { info } from '../../../../helpers/common';
import { resetPublishData } from '../../../../redux/actions/pubsub';
import EnumService from '../../../../services/enumService';
import { sharedService } from '../../../../services/sharedService';

interface iProps {
	props?: any;
	pubsub?: any;
	resetPublishData?: any;
	chatBoxHeight: number;
	unreadMessagesIds: any;
	isNewMessageSent: Boolean;
	readNextUnreadMessage: Function;
	readNextUnreadTaggedMessage: Function;
	readAllUnreadMessages: Function;
}

const UnreadMsgAccessBtnsView: React.FC<iProps> = ({
	props,
	chatBoxHeight,
	unreadMessagesIds,
	readNextUnreadMessage,
	readNextUnreadTaggedMessage,
	readAllUnreadMessages,
	isNewMessageSent,
}) => {
	// const [shouldShowMostRecentMessageBtn, setShouldShowMostRecentMessageBtn] = useState(false);
	const [shouldShowQuickAccessBtn, setShouldShowQuickAccessBtn] = useState(unreadMessagesIds.length > 0),
		shouldShowUnreadNavigateBtn = !isNewMessageSent && unreadMessagesIds.length > 0;

	useEffect(() => {
		if (props.pubsub.publishType === EnumService.PubSubEventType.QuickAccessBtnVisibility) {
			//info('props.pubsub.publishedData?.show', props.pubsub.publishedData?.show);
			if (props.pubsub.publishedData?.show) {
				setShouldShowQuickAccessBtn(true);
				sharedService.isQuickChatAccessBtnVisible = true;
			} else {
				sharedService.isQuickChatAccessBtnVisible = false;
				setShouldShowQuickAccessBtn(false);
			}
			props.resetPublishData();
		}
	}, [props, props.pubsub]);

	/*
	useEffect(() => {
		let scrollElement: any;
		const ionContent: any = document.getElementById('chat-ion-content');
		const ionContentOffsetHeight = ionContent.offsetHeight;

		ionContent.getScrollElement().then((elem: any) => {
			if (!scrollElement) {
				scrollElement = elem;

				const checkBtnVisibility = (scrollTop: number) => {
					const leaveSpaceFromBottomForVisible = (elem.offsetHeight * 380) / 100;

					if (elem.scrollHeight - 20 > elem.offsetHeight && (unreadMessagesIds?.length > 0 || scrollTop + elem.offsetHeight <= elem.scrollHeight - leaveSpaceFromBottomForVisible)) {
						setShouldShowMostRecentMessageBtn(true);
					} else {
						setShouldShowMostRecentMessageBtn(false);
					}
				};

				checkBtnVisibility(elem.scrollTop);

				scrollElement.addEventListener('scroll', (event: any) => {
					const scrollTop = event.currentTarget.scrollTop;
					if (unreadMessagesIds && unreadMessagesIds.length > 1) {
						unreadMessagesIds.forEach((unreadMessagesId: any) => {
							const unreadMessageElem = document.getElementById(unreadMessagesId);
							if (unreadMessageElem) {
								const unreadMessageElemTop = unreadMessageElem?.offsetTop + unreadMessageElem?.offsetHeight - ionContentOffsetHeight;
								if (scrollTop >= unreadMessageElemTop) {
									if (readMessageStore.indexOf(unreadMessagesId) === -1) {
										readMessageStore.push(unreadMessagesId);
										readUnreadMessage && readUnreadMessage(unreadMessagesId);
									}
								}
							}
						});
					}
					checkBtnVisibility(scrollTop);
				}, {passive: true});
			}
		});
		return () => {
			if (scrollElement) {
				scrollElement.removeEventListener('scroll', () => {});
			}
		};
	}, [unreadMessagesIds, readUnreadMessage]);
	*/

	let timer = 0;

	return (
		<div className="unread-msg-access-btns-view" style={{ bottom: chatBoxHeight + 20 + 'px' }}>
			{props.chat?.type === 'groupchat' && shouldShowUnreadNavigateBtn && shouldShowQuickAccessBtn && props.chat?.receiver?.taggedMessages?.length > 0 && (
				<div
					className="nav-button"
					onClick={() => {
						readNextUnreadTaggedMessage && readNextUnreadTaggedMessage();
					}}>
					<img alt="@" src="./assets/icon/@-icon.png" />
					<span>{props.chat.receiver.taggedMessages?.length}</span>
				</div>
			)}

			{shouldShowQuickAccessBtn && (
				<div
					className="nav-button"
					onClick={() => {
						if (timer === 0 && shouldShowUnreadNavigateBtn) {
							timer = 1;
							setTimeout(function () {
								if (timer === 1) {
									//info('Quick Scroll', 'Single Tap');
									if (unreadMessagesIds && unreadMessagesIds.length > 0) {
										readNextUnreadMessage && readNextUnreadMessage();
									} else {
										readAllUnreadMessages && readAllUnreadMessages();
									}
								}
								timer = 0;
							}, 200);
						} else {
							//info('Quick Scroll', 'Double Tap');
							timer = 0;
							readAllUnreadMessages && readAllUnreadMessages();
						}
					}}>
					<img alt="down-arrow" src="./assets/icon/double-down-arrow.png" />
					{shouldShowUnreadNavigateBtn && unreadMessagesIds?.length > 0 && <span>{unreadMessagesIds.length}</span>}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		pubsub: state.pubsub,
	};
};
const mapDispatchToProps = (dispatch: any) => ({
	resetPublishData: () => dispatch(resetPublishData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnreadMsgAccessBtnsView);

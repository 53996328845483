/* eslint-disable array-callback-return */
import React, { useState, useCallback } from 'react';
import { IonContent, IonImg, IonModal, IonIcon, IonButton } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import './VoiceRecord.scss';
import _ from 'lodash';
import { CircleProgress } from 'react-gradient-progress';

const RecordingStopIcon = './assets/icon/record-stop-icon.svg';
const RecordingMicIcon = './assets/icon/record-mic-icon.svg';
const Waves = './assets/icon/waves.svg';
const MicActionBtnBg = './assets/img/mic-action-btn-bg.png';
const VoiceTxtIcon = './assets/icon/Voice-Txt.svg';
const VoiceCloseIcon = './assets/icon/Voice-Close.svg';
const SpeakerIcon = './assets/icon/speaker-icon.svg';
const EnIcon = './assets/icon/En.svg';

interface VoiceRecordModalProps {
	show: boolean;
	onClose?: any;
}

const VoiceRecordModal: React.FC<VoiceRecordModalProps> = ({ show, onClose, ...props }) => {
	const [convertStart, setConvertStart] = useState(false);
	const [isRecorded, setIsRecorded] = useState(false);
	const [convertProgress, setConvertProgress] = useState(70);

	const onCloseCallBack = useCallback(() => {
		onClose && onClose();
	}, [onClose]);

	const ActionButton = useCallback((icon, className = '', onClick) => {
		return (
			<IonButton className={'action-fab-btn ' + className} fill="clear" onClick={onClick}>
				<div className="container">
					<img src={MicActionBtnBg} />
					<IonIcon src={icon}></IonIcon>
				</div>
			</IonButton>
		);
	}, []);

	return (
		<IonModal showBackdrop={false} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="voicerecord-modal" animated={false}>
			<div className="content">
				<div className="recording-info-view">
					<IonImg className="waves" src={Waves} />
					<div className="duration">1:24</div>
				</div>

				<div className="actions-btns">
					{ActionButton(VoiceCloseIcon, 'close', () => {
						setConvertStart(false);
						onCloseCallBack();
					})}
					<IonButton className="record-btn" fill="clear">
						<IonIcon src={RecordingStopIcon}></IonIcon>
					</IonButton>

					{convertStart ? (
						<div className={'convert-btn-progress-container'}>
							<div className="title">Convert</div>

							<IonButton className={'convert-btn-progress'} fill="clear" onClick={() => setConvertStart(false)}>
								<div className="container">
									<div className="circle-progress">
										<CircleProgress width={72} percentage={convertProgress} strokeWidth={3} strokeLinecap={'round'} primaryColor={['#ff5e5e', '#ffeeee']} secondaryColor="#F3F3F3" />
									</div>
									<IonIcon src={EnIcon}></IonIcon>
								</div>
							</IonButton>
						</div>
					) : (
						ActionButton(VoiceTxtIcon, 'txt', () => {
							setConvertStart(true);
						})
					)}
				</div>
				<div className="bottom-mic-view">
					{isRecorded ? (
						<IonButton fill="clear" className="recorded-view-btn" onClick={() => setIsRecorded(false)}>
							<div className="recorded-view">
								<div className="title">Tap to Send</div>
								<IonIcon src={SpeakerIcon}></IonIcon>
							</div>
						</IonButton>
					) : (
						<>
							<IonButton fill="clear" className="mic-btn" onClick={() => setIsRecorded(true)}>
								<IonIcon src={RecordingMicIcon}></IonIcon>
							</IonButton>
						</>
					)}

					<IonImg src={'./assets/img/voice-record-bottom-mask.svg'} className="bottom-mask"></IonImg>
				</div>
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => {
	return {
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		searchContact: state.searchContact,
	};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VoiceRecordModal);

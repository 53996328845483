import { apiService } from '../../services/apiService';
import { CHAT_INIT, CHAT_RESET, CHAT_RELOAD } from '../constants/chat';

export function initChat(payload: any) {
	return async (dispatch: any) => {
		dispatch({ type: CHAT_RESET, payload: {} });
		const list = await apiService.getConversationHistoryByHash(payload.conversationHash);
		dispatch({ type: CHAT_INIT, payload: { ...payload, history: list } });
	};
}

export function reloadChat(payload: any) {
	return async (dispatch: any) => {
		const list = await apiService.getConversationHistoryByHash(payload.conversationHash);
		dispatch({ type: CHAT_RELOAD, payload: { ...payload, history: list } });
	};
}

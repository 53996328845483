import React from 'react';
import { IonButton, IonContent, IonGrid, IonIcon, IonImg, IonLabel, IonPage, IonRow, IonSearchbar, IonThumbnail, IonToolbar } from '@ionic/react';
import './style.scss';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { connect } from 'react-redux';
import { validateEmail, validatePhone } from '../../helpers/common';
import { searchPageLoading, searchUserContact, resetSearchState } from '../../redux/actions/searchContact';
import EnumService from '../../services/enumService';
import { TopNavbar } from '../common/header/topbar';
import { locale } from '../../locales/local';
import { apiService } from '../../services/apiService';
interface iProps extends RouteComponentProps<{}> {
	loggedInUser: any;
	isLoggedIn: boolean;
	isLoading: boolean;
	isSearching: boolean;
	loaderMessage: string;
	searchText: string;
	searchContact: any;
	searchUserContact: Function;
	searchPageLoading: Function;
	resetSearchState: Function;
}
interface iState {
	loggedInUser?: any;
	searchResults: any[];
	conversations: any[];
	showInvite: boolean;
	searchText: string;
}

class SearchContacts extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;
	componentIsUpdating: Boolean = false;

	constructor(props: iProps) {
		super(props);

		this.state = {
			loggedInUser: undefined,
			searchText: this.props.location.state ? String(this.props.location.state) : '',
			searchResults: [],
			conversations: [],
			showInvite: false,
		};

		if (this.props.location.state) {
			this.searchAllGroups();
		}
	}

	setPageData = async () => {
		let user = await await apiService.me();

		this.componentIsMounted = true;
		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
		}));
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (this.componentIsMounted && !this.componentIsUpdating) {
			this.componentIsUpdating = true;
			await this.setPageData();
			this.componentIsUpdating = false;
		}

		if (this.props.searchContact.searchResults && this.props.searchContact.searchResults.length > 0) {
			const searchResults = this.props.searchContact.searchResults.filter((_user: any) => _user.userId !== this.state.loggedInUser?.userId);
			this.setState({ searchResults });
			this.props.resetSearchState();
		}
	};

	inviteUser() {}

	addContact(user: any) {}

	searchClickHandler(user: any) {
		if (this.props.location?.state) {
			this.props.history.replace({ state: '' });
		}

		this.props.history.push('/profile-friend', {
			data: user,
			friendProfileActionType: EnumService.ProfileFriendActionTypes.ContactAdd,
			contactAddBy: EnumService.ContactAddBy.Id,
		});
	}

	searchAllGroups() {
		if (this.state.searchText.length >= 1) {
			let payload: any;
			if (validateEmail(this.state.searchText.toLowerCase())) {
				payload = { email: this.state.searchText.toLowerCase() };
			} else if (validatePhone(this.state.searchText.toLowerCase())) {
				payload = { phone: this.state.searchText.toLowerCase() };
			} else {
				payload = { userId: this.state.searchText.toLowerCase() };
			}
			this.setState({ searchResults: [] });
			this.props.searchUserContact(payload);
		} else {
			//info('----reseting values----', this.props);
			// this.props.resetValues();
		}
	}

	render() {
		if (this.componentIsMounted) {
			return (
				<IonPage className="search-contacts">
					<TopNavbar
						{...this.props}
						hideSearchBar={true}
						pageTitle="Add Contact"
						showBack={true}
						isHideRightButton={true}
						isLoading={this.props.searchContact.isLoading}
						loadingText="Searching..."
					/>

					<div className="has-topbar">
						<IonToolbar>
							<form
								onSubmit={(e) => {
									e.preventDefault();
									this.searchAllGroups();
								}}
							>
								<IonSearchbar
									animated={true}
									className="searchbar-wrapper"
									style={{ color: '#000000' }}
									debounce={1}
									value={String(this.state.searchText)}
									placeholder={locale.contacts.search_contacts.placeholders.user_id}
									onIonChange={(e) => this.setState({ searchText: e.detail.value! })}
									showCancelButton="never"
								></IonSearchbar>
							</form>
						</IonToolbar>
					</div>
					<IonContent className="wrap-contacts has-bottom-navbar">
						{this.state.searchResults.length > 0 ? (
							<>
								<IonList lines="none" className="wrap-contact-list" style={{ paddding: '0!important' }}>
									{this.state.searchResults.map((user: any, i: any) => {
										if (user.userId === this.state.loggedInUser?.userId) return null;
										return (
											<IonItem detail={true} button={true} className="searched-list" lines="none" key={i} onClick={() => this.searchClickHandler(user)}>
												<IonThumbnail className="thumb-user" slot="start">
													<IonImg src={user.profilePhoto ? user.profilePhoto : 'assets/img/avatar-placeholder.gif'} />
												</IonThumbnail>
												<IonLabel>
													<h2>{user.userId || user.username}</h2>
													<p>{user.profession || user.quote}</p>
												</IonLabel>
											</IonItem>
										);
									})}
								</IonList>
								{/* <IonContent className="wrap-contacts">
									
								</IonContent> */}
							</>
						) : (
							<>
								{!this.props.searchContact.isLoading && this.props.searchContact.showInvite ? (
									<IonContent className="wrap-contacts">
										<IonGrid className="dbGrid">
											<IonRow className="dbRow">
												<IonButton color="light" disabled={false} onClick={() => this.inviteUser()}>
													{locale.contacts.search_contacts.invite_user}
												</IonButton>
											</IonRow>
										</IonGrid>
									</IonContent>
								) : (
									<div style={{ textAlign: 'center', width: '100%' }}>
										{locale.contacts.search_contacts.my_id} {this.state.loggedInUser?.userId || ''}
									</div>
								)}
							</>
						)}
					</IonContent>

					<div className="scanbtns-container">
						<IonButton
							mode="ios"
							fill="clear"
							onClick={() => {
								this.props.history.push('/my-qrcode', {
									myQrCodeType: 'all',
								});
							}}
						>
							<IonIcon src="./assets/icon/qrcode.svg"></IonIcon>
						</IonButton>
						<IonButton mode="ios" fill="clear">
							<IonIcon src="./assets/icon/scan.svg"></IonIcon>
						</IonButton>
					</div>

					<BottomNavbar unreadCount={0} {...this.props} />
				</IonPage>
			);
		} else {
			return <></>;
		}
	}
}

const mapStateToProps = (state: any) => {
	return {
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		searchContact: state.searchContact,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	searchUserContact: (payload: String) => dispatch(searchUserContact(payload)),
	searchPageLoading: (payload: String) => dispatch(searchPageLoading(payload)),
	resetSearchState: () => dispatch(resetSearchState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContacts);

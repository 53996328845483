import React from 'react';
import { IonContent, IonLabel, IonListHeader, IonLoading, IonPage, IonToggle } from '@ionic/react';
import './style.scss';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { TopNavbar } from '../common/header/topbar';
import { connect } from 'react-redux';
import { apiService } from '../../services/apiService';
import { SelectedContacts } from './components/SelectedContacts';
import { addGroup, resetValues } from '../../redux/actions/dashboard';
import { sortByAlias } from '../../helpers/common';

interface iProps extends RouteComponentProps<{ name: string }> {
	//addGroup: Function;
	resetValues: Function;
	location: any;
	dashboard: any;
}
interface iState {
	loggedInUser?: any;
	contacts: any;
	tagsList?: any[];
}

class Tags extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);

		this.state = {
			loggedInUser: undefined,
			contacts: undefined,
			tagsList: [
				{
					name: 'Family',
					contacts: ['60869c1b9540296a8b37df0f', '6086a6279540296a8b37e5d2', '608a68ad9540296a8b3a0fda', '608fe1e2ede38421e7e62811', '60916afa9540296a8b3fb28c'],
					showInChats: false,
				},
				{
					name: 'Creatives',
					contacts: ['60869c1b9540296a8b37df0f', '6086a6279540296a8b37e5d2', '608a68ad9540296a8b3a0fda', '608fe1e2ede38421e7e62811', '60916afa9540296a8b3fb28c'],
					showInChats: false,
				},
				{
					name: 'Tulum Contacts',
					contacts: ['60869c1b9540296a8b37df0f', '6086a6279540296a8b37e5d2', '608a68ad9540296a8b3a0fda', '608fe1e2ede38421e7e62811', '60916afa9540296a8b3fb28c'],
					showInChats: false,
				},
				{
					name: 'Design Hijack',
					contacts: ['60869c1b9540296a8b37df0f', '6086a6279540296a8b37e5d2', '608a68ad9540296a8b3a0fda', '608fe1e2ede38421e7e62811', '60916afa9540296a8b3fb28c'],
					showInChats: false,
				},
			],
		};

		this.props.resetValues();
	}
	getData = async () => {
		let user = await apiService.me(),
			contacts: any = await apiService.getContacts(false, 'confirmed').then((_contacts: any) => {
				let contacts: any = [];

				if (_contacts && _contacts.length > 0) {
					contacts = _contacts.sort(sortByAlias);
				}

				return contacts;
			});

		return [user, contacts];
	};

	setPageData = async () => {

		let [user, contacts] = await this.getData();

		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
			contacts: contacts,
		}));

		this.componentIsMounted = true;
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	setSearchText() {}
	searchAllGroups(searchValue: string, props: any) {
		props.history.push('/contacts/search', searchValue);
	}

	_onContactSelect = (cont: any) => {};

	_renderSwitchItem = (title: any, value: any, onChange: any) => {
		return (
			<div className={'swtich-item'} slot="end">
				<IonLabel>{title}</IonLabel>
				<IonToggle slot="end" className="toggle-customised" mode="ios" value={value} onChange={onChange} />
			</div>
		);
	};

	render() {
		const { tagsList } = this.state;
		return (
			<>
				<IonPage className="tags-page">
					<TopNavbar
						{...this.props}
						isHideRightButton={false}
						showBack={true}
						hideSearchBar={true}
						pageTitle={'Tags'}
						onRightButtonPress={() => {
							this.props.history.push('/taginfo');
						}}
					/>

					<IonContent className="main-content-profile has-bottom-navbar has-topbar">
						<IonList lines="none">
							{tagsList?.map((item, key) => {
								return (
									<React.Fragment key={key}>
										<IonListHeader onClick={() => this.props.history.push('/taginfo')}>
											{item.name} ({item.contacts.length})
										</IonListHeader>
										<IonItem onClick={() => this.props.history.push('/taginfo')}>
											<SelectedContacts props={this.props} contacts={this.state.contacts} selectedContacts={item.contacts} />
										</IonItem>
										<IonItem>
											{this._renderSwitchItem('Show in Chats', item.showInChats, () => {
												const tagsListTmp = tagsList;
												this.setState({ tagsList: tagsListTmp });
											})}
										</IonItem>
									</React.Fragment>
								);
							})}
						</IonList>
					</IonContent>

					<BottomNavbar {...this.props} />
				</IonPage>

				<IonLoading isOpen={this.props.dashboard.isLoading} message={this.props.dashboard.loaderMessage} />
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	//addGroup: (payload: any) => dispatch(addGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);

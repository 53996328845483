import { IonIcon, IonImg, IonToolbar, IonButtons, IonModal, IonContent, IonSlides, IonSlide, IonButton, IonActionSheet } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React, { useState, useEffect, useCallback } from 'react';
import { useLongPress } from 'use-long-press';
import { locale } from '../../../locales/local';
import { info } from '../../../helpers/common'
interface ContainerProps {
	show: boolean;
	photos: Array<any>;
	onClose?: any;
}

const PhotoViewer: React.FC<ContainerProps> = ({ show, photos, onClose }) => {
	const [showSlide, setShowSlide] = useState(false);
	const [showPhotoOptions, setShowPhotoOptions] = useState(false);

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				setShowSlide(true);
			}, 500);
		} else {
			setShowSlide(false);
		}
	}, [show]);

	const slideOpts = {
		initialSlide: 0,
	};

	const callback = useCallback((event: any) => {
		setShowPhotoOptions(true);
	}, []);

	const photoOptionLongpressBind = useLongPress(callback, {
		threshold: 800,
	});

	return (
		<IonModal isOpen={show} onDidDismiss={onClose} swipeToClose={true} showBackdrop={false} cssClass="photo-viewer-modal">
			<IonToolbar style={{ position: 'absolute', '--background': 'rgba(0,0,0,0.4)' }}>
				<IonButtons slot="end">
					<IonButton slot="end" onClick={onClose}>
						<IonIcon slot="icon-only" icon={closeOutline} style={{ color: '#fff' }}></IonIcon>
					</IonButton>
				</IonButtons>
			</IonToolbar>
			<IonContent style={{ width: '100%', height: '100%', '--background': '#000' }}>
				{showSlide && (
					<IonSlides pager={true} options={slideOpts} style={{ '--bullet-background-active': '#fff' }}>
						{photos.map((photo, key) => {
							return (
								<IonSlide key={key}>
									<IonImg
										{...photoOptionLongpressBind}
										style={{
											width: '100%',
											height: '100%',
											objectFit: 'contain',
										}}
										src={String(photo)}
									></IonImg>
								</IonSlide>
							);
						})}
					</IonSlides>
				)}
			</IonContent>

			<PhotoOptionSheet
				show={showPhotoOptions}
				onClose={() => {
					setShowPhotoOptions(false);
				}}
			/>
		</IonModal>
	);
};

export default PhotoViewer;

interface PhotoOptionSheetProps {
	show: boolean;
	onClose?: any;
}

const PhotoOptionSheet: React.FC<PhotoOptionSheetProps> = ({ show, onClose }) => {
	return (
		<IonActionSheet
			isOpen={show}
			onDidDismiss={onClose}
			translucent={true}
			buttons={[
				{
					text: locale.photo.photo_viewer.send_to_chat,
					handler: () => { },
				},
				{
					text: locale.photo.photo_viewer.favorites,
					handler: () => { },
				},
				{
					text: locale.photo.photo_viewer.save_photo,
					handler: () => { },
				},
				{
					text: locale.photo.photo_viewer.edit,
					handler: () => { },
				},
				{
					text: locale.photo.photo_viewer.cancel,
					role: 'cancel',
					handler: () => {
						onClose();
						info('Cancel clicked');
					},
				},
			]}
		></IonActionSheet>
	);
};

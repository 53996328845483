import { IonModal, IonToolbar, IonContent, IonButtons, IonButton, IonIcon, IonItem, IonCheckbox, IonLabel } from '@ionic/react';
import React, { useEffect, useMemo, useState } from 'react';
import './style.scss';
import { close } from 'ionicons/icons';
import { locale } from '../locales/local';

const PencilNoteIcon = './assets/icon/pencil-note.svg';
const HeartIcon = './assets/icon/heart.svg';
const ShareIcon = './assets/icon/share.svg';
const DownloadIcon = './assets/icon/download.svg';
const ChatAlbumnIcon = './assets/icon/chat-album.svg';

interface PhotoPreviewModalProps {
	show: boolean;
	isSendPreview: boolean;
	image: any;
	onClose?: any;
	onCancel?: any;
	onSend?: any;
}

export const PhotoPreviewModal: React.FC<PhotoPreviewModalProps> = ({ show, isSendPreview, image, onClose, onCancel, onSend, ...props }) => {
	const [message, setMessage] = useState('');
	const [isDownloading, setIsDownloading] = useState(false);
	const [isFullImage, setIsFullImage] = useState(false);

	const editorOptionBtns = useMemo(
		() => [
			{ title: locale.photo.photo_viewer_modal.edit, icon: PencilNoteIcon, type: 'edit' },
			{ title: locale.photo.photo_viewer_modal.favorite, icon: HeartIcon, type: 'favorite' },
			{ title: locale.photo.photo_viewer_modal.send, icon: ShareIcon, type: 'send' },
			{ title: locale.photo.photo_viewer_modal.save, icon: DownloadIcon, type: 'save' },
		],
		[]
	);

	useEffect(() => {
		if (!show) {
			setMessage('');
		}
	}, [show]);

	return (
		<IonModal cssClass="photo-preview-modal" isOpen={show} onDidDismiss={() => onClose()} swipeToClose={false} showBackdrop={false}>
			<IonToolbar style={{ '--background': '#e8e8e8' }}>
				{!isSendPreview && (
					<div className="header-btns">
						<div className="left">
							{isDownloading ? (
								<div className="btn download-progress-btn">
									<div className="percent-text">30%</div>
									<IonButton fill="clear" onClick={() => setIsDownloading(false)}>
										<IonIcon slot="icon-only" icon={close} />
									</IonButton>
								</div>
							) : (
								<div className="btn" onClick={() => setIsDownloading(true)}>
									{locale.photo.photo_viewer_modal.full_image} (1.8M)
								</div>
							)}
						</div>
						<div className="album-btn">
							<IonIcon src={ChatAlbumnIcon} />
						</div>
					</div>
				)}
			</IonToolbar>
			<IonContent onClick={onClose}>
				<div className="image-container">
					<img src={image} alt={locale.photo.photo_viewer_modal.close} />
				</div>
			</IonContent>
			{isSendPreview ? (
				<IonToolbar className="bottom-toolbar">
					<IonButtons slot="start">
						<IonButton className="cancel-btn" onClick={onClose}>
							Cancel
						</IonButton>
					</IonButtons>
					<div className="full-size-selection">
						<IonItem lines="none">
							<IonCheckbox mode="ios" id="imageSize" slot="start" color="dark" value="off" checked={isFullImage} onIonChange={(event) => setIsFullImage(event.detail.checked)}></IonCheckbox>
							<IonLabel>Full Image</IonLabel>
						</IonItem>
					</div>

					<IonButtons slot="end">
						<IonButton className="send-btn" onClick={() => onSend(message, isFullImage)}>
							Send
						</IonButton>
					</IonButtons>
				</IonToolbar>
			) : null}

			{!isSendPreview && (
				<IonToolbar>
					<div className="editor-btns">
						{editorOptionBtns.map((item, key) => {
							return (
								<div key={key} className="editor-btn">
									<img src={item.icon} alt={locale.photo.photo_viewer_modal.edit} />
									<span>{item.title}</span>
								</div>
							);
						})}
					</div>
				</IonToolbar>
			)}
		</IonModal>
	);
};

/* eslint-disable array-callback-return */
import React from 'react';
import { IonToolbar, IonTitle, IonContent, IonApp, IonButtons, IonButton, IonIcon, IonLabel, IonGrid, IonRow, IonPage, IonNote } from '@ionic/react';
import { Redirect, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import './style.scss';
import './thread.scss';
import ThreadComponent from './thread';
import { searchContacts } from '../../redux/actions/dashboard';
import store from '../../redux/store';
import { CHAT_CLEAR, CHAT_UPDATE_HISTORY } from '../../redux/constants/chat';
import { reloadChat } from '../../redux/actions/chat';
import { blankProfilePic, info, isBlank, compressSelectedFile, getActiveConversation, isPersonalNotepad } from '../../helpers/common';
import { apiService } from '../../services/apiService';
import ChatInputBox from './components/ChatBottomComponents/ChatInputBox';
import moment from 'moment';
import EnumService from '../../services/enumService';
import { sharedService } from '../../services/sharedService';
import { ChatSelectView } from './components/ChatBottomComponents/ChatSelectView';
import DropdownPopover from '../../components/DropdownPopover';
import FavoriteSearchModal from '../../modals/favoriteSearchModal';
import _ from 'lodash';
import { PhotoPreviewModal } from '../../modals/PhotoPreviewModal';
import { publishData, resetPublishData } from '../../redux/actions/pubsub';
import { locale } from '../../locales/local';
import UnreadMsgAccessBtnsView from './components/ChatBottomComponents/UnreadMsgAccessBtnsView';
import { Virtuoso, GroupedVirtuoso, ListRange, LogLevel } from 'react-virtuoso';
import CustomSpinner from '../../components/CustomSpinner';
import { xmpp } from '../../services/xmpp';
import { CHAT_INIT } from '../../redux/constants/chat';
import VoiceRecord from '../../modals/VoiceRecord/VoiceRecord';
const groupHeaderMenuOption = [
	{ title: locale.dashboard.chat.group_info, svgIcon: 'info.svg', type: 'groupInfo' },
	{ title: locale.dashboard.chat.favorite, svgIcon: 'heart.svg', type: 'favorite' },
	{ title: locale.dashboard.chat.todo, svgIcon: 'todos.svg', type: 'todos' },
	{ title: locale.dashboard.chat.tagged, svgIcon: 'tagged.svg', type: 'tagged' },
	{ title: locale.dashboard.chat.group_settings, svgIcon: 'settings.svg', type: 'groupSettings' },
];

require('default-passive-events');
class Chat extends React.Component<iProps, iState> {
	constructor(props: iProps) {
		super(props);

		this.state = {
			...this.props,
			dynamicClass: false,
			showFavoriteSearchModal: false,
			showReload: false,
			receiver: {},
			isInitialDataLoadCompleted: false,
			selectingMessages: false,
			message: '',
			selectedMessages: [],
			isSendPreview: false,
			captionMessage: '',
			openPreview: false,
			selectedImage: undefined,
			imageResolution: undefined,
			popoverEvent: undefined,
			showPopover: false,
			unreadMessagesIds: [],
			children: null,
			chatBoxHeight: 0,
			shouldShowQuickAccessBtn: false,
			isChatSyncing: false,
			isNetworkConnectionOnline: window.navigator.onLine,
		};
	}

	receiverProfilePhoto: any;
	receiverNameToDisplay: any;
	senderId: any;
	user: any;
	previousMessageTime: any;
	shouldShowTime: Boolean = false;
	conversationFetching: Boolean = false;
	componentIsMounted: Boolean = false;
	componentIsUnMounted: Boolean = false;
	componentIsUpdating: Boolean = false;
	loggedInUser: any = this.props.loggedInUser;
	activeConversation: any = undefined;
	personalNotepad: Boolean = isPersonalNotepad();
	conversations: any[] = [];
	isAllUnreadMessageUpdatingInProgress: Boolean = false;
	isManualScrolling: boolean = false;
	isNewMessageSent: boolean = false;
	unreadThreadlist: any = [];
	isUnreadThreadsAppended: boolean = false;
	isAtBottom: boolean = false;
	virtuosoRef: any = React.createRef();
	chatInputBoxRef: any = React.createRef();
	unreadRequestSentIds: any = []; // It is usefull when send unread update request send
	readMessageStore: any = [];
	virtualScrollOffsetHeight: number = 0;
	virtualScrollContentHeight: number = 0;
	virtualScrollOffsetTop: number = 0;
	chatSyncListenerTimer: any = null;
	showQuickScrollComponent: Boolean = false;
	isRendered: Boolean = false;
	bodyHeight: any = document?.querySelector('body')?.offsetHeight;
	conversationHeight: number = this.bodyHeight - 84 - 58;
	maxConversationItems: number = Math.trunc(this.conversationHeight / 112);
	range: ListRange = { startIndex: 0, endIndex: 0 };
	uploadController: any;
	isRebuilding: Boolean = false;
	firstItem: any = 0;

	virtuosoComponents: any = {
		Header: () => <div id="virtualsoHeader"></div>,
		Footer: () => <div id="virtualsoFooter" style={{ height: '48px' }}></div>,
	};

	toggleClass() {
		this.setState({ dynamicClass: !this.state.dynamicClass });
	}

	scrollTo(index: number = -1, duration = 0, scrollFrom = '') {
		if (sharedService.isChatScrolling) {
			return;
		}
		info('Log_Scroll_to_call ', scrollFrom);
		_.defer(async () => {
			//info('isManualScrolling', true);
			this.isManualScrolling = true;
			// let ionContent: any = document.getElementById('chat-ion-content');
			// if (ionContent) {
			// 	const elem = await ionContent.getScrollElement();
			// 	if (offsetTop) {
			// 		elem?.scrollTo(0, offsetTop);
			// 	} else {
			// 		ionContent?.scrollToBottom(duration);
			// 	}
			// } else {
			// 	info('scrollTo-call-from', 'scrollTo');
			// 	this.scrollTo(0);
			// }
			const scrollVirtualListTo = (indexNumber: Number) => {
				this.virtuosoRef?.current?.scrollToIndex({
					index: indexNumber,
					align: 'top',
					behavior: duration ? 'smooth' : 'auto',
				});
			};
			if (index >= 0) {
				scrollVirtualListTo(index);
			} else {
				scrollVirtualListTo(this.props?.chat?.history?.length - 1);
			}
			_.defer(() => {
				this.isManualScrolling = false;
			}, 1000);
			return '';
		});
	}

	scrollToIndex(index: number = -1, duration = 0, scrollFrom = '') {
		if (sharedService.isChatScrolling) {
			return;
		}
		_.defer(async () => {
			this.isManualScrolling = true;

			const scrollVirtualListTo = (indexNumber: Number) => {
				this.virtuosoRef?.current?.scrollToIndex({
					index: indexNumber,
					align: 'top',
					behavior: duration ? 'smooth' : 'auto',
				});
			};
			if (index >= 0) {
				scrollVirtualListTo(index);
			} else {
				scrollVirtualListTo(this.props?.chat?.history?.length - 1);
			}
			_.defer(() => {
				this.isManualScrolling = false;
			}, 1000);
			return '';
		});
	}

	setImagePreview() {
		this.setState({ openPreview: false, isSendPreview: true });
	}

	previewActionHandler(image: string, self: any) {
		self.setState({ openPreview: true, selectedImage: image, isSendPreview: false });
	}

	async sendImage() {
		const messageBody: any = {
			file: await compressSelectedFile(this.state.selectedImage, this.state?.imageResolution ? { x: this.state.imageResolution, y: this.state.imageResolution, fit: 'contain', upscale: false } : undefined),
			size: this.state.imageResolution ? 'reduced image' : 'full image',
			message: this.state.captionMessage,
		};

		let messageType = EnumService.ChatMessageType.IMAGE,
			relatedMessageId = '';

		if (this.chatInputBoxRef.current?.isReplyViewOpen) {
			messageType = EnumService.ChatMessageType.IMAGE_REPLY;
			relatedMessageId = this.chatInputBoxRef.current.replyForMessageId;
			this.chatInputBoxRef.current.closeReplyView();
		}

		this.uploadController = await xmpp.sendMessage(this.props.chat?.receiver.jid, messageBody, messageType, this.props.chat?.receiver?.lastMessage, relatedMessageId);

		this.setState({
			openPreview: false,
			message: '',
			captionMessage: '',
			selectedImage: undefined,
			isSendPreview: false,
		});

		//this.scrollTo(-1, 0, 'sendImage');
	}

	async cancelUpload() {
		await apiService.cancelUpload(this.uploadController);
		this.setState({ openPreview: false, selectedImage: undefined, isSendPreview: false });
	}

	pushToSelectedMessages(message: any) {
		const selectedMessages: any = this.state.selectedMessages || [];

		let selectedIndex = -1;
		selectedMessages.some((item: any, key: number) => {
			if (item.id === message.id) {
				selectedIndex = key;
				return true;
			}
		});

		if (selectedIndex >= 0) {
			selectedMessages.splice(selectedIndex, 1);
		} else {
			selectedMessages.push(message);
		}

		this.setState({ selectedMessages });
	}

	onOnline = () => {
		console.log('isNetworkConnectionOnline', true);
		if (!this.componentIsUnMounted) {
			if (!this.state.isNetworkConnectionOnline) {
				(async () => await xmpp.xmppManager())();
			}

			this.setState({ isNetworkConnectionOnline: true });
		}
	};

	onOffline = () => {
		console.log('isNetworkConnectionOnline', false);
		if (!this.componentIsMounted) {
			this.setState({ isNetworkConnectionOnline: false });
			xmpp.reset();
		}
	};

	componentWillUnmount() {
		this.componentIsUnMounted = true;
		store.dispatch({ type: CHAT_CLEAR });
		window.removeEventListener('online', this.onOnline, true);
		window.removeEventListener('offline', this.onOffline, true);
		this.chatSyncListenerTimer && clearInterval(this.chatSyncListenerTimer);
	}

	async setPageData() {
		if (!xmpp.isReady || xmpp.inErrorState) {
			await xmpp.xmppManager({ noReset: true });
		}

		this.loggedInUser = isBlank(this.loggedInUser) ? await apiService.me() : this.loggedInUser;
		this.activeConversation = getActiveConversation(this.loggedInUser);
		this.personalNotepad = isPersonalNotepad();
		//info(`Chat::setPageData: user: ${this.loggedInUser.userId}, activeConversation: ${this.activeConversation}, personalNotepad: ${this.personalNotepad}`);

		if (isBlank(this.props.chat.receiver) || isBlank(this.props.chat.history)) {
			if (!this.isRebuilding) {
				this.isRebuilding = true;
				info('Chat::setPageData: this.isRebuilding set');
			}

			let conversations: any = await apiService.getConversations(),
				receiver: any = conversations.find((_conversation: any) => _.includes([_conversation.userId, _conversation.room], this.activeConversation)),
				history: any = !isBlank(receiver) ? await apiService.getConversationHistoryByHash(receiver.conversationHash) : undefined;

			if (isBlank(receiver)) {
				this.props.history.goBack();
			} else {
				store.dispatch({
					type: CHAT_INIT,
					payload: { conversations: conversations, history: history, receiver: receiver },
				});
			}
		}
	}

	async componentDidMount() {
		//info('Chat::componentDidMount:start');
		window.addEventListener('online', this.onOnline, true);
		window.addEventListener('offline', this.onOffline, true);
		await this.setPageData();

		if (this.isRebuilding) {
			this.isRebuilding = false;
			info('Chat::componentDidMount: this.isRebuilding reset');
		}

		this.isRendered = true;
		this.componentIsMounted = true;
		this.setState({ isInitialDataLoadCompleted: true });
		//info('Chat::componentDidMount:end');
	}

	shouldComponentUpdate(nextProps: any, nextState: any) {
		let shouldUpdate = false;
		const isOnlineOfflineChanged: boolean = this.state.isNetworkConnectionOnline !== nextState.isNetworkConnectionOnline,
			isChatHistoryUpdated: boolean = this.props.chat.history?.length !== nextProps.chat.history?.length || nextState.selectedImage,
			isDataPublished: boolean = nextProps.pubsub?.publishType === EnumService.PubSubEventType.QuickAccessBtnVisibility && this.props.pubsub?.publishedData?.show !== nextProps.pubsub?.publishedData?.show,
			isSyncingStateChanged = this.state.isChatSyncing !== nextState.isChatSyncing,
			isRead: Boolean = nextProps.chat.history.length > 0 && nextProps.chat.history[nextProps.chat.history.length - 1]?.read === 1,
			isUnreadMessagesChanged = this.state.unreadMessagesIds.length !== nextState.unreadMessagesIds.length;

		if (nextProps.chat.history.length > 0 && (!this.componentIsMounted || isOnlineOfflineChanged || isChatHistoryUpdated || isDataPublished || isSyncingStateChanged || isUnreadMessagesChanged || isRead || this.isRebuilding)) {
			!this.componentIsMounted && info('Chat::shouldComponentUpdate: because !this.componentIsMounted');
			isOnlineOfflineChanged && info('Chat::shouldComponentUpdate: because isOnlineOfflineChanged');
			isChatHistoryUpdated && info('Chat::shouldComponentUpdate: because isChatHistoryUpdated');
			isDataPublished && info('Chat::shouldComponentUpdate: because isDataPublished');
			isSyncingStateChanged && info('Chat::shouldComponentUpdate: because isSyncingStateChanged');
			isRead && info('Chat::shouldComponentUpdate: because isRead');
			isUnreadMessagesChanged && info('Chat::shouldComponentUpdate: because isUnreadMessagesChanged');
			this.isRebuilding && info('Chat::shouldComponentUpdate: because this.isRebuilding');
			shouldUpdate = true;

			this.bodyHeight = document.querySelector('body')?.offsetHeight;

			if (this.bodyHeight > 0) {
				this.conversationHeight = this.bodyHeight - 84 - 58;
				this.maxConversationItems = Math.trunc(this.conversationHeight / 112);
				this.firstItem = this.props.chat.history.length > this.maxConversationItems ? this.props.chat.history.length - this.maxConversationItems + 1 : 0;
			} else {
				this.firstItem = 0;
			}

			//info('Chat::shouldComponentUpdate: this.firstItem', this.firstItem);
		} else if (nextProps.chat.history.length === 0) {
			info('Chat::shouldComponentUpdate: false because this.props.chat.history.length === 0');
		} else {
			info('Chat::shouldComponentUpdate: false because no conditions met');
		}

		return shouldUpdate;
	}

	async componentDidUpdate(prevProps: any) {
		//info('this.props.chat.receiver.unreadMessages.length', this.props.chat?.receiver?.unreadMessages?.length);
		//info('this.state.unreadMessagesIds.length', this.state.unreadMessagesIds.length);

		if (!this.componentIsUpdating) {
			//info('Chat::componentDidUpdate:start');
			this.componentIsUpdating = true;
			await this.setPageData();

			this.virtuosoRef?.current?.scrollToIndex({
				index: this.firstItem,
				align: 'end',
				behavior: 'auto',
			});

			if (this.props.chat.receiver.unreadCount > 0 && !this.isAllUnreadMessageUpdatingInProgress) {
				this.isAllUnreadMessageUpdatingInProgress = true;
				info(`Chat::componentDidUpdate: setting ${this.props.chat.receiver.unreadCount} unread messages read.`);
				apiService.updateReadStatus(this.props.chat.receiver.unreadCount > 1 ? { conversation: this.props.chat?.receiver } : { ids: this.props.chat?.receiver.unreadMessages });
			}

			if (this.isRebuilding) {
				this.isRebuilding = false;
				info('Chat::componentDidUpdate: this.isRebuilding reset');
			}

			this.componentIsUpdating = false;
			//info('Chat::componentDidUpdate:end');
		} else {
			//info('Chat::componentDidUpdate:ignored');
		}
	}

	/*async handleUnreadMessagesIds() {
		if (this.props?.chat?.receiver) {
			const unreadMessages: any[] = await apiService.getUnreadMessages(this.props.chat?.receiver?.conversationHash);
			const unreadMessagesIds: any = [];
			if (unreadMessages && unreadMessages.length > 0) {
				unreadMessages.forEach((_message: any) => {
					if (!_.includes(this.unreadRequestSentIds, _message.id)) {
						unreadMessagesIds.push(_message.id);
					}
				});
				//this.setState({ unreadMessagesIds });
			} else if (this.state.unreadMessagesIds.length > 0) {
				//this.setState({ unreadMessagesIds: [] });
			}
			//info('unreadMessagesIds', this.state.unreadMessagesIds);
		} else {
			//this.setState({ unreadMessagesIds: [] });
		}
	}*/

	async setAllMessagesRead() {
		if (!this.isAllUnreadMessageUpdatingInProgress && !this.personalNotepad && this.state.unreadMessagesIds && this.state.unreadMessagesIds.length > 0) {
			let isMsgNotSentAlreadyForReadStatus = false;
			const unreadMsgIds: any = [];
			this.state.unreadMessagesIds.forEach((item: any) => {
				if (!_.includes(this.unreadRequestSentIds, item)) {
					isMsgNotSentAlreadyForReadStatus = true;
					unreadMsgIds.push(item);
				}
			});

			if (isMsgNotSentAlreadyForReadStatus) {
				this.unreadRequestSentIds = this.unreadRequestSentIds.concat(unreadMsgIds);
				this.isAllUnreadMessageUpdatingInProgress = true;
				await apiService.updateReadStatus({ conversation: this.props.chat?.receiver });
				this.state.unreadMessagesIds.splice(0, this.state.unreadMessagesIds.length);
				console.log(this.state.unreadMessagesIds);

				this.setState({ unreadMessagesIds: [] });
				this.isAllUnreadMessageUpdatingInProgress = false;
				//this.setPageData();
			}
		}
	}

	async setReadMessagesByIds(unreadMessageIds: any) {
		if (!this.isAllUnreadMessageUpdatingInProgress && unreadMessageIds?.length > 0) {
			const ids: any = [];

			unreadMessageIds.forEach((msgId: any) => {
				if (!_.includes(this.unreadRequestSentIds, msgId)) {
					ids.push(msgId);
				}
			});

			this.unreadRequestSentIds = this.unreadRequestSentIds.concat(ids);

			if (ids && ids.length > 0) {
				await apiService.updateReadStatus({ ids: ids });
			}
		}
	}

	async readNextUnreadMessage(nextIndex: number) {
		await this.setReadMessagesByIds([nextIndex]);
	}
	async readNextUnreadMessage2(nextIndex: number) {
		if (!this.props.chat?.receiver?.notepadJid) {
			const unreadCount = this.props.chat?.receiver?.unreadCount ? this.props.chat?.receiver?.unreadCount : this.state.unreadMessagesIds ? this.state.unreadMessagesIds.length : 0;

			if (unreadCount > 0) {
				const firstUnreadMsgId: string = nextIndex < unreadCount && nextIndex >= 0 ? this.state.unreadMessagesIds[nextIndex] : this.state.unreadMessagesIds[0];
				const element: any = document.getElementById(firstUnreadMsgId);

				if (this.state.unreadMessagesIds?.length === 1) {
					//info('scrollTo-call-from', 'readNextUnreadMessage 1');
					this.scrollTo(-1, 0, 'readNextUnreadMessage->unreadMessagesIds.length is 1');
					await this.setReadMessagesByIds([firstUnreadMsgId]);
				} else {
					const chatInputBox: any = document.getElementById('chatInputBox');

					if (chatInputBox) {
						const minY = element ? element.offsetTop - (this.virtualScrollOffsetHeight || 0) / 2 : 0,
							maxY = minY + this.virtualScrollOffsetHeight - chatInputBox.offsetHeight,
							readThisMsgIds: any = [firstUnreadMsgId];

						this.state.unreadMessagesIds.map((unreadMessagesId: any) => {
							const msgElement = document.getElementById(unreadMessagesId);

							if (msgElement) {
								const offsetTop = msgElement.offsetTop,
									offsetBottom = offsetTop + msgElement.offsetHeight;

								if (offsetBottom <= maxY) {
									if (!_.includes(readThisMsgIds, unreadMessagesId)) {
										readThisMsgIds.push(unreadMessagesId);
									}
								}
							}
						});
						//info('scrollTo-call-from', 'readNextUnreadMessage 2');

						// this.scrollTo(element.offsetTop - (this.virtualScrollOffsetHeight || 0) / 2);
						const scrollToIndex = this.props?.chat?.history
							.map(function (o: any) {
								return o.id;
							})
							.indexOf(firstUnreadMsgId);

						this.scrollTo(scrollToIndex - 1, 0, 'readNextUnreadMessage->scrollToIndex');

						if (readThisMsgIds.length > 0) {
							await this.setReadMessagesByIds(readThisMsgIds);
						}
					}
				}
			}
		}
	}

	async readNextUnreadTaggedMessage(nextIndex: number) {
		if (!this.personalNotepad) {
			const taggedMessages = this.props.chat?.receiver?.taggedMessages;

			if (taggedMessages && taggedMessages.length > 0) {
				const firstUnreadTaggedMsg: any = nextIndex < taggedMessages.length && nextIndex >= 0 ? taggedMessages[nextIndex] : taggedMessages[0],
					firstUnreadMsgId: string = firstUnreadTaggedMsg.id,
					element: any = document.getElementById(firstUnreadMsgId),
					chatInputBox: any = document.getElementById('chatInputBox');

				if (element && chatInputBox) {
					const minY = element.offsetTop - (this.virtualScrollOffsetHeight || 0) / 2,
						maxY = minY + this.virtualScrollOffsetHeight - chatInputBox.offsetHeight,
						readThisMsgIds: any = [];

					this.state.unreadMessagesIds.map((unreadMessagesId: any) => {
						const msgElement = document.getElementById(unreadMessagesId);

						if (msgElement) {
							const offsetTop = msgElement.offsetTop,
								offsetBottom = offsetTop + msgElement.offsetHeight;

							if (offsetBottom <= maxY) {
								readThisMsgIds.push(unreadMessagesId);
							}
						}
					});

					taggedMessages.map((msg: any) => {
						const unreadMessagesId = msg.id,
							msgElement = document.getElementById(unreadMessagesId);

						if (msgElement) {
							const offsetTop = msgElement.offsetTop,
								offsetBottom = offsetTop + msgElement.offsetHeight;

							if (offsetBottom <= maxY) {
								readThisMsgIds.push(unreadMessagesId);
							}
						}
					});

					//info('scrollTo-call-from', 'readNextUnreadTaggedMessage');
					const scrollToIndex = this.props?.chat?.history
						.map(function (o: any) {
							return o.id;
						})
						.indexOf(firstUnreadMsgId);
					this.scrollTo(scrollToIndex - 1, 0, 'readNextUnreadTaggedMessage');

					if (readThisMsgIds.length > 0) {
						await this.setReadMessagesByIds(readThisMsgIds);
					}
				}
			}
		}
	}
	_openFriendProfile(profileType: string, userId?: any) {
		if (profileType === 'chat') {
			this.props.history.push('/profile-friend', {
				data: this.props.chat?.receiver,
				friendProfileActionType: EnumService.ProfileFriendActionTypes.ChatProfileView,
			});
		} else if (profileType === 'me') {
			this.props.history.push('/profile', {
				shouldBack: true,
			});
		} else if (profileType === 'group' && userId) {
			let userProfile: any;
			this.props.chat.conversations.some((user: any) => {
				if (user?.userId?.toLowerCase() === userId?.toLowerCase()) {
					userProfile = user;
					return true;
				}
			});

			this.props.history.push('/profile-friend', {
				data: userProfile,
				friendProfileActionType: EnumService.ProfileFriendActionTypes.ChatProfileView,
			});
		}
	}

	startSelectingMessages() {
		this.setState({ selectingMessages: true });
	}

	_selectAllMessages() {
		if (this.state.selectedMessages?.length !== this.props.chat?.history?.length) {
			this.setState({ selectedMessages: JSON.parse(JSON.stringify(this.props.chat?.history)) });
		}
	}

	_startForwardingMessages(message: any) {
		this.props.history.push('/contacts/chat', {
			actionType: EnumService.ChatContactsPageActionType.ForwardMessage,
			chatDetail: message,
		});
	}

	async _previewImage(image: any) {
		//info('Chat::_previewImage: state set with selectedImage.');
		this.setState({ openPreview: true, selectedImage: image, isSendPreview: true });
	}

	checkBtnVisibility(scrollTop: number, scrollHeight: number, offsetHeight: number) {
		// const leaveSpaceFromBottomForVisible = (offsetHeight * 380) / 100;
		const leaveSpaceFromBottomForVisible = (offsetHeight * 100) / 100;
		const isChatContentScrollable = scrollHeight - 20 > offsetHeight;
		if (isChatContentScrollable && scrollTop + offsetHeight <= scrollHeight - leaveSpaceFromBottomForVisible) {
			if (!sharedService.isQuickChatAccessBtnVisible) {
				this.props.publishData({
					type: EnumService.PubSubEventType.QuickAccessBtnVisibility,
					data: { show: true },
				});
			}
		} else if (sharedService.isQuickChatAccessBtnVisible) {
			this.props.publishData({
				type: EnumService.PubSubEventType.QuickAccessBtnVisibility,
				data: { show: false },
			});
		}
	}

	handleFollowOutput(isAtBottom: Boolean) {
		if (isAtBottom) {
			return 'smooth';
		} else {
			return false;
		}
	}

	handleItemsRendered = (e: any) => {
		//info('Chat::virtuoso-ItemsRendered: itemsRendered', e);

		if (e[e.length - 1]?.index > this.firstItem) {
			this.firstItem = e[e.length - 1].index;
			this.virtuosoRef?.current?.scrollToIndex({
				index: this.firstItem,
				align: 'end',
				behavior: 'auto',
				offset: e[e.length - 1].offset,
			});
			//info(`Chat::handleItemsRendered: this.firstItem=${this.firstItem}`);
		}
	};

	render() {
		//info('render');
		//let timerRef: any = null,
		//	dateGroups: any = [];

		// for potential future use of grouping conversations by date/time
		/*_.each(
			_.groupBy(this.props.chat.history, (_message: any) => _message.timestamp.split('T')[0]),
			(_date: any) => {
				let datedGroup = _.groupBy(_date, (_message: any) => {
					let firstPart: string = _message.timestamp.split('.')[0],
						secondPart: any = firstPart.split(':');

					secondPart.pop();
					return secondPart.join(':');
				});
				dateGroups.push(datedGroup);
			}
		);

		let dates: any = {
			dates: dateGroups.map((_date: any) => Object.keys(_date)[0]),
			messagesPerDate: dateGroups.map((_date: any) => Object.keys(_date).length),
		};*/

		//info(`Chat::render: this.firstItem=${this.firstItem}`);

		// this is required in order to handle the user refreshing their screen when the chat is open,
		// or when the chat is directly referenced via a url
		if (!this.props.chat.receiver.jid || !this.props.chat.history) {
			if (!this.props.chat.receiver.jid) {
				this.props.chat.receiver = apiService.getConversations().then((_conversations: any) => _conversations.find((_conversation: any) => _.includes([_conversation.userId, _conversation.room], this.activeConversation)));
			} else {
				info(`Chat::render: should not be here.  history length is ${this.props.chat?.history?.length}`);
			}
		}

		this.activeConversation = getActiveConversation(this.loggedInUser);

		return (
			<IonApp>
				{!this.props.chat?.receiver && this.componentIsMounted ? (
					<Redirect to="/auth" />
				) : (
					<IonPage className="chat-page">
						<div className="chat-top-header">
							<IonToolbar className="header-toolbar">
								<IonTitle className="ct-toolbar-title" onClick={() => this._openFriendProfile(this.props.chat?.type)}>
									{this.state.isChatSyncing || this.props.chat?.showLoading || !this.state.isNetworkConnectionOnline ? (
										<>
											<div className="synch-loader">
												<CustomSpinner />
												<IonLabel>{this.props?.chat?.loaderMessage || locale.global.loading}</IonLabel>
											</div>
										</>
									) : (
										<>
											<div className="main-title">{this.props.chat?.type === 'chat' || this.personalNotepad ? this.props.chat?.receiver?.alias || this.props.chat?.receiver?.name || this.props.chat?.receiver?.username || this.props.chat?.receiver?.userId : this.props.chat?.receiver.groupname}</div>
											{(this.props.chat?.type === 'chat' || this.personalNotepad) && <div className="profession">{this.props.chat?.receiver?.profession}</div>}
											{this.props.chat?.type === 'groupchat' && !this.personalNotepad && (
												<div className="profession">
													{this.props.chat?.receiver?.members?.length + ' '}
													{this.props.chat?.receiver?.members?.length > 1 ? 'members' : 'member'}
												</div>
											)}
										</>
									)}
								</IonTitle>

								<IonButtons slot="start">
									{this.state.selectingMessages ? (
										<IonButton className="select-all-btn" onClick={this._selectAllMessages}>
											{locale.dashboard.chat.select_all}
										</IonButton>
									) : (
										<IonButton
											className="ct-btn-back"
											onClick={(e) => {
												this.props.history.push('/auth');
											}}
										>
											<IonIcon className="ct-icon-back" slot="icon-only" src={'./assets/icon/back.svg'} />
										</IonButton>
									)}
								</IonButtons>
								<IonButtons slot="end">
									{this.state.selectingMessages ? (
										<IonButton
											className="selection-cancel-btn"
											fill="clear"
											onClick={(event: any) => {
												event.persist();
												this.setState({ selectingMessages: false, selectedMessages: [] });
											}}
										>
											{locale.global.cancel}
										</IonButton>
									) : (
										<IonButton
											className="ct-btn-option"
											onClick={(event: any) => {
												if (this.personalNotepad) {
													this.props.history.push('/profile', {
														shouldBack: true,
													});
												} else if (this.props.chat?.type === 'chat') {
													this._openFriendProfile(this.props.chat?.type);
												} else {
													event.persist();
													this.setState({ showPopover: true, popoverEvent: event });
												}
											}}
										>
											<IonIcon className="ct-icon-option" slot="icon-only" src={'./assets/icon/3doticon.svg'} />
										</IonButton>
									)}
								</IonButtons>
							</IonToolbar>
						</div>
						<IonContent id="chat-ion-content" scrollEvents={true}>
							{this.props.chat.receiver.jid && this.props.chat.history ? (
								<>
									<Virtuoso
										/*logLevel={LogLevel.DEBUG}*/
										ref={this.virtuosoRef}
										className="virtualso"
										components={this.virtuosoComponents}
										followOutput={'smooth'}
										data={this.props.chat.history}
										atBottomStateChange={(bottom) => {
											if (!bottom) {
												//info('Chat::render: bottom = ', bottom);
											}
										}}
										initialTopMostItemIndex={this.firstItem}
										initialItemCount={this.props.chat.history.length}
										itemsRendered={this.handleItemsRendered}
										isScrolling={(status) => {
											// sharedService.isChatScrolling = status;
										}}
										onScroll={(event: any) => {
											/*if (event.target?.scrollTop === 0) {
													this.isAtBottom = true;
												} else {
													this.isAtBottom = false;
												}
												console.log(event);
												sharedService.isChatScrolling = true;
												if (timerRef !== null) {
													clearTimeout(timerRef);
												}
												timerRef = setTimeout(function () {
													sharedService.isChatScrolling = false;
												}, 500);

												const scrollTop = event.target?.scrollTop;
												const scrollHeight = event.target?.scrollHeight;
												const offsetHeight = event.target?.offsetHeight;

												this.virtualScrollOffsetHeight = offsetHeight;
												this.virtualScrollContentHeight = scrollHeight;
												this.virtualScrollOffsetTop = scrollTop;

												// info('Event scrollTop ', scrollTop);
												// info('Event scrollHeight ', scrollHeight);
												// info('Event offsetHeight', offsetHeight);

												if (this.state.unreadMessagesIds && this.state.unreadMessagesIds.length > 1) {
													this.state.unreadMessagesIds.forEach((unreadMessagesId: any) => {
														const unreadMessageElem = document.getElementById(unreadMessagesId);
														if (unreadMessageElem) {
															const unreadMessageElemTop = unreadMessageElem?.offsetTop + unreadMessageElem?.offsetHeight - offsetHeight;
															if (scrollTop >= unreadMessageElemTop) {
																if (!_.includes(this.readMessageStore, unreadMessagesId)) {
																	this.readMessageStore.push(unreadMessagesId);
																	if (this.componentIsMounted && !this.isManualScrolling) {
																		this.setReadMessagesByIds([unreadMessagesId]);
																	}
																}
															}
														}
													});
												}
												this.checkBtnVisibility(scrollTop, scrollHeight, offsetHeight);*/
										}}
										itemContent={(index) => {
											const message = this.props.chat.history[index];
											if (index === 0) {
												this.previousMessageTime = undefined;
											}
											if (message && (message.body || message.mediaUrl || message.mediaThumbnail)) {
												const sameElseDateTimeFormat: string = '(MMM D, YYYY) h:mm A',
													messageSentTime: string = message.timestamp
														? moment(message.timestamp).calendar(null, {
																sameDay: 'h:mm A',
																lastDay: `[(${locale.dashboard.chat.yesterday}]) h:mm A`,
																lastWeek: '(dddd) h:mm A',
																sameElse: sameElseDateTimeFormat,
														  })
														: locale.dashboard.chat.recently,
													shouldShowTime: Boolean = this.previousMessageTime !== messageSentTime;

												this.previousMessageTime = messageSentTime;

												if (message.sender === 'Me') {
													this.receiverProfilePhoto = this.loggedInUser?.profileThumb || this.loggedInUser?.profilePhoto || blankProfilePic;
													this.receiverNameToDisplay = message.sender;
													this.senderId = this.loggedInUser?.userId;
												} else if (this.props.chat?.type === 'groupchat') {
													let receiverProfileDetail: any = this.props.chat.conversations?.find((_contact: any) => _contact?.userId === this.props.chat?.receiver?.members?.find((_member: any) => _member.alias === message.sender)?.userId);
													this.receiverProfilePhoto = receiverProfileDetail?.profileThumb || receiverProfileDetail?.profilePhoto || blankProfilePic;
													this.receiverNameToDisplay = message.sender;
													this.senderId = receiverProfileDetail?.userId;
												} else {
													this.receiverProfilePhoto = this.props.chat?.receiver?.profileThumb || this.props.chat?.receiver?.profilePhoto || blankProfilePic;
													this.receiverNameToDisplay = this.props.chat?.receiver?.alias || this.props.chat?.receiver?.name || this.props.chat?.receiver?.username || this.props.chat?.receiver?.userId;
													this.senderId = this.props.chat?.receiver.userId;
												}

												if (!isBlank(message.linkPreview)) {
													message.body = `<div class="preview-link-view"><a href="${message.linkPreview.url}" target="_blank"><img src="${message.linkPreview.image || message.linkPreview.logo}" /></a><div class="title">${message.linkPreview.title}</div><div class="url">${
														message.linkPreview.url.replace(/http?s:\/\//, '').split('/')[0]
													}</div></div>`;
												}

												return (
													<div key={message.id} className="chat-item-container" id={message.id}>
														{shouldShowTime && (
															<IonRow className="timestamp-row">
																<IonNote color="danger">
																	<span dangerouslySetInnerHTML={{ __html: messageSentTime.replace('(', '<span class="date-day">').replace(')', '</span>') }}></span>
																</IonNote>
															</IonRow>
														)}

														<ThreadComponent
															self={this}
															type={this.props.chat?.type}
															myProfilePhoto={this.loggedInUser?.profilePhoto || blankProfilePic}
															receiverProfilePhoto={this.receiverProfilePhoto}
															selectingMessages={this.state.selectingMessages}
															selectedMessages={this.state.selectedMessages as []}
															pushToSelectedMessages={() => this.pushToSelectedMessages(message)}
															startSelectingMessages={this.startSelectingMessages}
															startForwardingMessages={() => this._startForwardingMessages(message)}
															actionHandler={this.previewActionHandler}
															loggedInUser={this.props.loggedInUser}
															senderUserId={this.loggedInUser?.userId}
															senderProfileName={this.receiverNameToDisplay}
															align={!this.personalNotepad && message.sender === 'Me' ? 'right' : 'left'}
															message={message}
															lastMessage={index > 0 ? this.props.chat.history[index - 1] : {}}
															onProfilePhotoPress={() => {
																this._openFriendProfile(message.sender === 'Me' ? 'me' : this.props.chat?.type, message?.sender);
															}}
															onReplyItemSelect={(repliedItemIndex: number) => {
																if (repliedItemIndex > 0) {
																	this.scrollToIndex(repliedItemIndex - 1);
																} else {
																	this.scrollToIndex(repliedItemIndex);
																}
															}}
														/>
													</div>
												);
											}
											return <React.Fragment key={Math.random()}></React.Fragment>;
										}}
									/>

									{this.showQuickScrollComponent && this.state.isInitialDataLoadCompleted && !this.personalNotepad && this.props.chat.receiver.unreadMessages.length > 0 /*this.unreadThreadlist.length > 0*/ && (
										<UnreadMsgAccessBtnsView
											props={this.props}
											isNewMessageSent={this.isNewMessageSent}
											chatBoxHeight={this.state.chatBoxHeight}
											unreadMessagesIds={this.props.chat.receiver.unreadMessages} /*{this.unreadThreadlist}*/
											readNextUnreadMessage={async () => {
												await this.readNextUnreadMessage(0);
												this.props.chat.receiver.unreadMessages.splice(0, this.props.chat.receiver.unreadMessages.length - 1);
												//this.unreadThreadlist.splice(0, this.unreadThreadlist.length);
												//debugger;
											}}
											readNextUnreadTaggedMessage={async () => {
												if (this.componentIsMounted && !this.isManualScrolling) {
													await this.readNextUnreadTaggedMessage(0);
												}
											}}
											// readUnreadMessage={async (messageId: any) => {
											// 	if (this.componentIsMounted && !this.isManualScrolling) {
											// 		await this.setReadMessagesByIds([messageId]);
											// 	}
											// }}
											readAllUnreadMessages={async () => {
												await this.setAllMessagesRead();
												this.props.chat.receiver.unreadMessages.splice(0, this.props.chat.receiver.unreadMessages.length - 1);
												//this.unreadThreadlist.splice(0, this.unreadThreadlist.length);
												//debugger;

												// if (this.componentIsMounted) {
												// 	this.scrollTo(-1, 300, 'readAllUnreadMessages');
												// 	await this.setAllMessagesRead();
												//     //info('scrollTo-call-from', 'readAllUnreadMessages');
												// }
											}}
										/>
									)}
								</>
							) : !this.props.chat?.showLoading ? (
								<>
									<IonGrid className="chat-db-grid">
										<IonRow className="chat-db-row">
											<IonLabel color="light" style={{ textAlign: 'center' }}>
												<h1>{this.personalNotepad ? locale.chat.personal_chatpad : !this.componentIsMounted || this.conversationFetching ? locale.reducers.chat.no_message : this.props.chat?.emptyMessage}</h1>
												{this.state.showReload ? (
													<IonButton color="#000000" onClick={() => {}}>
														{locale.global.reload}{' '}
													</IonButton>
												) : null}
											</IonLabel>
										</IonRow>
									</IonGrid>
								</>
							) : null}
						</IonContent>

						{this.state.selectingMessages ? (
							<ChatSelectView
								count={this.state.selectedMessages?.length}
								onDelete={() => {
									this.setState({ selectingMessages: false });
								}}
								onForward={() => {
									this.setState({ selectingMessages: false });
								}}
							/>
						) : (
							<ChatInputBox
								myRef={(ref: any) => (this.chatInputBoxRef = ref)}
								props={this.props}
								self={this}
								allContacts={this.props.chat.conversations}
								loggedInUser={this.loggedInUser}
								onPhotoUpload={async (image: any) => {
									await this._previewImage(image);
								}}
								onPasteImageFromClipboard={async (image: any) => {
									await this._previewImage(image);
								}}
								onProfilePhotoPress={(userId: any) => {
									this._openFriendProfile('group', userId);
								}}
								onChatBoxHeightChange={(chatBoxHeight: number) => {
									const virtualsoFooter = document.getElementById('virtualsoFooter');
									virtualsoFooter?.style.setProperty('height', chatBoxHeight + 8 + 'px');
									this.setState({ chatBoxHeight: chatBoxHeight });
								}}
								onMessageSent={() => {
									this.isNewMessageSent = true;
								}}
							/>
						)}
					</IonPage>
				)}

				<DropdownPopover
					showPopover={this.state.showPopover}
					list={groupHeaderMenuOption}
					popoverEvent={this.state.popoverEvent}
					onDismiss={() => {
						this.setState({ showPopover: false, popoverEvent: undefined });
					}}
					onItemSelect={(item: any) => {
						this.setState({ showPopover: false, popoverEvent: undefined });
						switch (item.type) {
							case 'groupInfo':
								this.props.history.push('/group-info', {
									groupDetail: this.props.chat?.receiver,
								});
								break;
							case 'favorite':
								this.setState({ showFavoriteSearchModal: true });
								break;
							case 'todos':
								break;
							case 'tagged':
								break;
							case 'groupSettings':
								this.props.history.push('/group-setting', {
									groupDetail: this.props.chat?.receiver,
								});
								break;

							default:
								break;
						}
					}}
				/>

				<FavoriteSearchModal
					show={this.state.showFavoriteSearchModal}
					onClose={() => {
						this.setState({ showFavoriteSearchModal: false });
					}}
				/>

				<PhotoPreviewModal
					show={this.state.openPreview}
					isSendPreview={this.state.isSendPreview}
					image={this.state.selectedImage}
					onClose={() => this.setImagePreview()}
					onCancel={() => this.cancelUpload()}
					onSend={(message: any, isFullImage: Boolean) => {
						this.setState({ captionMessage: message }, () => {
							this.sendImage();
						});
					}}
				/>

				{/* <IonLoading
					isOpen={this.props.chat?.showLoading}
					onDidDismiss={() => store.dispatch({ type: CHAT_LOADING, payload: { showLoading: false, loaderMessage: 'Please wait...' } })}
					message={this.props?.chat?.loaderMessage}
				/> */}
			</IonApp>
		);
	}
}

interface iProps extends RouteComponentProps<{ name: string }> {
	pubsub: any;
	resetPublishData: any;
	publishData: any;
	reloadChat: any;
	receiver: any;
	type: String;
	chat: any;
	history: any;
	loggedInUser: any;
	emptyMessage: string;
	errorMessage: string;
}

interface iState {
	unreadMessagesIds: any;
	receiver: any;
	openPreview: boolean;
	isInitialDataLoadCompleted: boolean;
	showPopover: boolean;
	isSendPreview: boolean;
	message: String;
	dynamicClass: boolean;
	showFavoriteSearchModal: boolean;
	captionMessage: String;
	selectedImage: any;
	imageResolution: any;
	popoverEvent: any;
	selectingMessages: boolean;
	showReload: boolean;
	selectedMessages: any[];
	children: any;
	chatBoxHeight: number;
	shouldShowQuickAccessBtn: Boolean;
	isChatSyncing: Boolean;
	isNetworkConnectionOnline: Boolean;
}

const mapStateToProps = (state: any) => {
	//info('mapStateToProps---', state.chat);
	return {
		pubsub: state.pubsub,
		chat: state?.chat,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	searchContacts: (payload: String) => dispatch(searchContacts(payload)),
	publishData: (payload: String) => dispatch(publishData(payload)),
	reloadChat: (payload: any) => dispatch(reloadChat(payload)),
	resetPublishData: () => dispatch(resetPublishData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

import React from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonImg, IonLabel, IonPage, IonThumbnail, IonNote } from '@ionic/react';
import './style.scss';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { RouteComponentProps } from 'react-router';
import { IonItem } from '@ionic/react';
import { blankProfilePic, info } from '../../helpers/common';
import EnumService from '../../services/enumService';
import { connect } from 'react-redux';
import { DASHBOARD_LAST_MESSAGE_CLEAR } from '../../redux/constants/dashboard';
import { initChat } from '../../redux/actions/chat';

import store from '../../redux/store';
import { TopNavbar } from '../common/header/topbar';
import { locale } from '../../locales/local';
import { apiService } from '../../services/apiService';

interface iProps extends RouteComponentProps<{ name: string }> {
	location: any;
	confirmContact: Function;
	initChat: Function;
}
interface iState {
	loggedInUser: any;
	friendProfile?: any;
	friendProfileActionType?: string;
}

class ProfileFriend extends React.Component<iProps, iState> {
	starredContacts = [];
	componentIsMounted: Boolean = false;
	componentIsUpdating: Boolean = false;
	constructor(props: iProps) {
		super(props);

		let user: any;

		apiService.me().then((_user: any) => (user = _user));

		this.state = {
			loggedInUser: user,
			friendProfile: props.location?.state?.data,
			friendProfileActionType: props.location?.state?.friendProfileActionType,
		};
	}

	setPageData = async () => {
		let user = await apiService.me();

		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
		}));

		this.componentIsMounted = true;
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (this.componentIsMounted && !this.componentIsUpdating) {
			this.componentIsUpdating = true;
			await this.setPageData();
			this.componentIsUpdating = false;
		}
	};

	confirmContact = (user: any) => {
		this.props.history.push('/contacts/add', {
			data: user,
			friendProfileActionType: this.state.friendProfileActionType,
		});
	};

	blockContact = (user: any) => {
		apiService.blockContact({ contactId: user._id, username: user.username || '', type: 'chat' }).then((res: any) => {});
	};

	chatClickHandler = () => {
		const data = this.state.friendProfile;
		data.handlerText = locale.global.start_chat;
		this.props.initChat(data);
		store.dispatch({ type: DASHBOARD_LAST_MESSAGE_CLEAR, payload: data });
		this.props.history.push(data.room ? `/chat/${data.room}` : data.userId ? `/chat/${data.room}` : '/');
	};

	_renderHeader = () => {
		const { friendProfile, friendProfileActionType } = this.state,
			profileUserName = friendProfile?.alias || friendProfile?.userId || friendProfile?.username || '',
			profileUserNameToDisplay = (!profileUserName?.includes('@') ? '@' : '') + '' + profileUserName;

		let pageTitle = '';

		if (friendProfileActionType === EnumService.ProfileFriendActionTypes.ContactRequestView) {
			pageTitle = locale.global.request;
		} else if (friendProfileActionType === EnumService.ProfileFriendActionTypes.ContactView || friendProfileActionType === EnumService.ProfileFriendActionTypes.ChatProfileView) {
			pageTitle = profileUserNameToDisplay;
		}

		return (
			<TopNavbar
				{...this.props}
				friendProfile={friendProfile}
				pageTitle={pageTitle}
				showBack={true}
				isHideRightButton={friendProfileActionType !== EnumService.ProfileFriendActionTypes.ChatProfileView && friendProfileActionType !== EnumService.ProfileFriendActionTypes.ContactView}
			/>
		);
	};

	_renderBottomContentForAddFriend = () => {
		const contactAddBy = this.props.location?.state?.contactAddBy;
		return (
			<div style={{ display: 'flex', alignItems: 'center', flex: 1, flexDirection: 'column' }}>
				<IonItem style={{ width: '100%' }} lines="none">
					<IonLabel>
						{locale.profile.profile_friend.from}
						<IonNote style={{ marginLeft: '16px' }}>{contactAddBy}</IonNote>
					</IonLabel>
				</IonItem>
				<div style={{ flex: 1 }}></div>
				<IonButton
					style={{ marginBottom: '10%', width: '80%', '--background': '#FF5E5E' }}
					expand="block"
					onClick={() => {
						this.props.history.push('/contacts/add', {
							data: this.state.friendProfile,
							friendProfileActionType: this.state.friendProfileActionType,
						});
					}}
				>
					{locale.global.add}
				</IonButton>
			</div>
		);
	};

	_renderBottomContentForFriendRequestView = () => {
		const { friendProfile } = this.state;
		return (
			<div className="friendrequest-view-bottom-content">
				<IonItem style={{ width: '100%' }} lines="none">
					<IonLabel>
						{locale.profile.profile_friend.from}
						<IonNote style={{ marginLeft: '16px' }}>{locale.profile.profile_friend.search_id}</IonNote>
					</IonLabel>
				</IonItem>
				<div className="personal-msg">{friendProfile.personalMessage}</div>
				<div style={{ flex: 1 }}></div>
				<IonButton
					className="action-btn"
					expand="block"
					onClick={() => {
						this.confirmContact(this.state.friendProfile);
					}}
				>
					{locale.global.confirm}
				</IonButton>

				<IonButtons className="block-report-btns">
					<IonButton fill="clear">{locale.global.block}</IonButton>
					<div style={{ width: '1px', height: '12px', backgroundColor: '#535353' }}></div>
					<IonButton fill="clear">{locale.global.report}</IonButton>
				</IonButtons>
			</div>
		);
	};

	_renderBottomContentForViewFriendProfile = () => {
		return (
			<div className="communication-btns-view">
				<IonButton fill="clear" size="large">
					<IonIcon slot="icon-only" src="./assets/icon/video-round.svg" />
				</IonButton>
				<IonButton fill="clear" size="large" onClick={this.chatClickHandler}>
					<IonIcon slot="icon-only" src="./assets/icon/message-round.svg" />
				</IonButton>
				<IonButton fill="clear" size="large">
					<IonIcon slot="icon-only" src="./assets/icon/call-round.svg" />
				</IonButton>
			</div>
		);
	};

	render() {
		const { friendProfile, friendProfileActionType } = this.state,
			profileUserName = friendProfile?.userId || '';

		return (
			<IonPage className="profile-page">
				{this._renderHeader()}

				<IonContent className="main-content-profile has-bottom-navbar has-topbar-with-searchbar">
					<div className="inner-content">
						<div className="user-name-item">
							<div className="profile-row">
								<IonThumbnail className="profile-photo">
									<IonImg title="Change" src={friendProfile?.profilePhoto ? friendProfile?.profilePhoto : blankProfilePic} />
								</IonThumbnail>
							</div>

							<IonLabel className="name" color="light">
								{friendProfile?.username || ''}
							</IonLabel>

							{friendProfileActionType !== EnumService.ProfileFriendActionTypes.ContactView && friendProfileActionType !== EnumService.ProfileFriendActionTypes.ChatProfileView && (
								<IonLabel className="usernametxt" color="light">
									{(!profileUserName?.includes('@') ? '@' : '') + '' + friendProfile?.alias || friendProfile?.userId || friendProfile?.username || ''}
								</IonLabel>
							)}

							{friendProfile?.profession && (
								<IonLabel className="professiontxt" color="light">
									{friendProfile?.profession}
								</IonLabel>
							)}

							{friendProfile?.region && (
								<IonLabel className="region" color="light">
									{friendProfile?.region}
								</IonLabel>
							)}

							{friendProfile?.quote && (
								<IonLabel className="quote" color="light">
									{friendProfile?.quote}
								</IonLabel>
							)}
						</div>

						<IonLabel className="moment-label">{locale.global.moments}</IonLabel>
						<IonItem
							className="starred-wrapper"
							lines="none"
							detail={true}
							onClick={() => {
								this.props.history.push('/moments');
							}}
						>
							{this.starredContacts.map((e: any, index: any) => {
								return (
									<div key={index} className="starred">
										<IonThumbnail onClick={() => info('')}>
											<IonImg src={`https://randomuser.me/api/portraits/${e % 2 === 0 ? 'men' : 'women'}/${e}.jpg`} />
										</IonThumbnail>
									</div>
								);
							})}
							<div style={{ minWidth: '20px' }}></div>
						</IonItem>

						{friendProfileActionType === EnumService.ProfileFriendActionTypes.ContactAdd && this._renderBottomContentForAddFriend()}
						{friendProfileActionType === EnumService.ProfileFriendActionTypes.ContactRequestView && this._renderBottomContentForFriendRequestView()}
						{(friendProfileActionType === EnumService.ProfileFriendActionTypes.ContactView || friendProfileActionType === EnumService.ProfileFriendActionTypes.ChatProfileView) &&
							this._renderBottomContentForViewFriendProfile()}
					</div>
				</IonContent>

				<BottomNavbar unreadCount={0} {...this.props} />
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	initChat: (payload: any) => dispatch(initChat(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileFriend);

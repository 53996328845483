import React from 'react';
import { IonButton, IonContent, IonIcon, IonImg, IonLabel, IonPage, IonThumbnail } from '@ionic/react';
import './style.scss';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { RouteComponentProps } from 'react-router';
import { IonItem } from '@ionic/react';
import { blankProfilePic, fetchProfilePic, info } from '../../helpers/common';
//import { BS } from '../../global.d';
import { TopNavbar } from '../common/header/topbar';
import { locale } from '../../locales/local';
import { apiService } from '../../services/apiService';

interface iProps extends RouteComponentProps<{ name: string }> {
	location: any;
}
interface iState {
	loggedInUser: any;
	shouldBack: boolean;
	profilePic: any;
}

export default class ProfilePage extends React.Component<iProps, iState> {
	starredContacts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);

		this.state = {
			loggedInUser: undefined,
			shouldBack: props.location?.state?.shouldBack,
			profilePic: undefined,
		};
	}

	setPageData = async () => {
		let user: any = await apiService.me(),
			profilePic: any = await fetchProfilePic('', 'user', true);

		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
			profilePic: profilePic,
		}));
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	render() {
		const { loggedInUser } = this.state,
			profileUserName = loggedInUser?.userId || '';

		return (
			<IonPage className="profile-page">
				<TopNavbar {...this.props} pageTitle={(!profileUserName.includes('@') ? '@' : '') + '' + profileUserName} isHideLeftButton={!this.state.shouldBack} showBack={this.state.shouldBack} hideSearchBar={true} />

				<IonContent className="main-content-profile has-bottom-navbar has-topbar">
					<div className="inner-content">
						<div className="user-name-item" onClick={() => this.props.history.push('/profile/edit-profile')}>
							<div className="profile-row">
								<IonThumbnail className="profile-photo">
									<IonImg title="Change" src={this.state.profilePic} />
								</IonThumbnail>

								<div className="half-rounded-btns" onClick={(event) => event.stopPropagation()}>
									<IonButton className="wallet">
										<IonIcon src="./assets/icon/wallet.svg"></IonIcon>
									</IonButton>
									<IonButton fill="outline" className="favorite">
										<IonIcon src="./assets/icon/heart-red.svg"></IonIcon>
									</IonButton>
								</div>
							</div>

							<IonLabel className="name" color="light">
								{loggedInUser?.username}
							</IonLabel>

							{loggedInUser?.profession && (
								<IonLabel className="professiontxt" color="light">
									{loggedInUser?.profession}
								</IonLabel>
							)}

							{loggedInUser?.region && (
								<IonLabel className="region" color="light">
									{loggedInUser?.region}
								</IonLabel>
							)}

							{loggedInUser?.quote && (
								<IonLabel className="quote" color="light">
									{loggedInUser?.quote}
								</IonLabel>
							)}
						</div>

						<IonLabel className="moment-label">{locale.global.moments}</IonLabel>

						<IonItem
							className="starred-wrapper"
							lines="none"
							detail={true}
							onClick={() => {
								this.props.history.push('/moments');
							}}
						>
							{this.starredContacts.map((e: any, index: any) => {
								return (
									<div key={index} className="starred">
										<IonThumbnail onClick={() => info('')}>
											<IonImg src={`https://randomuser.me/api/portraits/${e % 2 === 0 ? 'men' : 'women'}/${e}.jpg`} />
										</IonThumbnail>
									</div>
								);
							})}
							<div style={{ minWidth: '20px' }}></div>
						</IonItem>

						<div className="my-qr-view">
							<img
								onClick={() => {
									this.props.history.push('/my-qrcode', {
										// myQrCodeType: 'all',
									});
								}}
								src={loggedInUser?.qrCode}
								alt="my-qr-view"
							/>
						</div>
					</div>
				</IonContent>
				<BottomNavbar unreadCount={0} {...this.props} />
			</IonPage>
		);
	}
}

import lsbridge from 'lsbridge';
import {isJsonString} from './common';

let Bridge: any = null;

export function createPubsub(namespace: string) {
	return {
		send: (msg: any) => Bridge.send(namespace, isJsonString(msg) ? JSON.stringify(msg) : msg),
		subscribe: (cb: any) => Bridge.subscribe(namespace, cb),
		unsubscribe: () => Bridge.unsubscribe(namespace),
	};
}

export function startBridge() {
	Bridge = lsbridge;
}

import { IonItem, IonLabel, IonThumbnail, IonImg, IonIcon } from '@ionic/react';

import React, { useCallback, useState } from 'react';
import './style.scss';
import ImageEditor from '../pages/profile/pinturaEditor';
import { chevronForward } from 'ionicons/icons';
import { locale } from '../locales/local';

interface PhotoInputItemProps {
	className?: string;
	label: string;
	fieldKey: string;
	isDisabled?: boolean;
	isEdittable?: boolean;
	photo: any;
	onPhotoAdded?: any;
	onItemClick?: any;
}

const PhotoInputItem: React.FC<PhotoInputItemProps> = ({ className, label, isEdittable, fieldKey, isDisabled, photo, onPhotoAdded, onItemClick }) => {
	const [showPinturaEditor, setShowPinturaEditor] = useState(false);
	const [selectedImage, setSelectedImage] = useState<any>(null);

	const _triggerPhotoUpload = useCallback(() => {
		let uploader = document.getElementById(fieldKey);
		uploader?.click();
	}, [fieldKey]);

	const _onFileChange = useCallback((event) => {
		const file = event.target.files[0];

		//Convert file object to base64 string
		let reader = new FileReader();
		reader.onloadend = async () => {
			const base64String = reader.result as string;
			base64String.replace('data:', '').replace(/^.+,/, '');

			setShowPinturaEditor(true);
			setSelectedImage(base64String);
		};
		reader.readAsDataURL(file);
	}, []);

	const _onPinturaEidtingDone = useCallback(
		(file: any) => {
			setShowPinturaEditor(false);

			const reader = new FileReader();
			reader.onloadend = async () => {
				const base64String = reader.result as string;
				base64String.replace('data:', '').replace(/^.+,/, '');
				onPhotoAdded && onPhotoAdded(base64String);
			};

			reader.readAsDataURL(file);
		},
		[onPhotoAdded]
	);

	return (
		<>
			<IonItem mode="ios" lines="none" className={'photo-input-item ' + (className ? className : '')} disabled={isDisabled} button key={fieldKey} detail={false}>
				<IonLabel>{label}</IonLabel>

				{photo ? (
					<IonThumbnail
						className="profile-thumb-user-edit"
						slot="end"
						onClick={() => {
							if (onItemClick) {
								onItemClick();
							} else {
								_triggerPhotoUpload();
							}
						}}
					>
						<IonImg src={photo} />
					</IonThumbnail>
				) : (
					<p slot="end" style={{ fontSize: '14px', color: '#858484' }}>
						{locale.global.not_set}
					</p>
				)}

				<div slot="end" className="detail-arrow-icon-view">
					{isEdittable && <IonIcon icon={chevronForward} />}
				</div>
			</IonItem>

			<input accept="image/*" id={fieldKey} onChange={_onFileChange} type="file" style={{ display: 'none' }} />

			<ImageEditor
				selectedImage={selectedImage}
				show={showPinturaEditor}
				onClose={() => {
					setShowPinturaEditor(false);
				}}
				onSave={_onPinturaEidtingDone}
			/>
		</>
	);
};

export default PhotoInputItem;

import React from 'react'
import { useJitsi } from 'react-jutsu'
import './style.scss';

const VV = () => {
  const jitsiConfig = {
    roomName: 'BESociety',
    displayName: 'User1',
    subject: 'meet',
    parentNode: 'jitsi-container',
    domain:'meet.jit.si',
    
    configOverwrite: {  prejoinPageEnabled: false, },
  };
  const { loading, error, jitsi } = useJitsi(jitsiConfig);

  return (
    <div>
      {error && <p>{error}</p>}
      <div  id={jitsiConfig.parentNode} />
    </div>
  );
}
export default VV;
import React from 'react'
import { useJitsi } from 'react-jutsu'
import './style.scss';

const ReceiverVV = () => {
  const jitsiConfig = {
    roomName: 'BESociety',
    displayName: 'User2',
    subject: 'meet',
    parentNode: 'jitsi-container',
    configOverwrite: {  prejoinPageEnabled: false, },
    startVideoMuted:false
  };
  const { loading, error, jitsi } = useJitsi(jitsiConfig);

  return (
    <div>
      {error && <p>{error}</p>}
      <div  id={jitsiConfig.parentNode} />
    </div>
  );
}
export default ReceiverVV;
import { USER_AUTHENTICATED, LOGIN_REQUESTED, LOGIN_SUCCESS, LOGIN_FAILED, LOGIN_ERROR, REGISTER, LOGOUT_REQUESTED, SET_PASSWORD, SET_USERNAME, LOGIN_INIT_CONTACTS } from '../constants/auth';
import { AUTH_SHOW_LOADER, AUTH_FORM_ERROR, AUTH_RESET_VALUES } from '../constants/common';
import { locale } from '../../locales/local';

const initialState = {
	isLoggedIn: false,
	loggedInUser: {},
	hasErrors: false,
	title: locale.reducers.auth.init_state.login,
	userId: '',
	password: '',
	placeholder_pwd: locale.reducers.auth.init_state.password,
	submitLabel: locale.reducers.auth.init_state.log_in,
	errorMessage: '',
	showLoading: false,
	loginSuccess: false,
	loaderText: locale.reducers.auth.init_state.logging_in,
	isAuthUser: false,
	showToast: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	let returnValue: any;
	switch (action.type) {
		case USER_AUTHENTICATED: {
			returnValue = {
				...state,
				isLoggedIn: true,
				loggedInUser: action.payload,
			};
			break;
		}

		case AUTH_RESET_VALUES: {
			returnValue = {
				...state,
				hasErrors: false,
				errorMessage: '',
				showToast: false,
				isLoggedIn: false,
			};
			break;
		}

		case LOGIN_REQUESTED: {
			returnValue = {
				...state,
				hasErrors: false,
				data: action.payload,
				errorMessage: '',
			};
			break;
		}

		case LOGIN_INIT_CONTACTS: {
			returnValue = {
				...state,
			};
			break;
		}

		case LOGOUT_REQUESTED: {
			returnValue = {
				...initialState,
			};
			break;
		}

		case LOGIN_SUCCESS: {
			returnValue = {
				...state,
				hasErrors: false,
				isLoggedIn: true,
				loggedInUser: action.payload,
				errorMessage: '',
			};
			break;
		}

		case LOGIN_FAILED: {
			returnValue = {
				...state,
				hasErrors: true,
				isLoading: false,
				isAuthUser: false,
				password: '',
				showToast: true,
				errorMessage: action.payload.Error.message,
			};
			break;
		}

		case SET_PASSWORD: {
			returnValue = {
				...state,
				hasErrors: action.payload.hasErrors,
				password: action.payload.password,
			};
			break;
		}

		case SET_USERNAME: {
			returnValue = {
				...state,
				hasErrors: action.payload.hasErrors,
				userId: action.payload.userId,
			};
			break;
		}

		case LOGIN_ERROR: {
			returnValue = {
				...state,
				isLoading: false,
				hasErrors: true,
				data: action.payload,
				isAuthUser: false,
				errorMessage: action.payload,
			};
			break;
		}

		case AUTH_SHOW_LOADER: {
			returnValue = {
				...state,
				isLoading: action.payload,
			};
			break;
		}

		case AUTH_FORM_ERROR: {
			returnValue = {
				...state,
				isLoading: false,
				hasErrors: true,
				errorMessage: action.payload.errorMessage,
			};
			break;
		}

		case REGISTER: {
			returnValue = {
				...state,
				data: action.payload,
			};
			break;
		}
		default:
			returnValue = state;
			break;
	}
	return returnValue;
}

import React, { createRef } from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonInput, IonLabel, IonLoading, IonPage, IonToggle, IonToolbar } from '@ionic/react';
import './style.scss';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { TopNavbar } from '../common/header/topbar';
import { connect } from 'react-redux';
import { apiService } from '../../services/apiService';
import { addGroup, resetValues } from '../../redux/actions/dashboard';
import { chevronDownOutline, chevronForwardOutline } from 'ionicons/icons';
import { ContactsList } from './components/ContactsList';
import { locale } from '../../locales/local';
import EnumService from '../../services/enumService';
import { info, sortByAlias } from '../../helpers/common';

interface iProps extends RouteComponentProps<{ name: string }> {
	//addGroup: Function;
	resetValues: Function;
	location: any;
	dashboard: any;
}
interface iState {
	loggedInUser?: any;
	contacts: any;
	isTagNameEdit: boolean;
	isTagContactsEdit: boolean;
	tagName: string;
	tagDetail: any;
}

class TagInfo extends React.Component<iProps, iState> {
	tagNameInputRef: any = createRef();
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);

		this.state = {
			loggedInUser: undefined,
			contacts: undefined,
			tagName: '',
			isTagNameEdit: false,
			isTagContactsEdit: false,
			tagDetail: {
				name: 'Design Hijack',
				contacts: [],
			},
		};

		this.props.resetValues();
	}

	getData = async () => {
		let user = await apiService.me(),
			contacts: any = await apiService.getContacts(false, 'confirmed').then((_contacts: any) => {
				let contacts: any = [];

				if (_contacts && _contacts.length > 0) {
					contacts = _contacts.sort(sortByAlias);
				}

				return contacts;
			});

		return [user, contacts];
	};

	setPageData = async () => {

		let [user, contacts] = await this.getData();

		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
			contacts: contacts,
		}));

		this.componentIsMounted = true;
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	setSearchText() {}
	searchAllGroups(searchValue: string, props: any) {
		props.history.push('/contacts/search', searchValue);
	}

	_onContactSelect = (cont: any) => {};

	_renderSwitchItem = (title: any, value: any, onChange: any) => {
		return (
			<div className={'swtich-item'} slot="end">
				<IonLabel>{title}</IonLabel>
				<IonToggle slot="end" className="toggle-customised" mode="ios" value={value} onChange={onChange} />
			</div>
		);
	};

	render() {
		const { tagDetail, tagName, isTagContactsEdit } = this.state;

		let isDoneBtnActive = false;

		if (tagName && (!tagDetail || (tagDetail && tagDetail.name !== tagName))) {
			isDoneBtnActive = true;
		}

		return (
			<>
				<IonPage className="taginfo-page">
					<TopNavbar
						{...this.props}
						isHideRightButton={false}
						showBack={true}
						hideSearchBar={true}
						pageTitle={'Tag'}
						onRightButtonPress={() => {
							this.props.history.push('/contacts', {
								actionType: EnumService.ContactsPageActionType.ChooseContactForTag,
							});
						}}
					/>

					<IonContent className="main-content-profile has-bottom-navbar has-topbar">
						<IonList lines="none">
							<IonItem className="tag-field-item">
								<IonLabel className={isTagContactsEdit ? 'active' : ''}>Members: 5</IonLabel>
								<IonButton slot="end" fill="clear" onClick={() => this.setState({ isTagContactsEdit: !this.state.isTagContactsEdit })}>
									<IonIcon slot="icon-only" icon={this.state.isTagContactsEdit ? chevronDownOutline : chevronForwardOutline} />
								</IonButton>
							</IonItem>

							<IonItem className="tagname-title-item">Tag Name</IonItem>
							<IonItem className="tag-field-item" lines="none">
								{this.state.isTagNameEdit ? (
									<IonInput
										value={tagName}
										onIonChange={(event) => {
											this.setState({ tagName: String(event.detail.value) });
										}}
										ref={this.tagNameInputRef}
										clearInput={true}
									/>
								) : (
									<IonLabel>Design Hijack</IonLabel>
								)}

								<IonButton
									slot="end"
									fill="clear"
									onClick={() => {
										if (!this.state.isTagNameEdit) {
											setTimeout(() => {
												this.tagNameInputRef?.current.setFocus(true);
											}, 200);
										}
										this.setState({ isTagNameEdit: !this.state.isTagNameEdit });
									}}>
									<IonIcon slot="icon-only" icon={this.state.isTagNameEdit ? chevronDownOutline : chevronForwardOutline} />
								</IonButton>
							</IonItem>
						</IonList>

						<ContactsList
							contacts={this.state.contacts}
							props={this.props}
							contactListMode={isTagContactsEdit ? EnumService.ContactsListMode.RemoveContactListMode : ''}
							onContactRemove={() => {
								info('On contact remove');
							}}
						/>
						{this.state.isTagNameEdit && (
							<div className="save-cancel-btns-container">
								<IonToolbar className="save-cancel-btns">
									<IonButtons slot="start">
										<IonButton
											slot="end"
											className="cancel-btn"
											onClick={() => {
												this.setState({ isTagNameEdit: false });
											}}>
											{locale.global.cancel}
										</IonButton>
									</IonButtons>

									<IonButtons slot="end">
										<IonButton
											disabled={!isDoneBtnActive}
											className={'done-btn  ' + (isDoneBtnActive ? 'active' : '')}
											slot="end"
											onClick={() => {
												this.setState({ isTagNameEdit: false });
											}}>
											{locale.global.done}
										</IonButton>
									</IonButtons>
								</IonToolbar>
							</div>
						)}
					</IonContent>

					<BottomNavbar {...this.props} />
				</IonPage>

				<IonLoading isOpen={this.props.dashboard.isLoading} message={this.props.dashboard.loaderMessage} />
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	//addGroup: (payload: any) => dispatch(addGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagInfo);

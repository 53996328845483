import { USER_LOGIN, USER_LOGOUT, SHOW_LOADER, SHOW_TOAST, SHOW_APP_UPDATE_POPUP } from '../constants/common';
import { locale } from '../../locales/local';

const initialState = {
		isLoggedIn: false,
		darkTheme: false,
		xmpp: false,
		showToast: false,
		toastMessage: '',
		toastCallBack: null,
		loaderMessage: locale.reducers.global.init_state.connecting,
		isLoading: false,
		appUpdatePopupLoading: false,
		appUpdatePopupLoadingMessage: '',
		showAppUpdatePopup: false,
		appUpdatePopupHeading: '',
		appUpdatePopupSubHeading: '',
		appUpdatePopupMessageTitle: '',
		appUpdatePopupMessage: '',
		appUpdatePopupActionBtnTitle: '',
		showAppUpdatePopupCssClass: '',
		appUpdatePopupCallBack: null,
	};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	let returnValue: any;

	switch (action.type) {
		case USER_LOGIN: {
			returnValue = {
				...state,
				isLoggedIn: true,
				loggedInUser: action.payload,
			};
			break;
		}
		case USER_LOGOUT: {
			returnValue = {
				...state,
				isLoggedIn: false,
				loggedInUser: {},
			};
			break;
		}

		case SHOW_TOAST: {
			returnValue = {
				...state,
				showToast: action.payload.showToast,
				toastMessage: action.payload.toastMessage,
				toastCallBack: action.payload.toastCallBack,
			};
			break;
		}

		case SHOW_APP_UPDATE_POPUP: {
			returnValue = {
				...state,
			};
			if (action.payload.appUpdatePopupLoading !== undefined) {
				returnValue['appUpdatePopupLoading'] = action.payload.appUpdatePopupLoading;
			}
			if (action.payload.appUpdatePopupLoadingMessage !== undefined) {
				returnValue['appUpdatePopupLoadingMessage'] = action.payload.appUpdatePopupLoadingMessage;
			}
			if (action.payload.showAppUpdatePopup !== undefined) {
				returnValue['showAppUpdatePopup'] = action.payload.showAppUpdatePopup;
			}
			if (action.payload.appUpdatePopupHeading !== undefined) {
				returnValue['appUpdatePopupHeading'] = action.payload.appUpdatePopupHeading;
			}
			if (action.payload.appUpdatePopupSubHeading !== undefined) {
				returnValue['appUpdatePopupSubHeading'] = action.payload.appUpdatePopupSubHeading;
			}
			if (action.payload.appUpdatePopupMessageTitle !== undefined) {
				returnValue['appUpdatePopupMessageTitle'] = action.payload.appUpdatePopupMessageTitle;
			}
			if (action.payload.appUpdatePopupMessage !== undefined) {
				returnValue['appUpdatePopupMessage'] = action.payload.appUpdatePopupMessage;
			}
			if (action.payload.appUpdatePopupActionBtnTitle !== undefined) {
				returnValue['appUpdatePopupActionBtnTitle'] = action.payload.appUpdatePopupActionBtnTitle;
			}
			if (action.payload.showAppUpdatePopupCssClass !== undefined) {
				returnValue['showAppUpdatePopupCssClass'] = action.payload.showAppUpdatePopupCssClass;
			}
			if (action.payload.appUpdatePopupCallBack !== undefined) {
				returnValue['appUpdatePopupCallBack'] = action.payload.appUpdatePopupCallBack;
			}

			break;
		}

		case SHOW_LOADER: {
			returnValue = {
				...state,
				isLoading: action.payload.isLoading,
				loaderMessage: action.payload.loaderMessage,
			};
			break;
		}

		default:
			returnValue = state;
			break;
	}
	return returnValue;
}

import React from 'react';
import { locale } from '../../locales/local';
import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react';

interface iProps {
	onEmojiChoose?: (event: any) => void;
}

const Emoji: React.FC<iProps> = ({ onEmojiChoose }) => {

	const onEmojiClick = (event: any, emojiObject: any) => {
		onEmojiChoose && onEmojiChoose(emojiObject);
	};

	return (
		<>
			<Picker 
				onEmojiClick={onEmojiClick} 
				disableAutoFocus={true} 
				skinTone={SKIN_TONE_NEUTRAL} 
				groupNames={locale.chat.emoji_labels} 
				native={true}
				preload={true}/>
		</>
	);
};

export default Emoji;

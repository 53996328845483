import { xmpp } from './../services/xmpp';
import { getInternalStorage, setInternalStorage, info, logError, logoutUser, sleep } from './../helpers/common';
import { apiService } from './../services/apiService';
import _ from 'lodash';
import store from './../redux/store';
import { SHOW_APP_UPDATE_POPUP } from './../redux/constants/common';
import { locale } from './../locales/local';
import { createNotification } from './../components/Notifications/notifications';
import fetch from 'node-fetch';

let cookies: any = getInternalStorage(),
	upgradeNotified: boolean = false;

const appManager = {
	askNotificationPermission: async () => {
		setInternalStorage('supportsPush', window.PushManager !== undefined);
		setInternalStorage('supportsNotification', window.Notification !== undefined);

		cookies = getInternalStorage();

		if (!cookies.supportsNotification) {
			setInternalStorage('desktopNotifications', false);
			info('Notification is not supported.');
		} else if (!cookies?.desktopNotifications) {
			info('Notification permission was previously denied.');
		} else {
			if (window.Notification.permission === 'granted') {
				// If it's okay let's create a notification
				info('Notification permission previously granted.');
				setInternalStorage('desktopNotifications', true);
			} else if (window.Notification.permission !== 'denied') {
				// must be explicity compared to 'denied'
				let permission = await window.Notification.requestPermission();
				// If the user accepts, let's create a notification
				if (permission === 'granted') {
					setInternalStorage('desktopNotifications', true);
					info('Notification permission granted.');
				} else {
					setInternalStorage('desktopNotifications', false);
					info('Notification permission denied.');
				}
			}
		}
	},
	handleVisibility: async (event: any) => {
		const updateVisibility = async (event: any) => {
			cookies = getInternalStorage();

			if (xmpp.isReady) {
				if (document.visibilityState === 'visible') {
					if (cookies.mobileDevice) {
						setInternalStorage('active', true);
						return appManager.reload();
					} else {
						await xmpp.sendPresence();
						await apiService.refreshMessages();
					}
				}
			} else if (xmpp.isDisconnected) {
				if (!xmpp.isReady || xmpp.inErrorState) {
					await xmpp.xmppManager();
				}
			} else {
				info(`AppManager::handleVisibility::updateVisibility::${event.type} ${document.visibilityState} xmpp.isReady: ${xmpp.isReady} xmpp.isDisconnecting ${xmpp.isDisconnecting}`, event);

				if (!xmpp.isReady || xmpp.inErrorState) {
					await xmpp.xmppManager();
				}
			}

			setInternalStorage('active', document.visibilityState === 'visible');
		};

		const getHiddenPropName = (document: Document & { msHidden?: boolean; webkitHidden?: boolean }) => {
			let response: any;

			if (typeof document.hidden !== 'undefined') {
				response = 'visibilitychange';
			} else if (typeof document.msHidden !== 'undefined') {
				response = 'msvisibilitychange';
			} else if (typeof document.webkitHidden !== 'undefined') {
				response = 'webkitvisibilitychange';
			}

			return response;
		};

		let visibilityChange = getHiddenPropName(document);

		if (!cookies.listeners) {
			cookies.listeners = [];
		}

		document.addEventListener(visibilityChange, (event: any) => updateVisibility(event), { capture: true, passive: true });
		cookies.listeners.push({ element: 'document', event: visibilityChange, fn: updateVisibility });
		document.addEventListener('pagehide', (event: any) => updateVisibility(event), { capture: true, passive: true });
		cookies.listeners.push({ element: 'document', event: 'pagehide', fn: updateVisibility });
		setInternalStorage('listeners', cookies.listeners);
		setInternalStorage('active', document.visibilityState === 'visible');
	},
	reload: async (delay: number = 0) => {
		const cookies = getInternalStorage(),
			currentMetadata = await apiService.ping().then((response: any) => response || require('../metadata.json')),
			currentVersion = `${currentMetadata.buildMajor}.${currentMetadata.buildMinor}.${currentMetadata.buildRevision} ${currentMetadata.buildTag}`,
			dbVersion = currentMetadata.dbVersion;

		_.defer(() => {
			_.delay(async () => {
				if (parseInt(cookies.dbVersion) !== dbVersion) {
					setInternalStorage('dbVersion', dbVersion);
					logoutUser(true);
				} else {
					setInternalStorage('version', currentVersion);
					info(`AppManager::reload: set version to ${currentVersion}`);
					window.location.href = '/auth';
				}
			}, delay);
		});
	},
	checkVersion: async () => {
		setInternalStorage('active', document.visibilityState === 'visible');

		const cookies = getInternalStorage(),
			currentMetadata: any =
				cookies.uuid && window.navigator.onLine
					? await apiService
							.ping()
							.then(async (_response: any) => {
								let response: any;

								if (_response?.Error === 'TypeError: Failed to fetch') {
									cookies.network = 'offline';

									response = require('../metadata.json');
								} else {
									cookies.network = 'online';
									response = _response ? _response : require('../metadata.json');
								}

								setInternalStorage('network', cookies.network);
								return response;
							})
							.catch(async (error: any) => {
								cookies.network = 'offline';
								setInternalStorage('network', 'offline');
								logError('AppManager::checkVersion:: Error: ', error);
								return require('../metadata.json');
							})
					: require('../metadata.json'),
			currentVersion: string = `${currentMetadata.buildMajor}.${currentMetadata.buildMinor}.${currentMetadata.buildRevision} ${currentMetadata.buildTag}`,
			dbVersion: number = currentMetadata.dbVersion;
			
		if (!cookies.isApp && !xmpp.isReloading) {
			info(`AppManager::checkVersion: ${currentVersion} (${cookies.network})`);

			if (window.navigator.onLine && cookies.uuid && cookies.version !== currentVersion && !cookies.skipUpdates && cookies.version && !cookies?.version?.endsWith('DEV') && !window.location.href.includes('local')) {
				const origin = window.location.origin;
				let upgradeReady: Boolean = false;

				fetch(origin, {
					method: 'HEAD',
					timeout: 1000,
				})
					.then(async (res: any) => {
						const doUpdate = async (res: any) => {
							xmpp.isReloading = true;
							setInternalStorage('reloading', true);

							store.dispatch({
								type: SHOW_APP_UPDATE_POPUP,
								payload: {
									appUpdatePopupLoading: true,
									appUpdatePopupLoadingMessage: `${locale.global.upgrading} ${currentVersion}`,
								},
							});

							appManager.reload(1000);
						};

						if (res.status === 200 && !upgradeReady && !currentVersion.includes('undefined')) {
							upgradeReady = true;
							let upgradeDb = parseInt(cookies.dbVersion) !== dbVersion;
							info(`AppManager::checkVersion: new version ${currentVersion} to replace ${cookies.version} is ready.`);

							if (upgradeDb) {
								info(`AppManager::checkVersion: new db version ${dbVersion} to replace ${cookies.dbVersion} is ready.`);
							}

							store.dispatch({
								type: SHOW_APP_UPDATE_POPUP,
								payload: {
									showAppUpdatePopup: true,
									appUpdatePopupHeading: `${locale.global.app_name}`,
									appUpdatePopupSubHeading: `${currentVersion}`,
									appUpdatePopupMessageTitle: `${locale.global.whats_new}`,
									appUpdatePopupMessage: `${locale.global[upgradeDb ? 'alert_reset' : 'alert_update']} ${currentVersion}`,
									appUpdatePopupActionBtnTitle: locale.global.upgrade_button,
									appUpdatePopupCallBack: doUpdate,
								},
							});

							if (!upgradeNotified) {
								await createNotification(
									{
										type: 'control',
										from: 'control',
										body: `${locale.global.upgrade_notification} ${currentVersion}`,
									},
									true
								);
								upgradeNotified = true;
							}
						}
					})
					.catch((err) => {
						upgradeReady = false;
						info(`AppManager::checkVersion: new version ${currentVersion} to replace ${cookies.version} is not ready. Error was:`, err);
					});
			}
		}

		return `${currentVersion} (${cookies.network})`;
	},
};

export const AppManager = appManager;

import {
	SEARCH_USER_RESETTED,
	SEARCH_USER_REQUESTED,
	SEARCH_USER_END,
	SEARCH_USER_SUCCESS,
	SEARCH_USER_SUCCESS_WITH_LOADING,
	SEARCH_USER_FAILED,
	SEARCH_USER_ERROR,
	SEARCH_USER_SHOW_LOADER,
} from '../constants/searchUser';
import { locale } from '../../locales/local';
const initialState = {
	hasErrors: false,
	hasEdited: false,
	errorMessage: false,
	showLoading: false,
	showInvite: false,
	isLoading: false,
	isSearching: false,
	showToast: false,
	searchResults: [],
	conversations: [],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	let returnValue: any;
	switch (action.type) {
		case SEARCH_USER_SHOW_LOADER: {
			returnValue = {
				...state,
				isLoading: action.payload.loader,
			};
			break;
		}

		case SEARCH_USER_RESETTED: {
			returnValue = {
				...initialState,
			};
			break;
		}

		case SEARCH_USER_REQUESTED: {
			returnValue = {
				...state,
				isLoading: true,
			};
			break;
		}
		case SEARCH_USER_END: {
			returnValue = {
				...state,
				isLoading: false,
				isSearching: false,
				showInvite: false,
			};
			break;
		}
		case SEARCH_USER_SUCCESS: {
			returnValue = {
				...state,
				isLoading: false,
				hasErrors: false,
				// isSearching: false,
				showInvite: action.showInvite,
				searchResults: action.payload,
			};
			break;
		}

		case SEARCH_USER_SUCCESS_WITH_LOADING: {
			returnValue = {
				...state,
				hasErrors: false,
				isSearching: true,
				showInvite: action.showInvite,
				searchResults: action.payload,
			};
			break;
		}
		case SEARCH_USER_FAILED: {
			returnValue = {
				...state,
				isLoading: false,
				hasErrors: true,
				isSearching: true,
				showToast: true,
				errorMessage: action.payload.Error.message,
			};
			break;
		}
		case SEARCH_USER_ERROR: {
			returnValue = {
				...state,
				isLoading: false,
				hasErrors: true,
				isSearching: true,
				showToast: true,
				errorMessage: locale.global.error_occured,
			};
			break;
		}

		default:
			returnValue = state;
			break;
	}
	return returnValue;
}

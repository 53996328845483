import { DbController } from './DbController';
import { serverApi } from './serverApi';
import { CHAT_UPDATE_HISTORY, CHAT_UPDATE_CONVERSATION } from '../redux/constants/chat';
import store from '../redux/store';
import { Parser } from 'xml2js';
import _ from 'lodash';
import { isBlank, getInternalStorage, setInternalStorage, logError, info, prefixPhotos, unprefixPhotos, isConversationOpen, getActiveConversation, sleep } from '../helpers/common';
import { controlMessageService } from './controlMessageService';
import md5 from 'md5';
import { locale } from '../locales/local';
import { DASHBOARD_LAST_MESSAGE, DASHBOARD_SHOW_LOADER, DASHBOARD_HIDE_LOADER, DASHBOARD_INIT } from '../redux/constants/dashboard';
import { xmpp } from '../services/xmpp';
import { Constants } from '../constants';
/*import { createPubsub } from '../helpers/pubsub';

const pubsub = createPubsub('com.be-society'); 

pubsub.subscribe((msg: any) => {
	if (msg.action === 'updateReadStatus') {

	}
});*/

const messageFormat = {
		id: undefined,
		to: undefined,
		from: undefined,
		conversationHash: undefined,
		conversationSequence: 0,
		sender: undefined,
		timestamp: undefined,
		language: undefined,
		type: undefined,
		read: 0,
		body: undefined,
		translation: undefined,
		messageType: undefined,
		tags: [],
		recalled: 0,
		deleted: 0,
		replaces: undefined,
		replaced: 0,
		replacedBy: undefined,
		inReplyTo: undefined,
		forwardedFrom: undefined,
		tagged: [],
		mediaType: undefined,
		mediaUrl: undefined,
		mediaThumbnail: undefined,
		linkPreview: undefined,
		status: undefined,
		uuid: undefined,
	};

export const apiService: any = {
	ping: async () => await serverApi.ping().catch((error: any) => error),

	listDatabases: async () => await DbController.getDatabases(),

	closeDatabase: async (database: any = undefined) => await DbController.closeDatabase(database),

	deleteDatabase: async (database: any = undefined) => await DbController.deleteDatabase(database),

	migrateData: async (data: any) => {
		await DbController.bulkPut('data', data);
	},

	saveDataItem: async (data: string) => await DbController.put('data', data),

	getData: async (data: any = undefined) => {
		let response: any;

		if (data) {
			response = await DbController.where('data', 'item', data);
		} else {
			response = await DbController.get('data');
		}

		return response;
	},

	deleteDataItem: async (item: string) => await DbController.delete('data', item),

	authenticate: async (payload: any) =>
		await serverApi
			.authenticate(payload)
			.then(async (response: any) => {
				if (response.Error) {
					logError('apiService::authenticate::Error', response);
				}

				return response;
			})
			.catch((error: any) => error),

	me: async () => await DbController.get('user'),

	checkUserIdAvailability: async (payload: any) => serverApi.checkAvailabliity(payload),

	registerUser: async (postData: any) => await serverApi.registerUser(postData).catch((error: any) => error),

	addUser: async (user: any) => {
		await DbController.put('user', user);
	},

	updateUser: async (user: any, updateServer: Boolean = false) => {
		let response: any = {};

		if (updateServer) {
			response = await serverApi
				.updateUser(unprefixPhotos(user))
				.then(async (_response: any) => {
					if (!_response.Error) {
						_response = await DbController.put('user', await prefixPhotos(_response));
					} else {
						logError('apiService::updateUser::Error', _response);
					}

					return _response;
				})
				.catch((error: any) => error);
		} else {
			response = await DbController.put('user', user);
		}

		return response;
	},

	getUsers: async (payload: any) => {
		let response: any;

		response = await serverApi.getUsers(payload).then((_response: any) => {
			if (!_response.Error) {
				_response.forEach(async (_user: any) => (_user = await prefixPhotos(_user)));
			} else {
				logError('apiService::getUsers::Error', response);
			}

			return _response;
		});

		return response;
	},

	getContacts: async (fetchFromServer: Boolean = false, status: string = 'confirmed', type: string = 'chat') => {
		let response: any;

		try {
			if (fetchFromServer) {
				response = await serverApi
					.getContacts()
					.then(async (_response: any) => {
						if (!_response.Error) {
							for (let contact of _response) {
								contact = await prefixPhotos(contact);
							}
						} else {
							logError('apiService::getContacts::serverApi.getContacts::Error:', _response);
						}

						return _response;
					})
					.catch((error: any) => error);
			} else {
				let query: any = _.omitBy(
					{
						type: type !== 'all' ? type : undefined,
						status: status !== 'all' ? status : undefined,
					},
					isBlank
				);

				response = await DbController.whereObject('contacts', query, 'lastMessage.timestamp');
			}
		} catch (err) {
			logError('apiService::getContacts::Error:', err);
		}

		return response;
	},

	getConfirmedContacts: async () => await DbController.whereObject('contacts', { type: 'chat', status: 'confirmed' }),

	getPendingReply: async () => await DbController.whereObject('contacts', { type: 'chat', status: 'pendingReply' }),

	getPendingConfirm: async () => await DbController.whereObject('contacts', { type: 'chat', status: 'pendingConfirm' }),

	getContactBy_id: async (_id: any) => (await DbController.where('contacts', '_id', _id))[0],

	getContactByJid: async (jid: any) => (await DbController.where('contacts', 'jid', jid))[0],

	getContactByUserId: async (userId: any) => (await DbController.where('contacts', 'userId', userId))[0],

	addContacts: async (data: any[]) => {
		await DbController.clear('contacts');
		await DbController.bulkPut('contacts', data);
	},

	addContact: async (contact: any, updateServer: Boolean = false) => {
		let user = await apiService.me();

		// called if user initiates a contact request
		if (updateServer) {
			let updatedContact = await serverApi.addContact(contact).catch((error: any) => error);

			if (!updatedContact.Error) {
				updatedContact = await prefixPhotos(updatedContact);
				updatedContact = await DbController.put('contacts', { ..._.omit(contact, ['personalMessage', 'remarks', 'tags']), ...updatedContact });

				user.contacts.push({
					_id: updatedContact._id,
					alias: updatedContact.alias,
					status: updatedContact.status,
				});

				await DbController.put('user', user);
				await xmpp.sendMessage(updatedContact.jid, contact.personalMessage, 'text');
			} else {
				logError('apiService::addContact::Error', updatedContact);
			}
		} else {
			// called if responding to a contact initiated request ... needs to be confirmed
			// also called when a group contains a member that is not a contact
			await DbController.put('contacts', contact);

			if (contact.type !== 'groupMember') {
				user.contacts.push({
					_id: contact._id,
					alias: contact.alias,
					status: contact.status,
				});

				await DbController.put('user', user);
			}
		}

		return await apiService.getContacts(false, 'confirmed');
	},

	confirmContact: async (contact: any, updateServer: Boolean = false) => {
		let user: any = await apiService.me(),
			contactToUpdate: any = await apiService.getContactBy_id(contact._id);

		contactToUpdate = { ...contactToUpdate, ...contact };

		if (updateServer) {
			contact = await serverApi.confirmContact({ contactId: contact._id, alias: contact.alias }).catch((error: any) => error);

			if (!contact.Error) {
				contactToUpdate = { ...contactToUpdate, ...contact };
				info('apiService::confirmContact: messageBody:', locale.chat.start);
				await xmpp.sendMessage(contactToUpdate.jid, locale.chat.start, 'text', contactToUpdate.lastMessage, undefined, []);
			} else {
				logError('apiService::confirmContact::Error', contact);
			}
		}

		if (!contact.Error) {
			await DbController.put('contacts', contactToUpdate);
			await DbController.put('user', user);
		}

		return contact;
	},

	denyContact: async (contact: any) => {
		contact = await serverApi.denyContact(unprefixPhotos(contact)).catch((error: any) => error);

		if (!contact.Error) {
			contact = await prefixPhotos(contact);
			await DbController.put('contacts', contact);
		} else {
			logError('apiService::denyContact::Error', contact);
		}

		return contact;
	},

	blockContact: async (contact: any) => {
		contact = await serverApi.blockContact(unprefixPhotos(contact)).catch((error: any) => error);

		if (!contact.Error) {
			contact = await prefixPhotos(contact);
			await DbController.put('contacts', contact);
		} else {
			logError('apiService::denyContact::Error', contact);
		}

		return contact;
	},

	unblockContact: async (contact: any) => {
		contact = await serverApi.blockContact(unprefixPhotos(contact)).catch((error: any) => error);

		if (!contact.Error) {
			contact = await prefixPhotos(contact);
			await DbController.put('contacts', contact);
		} else {
			logError('apiService::denyContact::Error', contact);
		}

		return contact;
	},

	updateContact: async (contact: any, updateServer: Boolean = false) => {
		if (updateServer) {
			contact = await serverApi.updateContact(unprefixPhotos(contact)).catch((error: any) => error);

			if (!contact.Error) {
				await DbController.put('contacts', await prefixPhotos(contact));
			} else {
				logError('apiService::updateContact::Error', contact);
			}
		} else {
			await DbController.put('contacts', contact);
		}

		return contact;
	},

	deleteContact: async (contact: any, updateServer: Boolean = false) => {
		let response: Boolean;

		if (updateServer) {
			contact = await serverApi.deleteContact(contact).catch((error: any) => error);

			if (!contact.Error) {
				await DbController.delete('contacts', { deleteBy: '_id', _id: contact._id });
				response = true;
			} else {
				logError('apiService::deleteContact::Error', contact);
				response = false;
			}
		} else {
			await DbController.delete('contacts', { deleteBy: '_id', _id: contact._id });
			response = true;
		}

		return response;
	},

	getGroupByJid: async (jid: any) => (await DbController.where('groups', 'jid', jid))[0],

	getGroups: async (fetchFromServer: Boolean = false) => {
		let response: any;

		if (fetchFromServer) {
			response = await serverApi
				.getGroups()
				.then(async (_response: any) => {
					if (!_response.Error) {
						for (let _group of _response) {
							_group = await prefixPhotos(_group);
						}
					} else {
						logError('apiService::getGroups::Error', _response);
					}
					return _response;
				})
				.catch((error: any) => logError('apiService::getGroups::Error:', error));
		} else {
			response = await DbController.get('groups');
		}

		return response;
	},

	getGroupMembers: async () => {
		let response = await serverApi
			.getGroups()
			.then((response: any) => response)
			.catch((error: any) => error);
		return response;
	},

	addGroups: async (data: any[]) => {
		await DbController.clear('groups');
		return await DbController.bulkPut('groups', data);
	},

	addGroup: async (group: any, updateServer: Boolean = false) => {
		let user = await apiService.me(),
			response: any;

		if (updateServer) {
			response = await serverApi.addGroup(group).then(async (_group: any) => {
				if (!_group.group.Error && !_group.user.Error) {
					info(`apiService::addGroup: group ${_group.group.groupname} created.`);
					delete _group.group.groupPhoto;
					delete _group.group.qrCode;
					await DbController.put('groups', _group.group);
					await DbController.put('user', await prefixPhotos(_group.user));
					_group.group.conversationHash = md5(`${_group.user.jid}_${_group.group.jid}`);
					return await DbController.put('contacts', { ..._group.group, firstMessage: {}, lastMessage: {}, unreadMessages: [], unreadMessageCount: 0, taggedMessages: [], taggedCount: 0, type: 'groupchat', conversationSequence: '-1' });
				} else {
					logError('apiService::addGroup::Error(s)', _group);
					return _group;
				}
			});
		} else {
			let newGroup: any = prefixPhotos(group);
			await DbController.put('groups', newGroup);
			newGroup.conversationHash = md5(`${(await apiService.me()).jid}_${newGroup.jid}`);
			response = await DbController.put('contacts', { newGroup, firstMessage: {}, lastMessage: {}, unreadMessages: [], unreadMessageCount: 0, taggedMessages: [], taggedCount: 0, type: 'groupchat', conversationSequence: '-1' });
		}

		if (!response?.group?.Error) {
			for (let memberIndex in response.members) {
				if (response.members[memberIndex]._id !== user._id && !user.contacts.some((_contact: any) => _contact._id === response.members[memberIndex]._id)) {
					await apiService.addContact({ ...response.members[memberIndex], type: 'groupMember' }, false);
					// should now be added to contacts as groupMember
				}
			}
		}

		return response;
	},

	updateGroup: async (group: any, updateServer: Boolean = false) => {
		let response: any;

		if (updateServer) {
			serverApi
				.updateGroup(unprefixPhotos(group))
				.then(async (_response: any) => {
					if (!_response.Error) {
						_response = await prefixPhotos(_response);
						await DbController.put('groups', await prefixPhotos(_response));
					} else {
						logError('apiService::updateGroup::Error', _response);
					}

					return _response;
				})
				.catch((error: any) => error);
		} else {
			response = await DbController.put('groups', group);
		}

		return response;
	},

	deleteGroup: async (group: any, updateServer: Boolean = false) => {
		let response: Boolean;

		if (updateServer) {
			response = await serverApi.deleteGroup(group).then(async (_response: any) => {
				if (!_response.Error) {
					await DbController.delete('groups', { deleteBy: '_id', _id: group._id });
					_response = true;
				} else {
					logError('apiService::deleteGroup::Error', _response);
					_response = false;
				}

				return _response;
			});
		} else {
			await DbController.delete('groups', { deleteBy: '_id', _id: group._id });
			response = true;
		}

		return response;
	},

	parseMessageXml: async (message: any) => {
		const parser: any = new Parser();

		if (message.xml) {
			message.xml = await parser
				.parseStringPromise(message.xml)
				.then(async (parsed: any) => parsed)
				.catch((err: any) => info(err));
		} else {
			logError(`Messages::parseMessageXml::message`, message);
		}

		return message.xml;
	},
	reformatMessageXml: async (message: any, parsed: any, isGroup: Boolean, conversation: any = {}) => {
		let response: any;

		try {
			let user = await apiService.me(),
				fromEvent: Boolean = parsed.message.hasOwnProperty('event'),
				actualMessage: any = fromEvent ? parsed.message.event[0].items[0].item[0].message[0].$ : parsed.message.$,
				actualFrom: String = actualMessage.from.includes('conference') ? actualMessage.from.split('/')[1] : message.from.split('@')[0],
				fromJid: String = actualMessage.from.split('/')[0],
				isMe: Boolean = (isGroup && actualFrom === user.userId) || (!isGroup && (user.jid === fromJid || user.userId === actualFrom)),
				sender: String = isMe ? 'Me' : isGroup && conversation?.members ? conversation.members.find((member: any) => member.userId === actualFrom).alias : conversation.alias || conversation.username || conversation.userId,
				messageBody: any | undefined = parsed.message?.body && _.isArray(parsed.message.body) ? parsed.message.body[0] : parsed.message?.body ? parsed.message.body : fromEvent ? parsed.message.event[0].items[0].item[0].message[0].body[0] : undefined,
				id: String = parsed.message.hasOwnProperty('origin-id') ? parsed.message['origin-id'][0].$.id : fromEvent ? parsed.message.event[0].items[0].item[0].message[0]['origin-id'][0].$.id : parsed.message.$.id;

			if (messageBody) {
				let bodyIndex: any = messageBody.includes('"body":') ? messageBody.indexOf('"body":') + 8 : undefined,
					endBodyIndex: any = !isBlank(bodyIndex) ? (messageBody.includes('",') ? messageBody.indexOf('",', bodyIndex) : messageBody.indexOf('",', bodyIndex)) : undefined,
					body: any = !isBlank(endBodyIndex) ? messageBody.substring(bodyIndex, endBodyIndex).replace(/"/g, '&quot;') : undefined,
					translationIndex: any = message.isTranslated === 1 ? (messageBody.indexOf('"translation":', endBodyIndex) >= 0 ? messageBody.indexOf('"translation":', endBodyIndex) + 16 : undefined) : undefined,
					endTranslatedIndex =
						message.isTranslated === 1
							? messageBody.indexOf(`','`, translationIndex) >= 0
								? messageBody.indexOf(`','`, translationIndex)
								: messageBody.indexOf(`'}'`, translationIndex) >= 0
								? messageBody.indexOf(`'}`, translationIndex)
								: messageBody.indexOf(`","`, translationIndex) >= 0
								? messageBody.indexOf(`","`, translationIndex)
								: messageBody.indexOf(`"}`, translationIndex) >= 0
								? messageBody.indexOf(`"}`, translationIndex)
								: undefined
							: undefined,
					translation: any = message.isTranslated === 1 ? messageBody.substring(translationIndex, endTranslatedIndex).replace(/"/g, '&quot;') : undefined;

				messageBody = `${messageBody.substr(0, bodyIndex)}${body}",${message.isTranslated === 1 ? `"translation":"${translation}"${messageBody.substr(endTranslatedIndex + 1)}` : `${messageBody.substr(endBodyIndex + 2)}`}`.replace(/\\/g, '');
				messageBody = JSON.parse(messageBody);
			}

			response = _.omitBy(
				{
					...messageFormat,
					id: id,
					to: isGroup ? fromJid : parsed.message.$.to,
					from: fromJid,
					conversationHash: message.conversationHash,
					conversationSequence: messageBody.conversationSequence,
					sender: sender,
					timestamp: message.originalTimestamp,
					language: actualMessage['xml:lang'],
					type: actualMessage.type || messageBody.type,
					read: messageBody.read ? 1 : 0,
					recalled: messageBody.recalled ? 1 : 0,
					deleted: messageBody.deleted ? 1 : 0,
					replaced: messageBody.replaced ? 1 : 0,
					translated: messageBody?.translated ? 1 : 0,
					tagged: messageBody?.tagged ? (messageBody.tagged.findIndex((_tagged: string) => _tagged === user.userId) >= 0 ? 1 : 0) : 0,
					body: messageBody.body,
					translation: messageBody?.translated ? messageBody.translation : '',
					messageType: messageBody.messageType,
					tags: messageBody.tags || [],
					replaces: !isBlank(messageBody.replaces) ? messageBody.replaces : undefined,
					replacedBy: !isBlank(messageBody.replacedBy) ? messageBody.replacedBy : undefined,
					inReplyTo: !isBlank(messageBody.inReplyTo) ? messageBody.inReplyTo : undefined,
					forwardedFrom: !isBlank(messageBody.forwardedFrom) ? messageBody.forwardedFrom : undefined,
					mediaType: messageBody.mediaType,
					mediaUrl: messageBody.mediaUrl && messageBody.mediaUrl.startsWith('/') ? await prefixPhotos(messageBody.mediaUrl) : messageBody.mediaUrl ? messageBody.mediaUrl : undefined,
					mediaThumbnail: messageBody.mediaThumbnail && messageBody.mediaThumbnail.startsWith('/') ? await prefixPhotos(messageBody.mediaThumbnail) : messageBody.mediaThumbnail ? messageBody.mediaThumbnail : undefined,
					linkPreview: messageBody.linkPreview,
					status: messageBody?.status,
					messageKey: messageBody?.messageKey,
				},
				isBlank
			);
		} catch (error) {
			logError('apiService::reformatMessageXml::Error', error);
			logError('apiService::reformatMessageXml::message', message);
			logError('apiService::reformatMessageXml::parsed', parsed);
			throw error;
		}

		return response;
	},

	getServerConversations: async (conversationHashes: any[] = [], onLoad: Boolean = false) => {
		info(`apiService::getServerConversations::requesting: `, conversationHashes);

		const storeMessages = async (conversationHash: any, parsedMessages: any[], conversation: any) => {
				info(`apiService::getServerConversations::storeMessages: adding ${parsedMessages.length} messages for ${conversation.conversationHash} (${conversation.jid})`);

				await apiService.addMessages(parsedMessages);

				if (conversationHash?.remaining > 0) {
					// set end to the batch's first message which is at the end of the array
					conversationHash.end = parseInt(parsedMessages[parsedMessages.length - 1].conversationSequence) - 1;
					conversationHash.start = 0;
					conversationHash.limit = conversationHash.remaining;
					info(`apiService::getServerConversations::storeMessages: Will request ${conversationHash.remaining} items of additional history for ${conversationHash.conversationHash} from ${conversationHash.start} until ${conversationHash.end}`);
					delete conversationHash.remaining;
				} else {
					conversationHash = {};
				}

				return conversationHash;
			};

		let user = await apiService.me(),
			localConversations = await apiService.getConversations(),
			serverConversations: any = await serverApi.getMessages({ conversationHashes: conversationHashes }).catch((error: any) => {
				logError(`apiService::getServerConversations::error:`, error);
			}),
			response: any = undefined;

		if (!serverConversations.Error) {
			try {
				for (let serverConversation of serverConversations) {
					const conversationHashIndex: number = conversationHashes.findIndex((_conversationHash: any) => _conversationHash?.conversationHash === serverConversation.conversationHash),
						conversationHash: any = conversationHashIndex >= 0 ? conversationHashes[conversationHashIndex] : undefined,
						isNotepad: Boolean = !isBlank(conversationHash) && user.personalNotepad === serverConversation.conversationHash,
						localConversation: any = !isBlank(conversationHash) ? (isNotepad ? user : localConversations.find((_conversation: any) => _conversation.conversationHash === conversationHash.conversationHash)) : undefined,
						isGroup: Boolean = !isBlank(localConversation) && !isNotepad && localConversation.type === 'groupchat',
						parsedMessages: any[] = [];

					if (serverConversation.messages.length > 0) {
						// are messages in sequence?  We expect descending order
						if (parseInt(serverConversation.messages[serverConversation.messages.length - 1].conversationSequence) > parseInt(serverConversation.messages[0].conversationSequence)) {
							info('apiService::getServerConversations: message sequence needed to be reversed.');
							serverConversation.messages.reverse();
						}

						// sequence of serverConversation.messages are in descending order

						for (let message of serverConversation.messages) {
							parsedMessages.push(await apiService.reformatMessageXml(message, await apiService.parseMessageXml(message), isGroup, localConversation));
						}

						// sequence of parsedMessages is in descending order

						if (parsedMessages.length > 0) {
							localConversation.firstMessage = parsedMessages[parsedMessages.length - 1];
							await apiService.setFirstMessage(conversationHash.conversationHash, localConversation.firstMessage);

							// set the last message if we are doing the initial load
							if (onLoad) {
								localConversation.lastMessage = parsedMessages[0];
								await apiService.setLastMessage(conversationHash.conversationHash, localConversation.lastMessage);
							}

							conversationHashes[conversationHashIndex] = await storeMessages({ ...conversationHash, remaining: serverConversation.remaining }, parsedMessages, localConversation);

							if (!isNotepad) {
								localConversation.unreadMessages = (await apiService.getUnreadMessages(localConversation.conversationHash)).map((_message: any) => _message.id);
								localConversation.unreadCount = localConversation.unreadMessages.length;

								if (isGroup) {
									localConversation.taggedMessages = (await apiService.getMemberTaggedMessages(localConversation.conversationHash)).map((_message: any) => _message.id);
									localConversation.taggedCount = localConversation.taggedMessages.length;
								}
							}
						}
					} else {
						conversationHashes[conversationHashIndex] = {};

						if (!isNotepad) {
							localConversation.unreadMessages = [];
							localConversation.unreadCount = 0;
						}
					}

					apiService.updateConversation(localConversation, parsedMessages);
				}

				response = _.compact(conversationHashes.map((conversationHash: any) => (!isBlank(conversationHash) ? conversationHash : false)));
			} catch (error) {
				logError('apiService::getServerConversations::Error:', error);
				logError('last known serverMessages: ', JSON.stringify(serverConversations));
				response = _.compact(conversationHashes.map((conversationHash: any) => (conversationHash.limit && conversationHash.limit > 0 ? conversationHash : false)));
			}
		} else {
			logError('apiService::getServerConversations::Server Error:', serverConversations);
			response = conversationHashes;
		}

		info('apiService::getServerConversations: done');
		return response;
	},

	refreshMessages: async (onLoad: Boolean = false, conversations: any[] = [], conversationHashes: any[] = [], history: any = undefined, fromLatest: Boolean = false) => {
		let cookies: any = getInternalStorage();

		if (cookies.uuid) {
			info('apiService::refreshMessages. fromLatest is', fromLatest);

			if (conversations.length === 0) {
				conversations = await apiService.getConversations();
			}

			if (conversationHashes.length === 0) {
				conversationHashes = _.compact(
					conversations.map((_conversation: any) => {
						let conversationHash: any = false;

						if (_conversation.status === 'confirmed') {
							if (isBlank(_conversation.lastMessage) && isBlank(_conversation.firstMessage)) {
								conversationHash = {
									conversationHash: _conversation.conversationHash,
									start: 0,
									end: 100,
									limit: 100,
								};
							} else {
								if (fromLatest) {
									conversationHash = {
										conversationHash: _conversation.conversationHash,
										start: parseInt(_conversation.lastMessage.conversationSequence) + 1,
									};
								} else if (parseInt(_conversation.firstMessage.conversationSequence) !== 0) {
									conversationHash = {
										conversationHash: _conversation.conversationHash,
										start: 0,
										end: parseInt(_conversation.lastMessage.conversationSequence) - 2,
									};
								} else {
									conversationHash = false;
								}
							}
						} else {
							conversationHash = false;
						}

						return conversationHash;
					})
				);
			}

			if (conversationHashes.length > 0) {
				if (!onLoad) {
					store.dispatch({ type: DASHBOARD_SHOW_LOADER, payload: { loader: true, loaderMessage: locale.reducers.chat.init_state.syncing_messages } });
				}

				while (conversationHashes && conversationHashes.length > 0) {
					let request = conversationHashes;

					conversationHashes = await apiService.getServerConversations(conversationHashes, onLoad).catch(async (error: any) => {
						logError(`apiService::refreshMessages::error: ${error}`);
						await sleep(1000);
						return request;
					});

					if (onLoad) {
						conversations = await apiService.getConversations();
						store.dispatch({ type: DASHBOARD_INIT, payload: { conversations: conversations, initialized: true } });
						history.push('/auth');
						break;
					}
				}

				if (!onLoad) {
					store.dispatch({ type: DASHBOARD_HIDE_LOADER });
					xmpp.setMessagesLoaded();
				}
			} else if (!onLoad) {
				xmpp.setMessagesLoaded();
			}
		}
	},

	getConversations: async (conversationHash: any = undefined) => {
		let user = await apiService.me(),
			response: any;

		if (conversationHash) {
			if (conversationHash === user.conversationHash) {
				response = user;
			} else {
				response = (await DbController.where('contacts', 'conversationHash', conversationHash))[0];
			}
		} else {
			response = await DbController.get('contacts');
			response.push(user);
		}

		return response;
	},

	getConversationByHash: async (conversationHash: any) => {
		let response: any,
			user: any = await apiService.me();

		if (conversationHash === user.conversationHash) {
			response = user;
		} else {
			response = (await DbController.where('contacts', 'conversationHash', conversationHash))[0];

			if (isBlank(response)) {
				response = (await DbController.where('groups', 'conversationHash', conversationHash))[0];
			}
		}

		return response;
	},

	getConversationSequence: async (conversationHash: string) => await serverApi.getSequence({ conversationHash: conversationHash }).catch((error: any) => error),

	getConversationHistoryByHash: async (conversationHash: any, reverse: Boolean = false) => await DbController.where('messages', 'conversationHash', conversationHash, 'timestamp', reverse),

	updateConversation: async (conversation: any, history: any = undefined) => {
		let response: any,
			user: any = await apiService.me();

		if (user.jid === conversation.jid) {
			response = await apiService.updateUser({ ...(await apiService.me()), lastMessage: conversation.lastMessage });
		} else {
			if (conversation.type === 'groupchat') {
				await apiService.updateGroup(conversation);
			}

			response = await apiService.updateContact(conversation);
		}

		if (isConversationOpen() && _.includes([conversation.userId, conversation.room], getActiveConversation(user))) {
			store.dispatch({ type: CHAT_UPDATE_CONVERSATION, payload: { history: history ? history : await apiService.getConversationHistoryByHash(conversation.conversationHash), receiver: conversation, isUpdate: isBlank(history) } });
		}

		return response;
	},

	updateDashboard: async (incoming: any = {}) => {
		async function dispatchAndUpdateConversation(conversation: any) {
			conversation.unreadMessages = (await apiService.getUnreadMessages(conversation.conversationHash)).map((_message: any) => _message.id);
			conversation.unreadCount = conversation.unreadMessages.length;

			if (conversation.type === 'groupchat') {
				conversation.taggedMessages = (await apiService.getMemberTaggedMessages(conversation.conversationHash)).map((_message: any) => _message.id);
				conversation.taggedCount = conversation.taggedMessages.length;
			}

			await apiService.updateConversation(conversation);
			store.dispatch({ type: DASHBOARD_LAST_MESSAGE, payload: conversation });
			return conversation;
		}

		let response: any;

		info(`apiService::updateDashboard: called with ${incoming.hasOwnProperty('conversation') ? 'conversation' : 'message'}`);

		if (incoming.hasOwnProperty('conversation')) {
			response = await dispatchAndUpdateConversation(incoming.conversation);
		} else {
			response = await apiService.getConversationByHash(incoming.message.conversationHash).then(async (conversation: any) => {
				if (conversation) {
					conversation.lastMessage = incoming.message;

					if (isBlank(conversation.firstMessage)) {
						conversation.firstMessage = incoming.message;
					}

					return await dispatchAndUpdateConversation(conversation);
				} else {
					logError(`apiService::updateDashboard: Could not find conversation for conversationHash in:`, incoming);
					return {};
				}
			});
		}

		return response;
	},

	getMessage: async (data: any) => {
		let search: string = Object.keys(data)[0],
			message = await DbController.where('messages', search, data[search]);
		return message ? message[0] : undefined;
	},

	getMessagesByJid: async (jid: any) => await DbController.where('messages', 'from', jid, 'timestamp'),

	getMessagesByHash: async (conversationHash: any) => await DbController.where('messages', 'conversationHash', conversationHash, 'timestamp'),

	getMessages: async (from: string) => await DbController.where('messages', 'from', from, 'timestamp'),

	getMessagesByIds: async (ids: any[]) => {
		let range: any[] = [];
		for (let _id of ids) {
			range.push([_id, _id]);
		}
		return await DbController.whereIn('messages', 'id', range);
	},

	getLastMessage: async (conversationHash: string) => {
		let message: any = await DbController.where('messages', 'conversationHash', conversationHash, 'timestamp', true);

		return message && message.length > 0 ? message[0] : {};
	},

	setLastMessage: async (conversationHash: string, lastMessage: any) => {
		let response: any,
			user = await apiService.me();

		if (conversationHash === user.conversationHash) {
			response = await DbController.put('user', { ...user, lastMessage: lastMessage });
		} else {
			response = await DbController.putWhere('contacts', 'conversationHash', conversationHash, { lastMessage: lastMessage });
		}

		return response;
	},

	getFirstMessage: async (conversationHash: string) => {
		let message: any = await DbController.where('messages', 'conversationHash', conversationHash, 'timestamp');

		return message && message.length > 0 ? message[0] : {};
	},

	setFirstMessage: async (conversationHash: string, firstMessage: any) => {
		let response: any,
			user = await apiService.me();

		if (conversationHash === user.conversationHash) {
			response = await DbController.put('user', { ...user, firstMessage: firstMessage });
		} else {
			response = await DbController.putWhere('contacts', 'conversationHash', conversationHash, { firstMessage: firstMessage });
		}

		return response;
	},

	getUnreadMessages: async (conversationHash: String) => await DbController.whereObject('messages', { conversationHash: conversationHash, read: 0 }),

	getMemberTaggedMessages: async (conversationHash: String) => await DbController.whereObject('messages', { conversationHash: conversationHash, tagged: 1 }),

	updateMessage: async (data: any, updateServer: Boolean = false) => {
		let response: any,
			user: any = await apiService.me(),
			updatedMessage: any,
			isRead: Boolean = !data.fromControl ? data.read === 1 : data.isRead,
			isRecalled: Boolean = !data.fromControl ? data.recalled === 1 : data.isRecalled,
			isReplaced: Boolean = !data.fromControl ? data.replaced === 1 : data.isReplaced,
			isTagged: Boolean = !data.fromControl ? data.tagged === 1 : data.isTagged,
			isTranslated: Boolean = !data.fromControl ? data.translated === 1 : data.isTranslated,
			isDeleted: Boolean = !data.fromControl ? data.deleted === 1 : data.isDeleted;

		if (updateServer) {
			// if the message is internal from xmpp, data.isX will be populated
			// otherwise if it comes from a control message, data.X will be populated
			updatedMessage = await serverApi
				.updateMessage(
					_.omitBy(
						{
							message: data,
							isRead: isRead,
							isRecalled: isRecalled,
							isReplaced: isReplaced,
							isTranslated: isTranslated,
							translation: isTranslated ? data.translation : '',
							isTagged: isTagged,
							isDeleted: isDeleted,
						},
						isBlank
					)
				)
				.then(async (updatedMessage: any) => updatedMessage)
				.catch((error: any) => error);
		} else {
			updatedMessage = data;
		}

		let conversation = await apiService.getConversations(updatedMessage.conversationHash),
			parsed = !isBlank(conversation) && (await apiService.parseMessageXml(updatedMessage));

		if (!isBlank(conversation)) {
			if (!isDeleted) {
				updatedMessage = await apiService.reformatMessageXml(updatedMessage, parsed, updatedMessage.type === 'groupchat', conversation);
				await apiService.saveMessage(updatedMessage);
			} else {
				await DbController.delete('messages', { deleteBy: 'id', id: data.id });
			}

			if (conversation?.lastMessage.id === updatedMessage.id) {
				await apiService.updateDashboard({ conversation: conversation });
			} else {
				await apiService.updateConversation(conversation);
			}

			if (isConversationOpen() && _.includes([updatedMessage.to.split('@')[0], updatedMessage.from.split('@')[0]], getActiveConversation(user))) {
				store.dispatch({ type: CHAT_UPDATE_HISTORY, payload: updatedMessage, receiver: conversation });
			}
		}

		info('serverApi::updateMessage::reponse: ', response);
		return response;
	},

	updateReadStatusQueue: [] as [],
	updateReadStatusProcessing: false as Boolean,

	updateReadStatus: async (data: any) => {
		if(data.ids || data.conversation) {
			apiService.updateReadStatusProcessing = true;
			info(`apiService::updateReadStatus::data:`, data);

			let serverResponse: any = {};

			if (data.ids && data.ids.length > 0) {
				info(`apiService::updateReadStatus::updating server`);
				serverResponse = await serverApi.updateReadStatus({ ids: data.ids }).catch((error: any) => error);
				
			} else if (data?.conversation?.conversationHash) {
				info(`apiService::updateReadStatus::updating server`);
				serverResponse = await serverApi.updateReadStatus({ conversationHash: data.conversation.conversationHash }).catch((error: any) => error);
			}

			if (serverResponse.Error) {
				logError(`apiService::updateReadStatus: Error:`, serverResponse.Error);
			} else {
				info(`apiService::updateReadStatus: serverResponse:`, serverResponse);
			}

			apiService.updateReadStatusProcessing = false;
		}
	},

	handleUpdateReadStatus: async (data: any) => {
		let nowReadMessages: any = [],
			user = await apiService.me();

		for (let messageId of data.ids) {
			info(`apiService::handleUpdateReadStatus: fetching messageId:`, messageId);
			let existingMessage = await apiService.getMessage({ id: messageId });

			if (!isBlank(existingMessage)) {
				nowReadMessages.push({ ...existingMessage, read: 1, status: existingMessage.status !== Constants.MESSAGE_STATUS.PendingUpload ? Constants.MESSAGE_STATUS.Sent : existingMessage.status });
			} else {
				logError('apiService::handleUpdateReadStatus: Could not find existing message in queue or specified messageId', messageId);
			}
		}

		if (nowReadMessages.length > 0) {
			// update the local database for all messages in this response
			await apiService.addMessages(nowReadMessages);
			
			let conversation: any = {};

			while (nowReadMessages.length > 0) {
				let message = nowReadMessages.pop();

				if (conversation?.conversationHash !== message.conversationHash) {
					conversation = await apiService.getConversations(message.conversationHash);
				}

				// this will not exist for personal-notepad, and should remain so
				if (conversation?.unreadMessages) {
					conversation.unreadMessages.splice(
						conversation.unreadMessages.findIndex((_message: any) => _message.id === message.id),
						1
					);

					conversation.unreadCount--;

					if (conversation.taggedCount && conversation.taggedCount > 0) {
						conversation.taggedMessages.splice(
							conversation.taggedMessages.findIndex((_message: any) => _message.id === message.id),
							1
						);
						conversation.taggedCount--;
					}

					await apiService.updateConversation(conversation);

					// check if conversation is open
					if (isConversationOpen() && _.includes([conversation.userId, conversation.room], getActiveConversation(user))) {
						store.dispatch({ type: CHAT_UPDATE_CONVERSATION, payload: { receiver: conversation, history: await apiService.getConversationHistoryByHash(conversation.conversationHash) } });
					}
				}
			}

			info(`apiService::updateReadStatus: ${data.ids.length} status changed to read.`);
		}
	},

	uploadMedia: async (data: any) => await serverApi.uploadMedia(data),

	getUnsentMessage: async (data: any) => {
		let unsent: any = await DbController.whereObject('data', { ...data, type: 'unsent' });
		return unsent;
	},

	saveUnsentMessage: async (data: any) => await DbController.put('data', { ...data, type: 'unsent' }),

	deleteUnsentMessage: async (data: any, message: any = undefined) => {
		await DbController.delete('data', data);

		if (message) {
			await apiService.deleteMessage({ ...message, deleted: 1 }, false);
		}
	},

	updateMedia: async (data: any) => {
		for (let messageIndex in data.ids) {
			try {
				let message: any = await apiService.getMessage({ id: data.ids[messageIndex] }),
					conversationHash: string = message ? message.conversationHash : undefined,
					conversation: any = conversationHash ? await apiService.getConversations(conversationHash) : undefined;

				if (conversation) {
					message.mediaType = data.media[messageIndex].mediaType;
					message.mediaUrl = await prefixPhotos(data.media[messageIndex].mediaUrl);
					message.read = 1;
					message.status = 'Sent';
					await apiService.saveMessage(message);
					apiService.updateDashboard({ conversation: conversation });

					// remove the storedMedia from the database data table as it is now confirmed to have been
					// uploaded and stored
					await apiService.deleteUnsentMessage({ deleteBy: 'id', id: message.messageKey });
				}
			} catch (error) {
				logError('apiService::updateMedia', error, data);
			}
		}
	},

	cancelUpload: async (controller: any) => {
		controller.abort();
	},

	saveMessage: async (message: any) => await DbController.put('messages', message),

	replaceMessage: async (message: any) => {
		await DbController.delete('messages', { deleteBy: 'id', id: message.messageKey });
		return await DbController.put('messages', message);
	},

	addMessages: async (messages: any) => await DbController.bulkPut('messages', messages),

	recallMessage: async (message: any, fromControl: Boolean = false) => {
		let response: any,
			serverResponse: any,
			conversation: any = await apiService.getConversations(message.conversationHash);

		if (conversation) {
			// recall due to user initiation (updateServer is true)
			// update server ... discard response, as changed message will be received back as a control message
			serverResponse = await apiService.updateMessage(message, true, 'recallMessage');
			info(`apiService::recallMessage: serverResponse:`, serverResponse);
		} else {
			logError(`apiService::recallMessage: Attempt to recall a message from a non-existent conversation`, message);
		}

		return response;
	},

	deleteMessage: async (message: any, updateServer: Boolean) => await apiService.updateMessage(message, updateServer),

	translateMessage: async (message: any) =>
		await serverApi
			.translateMessage({
				message: {
					...message,
					translated: true,
				},
				isTranslated: true,
			})
			.then(async (translation: any) => {
				await apiService.updateDashboard({ message: translation });
				return translation;
			})
			.catch((error: any) => error),

	fetchLinkPreview: async (url: any) =>
		await serverApi.fetchLinkPreview(url).then(async (linkPreview: any) => {
			return linkPreview;
		}),

	sendStatus: async (message: any) => await serverApi.sendStatus(message).catch((error: any) => error),

	ackControl: async (data: any) => await serverApi.ackControl(data).catch((error: any) => error),
};
